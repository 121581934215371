import React, { useState, useEffect, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Container } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import NavMenu from './NavMenu';
import AppContext from '../AppContext';
import { getContentWithAction } from '../apiConnect';
import Button from 'react-bootstrap/Button';
import { changePlantStyle } from './ComponentStyles/ButtonStyles.js'

import SowAccordionCard from './PlotOperationAccordionCards/SowAccordionCard';
import MemoAccordionCard from './PlotOperationAccordionCards/MemoAccordionCard';
import FertilizeAccordionCard from './PlotOperationAccordionCards/FertilizeAccordionCard';
import PlotModificationAccordionCard from './PlotOperationAccordionCards/PlotModificationAccordionCard';
import PlantProtectionAccordionCard from './PlotOperationAccordionCards/PlantProtectionAccordionCard';
import HarvestAccordionCard from './PlotOperationAccordionCards/HarvestAccordionCard';
import SowModal from './Modals/SowModal';
import FertilizeModal from './Modals/FertilizeModal';
import PlotModificationModal from './Modals/PlotTillageModal';
import PlantProtectionModal from './Modals/PlantProtectionModal';
import HarvestModal from './Modals/HarvestModal';
import NutrientModal from './Modals/NutrientModal';
import MemoModal from './Modals/MemoModal';
import ChangePlantModal from './Modals/ChangePlantModal';
import { getSelectedPlot, getSelectedYear, setSelectedPage, setSelectedPlot } from '../common';
import '../css/general.css'

const PlotOperation = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [selectSowEvent, setSelectSowEvent] = useState({ time: null, selectedElement: null });
    const [sowModalShow, setSowModalShow] = useState(false);
    const [refreshSows, setRefreshSows] = useState(null);

    const [selectFertilizingEvent, setSelectFertilizingEvent] = useState(0)
    const [fertilizeModalShow, setFertilizeModalShow] = useState(false);
    const [refreshFertilizes, setRefreshFertilizes] = useState(null);

    const [selectModificationEvent, setSelectModificationEvent] = useState({ time: null, selectedElement: null });
    const [plotModificationModalShow, setPlotModificationModalShow] = useState(false);
    const [refreshPlotModifications, setRefreshPlotModifications] = useState(null);

    const [selectPlantProtectionEvent, setSelectPlantProtectionEvent] = useState({ time: null, selectedElement: null });
    const [plantProtectionModalShow, setPlantProtectionModalShow] = useState(false);
    const [refreshPlantProtections, setRefreshPlantProtections] = useState(0);

    const [selectHarvestEvent, setSelectHarvestEvent] = useState({time: null, selectedElement: null});
    const [harvestModalShow, setHarvestModalShow] = useState(false);
    const [refreshHarvests, setRefreshHarvests] = useState(null);

    const [selectMemoEvent, setSelectMemoEvent] = useState({ time: null, selectedElement: null });
    const [memoModalShow, setMemoModalShow] = useState(false);
    const [refreshMemos, setRefreshMemos] = useState(null);
    const [memoType, setMemoType] = useState(0);
    const [memos, setMemos] = useState([]);
    const [buttonWriteShow, setButtonWriteShow] = useState(false); // allowed save btn disabled
    const [memosIsLoading, setMemosIsLoading] = useState(true);
    const [plantExists, setPlantExists] = useState(false);

    const [changePlantModalShow, setChangePlantModalShow] = useState(false);
    const [nutrientModalShow, setNutrientModalShow] = useState(false);

    const [activeCard, setActiveCard] = useState(1);

    const [p, setP] = useState(getSelectedPlot());

    const refreshProtectionFertilizer = (data) => {
        setRefreshPlantProtections(data);
        setRefreshFertilizes(data);
    }

    setSelectedPage(2);

    useEffect(() => {
        getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}).then((result) => {
            //if (!result || !result.length)
            //    props.history.push('/');

            setButtonWriteShow(result);
        });
    }, []);

    useEffect(() => {
        if (p) {
            if (p.plantId && p.plantId !== "" && p.plantName !== "") {
                setPlantExists(true);
            }
            setMemosIsLoading(true);
            getContentWithAction(auth.getAccessToken(), 'memo', 'memos', { plotId: getSelectedPlot().apNumber }).then((memos) => {
                if (!memos) {
                    setMemosIsLoading(false);
                    return;
                }
                setMemosIsLoading(false);
                setMemos(memos);
            });
        }
        else
            props.history.push('/');

    }, [refreshMemos]);

    const movePlots = () => {
        props.history.push('/plots');
    }

    const refreshPlantChange = () => {
        setChangePlantModalShow(false);

        getContentWithAction(auth.getAccessToken(), 'farmPlot', 'getPlot', { plotId: getSelectedPlot().apNumber }).then((plot) => {
            setP(plot);
            setSelectedPlot(plot);
            if (plot.plantId && plot.plantId !== "" && plot.plantName !== "") {
                setPlantExists(true);
            }
        });

        setRefreshSows(Date.now());
    }

    document.title = t('Action') + ' | Mobiwisu';

    return (
        <Container>
            <NavMenu {...props} getPlots={movePlots} />
            <Card>
            <Card.Body>
                    <div className="text-dark farm-info-header">
                        {buttonWriteShow && plantExists ?
                            <Button variant='success' style={changePlantStyle} className={"float-right"}
                                onClick={() => {
                                    setChangePlantModalShow(true);
                                }}>{t('Change_Plant')}</Button>
                            : ''}
                        <span className="farm-year-span"><h6>{getSelectedYear()}</h6></span>

                        {p !== null ?
                            <h5>{p.name} - {p.apChar}</h5>
                            : ''}
                        <br />
                        {p !== null ?
                            <h5 className='plot-plant'>{p.area}ha ({p.plantName})</h5>
                            : ''}
                    
                </div>
                </Card.Body>
            </Card>
            {/* Sections */}
            <Accordion defaultActiveKey="0">
                <SowAccordionCard
                    setMemoType={setMemoType}
                    setRefresh={setRefreshSows}
                    refresh={refreshSows}
                    refreshMemos={refreshMemos}
                    setModalShow={setSowModalShow}
                    setSelectEvent={setSelectSowEvent}
                    setMemoModalShow={setMemoModalShow}
                    setSelectMemoEvent={setSelectMemoEvent}
                    allowedSave={buttonWriteShow}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    memos={memos}
                    memosIsLoading={memosIsLoading}
                />
                <FertilizeAccordionCard
                    setMemoType={setMemoType}
                    setRefresh={refreshProtectionFertilizer}
                    refresh={refreshFertilizes}
                    refreshMemos={refreshMemos}
                    setModalShow={setFertilizeModalShow}
                    setSelectEvent={setSelectFertilizingEvent}
                    setMemoModalShow={setMemoModalShow}
                    setSelectMemoEvent={setSelectMemoEvent}
                    allowedSave={buttonWriteShow}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    memos={memos}
                    memosIsLoading={memosIsLoading}
                    setNutrientModalShow={setNutrientModalShow}
                />
                <PlotModificationAccordionCard
                    setMemoType={setMemoType}
                    setRefresh={setRefreshPlotModifications}
                    refresh={refreshPlotModifications}
                    refreshMemos={refreshMemos}
                    setModalShow={setPlotModificationModalShow}
                    setSelectEvent={setSelectModificationEvent}
                    setMemoModalShow={setMemoModalShow}
                    setSelectMemoEvent={setSelectMemoEvent}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    memos={memos}
                    memosIsLoading={memosIsLoading}
                />
                <PlantProtectionAccordionCard
                    setMemoType={setMemoType}
                    setRefresh={refreshProtectionFertilizer}
                    refresh={refreshPlantProtections}
                    refreshMemos={refreshMemos}
                    setModalShow={setPlantProtectionModalShow}
                    setSelectEvent={setSelectPlantProtectionEvent}
                    setMemoModalShow={setMemoModalShow}
                    setSelectMemoEvent={setSelectMemoEvent}
                    allowedSave={buttonWriteShow}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    memos={memos}
                    memosIsLoading={memosIsLoading}
                />
                <HarvestAccordionCard
                    setMemoType={setMemoType}
                    setRefresh={setRefreshHarvests}
                    refresh={refreshHarvests}
                    refreshMemos={refreshMemos}
                    setModalShow={setHarvestModalShow}
                    setMemoModalShow={setMemoModalShow}
                    setSelectMemoEvent={setSelectMemoEvent}
                    setSelectEvent={setSelectHarvestEvent}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    memos={memos}
                    memosIsLoading={memosIsLoading}
                />
                <MemoAccordionCard
                    setRefresh={setRefreshMemos}
                    refresh={refreshMemos}
                    setModalShow={setMemoModalShow}
                    setSelectMemoEvent={setSelectMemoEvent}
                    setMemoType={setMemoType}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    memos={memos}
                    memosIsLoading={memosIsLoading}
                />
            </Accordion>

            {/* Modals */}
            {sowModalShow === true ?
                <SowModal
                    show={sowModalShow}
                    setRefresh={setRefreshSows}
                    onHide={() => setSowModalShow(false)}
                    selectEvent={selectSowEvent}
                />
                : ''}
            {fertilizeModalShow === true ?
                <FertilizeModal
                    show={fertilizeModalShow}
                    setRefresh={refreshProtectionFertilizer}
                    onHide={() => setFertilizeModalShow(false)}
                    selectEvent={selectFertilizingEvent}
                />
                : ''}
            {nutrientModalShow === true ?
                <NutrientModal
                    selectedPlot={p}
                    show={nutrientModalShow}
                    onHide={() => setNutrientModalShow(false)}
                    selectEvent={selectFertilizingEvent}
                />
                : ''}
            {plotModificationModalShow === true ?
                <PlotModificationModal
                    show={plotModificationModalShow}
                    setRefresh={setRefreshPlotModifications}
                    onHide={() => setPlotModificationModalShow(false)}
                    selectEvent={selectModificationEvent}
                />
                : ''}
            {plantProtectionModalShow === true ?
                <PlantProtectionModal
                    show={plantProtectionModalShow}
                    setRefresh={refreshProtectionFertilizer}
                    onHide={() => setPlantProtectionModalShow(false)}
                    selectEvent={selectPlantProtectionEvent}
                />
                : ''}
            {harvestModalShow === true ?
                <HarvestModal
                    show={harvestModalShow}
                    onHide={() => setHarvestModalShow(false)}
                    setRefresh={setRefreshHarvests}
                    selectEvent={selectHarvestEvent}
                />
                : ''}
            {changePlantModalShow === true ?
                <ChangePlantModal
                    show={changePlantModalShow}
                    onHide={() => setChangePlantModalShow(false)}
                    setRefresh={() => { refreshPlantChange(); }}
                    selectEvent={{ time: Date.now(), selectedElement: null }}
                    selectedPlot={p}
                />
                : ''}
            <MemoModal
                show={memoModalShow}
                memotype={memoType}
                onHide={() => setMemoModalShow(false)}
                setRefresh={setRefreshMemos}
                baseplotid={p !== null ? p.number: 0}
                selectEvent={selectMemoEvent}
            />
        </Container>
    );
}

export default PlotOperation;