import { GeoJSON } from 'ol/format';
import * as proj from 'ol/proj';

export const retrieveWgsCoordinates = (coor3857) => {
    let wgsCoord = proj.transform(coor3857, 'EPSG:3857', 'EPSG:4326');

    return {
        latitude: wgsCoord && wgsCoord.length > 1 ? returnRoundedValue(wgsCoord[1], 3) : 0,
        longitude: wgsCoord && wgsCoord.length > 0 ? returnRoundedValue(wgsCoord[0], 3) : 0
    };
}

export const returnRoundedValue = function (dataValue, decimals) {
    decimals = decimals || 1;
    const decim = decimals === 1 ? 10 : decimals === 2 ? 100 : decimals === 3 ? 1000 : 10;
    return Math.round(dataValue * decim) / decim;
};

export const featuresReader = (data) => {
    var readFeatures = new GeoJSON().readFeatures(data.featureCollection, {
        dataProjection: data.crs,
        featureProjection: 'EPSG:3857'//Config.CRS.WEBMERCATOR
    });
    return readFeatures;
}