import React, { useState } from 'react';

import StopSelectFrom from './StopSelectForm';
import EndFinishedTask from './EndFinishedTask';
import Summary from './Summary';

import { taskViewer_taskStatuses, taskViewer_stopComponents } from './../../constants';

const Stop = (props) => {
    const [stopComponent, setStopComponent] = useState(taskViewer_stopComponents.stopSelectionForm);

    const stopComponentSetter = (component) => {
        setStopComponent(component);
    }

    const StopComponentSelector = () => {
        switch (stopComponent) {
            case (taskViewer_stopComponents.stopSelectionForm):
                return <StopSelectFrom runStateCallBack={props.callBack} stopComponentCallBack={stopComponentSetter} />;
            case (taskViewer_stopComponents.endTaskFinished):
                return <Summary callback={stopComponentSetter} taskId={props.taskSpecificData.taskDataId} />
            case (taskViewer_stopComponents.endTaskMarkDone):
                return <EndFinishedTask returnCallback={stopComponentSetter} taskSpecificData={props.taskSpecificData} />
            case (taskViewer_stopComponents.showDataForFinishedTask):
                return <Summary callback={stopComponentSetter} taskId={props.taskSpecificData.taskDataId} />
            case (taskViewer_stopComponents.endUnfinishedContinueLater):
                props.callBack(taskViewer_taskStatuses.endUnfinished);
                break;
            case (taskViewer_stopComponents.finish):
                props.callBack(taskViewer_taskStatuses.ended);
                break;
            default:
        }
    }

    return (
        <>
            {StopComponentSelector()}
        </>
    );
}

export default Stop;