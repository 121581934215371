import React from 'react';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';

import { taskViewer_viewComponents, taskViewer_taskStatuses } from './../../constants';

const TaskTypeSelectionListItem = (props) => {

    const allCompleted = (operations) => {
       return  operations.every((operation) => {
           return operation.taskStatus === taskViewer_taskStatuses.completed
        })
    }

    const somePaused = (operations) => {
        return operations.some((operation) => {
            return operation.taskStatus === taskViewer_taskStatuses.paused || operation.taskStatus === taskViewer_taskStatuses.running || operation.taskStatus === taskViewer_taskStatuses.endUnfinished
        })
    }

    const resolveBadgeColor = (operations) => {

        if (operations.length === 0 || allCompleted(operations)) return "secondary";
        else if (somePaused(operations)) return "warning";
        else return "success"
            
    }

    return (
        <ListGroup.Item
            onClick={() => props.callBack({ viewComponent: taskViewer_viewComponents.tasks, data: { taskType: props.taskType, dataArray: props.taskList } })}
            disabled={(props.taskList.length === 0 || allCompleted(props.taskList)) ? "disabled" : ""}
        >
            {props.name}
            {(props.isLoading) ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
            <Badge className="float-right" pill variant={resolveBadgeColor(props.taskList)} >{props.taskList.length}</Badge>
        </ListGroup.Item>
        )
}

export default TaskTypeSelectionListItem;