import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Farm from './components/Farm';
import TaskViewer from './components/TaskViewer/TaskViewer';
import FarmPlot from './components/FarmPlot';
import Map from './components/MapViewer';
import PlotOperation from './components/PlotOperation';
import Auth from './Auth/Auth';
import Callback from './Callback';
import i18n from './i18n';
import AppContext from './AppContext';
import { getContent } from './api';
import { getSelectedFarmId, getSelectedLang } from './common';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/Alerts/AlertTemplate'

const App = (props) => {
    const [geolocation, setGeolocation] = useState(null);
    const [mapInitCoordinates, setMapInitCoordinates] = useState({ coords: { longitude: 0, latitude: 0 } });
    const [selectedLanguage] = useState(getSelectedLang());
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        i18n.changeLanguage(getSelectedLang());
        getContent('auth', {}).then((settings) => {
            setAuth(new Auth(props.history, settings));
        }).catch(c => { console.log(c); auth.login(); });

    }, [props.history]);

    const alertOptions = {
        position: positions.BOTTOM_CENTER,
        timeout: 7000,
        offset: '30px',
        /* zIndex: 1060, This doesn't work for some reason */
        transition: transitions.FADE
    }

    const context = {
        geolocation: geolocation,
        setGeolocation: setGeolocation,
        setMapInitCoordinates: setMapInitCoordinates,
        mapInitCoordinates: mapInitCoordinates,
        selectedLanguage: selectedLanguage,
        auth: auth
    }

    const spinnerStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        /* transform: translate(-50%, -50%); */
        zIndex: 1
    }

    return (
        <>
            <AlertProvider className="alert-index" template={AlertTemplate} {...alertOptions}>
                {auth === null ?

                    <div style={spinnerStyle}>
                        <Spinner as="span" animation="border" size="md" role="status" variant="secondary">
                        </Spinner> <span className="text-dark">Loading...</span>
                    </div>

                    :
                    <AppContext.Provider value={context}>
                        <Route path='/' exact render={(props) => auth.isAuthenticated() ? (<Farm {...props} />) : (auth.login())} />
                        <Route path='/taskViewer' render={(props) => auth.isAuthenticated() ? (getSelectedFarmId() === 0 ? <Redirect to='/' /> :    <TaskViewer {...props} />) : (auth.login())} />
                        <Route path='/mapViewer' render={(props) => auth.isAuthenticated() ? (getSelectedFarmId() === 0 ? <Redirect to='/' /> : <Map    {...props} />) : (auth.login())} />
                        {/*<Route path='/settings' render={(props) => auth.isAuthenticated() ? <FarmSettings {...props} /> : auth.login()} />*/}
                        <Route path='/plots' render={(props) => auth.isAuthenticated() ? <FarmPlot {...props} /> : auth.login()} />
                        <Route path='/plotOperation' render={(props) => auth.isAuthenticated() ? <PlotOperation {...props} /> : auth.login()} />
                        <Route path='/callback' render={(props) => <Callback auth={auth} {...props} />} />
                    </AppContext.Provider>
                    }
            </AlertProvider>
        </>
    );
}

export default App;