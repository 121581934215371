import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
import { getContentWithAction, getContent } from '../../apiConnect';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Spinner from 'react-bootstrap/Spinner';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'

import 'ol/ol.css';
import '../../css/map.css';
import '../../css/general.css'

import { Map as Map2, View } from 'ol';
import { defaults as defaultInteractions, Select } from 'ol/interaction';
import * as proj from 'ol/proj';
import Collection from 'ol/Collection';
import { defaults as defaultControls, Attribution, ScaleLine } from 'ol/control';
import { click } from 'ol/events/condition';
import MousePosition from 'ol/control/MousePosition';
//import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import { toStringHDMS } from 'ol/coordinate';
import { GeoJSON } from 'ol/format';
import { Group as LayerGroup, Vector as VectorLayer } from 'ol/layer';
import { all as strategyAll } from 'ol/loadingstrategy.js';
import { Vector as VectorSource } from 'ol/source';
import {
    getGPSfromStorage, getSelectedPlots, getSelectedLang, getSelectedPlot, getSelectedYear
} from '../../common';
import AppContext from '../../AppContext';
import { enableGPS, disableGPS } from '../../gps.js';
import { addMapBaseLayer } from '../../Map/addMapBaseLayer';
import { checkCoordsInsideGridPolygon } from '../../Map/addMapTaskGrid';
import { addMapPosition, updateMapPosition } from '../../Map/addMapPosition';
import * as styles from '../../Map/mapStyle'
import { MapConfig } from '../../Map/mapConfig';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

import { featuresReader, retrieveWgsCoordinates, returnRoundedValue } from '../../Map/commonMapFunctions';

proj4.defs("EPSG:3067", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:3857", "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs");
register(proj4);

let WisuMap = {}, _styles = null;


const MapModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    let [mapFeatures, setMapFeatures] = useState([]);
    const { setGeolocation, setMapInitCoordinates } = useContext(AppContext);
    const [currentTaskAmount, setCurrentTaskAmount] = useState(-1);
    const [activatedGps, setActivatedGps] = useState(false);
    const [mapIsRendered, setMapIsRendered] = useState(false);
    const [pageIsLoading, setPageIsLoading] = useState(true);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [allowChanges, setAllowChanges] = useState(false);

    //layer selects
    const [basicLayer, setBasic] = useState(null)
    const [satelliteLayer, setSatellite] = useState(null)
    //const [selectedLayer, setSelectedLayer] = useState(0)

    const currentPosition = useRef({ latitude: null, longitude: null, timestamp: null });
    const gpsWatch = useRef(null);
    const running = useRef(false);

    const [enableCollectButton, setEnableCollectButton] = useState(false);
    const [enableDeleteButton, setEnableDeleteButton] = useState(false);
    const [collectedType, setCollectedType] = useState(false);

    let RasterBaseGroup;    

    const taskLayerName = 'taskLayer';
    //const logInterval = 2000; // Milliseconds
    const gpsAccuracyUpperLimit = 20; // Meters
    const gpsAccuracyLowerLimit = 0;
    const gpsLocationFilterSpeed = 6; // m/s
    const gpsOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    }

    const sSelectedPlot = getSelectedPlot();

    useEffect(() => {
        _styles = styles.init();
        styles.setLang(t);
        setPageIsLoading(true);

        if (!mapIsRendered && props.show) {
            Promise.all([
                getContent(auth.getAccessToken(), 'farmPlot', { lang: getSelectedLang() }),
                getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}),
            ]).then(([data, session]) => {
                data.forEach(d => {
                    d.plantName = d.plantName ? d.plantName : t('NoPlants');
                });
                renderMap(data);
                setMapIsRendered(true);
                setPageIsLoading(false);
                setAllowChanges(session);                
            });
        }

        if (!props.show) setMapIsRendered(false);
    }, [props.show]);

    const view = new View({
        center: proj.transform([23.66702093861786, 63.019978712597218], 'EPSG:4326', 'EPSG:3857'), // Temporary center for testing, todo figure out center based on selected farm.
        zoom: 14
    })

    const renderMap = (plots) => {
        // kasvulohkon rajat
        let agrPlotFeature = new VectorLayer({
            title: 'AgrPlot feature',
            source:
                createMapVectorSource({
                    layer: 'mtech:agrplots',
                    setExtent: true,
                    callBack: function (features) {

                        agrPlots.setVisible(true);
                        WisuMap.ContextChanged = true;


                        // load fertility collection and center map to these points
                        getCollectionFromDb(props.selectedSet ? props.selectedSet : 0);
                    }
                }),
            style: _styles.agr_plot.default,
            zIndex: 300,
            visible: true
        });

        // peruslohkon rajoista LayerGroup
        let agrPlots = new LayerGroup({
            title: 'AgrPlots',
            layers: [agrPlotFeature],
            zIndex: 300,
            visible: true
        });


        // map points
        let usermapFeatures = new VectorLayer({
            title: 'map features',
            source: createMapVectorSourceMapFeatures({
                layer: 'mtech:features',
                //setExtent: true,
                callBack: function (features) {
                    mapPointsGroup.setVisible(true);
                    WisuMap.usermapFeatures = usermapFeatures;
                }
            }),
            style: _styles.map_features.select,
            zIndex: 1000,
            visible: true
        });

        // karttapisteistä LayerGroup
        let mapPointsGroup = new LayerGroup({
            title: 'mapPointsGroup',
            layers: [usermapFeatures],
            zIndex: 1000,
            visible: true
        });

        // liitetään basePlots Group yleiseen VectorGrouppiin
        let VectorGroup = new LayerGroup({
            title: 'vectors',
            layers:
                [
                    agrPlots
                ],
            zIndex: 300,
            visible: true
        });

        let orthoImage = addMapBaseLayer();
        setSatellite(orthoImage);

        let basicImage = addMapBaseLayer("taustakartta");
        setBasic(basicImage);

        orthoImage.setVisible(true);
        basicImage.setVisible(false);

        RasterBaseGroup = new LayerGroup({
            title: 'Base rasters',
            layers: [orthoImage, basicImage]
        });

        WisuMap = new Map2({
            target: 'map',
            layers: [
                //addMapBaseLayer(), // map layer
                RasterBaseGroup,
                addMapPosition(), // gps point
                VectorGroup, // agrplots
                mapPointsGroup
            ],
            view: view,
            interactions: defaultInteractions({
                doubleClickZoom: true,
                mouseWheelZoom: true
            }),
            controls: defaultControls({ attribution: false }).extend([
                new MousePosition({
                    projection: 'EPSG:4326', //Config.CRS.WGS84,
                    coordinateFormat: function (coord) {
                        return toStringHDMS(coord) + ' (WGS84)';
                    },
                    className: 'mouse-position'
                }),
                new Attribution({
                    collapsible: true,
                    label: '©'
                }),
                new ScaleLine({
                    units: 'metric',
                    minWidth: 80
                })
            ]),
            moveTolerance: 5
        });

        WisuMap.ContextChanged = false;
        WisuMap.plots = plots;
        WisuMap.usermapFeatures = null;
        WisuMap.mapPointsGroup = mapPointsGroup;

        var gpsValue = getGPSfromStorage();
        if (gpsValue)
            startGPS();
        else if (gpsWatch.current)
            disableGPS(gpsWatch.current);

        //// Custom zoom
        //WisuMap2.on('click', function (event) {
        //    // Move zoom array idx
        //    shiftZoomArrayIdx();
        //    // Set view zoom
        //    WisuMap.getView().setZoom(zoomLevels[zoomIdx.current]);
        //});

        mapPointsGroup.interaction = {};
        mapPointsGroup.interaction.collection = { select: new Collection(), single: new Collection() };

        mapPointsGroup.interaction.select = new Select({
            condition: click,
            toggleCondition: click,
            multi: false,
            layers: WisuMap.usermapFeatures,
            hitTolerance: 6,
            style: [styles.retrieveMapFeatureStyle()],
            features: mapPointsGroup.interaction.collection.select
        });
        WisuMap.addInteraction(mapPointsGroup.interaction.select);
        mapPointsGroup.interaction.select.setActive(true);

        function selectFeature(e) {
            if (e.selected.length) {
                //eventListener.callBack(e.selected[0]);

                e.selected.forEach(function (feature) {
                    var prop = feature.getProperties();

                    if (prop.PlotId) {
                        mapPointsGroup.interaction.collection.select.clear();
                    }
                    else {
                        //console.log(prop);

                        // only one point to be selected
                        mapPointsGroup.interaction.collection.select.clear();
                        mapPointsGroup.interaction.collection.select.push(feature);                       
                        checkFeatureSelectionToButtons(feature);
                    }
                });
            }
            else if (e.deselected.length) {
                // automatically take off from collection.select list
            }

            e.mapBrowserEvent.stopPropagation();
            e.mapBrowserEvent.preventDefault();
        }

        mapPointsGroup.interaction.select.on("select", selectFeature);


        /** agrPlots select interaction - select/unselect on singleClick */
        /* DISABLED PLOT SELECTION */
        agrPlots.interaction = {};
        agrPlots.interaction.collection = { hover: new Collection(), select: new Collection(), single: new Collection() };
        agrPlots.interaction.select = new Select({
            condition: click,
            toggleCondition: click,
            layers: [agrPlotFeature],
            multi: true,
            hitTolerance: 4,
            style: _styles.agr_plot.select,
            features: agrPlots.interaction.collection.select
        });
        WisuMap.addInteraction(agrPlots.interaction.select);
        agrPlots.interaction.select.setActive(false);

        //this is for selected plot colors..
        let agrPlotSource = agrPlotFeature.getSource();
        agrPlotSource.on("change", function () {
            var state = agrPlotSource.getState();

            // only onload
            if (state == 'ready' && WisuMap.ContextChanged == true) {
                WisuMap.ContextChanged = false;

                let features = agrPlotSource.getFeatures();
                let selectedAgrPlotFeatureIds = getSelectedPlots();
                agrPlots.interaction.collection.select.clear();

                if (selectedAgrPlotFeatureIds.length) {
                    for (let i = 0; i < features.length; i++) {
                        let feature = features[i];
                        let p = feature.getProperties();

                        let find = WisuMap.plots.find(w => w.apChar === p.PlotLetter && w.number === p.BasePlotId);
                        if (find) {
                            if (selectedAgrPlotFeatureIds.indexOf(parseInt(find.apNumber)) !== -1) {
                                agrPlots.interaction.collection.select.push(feature);
                            }
                        }
                    }
                }
            }
        });

        WisuMap.on('click', function (e) {
            clickHandler(e);
        });
    }


    let checkFeatureSelectionToButtons = (feature) => {

        if (feature.values_.FeatureType === MapConfig.FEATURE.NEW_FERTILITY_SERIES ||
            feature.values_.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES ||
            feature.values_.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES_COLLECTED) {
            WisuMap.enableCollect = false;
        }
        else {
            WisuMap.enableCollect = true;
            setEnableCollectButton(true);
        }

        WisuMap.SelectedFeature = feature;

        setCollectedType(feature.values_.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES_PLANNED ? false : true);

        WisuMap.enableDelete = true;
        setEnableDeleteButton(true);
    }


    let collectFeature = () => {

        let source = WisuMap.usermapFeatures.getSource();
        let features = source.getFeatures();
        let collectedFeature = WisuMap.SelectedFeature;

        collectedFeature.values_.FeatureType = MapConfig.FEATURE.FERTILITY_SERIES_COLLECTED;
        let nbrMax = Math.max(...features.map(x => x.values_.UnitType), 0) + 1;
        collectedFeature.values_.UnitType = nbrMax ? nbrMax : 1;
        

        source.removeFeature(WisuMap.SelectedFeature);
        source.addFeature(collectedFeature);

        WisuMap.enableCollect = false;
        WisuMap.enableDelete = false;
        WisuMap.mapPointsGroup.interaction.collection.select.clear();

        setEnableDeleteButton(false);
        setEnableCollectButton(false);

        if (WisuMap.CollectableSamples) {
            let collectableSamaplePoints = WisuMap.CollectableSamples;

            if (Array.isArray(collectableSamaplePoints)) {
                WisuMap.CollectableSamples = [...collectableSamaplePoints, WisuMap.SelectedFeature.values_]
            }
            else {
                WisuMap.CollectableSamples = [collectableSamaplePoints, WisuMap.SelectedFeature.values_];
            }
        }
        else {
            WisuMap.CollectableSamples = WisuMap.SelectedFeature.values_;
        }
    }

    let deleteFeature = () => {

        let source = WisuMap.usermapFeatures.getSource();
        source.removeFeature(WisuMap.SelectedFeature);

        WisuMap.enableCollect = false;
        WisuMap.enableDelete = false;

        setEnableDeleteButton(false);
        setEnableCollectButton(false);

        if (WisuMap.DeletableSamples) {
            let deletableSamaplePoints = WisuMap.DeletableSamples;
            WisuMap.DeletableSamples = !!deletableSamaplePoints.forEach ? [...deletableSamaplePoints, WisuMap.SelectedFeature.values_.FeatureGeometryId] : [deletableSamaplePoints, WisuMap.SelectedFeature.values_.FeatureGeometryId];
        }
        else {
            WisuMap.DeletableSamples = WisuMap.SelectedFeature.values_.FeatureGeometryId;
        }
        if (!source.getFeatures().length) {
            WisuMap.DeleteSet = WisuMap.SelectedFeature.values_.FieldNo;
            WisuMap.DeletableSamples = undefined;
        }
    }

    function addSelectedFeatures(source, features) {
        source.clear();

        if (props.selectedSet) {
            source.addFeatures(features);
        }
    }

    const getCurrentLayer = () => {
        let layers = WisuMap.getLayers().array_;
        let currentLayer;
        //if (taskSpecificData.taskType === 2) {
        //    let fertLayer = layers.filter(layer => layer.get('name') === fertLayerName)[0];
        //    let seedLayer = layers.filter(layer => layer.get('name') === seedLayerName)[0];
        //    if (fertLayer.getVisible()) {
        //        currentLayer = fertLayer;
        //    } else {
        //        currentLayer = seedLayer;
        //    }
        //} else
        {
            let taskLayer = layers.filter(layer => layer.get('name') === taskLayerName)[0];
            currentLayer = taskLayer;
        }
        return currentLayer;
    }

    const success = (position) => {
        //console.log('got new GPS location!');
        let pos = validateNewPosition(position.coords);
        if (!pos.valid) {
            console.log('Insufficient GPS accurary');
            return;
        }
        currentPosition.current = pos;
        // Update user location on map
        updateMapPosition(currentPosition.current);
        // Move map center to user position
        WisuMap.getView().setCenter(proj.transform([currentPosition.current.longitude, currentPosition.current.latitude], 'EPSG:4326', 'EPSG:3857'));
        // Check which grid cell you are in and update task value
        if (running.current === true) checkCoordsInsideGridPolygon(getCurrentLayer(), currentPosition.current, setCurrentTaskAmount);
    }

    const errorHandler = () => {
        console.log('gps error');
    }

    /**
     * Vector layer source and get from db
     */
    function createMapVectorSource(obj) {

        obj.callBack = (typeof obj.callBack !== 'undefined') ? obj.callBack : function () { };

        var vectorSource = new VectorSource({
            format: new GeoJSON(),
            strategy: strategyAll,
            loader: function () {

                getContentWithAction(auth.getAccessToken(), 'map', 'agrplots', {})
                    .then((data) => {
                        //setAgrPlotFeatures(data);
                        featureLoader(data);

                    })
                    .catch(errorHandler);
            }
        });

        function featureLoader(response) {
            var features = new GeoJSON().readFeatures(response.featureCollection, {
                dataProjection: response.crs,
                featureProjection: 'EPSG:3857'//Config.CRS.WEBMERCATOR
            });

            obj.callBack(features);

            if (!features.length) {
                return false;
            }


            if (response.dataYear !== getSelectedYear()) {
                //const alertText = t('Error_old_boundaries').replace('{0}', response.dataYear);

                // change previous year plant information
                features.forEach(f => {
                    var pro = f.getProperties();
                    let find = WisuMap.plots.find(w => w.apChar === pro.PlotLetter && w.number === pro.BasePlotId);
                    if (find) {
                        f.set('PlantName', find.plantName);
                        f.set('PlantCode', find.plantId);
                        f.set('Area', find.area);
                    }
                });
            }

            vectorSource.addFeatures(features);

            // selected plots
            let plotIds = getSelectedPlots();
            var vectorSource2 = null;

            //check selected plot
            let selectedFeature = null;
            if (sSelectedPlot) {
                features.forEach(f => {
                    var pro = f.getProperties();

                    let find = WisuMap.plots.find(w => w.apChar === pro.PlotLetter && w.number === pro.BasePlotId);
                    if (find) {
                        if (find.apNumber === sSelectedPlot.apNumber)
                            selectedFeature = f;
                    }
                });
            }
            else if (plotIds && plotIds.length) {

                let plotFound = false;
                vectorSource2 = new VectorSource({
                    format: new GeoJSON(),
                    strategy: strategyAll
                });

                features.forEach(f => {
                    var pro = f.getProperties();

                    plotIds.forEach(p => {
                        if (pro.PlotId === p) {
                            plotFound = true;
                            vectorSource2.addFeature(f);
                        }
                    });
                });

                if (!plotFound)
                    vectorSource2 = null;
            }

            if (selectedFeature) { // zoom to plot
                WisuMap.getView().fit(selectedFeature.getGeometry(), { duration: 1000 });
            }
            else if (vectorSource2) { // this will center map on selected plots
                WisuMap.getView().fit(vectorSource2.getExtent());
            }
            else if (obj.setExtent === true) { // center to farm plots
                WisuMap.getView().fit(vectorSource.getExtent());

            } // this will center map

        }

        return vectorSource;
    }

    function createMapVectorSourceMapFeatures(obj) {

        obj.callBack = (typeof obj.callBack !== 'undefined') ? obj.callBack : function () { };

        var vectorSource = new VectorSource({
            format: new GeoJSON(),
            strategy: strategyAll,
            loader: function () {

                setMapFeatures([]);
                obj.callBack([]);               
            }
        });

        return vectorSource;
    }

    function featureLoaderCommon(response, obj, vectorSource, addFeatures) {
        var features = featuresReader(response);

        /*****************/
        //let center = WisuMap.getView().getCenter();
        //const iconFeature = new Feature({
        //    geometry: new Point([0,1]),
        //    name: 'Null Island',
        //    population: 4000,
        //    rainfall: 500,
        //});

        //const iconStyle = new Style({
        //    image: new Icon({
        //        anchor: [100.5, 1046],
        //        anchorXUnits: 'fraction',
        //        anchorYUnits: 'pixels',
        //        src: './data/icon.png',
        //    }),
        //});

        //iconFeature.setStyle(iconStyle);
        //features.push(iconFeature);
        /*****************/

        if (obj.callBack)
            obj.callBack(features);

        if (!features.length) {
            return false;
        }

        if (addFeatures) {
            // this will handle seleced features to visible/on draw
            addSelectedFeatures(vectorSource, features)


            if (props.selectedSet) {
                let vectorSource2 = new VectorSource({
                    format: new GeoJSON(),
                    strategy: strategyAll
                });

                features.forEach(f => {
                    vectorSource2.addFeature(f);
                });

                WisuMap.getView().fit(vectorSource2.getExtent(), { maxZoom: 18 });
            }
        }
    }

    // Return a potential object for updating current position
    const validateNewPosition = (newPosition) => {
        let potentialPosition = { valid: false };

        if (currentPosition.current.latitude === null) {
            if (newPosition.accuracy > gpsAccuracyUpperLimit || newPosition.accuracy < gpsAccuracyLowerLimit) {
                console.log('Bad accuracy for a potential initial position');
            }

            let lat = newPosition.latitude;
            let lon = newPosition.longitude;
            let time = new Date();
            potentialPosition = { valid: true, latitude: lat, longitude: lon, timestamp: time, heading: newPosition.heading, velocity: 0 };
        }

        let newTime = new Date();
        let timeDiff = (newTime - currentPosition.current.timestamp) / 1000; // seconds
        let dist = distance(newPosition.latitude, newPosition.longitude, currentPosition.current.latitude, currentPosition.current.longitude); // metres
        let velocity = dist / timeDiff;
        if (velocity <= gpsLocationFilterSpeed) {
            potentialPosition = { valid: true, latitude: newPosition.latitude, longitude: newPosition.longitude, timestamp: newTime, heading: newPosition.heading, velocity: velocity };
        }

        return potentialPosition;
    }

    const distance = (lat1, lon1, lat2, lon2) => {
        const R = 6378137;
        let dlon = radians(lon2 - lon1);
        let dlat = radians(lat2 - lat1);
        let f = squared(Math.sin(dlat / 2)) + Math.cos(radians(lat1)) * Math.cos(radians(lat2)) * squared(Math.sin(dlon / 2.0));
        let c = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(1 - f));
        return R * c;
    }

    const radians = (x) => {
        return x * Math.PI / 180;
    }

    const squared = (x) => {
        return x * x;
    }

    const redirect = (geo, mapInitPosition) => {
        setMapInitCoordinates(mapInitPosition);
        setGeolocation(geo);
        if (WisuMap != null) {
            gpsWatch.current = geo.watchPosition(success, errorHandler, gpsOptions);
        }
    }

    const startGPS = () => {
        if (gpsWatch.current) {
            disableGPS(gpsWatch.current);
            document.getElementById('coordinate-btn').style.backgroundColor = 'red';
        }
        if (!activatedGps) {
            enableGPS(redirect)
            document.getElementById('coordinate-btn').style.backgroundColor = 'green';
        }
        setActivatedGps(!activatedGps);
    }

    function getNewPointCoor(coor3857) {
        //var zoom = map.getView().getZoom();
        let coor = proj.transform(coor3857, 'EPSG:3857', 'EPSG:3067');

        let coordinates = {
            wgs: {},
            latitude: coor && coor.length > 1 ? Math.round(returnRoundedValue(coor[1], 0)) : 0,
            longitude: coor && coor.length > 0 ? Math.round(returnRoundedValue(coor[0], 0)) : 0
        };

        // return gps also in another format (if wanted to show in somewhere..)
        coordinates.wgs = retrieveWgsCoordinates(coor3857);

        return coordinates;
    }

    const clickHandler = (e) => {

        if (!WisuMap.usermapFeatures) return;

        var pixel = WisuMap.getEventPixel(e.originalEvent);
        var hit = WisuMap.hasFeatureAtPixel(pixel);
        var cPoint = WisuMap.getEventCoordinate(e.originalEvent);

        //Add sample point
        if (hit && WisuMap.addNewPointSample) {

            var data = {
                "type": "FeatureCollection",
                "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3067" } },
                "features": [
                    {
                        "type": "Feature",
                        "properties": { "id": 1, "FeatureType": MapConfig.FEATURE.NEW_FERTILITY_SERIES, "Note": "" },
                        "geometry": { "type": "Point", "coordinates": [cPoint[0], cPoint[1]] }
                    }
                ]
            };

            var features = new GeoJSON().readFeatures(data);
            let source = WisuMap.usermapFeatures.getSource();
            source.addFeature(...features);
            let baseNo = null;

            WisuMap.forEachFeatureAtPixel(pixel, function (feature, layer) {
                if (!baseNo && feature && feature.get("BasePlotId")) {
                    baseNo = feature.get("BasePlotId");
                }
            }, {});

            // returnvalue to modal
            const pointInfo = { coordinates: getNewPointCoor(cPoint), basePlotId: baseNo, sampleSetId: props.selectedSet ? props.selectedSet : undefined };
            if (WisuMap.AddedSamaplePoints) {
                let addedSamaplePoints = WisuMap.AddedSamaplePoints;
                WisuMap.AddedSamaplePoints = !!addedSamaplePoints.forEach ? [...addedSamaplePoints, pointInfo] : [addedSamaplePoints, pointInfo];
            }
            else {
                WisuMap.AddedSamaplePoints = pointInfo;
            }
            WisuMap.addNewPointSample = false;
        }
        if (hit && !props.selectedSet && !props.newSampleSet) {

            const note = props.pointNote ? props.pointNote : "";
            const featureType = props.featureType ? props.featureType : MapConfig.FEATURE.OBSERVATION;

            var data = {
                "type": "FeatureCollection",
                "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3067" } },
                "features": [
                    {
                        "type": "Feature",
                        "properties": { "id": 1, "FeatureType": featureType, "Note": note }, // add observation or memo
                        "geometry": { "type": "Point", "coordinates": [cPoint[0], cPoint[1]] }
                    }
                ]
            };
            var features = new GeoJSON().readFeatures(data);
            let source = WisuMap.usermapFeatures.getSource();
            source.clear(); // remove all to get point move when click on map
            source.addFeature(...features);

            // returnvalue to modal
            const pointInfo = { coord: getNewPointCoor(cPoint), note: "", feature: "" };
            setSelectedPoint(pointInfo);
        }
        if (hit && (WisuMap.enableDelete || WisuMap.enableCollect)) {
            setEnableCollectButton(false);
            setEnableDeleteButton(false);
            WisuMap.enableDelete = false;
            WisuMap.enableCollect = false;
        }
    }

    function getCollectionFromDb(id) {
        const source = WisuMap.usermapFeatures.getSource();
        source.clear();
        getContentWithAction(auth.getAccessToken(), 'map', 'mapFertilitySet', { id: id })
            .then((data) => {
                setMapFeatures(data);
                featureLoaderCommon(data, { layer: 'mtech:fertilityCollection' }, source, true)
            })
            .catch(errorHandler);
    }
    //End of map add feature
    // #endregion

    return (
        <>
            <Modal style={{ height: "100%" }} show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {t('Map')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "100%" }}>
                    {props.selectedSet > 0 || props.newSampleSet ? '' :
                        <div><span><i>{t('AddLocationNote')}</i></span></div>
                    }
                    {pageIsLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> : ''}
                    <div className="modal-map" id='map'>
                        <div id="center" className="center-area">
                            {allowChanges && (props.selectedSet > 0 || props.newSampleSet) ?
                                <span id="feature_add" className="feature_add_point-sample" >
                                    <Button size="sm" variant="success" className="feature_add_point-btn greenBackground" onClick={() => {
                                        WisuMap.addNewPointSample = true;
                                    }}> {t('New')}</Button>
                                </span >
                                : ''
                            }
                            <div className="gps-modal-button-div">
                                <button type="button" className="btn btn-info mapButtonStyle gpsMapButton" onClick={() => startGPS()} id="coordinate-btn" >
                                    <Icon icon={ICONS.GPS} color='black' size='22' />
                                </button>
                            </div>

                            {/* ACTION BUTTONS */}
                            {props.featureType === "5306" || props.featureType === "5304" ? '' :
                                <ButtonGroup vertical className="map-modal-action-button-group">
                                    {allowChanges && !props.collected ? 
                                        <button type="button" id="map-collect-btn" className={enableCollectButton ? collectedType ? "btn-info2 mapModalCollectAsStyle mapModalGreenButton" :
                                            "btn-info2 mapModalCollectAsStyle mapGreenInvertedButton" : "btn-info2 mapModalCollectAsStyle mapModalGreenButton"}
                                            disabled={enableCollectButton ? false : true}
                                            onClick={() => {
                                                collectFeature();
                                            }}>
                                            <Icon icon={ICONS.SUCCESS} color={collectedType ? 'white' : 'green'} size='51' />
                                        </button>
                                        : ''}
                                    {allowChanges ? 
                                        <button type="button" id="map-delete-btn" className="btn-info2 mapModalDeleteStyle mapRedButton"
                                            disabled={enableDeleteButton ? false : true}
                                            onClick={() => {
                                                deleteFeature();
                                            }}>
                                            <Icon icon={ICONS.DELETE} color='white' size='25' />
                                        </button>
                                        : ''}
                                </ButtonGroup>
                            }
                            {/* --------------- */}

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={() => { props.onHide(); }}>{t('Back')}</Button>
                    {allowChanges ? 
                        <Button variant="success" className="btn-show-more" onClick={() => {
                            /*FROM MEMOS*/
                            if (selectedPoint) {
                                props.onHide(selectedPoint);
                            }
                            /*FROM SAMPLE SET MODAL*/
                            if (props.selectedSet) {
                                props.onHide(WisuMap.CollectableSamples, WisuMap.AddedSamaplePoints, WisuMap.DeletableSamples, WisuMap.DeleteSet);
                            }
                            /*FROM SAMPLE SET LIST MODAL*/
                            if (props.newSampleSet) {
                                props.onHide(WisuMap.AddedSamaplePoints);
                            }
                        }}>{t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MapModal;