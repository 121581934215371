import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as dayjs from 'dayjs'
import { useTranslation } from 'react-i18next';
import { postContentWithAction } from '../../apiConnect';
import { getSelectedLang } from '../../common';
import { formatPlannedDate, groupByKey, valueMax, dayJsFormatDate, groupByMarked, round, updateCalculativeArray } from '../../commonFunctions';
import NumberFormat from 'react-number-format';
import CalendarModal from './CalendarModal';
import Spinner from 'react-bootstrap/Spinner';
import { useAlert } from 'react-alert'
import AppContext from '../../AppContext';
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'

import '../../css/general.css'

const MarkPesticideModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);
    const [plannedPests, setPlannedPests] = useState([]);
    const [combinedPests, setCombinedPests] = useState([]);
    const [calendarShow, setCalendarShow] = useState(false);
    const [date, setDate] = useState(dayjs().format());
    const [contentIsLoading, setContentIsLoading] = useState(true);
    const [infoText, setInfoText] = useState('Ladataan tietoja');
    const [saveInProgress, setSaveInProgress] = useState(false);

    //For area and amount calculations, CCD = CalculationCombinedData
    const [cCD, setCCD] = useState([]);

    const alert = useAlert();

    useEffect(() => {
        var plotIds = [];

        props.selectEvent.selectedPlots.forEach(p => {
            plotIds.push({ plotId: p.apNumber });
        });

        postContentWithAction(auth.getAccessToken(), 'plotOperation', 'getPlantProtectionOperations', { models: plotIds, lang: getSelectedLang() }, (data) => {
            data = data.filter((pest) => pest.growthInformation.date || pest.growthInformation.plannedDate);

            data.forEach(pest => {
                pest.plotData = props.selectEvent.selectedPlots.find(p => p.apNumber === pest.growthInformation.plotId);
                pest.plannedDate = pest.growthInformation.plannedDate;
                if (pest.growthInformation.date) {
                    pest.date = pest.growthInformation.date;
                }

                var combinedProducts = "";
                if (pest.pesticides) {
                    pest.pesticides.forEach(p => {
                        combinedProducts = combinedProducts.concat('', p.product);
                    });
                }
                if (pest.fertilizings) {
                    pest.fertilizings.forEach(f => {
                        combinedProducts = combinedProducts.concat('', f.product);
                    });
                }
                pest.combinedProducts = combinedProducts;
            });
            setPlannedPests(data);

            var grouped1 = groupByKey(data, 'combinedProducts');
            var grouped2 = [];
            for (const key in grouped1) {
                var m = groupByMarked(grouped1[key], 'date', 'plannedDate');
                var b = [];
                for (const type in m) {
                    for (const key in m[type]) { // Looping grouped keys
                        var combinedIds = [];
                        var combinedArea = 0;
                        var combinedAmounts = [];

                        m[type][key].forEach(p => { // Looping pesticide operations
                            if (p.fertilizings.length && p.fertilizings[0].area) {
                                combinedArea += p.fertilizings[0].area;
                            }
                            else
                                combinedArea += p.plotData.area;

                            combinedIds.push({
                                plotId: p.growthInformation.plotId,
                                pesticideId: p.growthInformation.pesticideId,
                                plotName: p.plotData.name + ' - ' + p.plotData.apChar,
                                plotArea: p.plotData.area
                            });

                            // Finding min and max values for pesticides
                            p.pesticides.forEach(f => {
                                if (!combinedAmounts.find(el => el.product === f.product)) {
                                    var minMax = {
                                        product: f.product,
                                        min: f.amount,
                                        max: f.amount
                                    };
                                    combinedAmounts.push(minMax);
                                }
                                else {
                                    var combinedAmount = combinedAmounts.find(el => el.product === f.product);
                                    combinedAmount.min = Math.min(combinedAmount.min, f.amount);
                                    combinedAmount.max = Math.max(combinedAmount.max, f.amount);
                                }
                            });

                            // Finding min and max values for fertilizings
                            p.fertilizings.forEach(f => {
                                if (!combinedAmounts.find(el => el.product === f.product)) {
                                    var minMax = {
                                        product: f.product,
                                        min: f.amount,
                                        max: f.amount
                                    };
                                    combinedAmounts.push(minMax);
                                }
                                else {
                                    var combinedAmount = combinedAmounts.find(el => el.product === f.product);
                                    combinedAmount.min = Math.min(combinedAmount.min, f.amount);
                                    combinedAmount.max = Math.max(combinedAmount.max, f.amount);
                                }
                            });
                        });

                        var combinedData = { ...m[type][key][0] }; // Copying first element for base data
                        combinedAmounts.forEach(c => {
                            c.min != c.max ? c.amount = null : c.amount = c.min;
                        });

                        b.push({
                            date: key,
                            combinedData: combinedData,
                            combinedArea: combinedArea,
                            combinedIds: combinedIds,
                            combinedAmounts: combinedAmounts
                        });
                    }
                }
                grouped2.push({
                    combinedProducts: key,
                    objects: b
                });
            }

            setCCD(grouped2);
            setCombinedPests(grouped2);

            setContentIsLoading(false);
            setInfoText('Ei suunniteltuja');
        });

    }, [props.selectEvent]);

    const updateDate = (value) => {
        var newDate = date;
        newDate.date = value;
        setDate(newDate);
    };

    const getSectionHeader = (data) => {
        var text = [];
        data.combinedData.pesticides.forEach(p => {
            text.push(p.productName);
        });
        data.combinedData.fertilizings.forEach(f => {
            text.push(f.productName);
        });
        return text.join(' + ');
    }

    const savePesticides = () => {
        setSaveInProgress(true);
        var operations = [];

        combinedPests.forEach(pesticides => {
            pesticides.objects.forEach(data => {
                data.combinedIds.forEach(id => {
                    if (data.combinedData.date) { // Only save if has a date
                        var originalData = plannedPests.find(pest => pest.growthInformation.pesticideId === id.pesticideId);
                        var updatePest = { ...originalData };

                        if (updatePest.fertilizings) {
                            updatePest.fertilizings.forEach(f => {
                                var defaultAmount = f.amount;
                                f.amount = data.combinedAmounts.find(a => a.product === f.product).amount;
                                if (!f.amount) {
                                    f.amount = defaultAmount;
                                }
                                else {
                                    let rounded = round(f.amount);
                                    f.amount = rounded;
                                }

                                f.date = data.combinedData.date;
                            });
                        }

                        if (updatePest.pesticides) {
                            updatePest.pesticides.forEach(p => {
                                var defaultAmount = p.amount;
                                p.amount = data.combinedAmounts.find(a => a.product === p.product).amount;
                                if (!p.amount) {
                                    p.amount = defaultAmount;
                                }
                                else {
                                    let rounded = round(p.amount);
                                    p.amount = rounded;
                                }
                            });
                        }

                        updatePest.growthInformation.date = data.combinedData.date;
                        updatePest.growthInformation.saveSpray = true;
                        updatePest.pesticideId = id.pesticideId;

                        var protectionOp = {
                            growthInfo: updatePest.growthInformation,
                            inserts: {
                                pesticideId: 0,
                                growthInformation: null,
                                pesticides: [],
                                fertilizings: [],
                                observations: []
                            },
                            updates: updatePest
                        };

                        var pesticideSaveModel = {
                            plotId: id.plotId,
                            protectionOperations: [protectionOp]
                        };

                        operations.push(pesticideSaveModel);
                    }
                });
            });
        });

        postContentWithAction(auth.getAccessToken(), 'plotOperation', 'savePlantProtectionOperations',
            { models: operations }, () => {
                props.setRefresh(Date.now());
                setSaveInProgress(false);
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                setSaveInProgress(false);
                alert.show(t('SavedFailed'), { type: 'error' });
            });

        props.onHide();
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('MarkPesticideDone')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-dark">
                    <Form>
                        {contentIsLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> : ''}
                        {(combinedPests) && (combinedPests.length > 0) ? combinedPests.map((product, i) =>
                            product.objects.map((pest, j) =>
                                <div key={j} id={i} className="mark-row-style border-bottom">
                                    <div className="mark-row-header">
                                        <b>{getSectionHeader(pest)}</b>
                                        <br />
                                        {pest.combinedIds.length > 1 ?
                                            <OverlayTrigger trigger="click" rootClose placement="right" overlay={
                                                <Tooltip id="overlay">
                                                    {pest.combinedIds.slice(1).map((p, i) =>
                                                        <div>{p.plotName}</div>
                                                    )}
                                                </Tooltip>
                                            }>
                                                <Button size="sm" variant="light">
                                                    {pest.combinedIds[0].plotName} <b>+</b> <Badge pill bg="secondary">{pest.combinedIds.length - 1}</Badge>
                                                </Button>
                                            </OverlayTrigger>
                                            :
                                            pest.combinedIds.length === 1 ?
                                                <Button size="sm" variant="light" className="disabledButton">
                                                    {pest.combinedIds[0].plotName}
                                                </Button>
                                                : ''}
                                    </div>

                                    <div className="mark-row-content">

                                        {pest.combinedData.pesticides ? pest.combinedData.pesticides.map((pestProduct, k) =>
                                            <div className="mark-product-row" key={i + k}>
                                                <div className="mark-product-row-header"><b>{pestProduct.productName}</b></div>
                                                <Form.Group className="mark-product-field" controlId="formAmount">
                                                    <Form.Label className="text-dark">{t('Amount_ha')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control inline-form-field"
                                                        value={cCD[i].objects[j].combinedAmounts.find(p => p.product === cCD[i].objects[j].combinedData.pesticides[k].product).amount}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        placeholder={`${pest.combinedAmounts.find(p => p.product === pestProduct.product).min} - ${pest.combinedAmounts.find(p => p.product === pestProduct.product).max}`}
                                                        onValueChange={(value) => {
                                                            if (value.floatValue && round(value.floatValue) !== round(cCD[i].objects[j].combinedAmounts.find(p => p.product === cCD[i].objects[j].combinedData.pesticides[k].product).amount)) {
                                                                if (value.floatValue) {
                                                                    setCCD(updateCalculativeArray(cCD, value.floatValue, i, j, k, true));
                                                                    pest.combinedAmounts.find(p => p.product === pestProduct.product).amount = value.floatValue;
                                                                }
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        thousandSeparator={' '}
                                                        suffix={'  ' + pestProduct.unitText}
                                                        isAllowed={valueMax}
                                                        allowNegative={false}
                                                        allowedDecimalSeparators={['.', ',']} />
                                                </Form.Group>
                                                <Form.Group className="mark-product-field" controlId="formAmountHa">
                                                    <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control inline-form-field"
                                                        value={cCD[i].objects[j].combinedData ? cCD[i].objects[j].combinedAmounts.find(p => p.product === cCD[i].objects[j].combinedData.pesticides[k].product).amount * cCD[i].objects[j].combinedArea : 0}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        onValueChange={(value) => {
                                                            if (value.floatValue && round(value.floatValue) !== round(cCD[i].objects[j].combinedData.amount * cCD[i].objects[j].combinedArea)) {
                                                                if (value.floatValue) {
                                                                    if (cCD[i].objects[j].combinedArea > 0) {
                                                                        setCCD(updateCalculativeArray(cCD, (value.floatValue / cCD[i].objects[j].combinedArea), i, j, k, true));
                                                                        pest.combinedAmounts.find(p => p.product === pestProduct.product).amount = value.floatValue / pest.combinedArea;
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        thousandSeparator={' '}
                                                        suffix={'  ' + pestProduct.unitText}
                                                        isAllowed={valueMax}
                                                        allowNegative={false}
                                                        allowedDecimalSeparators={['.', ',']} />
                                                </Form.Group>
                                            </div>
                                        ) : ''}

                                        {pest.combinedData.fertilizings ? pest.combinedData.fertilizings.map((fertilizer, k) =>
                                            <div className="mark-product-row" key={i + k}>
                                                <div className="mark-product-row-header"><b>{fertilizer.productName}</b></div>
                                                <Form.Group className="mark-product-field" controlId="formAmount">
                                                    <Form.Label className="text-dark">{t('Amount_ha')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control inline-form-field"
                                                        value={cCD[i].objects[j].combinedAmounts.find(p => p.product === cCD[i].objects[j].combinedData.fertilizings[k].product).amount}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        placeholder={`${pest.combinedAmounts.find(p => p.product === fertilizer.product).min} - ${pest.combinedAmounts.find(p => p.product === fertilizer.product).max}`}
                                                        onValueChange={(value) => {
                                                            if (value.floatValue && round(value.floatValue) !== round(cCD[i].objects[j].combinedAmounts.find(p => p.product === cCD[i].objects[j].combinedData.fertilizings[k].product).amount)) {
                                                                if (value.floatValue) {
                                                                    setCCD(updateCalculativeArray(cCD, value.floatValue, i, j, k, false));
                                                                    pest.combinedAmounts.find(p => p.product === fertilizer.product).amount = value.floatValue;
                                                                }
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        thousandSeparator={' '}
                                                        suffix={'  ' + fertilizer.unitText}
                                                        isAllowed={valueMax}
                                                        allowNegative={false}
                                                        allowedDecimalSeparators={['.', ',']} />
                                                </Form.Group>
                                                <Form.Group className="mark-product-field" controlId="formAmountHa">
                                                    <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control inline-form-field"
                                                        value={cCD[i].objects[j].combinedData ? cCD[i].objects[j].combinedAmounts.find(p => p.product === cCD[i].objects[j].combinedData.fertilizings[k].product).amount * cCD[i].objects[j].combinedArea : 0}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        onValueChange={(value) => {
                                                            if (value.floatValue && round(value.floatValue) !== round(cCD[i].objects[j].combinedData.amount * cCD[i].objects[j].combinedArea)) {
                                                                if (value.floatValue) {
                                                                    if (cCD[i].objects[j].combinedArea > 0) {
                                                                        setCCD(updateCalculativeArray(cCD, (value.floatValue / cCD[i].objects[j].combinedArea), i, j, k, false));
                                                                        pest.combinedAmounts.find(p => p.product === fertilizer.product).amount = value.floatValue / pest.combinedArea;
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        thousandSeparator={' '}
                                                        suffix={'  ' + fertilizer.unitText}
                                                        isAllowed={valueMax}
                                                        allowNegative={false}
                                                        allowedDecimalSeparators={['.', ',']} />
                                                </Form.Group>
                                            </div>
                                        ) : ''}

                                        <Form.Group className="mark-product-field" controlId="fromDate">
                                            <Form.Label className="text-dark">{t('Date')}</Form.Label>
                                            <Form.Control className="form-control inline-form-field" type="text" onClick={() => {
                                                setDate(pest.combinedData);
                                                setCalendarShow(true);
                                            }}
                                                placeholder={formatPlannedDate(pest.combinedData.plannedDate, t)}
                                                value={pest.combinedData.date ? dayJsFormatDate(pest.combinedData.date) : ''}
                                                onChange={() => { }} />
                                        </Form.Group>
                                    </div>
                                </div>)
                        ) : <div>{infoText}</div>}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Cancel')}</Button>
                    <Button variant="success" disabled={saveInProgress} className="btn-show-more" onClick={() => { savePesticides(); }}>{saveInProgress ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : t('Save')}</Button>
                </Modal.Footer>
            </Modal>
            {calendarShow === true ?
                <CalendarModal
                    show={calendarShow}
                    onHide={() => setCalendarShow(false)}
                    onChange={updateDate}
                    selecteddate={date.date}
                />
                : ''}
        </>
    )
}

export default MarkPesticideModal;