import React, { useState, useEffect, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { Col, Row, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
import { postContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';
import { nutrientNumberCellStyle } from '../ComponentStyles/ButtonStyles.js'

import '../../css/general.css'
import '../../css/farmPlot.css'

const NutrientModal = (props) => {
    const { auth } = useContext(AppContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState("");
    const [nutrientData, setNutrientData] = useState(null);

    useEffect(() => {
        setTitle(t('Nutrient_Calculation'));
        setIsLoading(true);
        callMMNutrientCalculation(props.selectedPlot);
    }, [props.selectEvent]);

    const callMMNutrientCalculation = (plot) => {
        var plotIds = [];
        plotIds.push({ plotId: plot.apNumber });
        postContentWithAction(auth.getAccessToken(), 'fertilize', 'calculateNutrients', { models: plotIds }, (response) => {
            if (response) {
                response.nDiff = response.nCalculated - response.nMax;
                response.pDiff = response.pCalculated - response.pMax - response.manureException;
                setNutrientData(response);
            }
            setIsLoading(false);
        });
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                    <div>
                            {!nutrientData ? <p>{t('Nutrient_Error')}</p> :
                                <Form>
                                    {/* HEADERS */}
                                    <div>
                                        <Form.Group className="nutrient-table-header">
                                            <Row className="nutrient-table-title" >
                                                <Col xs={10}>
                                                    {t('Nutrient_Calculation')}
                                                </Col>
                                            </Row>
                                            <Row className="nutrient-header-row" >
                                                <Col xs={5}>
                                                </Col>
                                                <Col xs={2} style={nutrientNumberCellStyle}>
                                                    <b>N</b>
                                                </Col>
                                                <Col xs={2} style={nutrientNumberCellStyle}>
                                                    <b>P</b>
                                                </Col>
                                                <Col xs={2} style={nutrientNumberCellStyle}>
                                                    <b>K</b>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </div>

                                    <Form.Group className="nutrient-table" key={'001'}>
                                        <Row className="nutrient-data-row" >
                                            <Col xs={5}>
                                                {t('Nutrient_Total')}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.nCalculated}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.pCalculated}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.kCalculated}
                                            </Col>
                                        </Row>

                                        <Row className="nutrient-data-row" >
                                            <Col xs={5}>
                                                {t('Nutrient_Need')}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.nNeed ? nutrientData.nNeed : 0}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.pNeed ? nutrientData.pNeed : 0}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.kNeed ? nutrientData.kNeed : 0}
                                            </Col>
                                        </Row>

                                        <Row className="nutrient-data-row" >
                                            <Col xs={5}>
                                                {t('Nutrient_Max')}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.nMax ? nutrientData.nMax : 0}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.pMax ? nutrientData.pMax : 0}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.kMax ? nutrientData.kMax : 0}
                                            </Col>
                                        </Row>

                                        <Row className="nutrient-data-row" >
                                            <Col xs={5}>
                                                {t('Nutrient_Diff')}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.nDiff > 0 ? '+' + nutrientData.nDiff : nutrientData.nDiff}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                {nutrientData.pDiff > 0 ? '+' + nutrientData.pDiff : nutrientData.pDiff}
                                            </Col>
                                            <Col xs={2} style={nutrientNumberCellStyle}>
                                                
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            }
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NutrientModal;