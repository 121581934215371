import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import { textStyle, rowStyle, addNoteRowStyle, addButtonStyle } from '../ComponentStyles/ButtonStyles.js'
import '../../css/general.css'
import * as Cards from '../../Constants/AccordionCardConstants.js';
import { toggleActive, dayJsFormatDate } from '../../commonFunctions.js'
import { OperationCardToggle } from '../../commonElements.js'
import { postContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';

const MemoAccordionCard = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [memos, setMemos] = useState([]);
    const [memosIsLoading, setMemosIsLoading] = useState(true);
    const [memoAttachments, setMemoAttachments] = useState([]);

    useEffect(() => {
        setMemosIsLoading(true);
        props.memos.sort((a, b) => b.memo > a.memo ? -1 : 1);
        var memos = props.memos.filter(m => m.plantMemoType === 0);
        setMemos(memos);
        if (!props.memosIsLoading) setMemosIsLoading(false);
    }, [props.memos]);

    useEffect(() => {
        if (memos.length > 0) {
            postContentWithAction(auth.getAccessToken(), 'photo', 'memoPhotosAll', { models: memos },
                (data) => {
                    if (data.length > 0) {
                        const memoPhotos = memoAttachments;
                        data.forEach(d => {
                            memoPhotos.push({ mediaId: d.mediaId, memoId: d.memoId });
                        });
                        setMemoAttachments(memoPhotos);
                    }
                    setMemosIsLoading(false);
                });
        }
    }, [memos]);

    const memoHasAttachment = (memo) => {
        var item = memoAttachments.find(m => m.memoId === memo.memoId);
        if (item) return true;
        return false;
    }

    return (
        <Card className="lastCard">
            <Card.Header className={props.activeCard === Cards.memoCardId ? 'active-panel lastCard' : 'inactive-panel lastCard'}>
                <OperationCardToggle classname={props.activeCard === Cards.memoCardId ? 'active-panel' : 'inactive-panel'} callback={() => toggleActive(props.activeCard, Cards.memoCardId, props.setActiveCard)} eventKey="5">
                    {t('Plot_Memos')}
                    {memosIsLoading ? <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" /> : <Badge pill bg='light' text='dark' className="badge-pill">{memos.length}</Badge>}
                </OperationCardToggle>
                <Button style={addButtonStyle} variant='mmfi-add-btn' className="float-right" onClick={() => {
                    props.setMemoType(0);
                    props.setSelectMemoEvent({ time: Date.now(), selectedElement: null });
                    props.setModalShow(true);
                }}><Icon icon={ICONS.ADD} color='white' size='16' /></Button>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
                <Card.Body>
                    <ListGroup>
                        {!memosIsLoading ? memos.map(p =>
                            <div key={p.memoId} className="row-margin-bot">
                                <div className='row-icon-left row-icon-memo'><Icon icon={ICONS.EDIT} color='white' size='20' /></div>
                                <ListGroup.Item className="plot-memo-row" key={p.memoId} style={rowStyle} action onClick={() => {
                                    props.setMemoType(0);
                                    props.setSelectMemoEvent({ time: Date.now(), selectedElement: p });
                                    props.setModalShow(true);
                                }}>
                                    <div className="memo-accordion-attachment-icon">
                                        {memoHasAttachment(p) ?
                                            <Icon icon={ICONS.ATTACHMENT} color='gray' size='20' />
                                            : ''}
                                    </div>
                                    <div><b> {p.memo}</b></div>
                                    <div className='memo-row-subtext'>{p.explanation}</div>
                                    <div className='row-subtext'>{dayJsFormatDate(p.memoDate)}</div>
                                </ListGroup.Item>
                                <span style={textStyle}></span>
                            </div>
                        ) : ''}
                        <ListGroup.Item key="AddGeneralNote" style={addNoteRowStyle} action onClick={() => {
                            props.setMemoType(0);
                            props.setSelectMemoEvent({ time: Date.now(), selectedElement: null });
                            props.setModalShow(true);
                        }}>
                            <div className='add-note-icon'><Icon icon={ICONS.ADD} color='black' size='16' /></div>
                            <div>{t('AddNote')}</div>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default MemoAccordionCard;