import React, { useState }from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../css/taskviewer.css';

const TaskViewerMapHeader = (props) => {
    const { t } = useTranslation();

    // Seeding-fertilizing states
    const [seedFertFertilizing, setSeedFertFertilizing] = useState('fertilizing-header');
    const [seedFertSeeding, setSeedFertSeeding] = useState('seeding-inactive-header');

    // To find the correct resource text
    const taskTypeTextMapping = {
        1: 'Fertiliser',
        2: 'Seed',
        3: 'Pesticide',
        4: 'SoilTillage'
    }

    const seedingFertilizingClick = (taskSelection) => {
        if (taskSelection === 1) {
            setSeedFertFertilizing('fertilizing-header');
            setSeedFertSeeding('seeding-inactive-header');
        } else if (taskSelection === 2) {
            setSeedFertFertilizing('fertilizing-inactive-header');
            setSeedFertSeeding('seeding-header');
        }
        props.switchGridLayer(taskSelection);
    }

    const generateTopInfo = () => {
        if (props.taskSpecificData.taskType == 2) {
            // Seeding-fertilizing. First item in product array is the sow data, the 2nd is fertilizing
            let sowData = props.taskSpecificData.products[0];
            let fertData = props.taskSpecificData.products[1];
            return (
                <Card>
                    <Row className='map-header'>
                        <Col className={seedFertFertilizing} onClick={() => seedingFertilizingClick(1)}>
                            <div>{t('Fertiliser')}</div>
                            <div>{fertData.productDesignator}</div>
                            <div>{t('OnAverage') + ' ' + fertData.averageAmount + ' ' + fertData.unitDesignator}</div>
                        </Col>
                        <Col className={seedFertSeeding} onClick={() => seedingFertilizingClick(2)}>
                            <div>{t('Seed')}</div>
                            <div>{sowData.productDesignator}</div>
                            <div>{t('OnAverage') + ' ' + sowData.averageAmount + ' ' + sowData.unitDesignator}</div>
                        </Col>
                    </Row>
                </Card>
            );
        } else if (props.taskSpecificData.taskType == 1) {
            return (
                <Card className='fertilizing-header'>
                    <div>{t(taskTypeTextMapping[props.taskSpecificData.taskType])}</div>
                    <div>{props.taskSpecificData.products[0].productDesignator}</div>
                    <div>{t('OnAverage') + ' ' + props.taskSpecificData.products[0].averageAmount + ' ' + props.taskSpecificData.products[0].unitDesignator}</div>
                </Card>
            );
        } else if (props.taskSpecificData.taskType == 3) {
            return (
                <Card className='spraying-header'>
                    <div>{t(taskTypeTextMapping[props.taskSpecificData.taskType])}</div>
                    <div>{props.taskSpecificData.products[0].productDesignator}</div>
                    <div>{t('OnAverage') + ' ' + props.taskSpecificData.products[0].averageAmount + ' ' + props.taskSpecificData.products[0].unitDesignator}</div>
                </Card>
            );
        } else {
            return (
                <Card className='tillage-header'>
                    <div>{t(taskTypeTextMapping[props.taskSpecificData.taskType])}</div>
                    <div>{props.taskSpecificData.products[0].productDesignator}</div>
                    <div>{t('OnAverage') + ' ' + props.taskSpecificData.products[0].averageAmount + ' ' + props.taskSpecificData.products[0].unitDesignator}</div>
                </Card>
            );
        }
    }

    return (
        <>
            {generateTopInfo()}
        </>
    );
}

export default TaskViewerMapHeader;