import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import ShowItemSelectionModal from './Modals/ShowItemSelectionModal'
import ShowGrowthStageListModal from './Modals/ShowGrowthStageListModal'
import ShowMemoListModal from './Modals/ShowMemoListModal'
import MemoModal from './Modals/MemoModal';
import ShowSampleSetListModal from './Modals/ShowSampleSetListModal'
import ShowDValuesListModal from './Modals/ShowDValuesListModal';
import ChartModal from './Modals/ChartModal';
import SampleSetModal from './Modals/SampleSetModal';
import MapModal from './Modals/MapModal';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import AppContext from '../AppContext';
import { useAlert } from 'react-alert'

import NavMenu from './NavMenu';
import { getContent, getContentWithAction, postContentWithAction, putContentWithActionFertility } from '../apiConnect';
import {
    removeSelectedPlot, setSelectedFarmId, getSelectedFarmId,
    getSelectedLang, getSelectedYear, setSelectedPage,
    FarmIdToView, getSelectedFarmName, setSelectedFarmName,
    getSelectedPlantsToView, setSelectedPlantsToView,
    getSelectedPlotsToView, setSelectedPlotsToView,
    removeSelectedPlantId, removeSelectedPlotGroupId, getSelectedForecastStation, setSelectedForecastStation, getSelectedHistoryStation
} from '../common';
import { chartMode } from '../constants';
import { groupByKey, dayJsFormatDate, getGrowthStageText } from '../commonFunctions';
import { GetPlantIcon, GetGroupIcon, GetEditIcon, GetFertilityIcon, GetPlotsIcon } from '../commonElements';
import { forecastMeterStyle, homeHeaderStyle, forecastDropStyle } from '../components/ComponentStyles/IconStyles';

import { ICONS } from '../img/icons'
import Icon from '../img/Icon'
import '../css/farmHome.css'
import '../css/general.css'

// Homepage
const Farm = (props) => {

    const { auth } = useContext(AppContext);
    const { t } = useTranslation();
    const [retievedFarms, setRetievedFarms] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMemos, setIsLoadingMemos] = useState(true);
    const [isLoadingSamples, setIsLoadingSamples] = useState(true);
    const [errorText, setErrorText] = useState(false);
    const [plants, setPlants] = useState([]);
    const [plantsDraw, setPlantsDraw] = useState([]);
    const [plots, setPlots] = useState([]);
    const [plotsDraw, setPlotsDraw] = useState([]);
    const [memos, setMemos] = useState([]);
    const [memosDraw, setMemosDraw] = useState([]);
    const [visible, setVisible] = useState(true);
    const [noPlots, setNoPlots] = useState(false);
    const [showChoiceModal, setShowChoiceModal] = useState(false);
    const reload = () => setPlantsDraw(getSelectedPlantsToView().sort((a, b) => b.area - a.area));
    const reload2 = () => setPlotsDraw(getSelectedPlotsToView().sort(comparePlots));
    const reload3 = () => handleMemoFetching();
    const reload4 = () => handleFertilityAnalyses();
    const [plantModal, setPlantModal] = useState(false);
    const [plotModal, setPlotModal] = useState(false);

    const [memoListModal, setMemoListModal] = useState(false);
    const [selectMemoEvent, setSelectMemoEvent] = useState({ time: null, selectedElement: null });
    const [memoModalShow, setMemoModalShow] = useState(false);
    const [memoType, setMemoType] = useState(0);
    const [memoAttachments, setMemoAttachments] = useState([]);

    const [sampleSetsCollected, setSampleSetsCollected] = useState([]);
    const [sampleSetCollectedDraw, setSampleSetCollectedDraw] = useState([]);
    const [sampleSetsPlanned, setSampleSetsPlanned] = useState([]);
    const [sampleSetPlannedDraw, setSampleSetPlannedDraw] = useState([]);

    const [sampleSetListModal, setSampleSetListModal] = useState(false);
    const [sampleSetModalShow, setSampleSetModalShow] = useState(false);
    const [dValueListModalShow, setdValueListModalShow] = useState(false);
    const [growthStageListModalShow, setGrowthStageListModalShow] = useState(false);
    const [chartModalShow, setChartModalShow] = useState(false);

    const [selectSampleSetEvent, setSelectSampleSetEvent] = useState({ time: null, selectedElement: null });
    const [noSampleSets, setNoSampleSets] = useState(false);
    const [collected, setCollected] = useState(false);

    const [forecastData, setForecastData] = useState([]);
    const [DValueData, setDValueData] = useState([]);
    const [growthStageData, setGrowthStageData] = useState([]);
    const [growthStageListModalData, setGrowthStageListModalData] = useState([]);
    const [chartModalData, setChartModalData] = useState([]);
    const [chartModalMode, setChartModalMode] = useState(0);

    const [memoAttachmentsLoaded, setMemoAttachmentsLoaded] = useState(false);
    const [forecastIsLoading, setForecastIsLoading] = useState(true);
    const [dValueIsLoading, setDValueIsLoading] = useState(true);
    const [growthStageIsLoading, setGrowthStageIsLoading] = useState(true);

    const [wheatStageCard, setWheatStageCard] = useState(null);
    const [barleyStageCard, setBarleyStageCard] = useState(null);
    const [oatStageCard, setOatStageCard] = useState(null);

    const [mapModal, setMapModalShow] = useState(false);
    const [sessionResult, setSessionResult] = useState(false);

    const alert = useAlert();

    document.title = t('Home') + ' | Mobiwisu';
    setSelectedPage(0);

    useEffect(() => {
        let mounted = true;
        getContent(auth.getAccessToken(), 'farm', {}).then((data) => {
            if (mounted) {
                setRetievedFarms(data);
                setFarm(data);
            }
        }).catch(c => {
            setFarm([]);
        })

        return function cleanup() {
            mounted = false;
        }
    }, []);

    const handleCloseModal = () => {
        if (plantModal) {
            reload();
            setPlantModal(false);
        }
        if (plotModal) {
            reload2();
            setPlotModal(false);
        }
        if (memoModalShow || memoListModal) {
            setIsLoadingMemos(true);
            reload3();
        }
        if (sampleSetModalShow) {
            setSampleSetModalShow(false);
            reload4();
        }
    };

    const setFarm = (farms) => {
        if (farms && farms.length > 0) {
            let farm = getSelectedFarmId();

            if (farm) { // if previous (session) farmid have saved

                let haveFarm = farms.find(f => f.farmId === Number(farm));
                if (haveFarm) // if current user have saved farmid -> farmid is right, save only finded name
                    setSelectedFarmName(haveFarm ? haveFarm.name : '');
                else {
                    // user don't have saved farmid -> get first one 
                    setSelectedFarmId(farms && farms.length ? farms[0].farmId : 0);
                    setSelectedFarmName(farms && farms.length ? farms[0].name : '');
                }
            }
            else {
                setSelectedFarmId(farms && farms.length ? farms[0].farmId : 0);
                setSelectedFarmName(farms && farms.length ? farms[0].name : '');
            }

            getPlotsData(farms);
        }
        else {
            setSelectedFarmId(0);
            setSelectedFarmName('');
            setErrorText(true);
            //auth.logout();
            setIsLoading(false);
            setIsLoadingMemos(false);
            setIsLoadingSamples(false);
        }
    };

    const getPlotsData = (farms) => {
        if (farms && farms.length > 0) {
            removeSelectedPlot();
            Promise.all([
                getContent(auth.getAccessToken(), 'farmPlot', { lang: getSelectedLang() }),
                getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}),
            ]).then(([data, session]) => {
                if (data.length > 0) {
                    setNoPlots(false);

                    let group = groupByKey(data, 'plantId');
                    formatPlantsGroup(group);
                    let group2 = groupByKey(data, 'groupId');
                    formatPlotsGroup(group2);
                }
                else {
                    setNoPlots(true);
                    setPlantsDraw([]);
                }

                /*setSessionResult(session);*/
                setSessionResult(session);
                setIsLoading(false);
            }).catch((c) => { setIsLoading(false); });

            handleMemoFetching();
            handleFertilityAnalyses();
            handleForecastsFetching();
            handleDValueFetching();
            handleGrowthDataFetching();
        }
    }

    const getPlotsDataOnRefresh = () => {
        getPlotsData(retievedFarms);
    }

    const handleMemoFetching = () => {
        setMemoAttachmentsLoaded(false);
        getContentWithAction(auth.getAccessToken(), 'memo', 'memosAll', {}).then((memos) => {
            if (!memos) return;
            formatMemos(memos);
            setIsLoadingMemos(false);
            getMemoAttachments(memos);
        }).catch((c) => { setIsLoadingMemos(false) });
    }

    const getMemoAttachments = (memos) => {
        postContentWithAction(auth.getAccessToken(), 'photo', 'memoPhotosAll', { models: memos },
            (data) => {
                if (data.length > 0) {
                    const memoPhotos = [];
                    data.forEach(d => {
                        memoPhotos.push({ mediaId: d.mediaId, memoId: d.memoId });
                    });
                    setMemoAttachments(memoPhotos);
                }
                setMemoAttachmentsLoaded(true);
            }).catch((c) => { setMemoAttachmentsLoaded(false); });
    }

    const handleFertilityAnalyses = () => {
        getContentWithAction(auth.getAccessToken(), 'fertility', 'analyseCollections', {}).then((data) => {
            if (data.length > 0) {
                formatFertility(data);
                setNoSampleSets(false);
            }
            else {
                setSampleSetCollectedDraw([]);
                setNoSampleSets(true);
            }
            setIsLoadingSamples(false);
        }).catch((c) => { setIsLoadingSamples(false) });
    }

    const handleForecastsFetching = () => {
        setForecastIsLoading(true);
        var station = getSelectedForecastStation();
        getContentWithAction(auth.getAccessToken(), 'forecast', 'retrieveForecastSums', { lang: getSelectedLang(), stationId: station.stationId, isLocalStation: station.localStation }).then((data) => {
            setForecastData(data);
            setForecastIsLoading(false);
        }).catch((c) => { setForecastIsLoading(false); });
    }

    const handleDValueFetching = () => {
        setDValueIsLoading(true);
        var station = getSelectedForecastStation();
        var station30Y = getSelectedHistoryStation();
        getContentWithAction(auth.getAccessToken(), 'forecast', 'retrieveForecastDValues', { lang: getSelectedLang(), stationId: station.stationId, stationId30Y: station30Y.stationId, isLocalStation: station.localStation }).then((data) => {
            setDValueData(data);
            setDValueIsLoading(false);
        }).catch((c) => {
            setDValueIsLoading(false);
            setDValueData([]);
            console.log('dvalue error')
        });
    }

    const handleGrowthDataFetching = () => {
        setGrowthStageIsLoading(true);
        var station = getSelectedForecastStation();
        var station30Y = getSelectedHistoryStation();
        getContentWithAction(auth.getAccessToken(), 'forecast', 'retrieveForecastGrowthData', { lang: getSelectedLang(), stationId: station.stationId, stationId30Y: station30Y.stationId, isLocalStation: station.localStation }).then((data) => {
            setGrowthStageData(data);
            setGrowthStageCardData(data);
            setGrowthStageIsLoading(false);
        }).catch((c) => { setGrowthStageIsLoading(false); });
    }


    const handleOperations = (operations) => {       
        var promiseArray = [];

        if (operations[0]) {
            promiseArray.push(updateSamplePoints(operations[0]));
        }
        if (operations[1]) {
            promiseArray.push(saveSamplePoints(operations[1]));
        }
        if (operations[2]) {
            promiseArray.push(deleteSamplePoints(operations[2]));
        }

        Promise.all(promiseArray).then(resolve => {
            handleFertilityAnalyses();
        }).catch(error => console.log(`Error in executing ${error}`));
    }

    const saveSampleSet = (additions) => {
        getContentWithAction(auth.getAccessToken(), 'fertility', 'newSet', {}).then((newSetId) => {
            if (!newSetId) return;
            saveSamplePoints(additions, newSetId);
        }).catch((c) => { setIsLoadingSamples(false) });
    }


    const saveSamplePoints = (additions, newSetId) => {
        var newSamplePoints = [];

        if (!!additions.forEach) {
            additions.forEach((sample) => {
                newSamplePoints.push({
                    sampleSetId: newSetId != null && newSetId > 0 ? newSetId : sample.sampleSetId,
                    basePlotId: sample.basePlotId,
                    longitude: sample.coordinates ? parseInt(sample.coordinates.longitude) : 0,
                    latitude: sample.coordinates ? parseInt(sample.coordinates.latitude) : 0,
                    collected: 1
                });
            });
        }
        else {
            newSamplePoints.push({
                sampleSetId: newSetId != null && newSetId > 0 ? newSetId : additions.sampleSetId,
                basePlotId: additions.basePlotId,
                longitude: additions.coordinates ? parseInt(additions.coordinates.longitude) : 0,
                latitude: additions.coordinates ? parseInt(additions.coordinates.latitude) : 0,
                collected: 1
            });
        }

        const promise = new Promise((resolve, reject) => {
            postContentWithAction(auth.getAccessToken(), 'fertility', 'new', {
                models: newSamplePoints
            }, () => {
                alert.show(t('SavedSuccesfully'), { type: 'success' });
                resolve("Promise resolved successfully");
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
                reject(Error("Promise rejected"));
            });
        });

        if (newSetId) {
            promise.then(result => {
                handleFertilityAnalyses();
            }, reason => {
                console.error(reason); // Error!
            });
        }
        else
            return promise;
    }

    const updateSamplePoints = (updatables) => {
        var updatablePoints = [];

        if (!!updatables.forEach) {
            updatables.forEach((point) => {
                updatablePoints.push({
                    sampleSetId: point.FieldNo,
                    id: point.FeatureGeometryId,
                    collected: point.FeatureType !== "5312" ? 1 : 0
                });
            });
        }
        else {
            updatablePoints.push({
                sampleSetId: updatables.FieldNo ? updatables.FieldNo : updatables.sampleSetId,
                id: updatables.FeatureGeometryId ? updatables.FeatureGeometryId : updatables.id,
                collected: updatables.FeatureType && updatables.FeatureType !== "5312" ? 1 :
                    typeof updatables.collected === 'boolean' && !updatables.collected ? 1 : 0
            });
        }

        const promise = new Promise((resolve, reject) => {
            putContentWithActionFertility(auth.getAccessToken(), 'fertility', 'update', {
                models: updatablePoints
            }, () => {
                alert.show(t('SavedSuccesfully'), { type: 'success' });
                resolve("Promise resolved successfully");
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
                reject(Error("Promise rejected"));
            });
        });

        return promise;
    }

    const deleteSamplePoints = (deletables) => {
        var deletablePoints = [];

        if (!!deletables.forEach) {
            deletables.forEach((id) => {
                deletablePoints.push({
                    id: id
                });
            });
        }
        else {
            deletablePoints.push({
                id: deletables
            });
        }

        const promise = new Promise((resolve, reject) => {
            postContentWithAction(auth.getAccessToken(), 'fertility', 'delete', {
                models: deletablePoints
            }, () => {
                alert.show(t('SavedSuccesfully'), { type: 'success' });
                resolve("Promise resolved successfully");
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
                reject(Error("Promise rejected"));
            });
        });

        return promise;       
    }

    const deleteSampleSet = (ids) => {
        setIsLoadingSamples(true);
        var deletableSets = [];

        if (!!ids.forEach) {
            ids.forEach((set) => {
                deletableSets.push({
                    sampleSetId: set
                });
            });
        }
        else {
            deletableSets.push({
                sampleSetId: ids
            });
        }

        const promise = new Promise((resolve, reject) => {
            postContentWithAction(auth.getAccessToken(), 'fertility', 'deleteSet', {
                models: deletableSets
            }, () => {
                alert.show(t('SavedSuccesfully'), { type: 'success' });
                resolve("Promise resolved successfully");
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
                reject(Error("Promise rejected"));
            });
        });

            promise.then(result => {
                handleFertilityAnalyses();
            }, reason => {
                console.error(reason); // Error!
            });
    }

    //const getDValue = () => {
    //    var dValue = DValueData.length ? DValueData[0] : 0;
    //    return dValue.dValue;
    //}

    const getHeatSum = () => {
        var sum = forecastData.latestTemp ? forecastData.latestTemp : 0;
        return sum.toFixed(0);
    }

    const getRainSum = () => {
        var sum = forecastData.latestRain ? forecastData.latestRain : 0;
        return sum.toFixed(0);
    }

    const formatPlantsGroup = (groups) => {
        let plantList = [];
        for (var g in groups) {

            let obj = { countId: groups[g].length, area: 0 };

            for (var p in groups[g]) {
                let plot = groups[g][p];
                obj.area += plot.area;
                obj.plantId = plot.plantId;
                obj.plantName = plot.plantName ? plot.plantName : 'Ei kasvia';
            }
            obj.area = Math.round(obj.area * 100) / 100;
            plantList.push(obj);

        }

        let sortedPlants = plantList.sort((a, b) => b.area - a.area);
        setPlants(sortedPlants);

        const view = getSelectedPlantsToView();
        if (!view.length) {
            const slice = plantList.slice(0, 6)
            setPlantsDraw(slice);
            setSelectedPlantsToView(slice);
        }
        else {
            let newList = []; // collect a list of changed plants
            view.forEach(f => {
                if (sortedPlants.some(s => s.plantId === f.plantId))
                    newList.push(sortedPlants.find(s => s.plantId === f.plantId));

            });
            if (newList.length)
                setSelectedPlantsToView(newList);
            else
                setSelectedPlantsToView(sortedPlants.slice(0, 6));

            reload();
        }

        var element = document.getElementById('div-show-all-plants');
        if (element)
            element.classList.remove('d-none');
    };

    const formatPlotsGroup = (groups) => {
        let plotList = [];
        for (var g in groups) {
            if (!parseInt(g, 10) > 0) {
                continue;
            }
            let obj = { countId: groups[g].length, area: 0 };

            for (var p in groups[g]) {
                let plot = groups[g][p];
                obj.area += plot.area;
                obj.groupId = plot.groupId;
                obj.groupName = plot.groupName;
            }
            obj.area = Math.round(obj.area * 100) / 100;
            plotList.push(obj);
        }

        if (!plotList === null || plotList.length > 0) {

            let sortedplotList = plotList.sort(comparePlots);
            setPlots(sortedplotList);

            const view2 = getSelectedPlotsToView();
            if (!view2.length) {
                const slice2 = plotList.slice(0, 6)
                setPlotsDraw(slice2);
                setSelectedPlotsToView(slice2);
            }
            else {
                let newList = [];
                view2.forEach(f => {
                    if (sortedplotList.some(s => s.groupId === f.groupId && s.groupName === f.groupName))
                        newList.push(sortedplotList.find(s => s.groupId === f.groupId && s.groupName === f.groupName));
                });
                if (newList.length)
                    setSelectedPlotsToView(newList);
                else
                    setSelectedPlotsToView(sortedplotList.slice(0, 6));

                reload2();
            }

            setVisible(true)
        }
        else {
            setVisible(false)
        }

        var element = document.getElementById('div-show-all-plots');
        if (element)
            element.classList.remove('d-none');
    };

    const formatMemos = (memos) => {
        let sortedMemoList = memos.sort((a, b) => new Date(b.memoDate) - new Date(a.memoDate))
        setMemos(sortedMemoList);
        setMemosDraw(sortedMemoList.slice(0, 3));

        var element = document.getElementById('div-show-all-memos');
        if (element)
            element.classList.remove('d-none');
    };

    const formatFertility = (groups) => {
        let collectedSampleList = [];
        let plannedSampleList = [];

        for (var g in groups) {

            let sampleList = [];

            let obj = { plotCount: new Set(groups[g].samples.map(item => item.basePlotId)).size, sampleCount: groups[g].samples.length, id: groups[g].id, sampleDate: groups[g].sampleDate };

            for (var p in groups[g].samples) {
                let sample = groups[g].samples[p];
                sampleList.push(sample)
            }
            obj.samples = sampleList;

            if (groups[g].collected === true)
                collectedSampleList.push({ ...obj, samples: sampleList });
            else
                plannedSampleList.push({ ...obj, samples: sampleList });
        }

        if (collectedSampleList.length) {
            let sortedSampleSets = collectedSampleList.sort((a, b) => new Date(b.sampleDate) - new Date(a.sampleDate));
            setSampleSetsCollected(sortedSampleSets);
            const slice = sortedSampleSets.slice(0, 1)
            setSampleSetCollectedDraw(slice);
            if (sampleSetListModal) {
                if (collected) {
                    const temp = selectSampleSetEvent.selectedElement.id;
                    if (sortedSampleSets.find(x => x.id === temp))
                        setSelectSampleSetEvent({ time: Date.now(), selectedElement: sortedSampleSets.find(x => x.id === temp) });
                    else
                        setSelectSampleSetEvent({ time: null, selectedElement: null });
                }
            }
            if (sampleSetModalShow) {
                if (collected) {
                    const temp = selectSampleSetEvent.selectedElement.id;
                    if (sortedSampleSets.find(x => x.id === temp))
                        setSelectSampleSetEvent({ time: Date.now(), selectedElement: sortedSampleSets.find(x => x.id === temp) });
                    else
                        setSampleSetModalShow(false);
                }
            }
        }

        if (plannedSampleList.length) {
            let sortedSampleSets = plannedSampleList.sort((a, b) => new Date(a.sampleDate) - new Date(b.sampleDate));
            setSampleSetsPlanned(sortedSampleSets);
            const slice = sortedSampleSets.slice(0, 1)
            setSampleSetPlannedDraw(slice);
            if (sampleSetModalShow) {
                if (!collected) {
                    const temp = selectSampleSetEvent.selectedElement.id;
                    if (sortedSampleSets.find(x => x.id === temp))
                        setSelectSampleSetEvent({ time: Date.now(), selectedElement: sortedSampleSets.find(x => x.id === temp) });
                    else
                        setSampleSetModalShow(false);
                }
            }
        }
        if (!plannedSampleList.length && !collected) {
            setSampleSetModalShow(false);
            setSampleSetsPlanned([]);
            setSampleSetPlannedDraw([]);
        }
    };

    const comparePlots = (a, b) => {
        if (a.area < b.area) {
            return 1;
        }
        if (a.area > b.area) {
            return -1;
        }
        if (a.area === b.area) {
            if (a.groupName > b.groupName) {
                return 1;
            }
            if (a.groupName < b.groupName) {
                return -1;
            }
        }
        return 0;
    };

    const getMemoText = (button) => {
        return button.memo;
    };

    const ButtonGroup2 = ({ buttons }) => {

        buttons.forEach(b => {
            b.plantNameShort = b.plantName && b.plantName.length > 9 ? b.plantName.substr(0, 9) + '...' : b.plantName;
        });

        return (
            <>
                {buttons.map((button, i) => (
                    <button
                        key={i}
                        value={button.plantId}
                        data-name={button.plantId}
                        onClick={(event) => buttonClick(event)}
                        className={"customButton"}>
                        <div className="text-dark button-div" data-name={button.plantId} >
                            {button.plantNameShort}
                            <br />
                            <span className="highlighted-green-number">{button.countId || 0}</span>
                            <br />
                            <span className="smaller-text">{button.area || 0} ha </span>
                        </div>
                    </button>
                ))}
            </>
        );
    };
    const buttonClick = (event) => {
        var plant = plants.find(p => Number(p.plantId) === Number(event.currentTarget.value));
        var plantName = plant && plant.plantName ? plant.plantName : '';
        removeSelectedPlantId();
        removeSelectedPlotGroupId();
        props.history.push('plots', { id: event.currentTarget.value, plantName: plantName });
    };

    const buttonShowMoreClick = () => {
        plants.sort((a, b) => b.area - a.area);
        setPlantModal(true)
        setShowChoiceModal(true)
    };

    const ButtonGroup3 = ({ buttons }) => {

        buttons.forEach(b => {
            b.groupNameShort = b.groupName && b.groupName.length > 9 ? b.groupName.substr(0, 9) + '...' : b.groupName;
        });

        return (
            <>
                {buttons.map((button, i) => (
                    <button
                        key={i}
                        value={button.groupId}
                        data-name={button.groupId}
                        onClick={(event) => buttonClick2(event)}
                        className={"customButton"}>
                        <div className="text-dark button-div" data-name={button.groupId} >
                            {button.groupNameShort}
                            <br />
                            <span className="highlighted-green-number">{button.countId || 0}</span>
                            <br />
                            <span className="smaller-text">{button.area || 0} ha </span>
                        </div>
                    </button>
                ))}
            </>
        );
    };
    const buttonClick2 = (event) => {
        removeSelectedPlantId();
        removeSelectedPlotGroupId();
        props.history.push('plots', { groupId: event.currentTarget.value });
    };

    const buttonShowMoreClick2 = () => {
        plots.sort((a, b) => b.area - a.area);
        setPlotModal(true)
        setShowChoiceModal(true)
    };

    const memoHasAttachment = (memo) => {
        var item = memoAttachments.find(m => m.memoId === memo.memoId);
        if (item) return true;
        return false;
    }

    const ButtonGroup4 = ({ buttons }) => {

        buttons.forEach(b => {
            b.groupNameShort = b.plotName ? b.plotName : t('Common');
        });

        return (
            <>
                {memoAttachmentsLoaded ? buttons.map((button, i) => (
                    <button
                        key={i}
                        value={button.memoId}
                        data-name={button.memoId}
                        onClick={() => {
                            setMemoType(button.plantMemoType);
                            setSelectMemoEvent({ time: Date.now(), selectedElement: button });
                            setMemoModalShow(true);
                        }}
                        className={"homeMemoButton"}>
                        <div className="text-dark button-notes-div" data-name={button.memoId} >
                            <div className="memo-attachment-icon">
                                {memoHasAttachment(button) ?
                                    <Icon icon={ICONS.ATTACHMENT} color='gray' size='20' />
                                    : ''}
                            </div>
                            <div className="highlighted-text">{getMemoText(button)}</div>
                            <div className="smaller-text-notes">{button.explanation}</div>
                            <div className="smaller-text-notes-last"><span className="memo-button-plot">{button.groupNameShort}</span>, {dayJsFormatDate(button.memoDate)}</div>
                        </div>
                    </button>
                )) : ''}
            </>
        );
    };

    const getButtonDValue = (dData) => {
        if (dData === undefined || !dData || dData.length === 0) return null;

        var dValueCard = dData[0];
        dData.forEach(d => {
            if (d.dValue < dValueCard.dValue) // Find smallest d-value
                dValueCard = d;
        });

        return dValueCard.dValue;
    };

    const setGrowthStageCardData = (gData) => {
        if (!gData || gData.length === 0) return;

        var barleyData = gData.filter(d => d.plantId.startsWith("13"));
        if (barleyData.length > 0) {
            var growthStageCard = barleyData[0];
            barleyData.forEach(d => {
                if (d.growthStage > growthStageCard.growthStage) // Find highest growthStage
                    growthStageCard = d;
            });
            setBarleyStageCard(growthStageCard);
        }
        else setBarleyStageCard(null);

        var wheatData = gData.filter(d => d.plantId.startsWith("11"));
        if (wheatData.length > 0) {
            growthStageCard = wheatData[0];
            wheatData.forEach(d => {
                if (d.growthStage > growthStageCard.growthStage) // Find highest growthStage
                    growthStageCard = d;
            });
            setWheatStageCard(growthStageCard);
        }
        else setWheatStageCard(null);

        var oatData = gData.filter(d => d.plantId.startsWith("14"));
        if (oatData.length > 0) {
            growthStageCard = oatData[0];
            oatData.forEach(d => {
                if (d.growthStage > growthStageCard.growthStage) // Find highest growthStage
                    growthStageCard = d;
            });
            setOatStageCard(growthStageCard);
        }
        else setOatStageCard(null);
    };

    const shortenText = (text) => {
        return text && text.length > 11 ? text.substr(0, 11) + '.' : text;
    }

    const buttonShowMoreClick3 = () => {
        memos.sort((a, b) => b.memoDate - a.memoDate);
        setMemoListModal(true)
    };

    //Collected sample sets

    const ButtonSampleSetCollected = ({ buttons }) => {

        return (
            <>
                {buttons.map((button, i) => (
                    <button
                        key={i}
                        value={button.Id}
                        data-name={button.Id}
                        onClick={() => {
                            setSelectSampleSetEvent({ time: Date.now(), selectedElement: button });
                            setCollected(true);
                            setSampleSetModalShow(true);
                        }}
                        className={"customButton"}>
                        <div className="text-dark button-div" data-name={button.Id} >
                            <span className="highlighted-text">{t('SampleSetsCollected')}</span>
                            <br />
                            <span className="highlighted-green-number">{button.plotCount} </span>
                            <br />
                            <span className="highlighted-text">{dayJsFormatDate(button.sampleDate)}</span>
                        </div>
                    </button>
                ))}
            </>
        );
    };   

    //Planned sample sets

    const ButtonSampleSetPlanned = ({ buttons }) => {

        return (
            <>
                {buttons.map((button, i) => (
                    <button
                        key={i}
                        value={button.Id}
                        data-name={button.Id}
                        onClick={() => {
                            setSelectSampleSetEvent({ time: Date.now(), selectedElement: button });
                            setCollected(false);
                            setSampleSetModalShow(true);
                        }}
                        className={"customButtonPlanned"}>
                        <div className="text-dark button-div" data-name={button.Id} >
                            <span className="highlighted-text-samples">{t('SamplePlan')}</span>
                            <br />
                            <span className="highlighted-green-number">{button.plotCount} </span>
                            <br />
                            <span className="highlighted-text">{dayJsFormatDate(button.sampleDate)}</span>
                        </div>
                    </button>
                ))}
            </>
        );
    };

    const buttonShowMoreSampleSetsCollected = () => {
        setSampleSetListModal(true);        
    };

    return (
        <Container>
            <NavMenu {...props} getPlots={getPlotsDataOnRefresh} />

            {errorText ?
                <div className="text-dark error-div">{t('NoRights')}
                    <span className="logout-before-btn">&nbsp;</span>
                    <Button onClick={() => auth.logout()} variant="outline-secondary">
                        <span className="text-dark">{t('LogOut')}</span>
                    </Button>
                </div>
                : ''
            }

            <Card>
                <Card.Body>
                    {!errorText ?
                        <div className="text-dark farm-info-header">
                            <span className="farm-name-span"><h6>{FarmIdToView(getSelectedFarmId())} - {getSelectedFarmName()}</h6></span>
                            <span className="farm-year-span"><h6>{getSelectedYear()}</h6></span>
                        </div>
                        : ''
                    }

                    <div className="text-dark first-card-div">
                        <GetPlantIcon />
                        <h5>{t('Plants')}</h5>
                    </div>
                    {(isLoading) ? <Spinner animation="border" size="sm" role="status" variant="secondary" /> : ''}
                    <div className="App">
                        <ButtonGroup2 buttons={plantsDraw} />
                    </div>

                    {noPlots ?
                        <div className="no-plots-div">{t('PlotDataNotFound')}</div>
                        :
                        <div id="div-show-all-plants" className="show-more-div d-none">
                            {sessionResult ? 
                                <Button variant="success" className="btn-show-more" onClick={() => buttonShowMoreClick()}>{t('ShowMore')}</Button>
                                : ''}
                        </div>
                    }
                </Card.Body>
            </Card>
            <Card>
                <Card.Body style={{ display: !visible && 'none' }}>
                    <div className="text-dark">
                        <GetGroupIcon />
                        <h5>{t('Plotgroups')}</h5>
                    </div>
                    {(isLoading) ? <Spinner animation="border" size="sm" role="status" variant="secondary" /> : ''}
                    <div className="App">
                        <ButtonGroup3 buttons={plotsDraw} />
                    </div>
                    <div id="div-show-all-plots" className="show-more-div d-none">
                        {sessionResult ? 
                            <Button variant="success" className="btn-show-more" onClick={() => { buttonShowMoreClick2(); }}>{t('ShowMore')}</Button>
                            : ''}
                    </div>
                </Card.Body>
            </Card>

            {/* ---  Forecasts --- */}
            {auth.showForecasts() ?
                <Card>
                    <Card.Body>
                        <div className="text-dark first-card-div">
                            <Icon icon={ICONS.STATS} color='black' size='26' style={homeHeaderStyle} />
                            <h5>{t('Forecast')}</h5>
                        </div>
                        <div className="text-dark">
                            <div><i>{getSelectedForecastStation().stationId === -1 || getSelectedHistoryStation().stationId === -1 ? t('ForecastStation_SelectionError') : ''}</i></div>
                            <div className="App">
                                {/* ---  Sums --- */}
                                <button
                                    key={"heatSum"}
                                    onClick={() => {
                                        setChartModalData(forecastData);
                                        setChartModalMode(chartMode.temperatureSum);
                                        setChartModalShow(true);
                                    }}
                                    className={"customButton"}>
                                    <div className="text-dark button-div forecast-button">
                                        <span className="highlighted-text">{t('Forecast_HeatSum')}</span>
                                        <br />
                                        {forecastIsLoading ? <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" /> :
                                            <span className="highlighted-green-number"><Icon style={forecastMeterStyle} icon={ICONS.METER} color='gray' size='32' />{getHeatSum()} &deg;C</span>
                                        }
                                    </div>
                                </button>
                                <button
                                    key={"rainSum"}
                                    onClick={() => {
                                        setChartModalData(forecastData);
                                        setChartModalMode(chartMode.rainSum);
                                        setChartModalShow(true);
                                    }}
                                    className={"customButton"}>
                                    <div className="text-dark button-div forecast-button">
                                        <span className="highlighted-text">{t('Forecast_RainSum')}</span>
                                        <br />
                                        {forecastIsLoading ? <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" /> :
                                            <span className="highlighted-green-number"><Icon style={forecastDropStyle} icon={ICONS.DROP} color='gray' size='24' />{getRainSum()} mm</span>
                                        }
                                    </div>
                                </button>

                                {/* --- D-Value --- */}
                                { dValueIsLoading || getButtonDValue(DValueData) ? 
                                    <button
                                        key={"dValue"}
                                        onClick={() => {
                                            setdValueListModalShow(true);
                                        }}
                                        className="customButton">
                                        <div className="text-dark button-div forecast-button">
                                            <span className="highlighted-text">{t('DValue')}</span>
                                            <br />
                                            {(dValueIsLoading) ? <Spinner className="badge-loading" as="span" animation="border" size="sm"  role="status" variant="secondary" /> : <span className="highlighted-green-number">{getButtonDValue   (DValueData)} </span>}
                                        </div>
                                    </button>
                                    : ''}

                                {/* --- GrowthStage --- */}
                                { growthStageIsLoading || barleyStageCard ? 
                                    <button
                                        key={"growthStageBarley"}
                                        onClick={() => {
                                            setGrowthStageListModalShow(true);
                                            setGrowthStageListModalData(growthStageData.filter(d => d.plantId.startsWith("13")));
                                        }}
                                        className="customButton">
                                        <div className="text-dark button-div forecast-button">
                                            <span className="highlighted-text">{t('Plant_Barley')}</span>
                                            <br />
                                            {(growthStageIsLoading) ? <Spinner className="badge-loading" as="span" animation="border" size="sm"     role="status" variant="secondary" />
                                                : <div>
                                                    <div className="growthstage-card-row">
                                                        <div className="growthstage-green-number">{barleyStageCard && barleyStageCard.growthStage != -1 ? barleyStageCard.growthStage : ''}  </div>
                                                        <div className="growthstage-card-text">{barleyStageCard ? shortenText(getGrowthStageText    (barleyStageCard.growthStage, t)) : ''}</div>
                                                    </div>
                                                    <div className="growthstage-card-date">{barleyStageCard && barleyStageCard.harvest ?    dayJsFormatDate(barleyStageCard.harvest) : ''}</div>
                                                </div>
                                            }
                                        </div>
                                    </button>
                                    : ''}

                                { growthStageIsLoading || wheatStageCard ?
                                    <button
                                        key={"growthStageWheat"}
                                        onClick={() => {
                                            setGrowthStageListModalShow(true);
                                            setGrowthStageListModalData(growthStageData.filter(d => d.plantId.startsWith("11")));
                                        }}
                                        className="customButton">
                                        <div className="text-dark button-div forecast-button">
                                            <span className="highlighted-text">{t('Plant_Wheat')}</span>
                                            <br />
                                            {(growthStageIsLoading) ? <Spinner className="badge-loading" as="span" animation="border" size="sm"     role="status" variant="secondary" />
                                                : <div>
                                                    <div className="growthstage-card-row">
                                                        <div className="growthstage-green-number">{wheatStageCard && wheatStageCard.growthStage != -1 ? wheatStageCard.growthStage : ''}</  div>
                                                        <div className="growthstage-card-text">{wheatStageCard ? shortenText(getGrowthStageText (wheatStageCard.growthStage, t)) : ''}</div>
                                                    </div>
                                                    <div className="growthstage-card-date">{wheatStageCard && wheatStageCard.harvest ? dayJsFormatDate  (wheatStageCard.harvest) : ''}</div>
                                                </div>
                                            }
                                        </div>
                                    </button>
                                    : ''}

                                { growthStageIsLoading || oatStageCard ?
                                     <button
                                         key={"growthStageOat"}
                                         onClick={() => {
                                             setGrowthStageListModalShow(true);
                                             setGrowthStageListModalData(growthStageData.filter(d => d.plantId.startsWith("14")));
                                         }}
                                         className="customButton">
                                         <div className="text-dark button-div forecast-button">
                                             <span className="highlighted-text">{t('Plant_Oat')}</span>
                                             <br />
                                             {(growthStageIsLoading) ? <Spinner className="badge-loading" as="span" animation="border" size="sm"        role="status" variant="secondary" />
                                                 : <div>
                                                     <div className="growthstage-card-row">
                                                         <div className="growthstage-green-number">{oatStageCard && oatStageCard.growthStage != -1 ? oatStageCard.growthStage : ''} </    div>
                                                         <div className="growthstage-card-text">{oatStageCard ? shortenText(getGrowthStageText      (oatStageCard.growthStage, t)) : ''}</div>
                                                     </div>
                                                     <div className="growthstage-card-date">{oatStageCard && oatStageCard.harvest ? dayJsFormatDate     (oatStageCard.harvest) : ''}</div>
                                                 </div>
                                             }
                                         </div>
                                    </button>
                                    : ''}

                            </div>
                        </div>
                    </Card.Body>
                </Card>
                : ''}
            {/* --- END OF Forecasts */}

            <Card className="lastCard">
                <Card.Body>
                    <div className="text-dark">
                        <GetEditIcon />
                        <h5>{t('Notes')}</h5>
                    </div>
                    {(isLoadingMemos) ? <Spinner animation="border" size="sm" role="status" variant="secondary" /> : ''}
                    <div className="App">
                        {(isLoadingMemos) ? '' : <ButtonGroup4 buttons={memosDraw} />}
                    </div>
                    <div id="div-show-all-memos" className="show-more-div d-none">
                        {(isLoadingMemos) ? '' : <Button variant="success" className="btn-show-more" onClick={() => { buttonShowMoreClick3(); }}>{t('ShowMore')}</Button>}
                    </div>
                </Card.Body>
            </Card>
            <Card >
                <Card.Body>
                    <div className="text-dark">
                        <GetFertilityIcon />
                        <h5>{t('Fertility')}</h5>
                    </div>
                    {(isLoadingSamples) ? <Spinner animation="border" size="sm" role="status" variant="secondary" /> : ''}
                    <div className="App">
                        <ButtonSampleSetPlanned buttons={sampleSetPlannedDraw} />                   
                        <ButtonSampleSetCollected buttons={sampleSetCollectedDraw} />
                    </div>
                    <div>
                        <div id="div-show-all-fertilities" className="show-more-div">
                            {(isLoadingSamples || !sessionResult) ? '' :
                                noSampleSets ?
                                    <Button variant="success" className="btn-show-more" onClick={() => { setMapModalShow(true); }}>{t('CreateSampleSet')}</Button>
                                    :
                                    <Button variant="success" className="btn-show-more" onClick={() => { buttonShowMoreSampleSetsCollected(); }}>{t('ShowMore')}</Button>
                            }
                        </div>
                    </div>                   
                </Card.Body>
            </Card>
            {showChoiceModal === true ?
                <ShowItemSelectionModal
                    show={showChoiceModal}
                    itemList={plantModal ? plants : plots}
                    plants={plantModal ? true : false}
                    plots={plotModal ? true : false}
                    onHide={() => {
                        handleCloseModal();
                        setShowChoiceModal(false);
                    }}
                />
                : ''}
            {memoModalShow === true ?
                <MemoModal
                    show={memoModalShow}
                    memotype={memoType}
                    onHide={() => setMemoModalShow(false)}
                    setRefresh={() => handleCloseModal()}
                    baseplotid={selectMemoEvent.basePlotId}
                    selectEvent={selectMemoEvent}
                />
                : ''}
            {memoListModal === true ?
                <ShowMemoListModal
                    show={memoListModal}
                    onHide={() => {
                        setMemoListModal(false);
                        handleCloseModal();
                    }}
                    memos={memos}
                />
                : ''}
            {sampleSetModalShow === true ?
                <SampleSetModal
                    show={sampleSetModalShow}
                    onHide={() => {
                        setSampleSetModalShow(false);
                        handleCloseModal();
                    }}
                    collected={collected}
                    selectEvent={selectSampleSetEvent}
                    refreshAndHandleOperationsSamples={(operations) => {
                        if (operations.length)
                            handleOperations(operations);
                    }}
                    refreshAndHandleOperationsSet={(operation) => {
                        if (operation) {
                            deleteSampleSet(operation);
                        }
                    }}
                    allowSave={sessionResult}
                />
                : ''}
            {sampleSetListModal === true ?
                <ShowSampleSetListModal
                    show={sampleSetListModal}
                    onHide={() => {
                        setSampleSetListModal(false);
                        handleCloseModal();
                    }}
                    sampleSetsCollected={sampleSetsCollected}
                    sampleSetsPlanned={sampleSetsPlanned}
                    collected={collected}
                    refreshAndHandleOperationsSamples={(operations) => {
                        if (operations.length)
                            handleOperations(operations);
                    }}
                    refreshAndHandleOperationsSet={(...operations) => {
                        if (operations[0]) {
                            saveSampleSet(operations[0]);
                        }
                        if (operations[1]) {
                            deleteSampleSet(operations[1]);
                        }
                    }}
                />
                : ''}
            {mapModal === true ?
                <MapModal
                    show={mapModal}
                    newSampleSet={true}
                    onHide={(newSamplePoints) => {
                        setMapModalShow(false);
                        if (newSamplePoints) {
                            saveSampleSet(newSamplePoints);
                        }
                    }}
                />
                : ''}
            {dValueListModalShow === true ?
                <ShowDValuesListModal
                    show={dValueListModalShow}
                    data={DValueData}
                    onHide={() => {
                        setdValueListModalShow(false);
                    }}
                />
                : ''}
            {growthStageListModalShow === true ?
                <ShowGrowthStageListModal
                    show={growthStageListModalShow}
                    data={growthStageListModalData}
                    onHide={() => {
                        setGrowthStageListModalShow(false);
                    }}
                />
                : ''}
            {chartModalShow === true ?
                <ChartModal
                    show={chartModalShow}
                    data={chartModalData}
                    chartMode={chartModalMode}
                    onHide={() => {
                        setChartModalShow(false);
                    }}
                />
                : ''}
        </Container>
    );
}

export default Farm;

