import MultiPoint from 'ol/geom/MultiPoint';
import { Fill, Circle, RegularShape, Stroke, Style, Text } from 'ol/style.js';
import { MapConfig } from './mapConfig';

let t = null;
export let setLang = (language) => {
    t = language;
}

export let retrieveMapFeatureStyle = function () {
    return new Style({
        image: new Circle({
            radius: 7,
            fill: new Fill({
                color: style_config.features.map_features.select.fillColor
            }),
            stroke: new Stroke({
                color: style_config.features.map_features.select.strokeColor,
                width: 2
            }),
        })
    })
}



var style_config = {

    zones: {
        Fertilizer: { fillColor: 'rgba(245, 245, 61, 0)', strokeColor: 'rgba(0, 0, 0, 0.7)' },
        Seed: { fillColor: 'rgba(150, 146, 20, 0)', strokeColor: 'rgba(0, 0, 0, 0.7)' },
        Pesticide: { fillColor: 'rgba(245, 245, 61, 0)', strokeColor: 'rgba(0, 0, 0, 0.7)' },
        Tillage: { fillColor: 'rgba(245, 245, 61, 0)', strokeColor: 'rgba(0, 0, 0, 0.7)' }
    },
    features: {
        base: {
            hover: { fillColor: 'rgba(219, 44, 44, 0.2)', strokeColor: 'rgba(219, 44, 44, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            select: { fillColor: 'rgba(255, 150, 0, 0)', strokeColor: 'rgba(255, 150, 0, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.8)' }
        },
        agr_plot: {
            label: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(0, 0, 0, 0)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(57, 178, 13, 0.8)' },
            default: { fillColor: 'rgba(57, 178, 13, 0)', strokeColor: 'rgba(57, 178, 13, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(57, 178, 13, 0.8)' },
            hover: { fillColor: 'rgba(57, 178, 13, 0)', strokeColor: 'rgba(57, 178, 13, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(57, 178, 13, 1)' },
            select: { fillColor: 'rgba(255, 150, 0, 0.5)', strokeColor: 'rgba(255, 150, 0, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(255, 150, 0, 0.8)' },
            modify: { fillColor: 'rgba(255, 255, 255, 1)', strokeColor: 'rgba(0, 0, 0, 1)', textColor: 'rgba(0, 0, 0, 1)', textLabelColor: 'rgba(255, 255, 255, 1)' }
        },
        base_plot: {
            label: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(0, 0, 0, 0)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            default: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(219, 44, 44, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            hover: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(219, 44, 44, 0)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            select: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(0, 0, 0, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            modify: { fillColor: 'rgba(255, 255, 255, 1)', strokeColor: 'rgba(0, 0, 0, 1)', textColor: 'rgba(0, 0, 0, 1)', textLabelColor: 'rgba(255, 255, 255, 1)' }
        },
        ffa_plot: {
            label: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(0, 0, 0, 0)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            default: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(219, 44, 244, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            hover: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(219, 44, 244, 0)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            select: { fillColor: 'rgba(255, 255, 255, 1)', strokeColor: 'rgba(220, 220, 220, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            modify: { fillColor: 'rgba(255, 255, 255, 1)', strokeColor: 'rgba(0, 0, 0, 1)', textColor: 'rgba(0, 0, 0, 1)', textLabelColor: 'rgba(255, 255, 255, 1)' }
        },
        mavi_plot: {
            default: { fillColor: 'rgba(170, 47, 201, 0)', strokeColor: 'rgba(170, 47, 201, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            hover: { fillColor: 'rgba(170, 47, 201, 0.4)', strokeColor: 'rgba(170, 47, 201, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(170, 47, 201, 0.8)' },
            select: { fillColor: 'rgba(0, 77, 153, 0)', strokeColor: 'rgba(20, 50, 250, 1)', textColor: 'rgba(61, 31, 81, 1)', textLabelColor: 'rgba(255, 255, 255, 1)' },
            modify: { fillColor: 'rgba(28, 120, 55, 0)', strokeColor: 'rgba(0, 100, 0, 0.9)', textColor: 'rgba(0, 0, 0, 1)', textLabelColor: 'rgba(255, 255, 255, 1)' }
        },
        cultivated_land: {
            default: { fillColor: 'rgba(255, 250, 0, 0)', strokeColor: 'rgba(255, 250, 0, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            select: { fillColor: 'rgba(57, 178, 13, 0)', strokeColor: 'rgba(255, 150, 0, 1)', textColor: 'rgba(61, 31, 81, 1)', textLabelColor: 'rgba(255, 255, 255, 1)' }
        },
        map_features: {
            label: { fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(0, 0, 0, 0)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(57, 178, 13, 0.8)' },
            default: { fillColor: 'rgba(89, 89, 89, 1)', strokeColor: 'rgba(89, 89, 89, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(50, 50, 50, 0.6)' },
            hover: { fillColor: 'rgba(170, 47, 201, 0.4)', strokeColor: 'rgba(170, 47, 201, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(170, 47, 201, 0.8)' },
            select: { fillColor: 'rgba(255, 255, 255, 1)', strokeColor: 'rgba(69, 197, 0, 1)', textColor: 'rgba(255, 255, 255, 1)', textLabelColor: 'rgba(255, 255, 255, 1)' },
            yellowFill: { fillColor: 'yellow' },
            violetFill: { fillColor: 'rgba(170, 47, 201, 1)' },
            greenFill: { fillColor: 'green' },
            redFill: { fillColor: 'red' },
            blueFill: { fillColor: 'rgba(255, 165, 0, 1)' }
        }
    },
    grid: {
        ndvi: {
            0.00: { fillColor: 'rgba(205,125,70, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.05: { fillColor: 'rgba(215,151,56, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.10: { fillColor: 'rgba(225,177,42, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.15: { fillColor: 'rgba(235,203,28, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.20: { fillColor: 'rgba(245,229,14, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.25: { fillColor: 'rgba(255,255,0, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.30: { fillColor: 'rgba(238,242,1, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.35: { fillColor: 'rgba(221,229,3, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.40: { fillColor: 'rgba(205,217,5, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.45: { fillColor: 'rgba(188,204,6, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.50: { fillColor: 'rgba(172,192,8, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.55: { fillColor: 'rgba(155,179,10, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.60: { fillColor: 'rgba(139,167,12, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.65: { fillColor: 'rgba(122,154,13, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.70: { fillColor: 'rgba(106,142,15, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.75: { fillColor: 'rgba(89,129,17, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.80: { fillColor: 'rgba(73,117,19, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.85: { fillColor: 'rgba(62,103,16, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.90: { fillColor: 'rgba(52,89,14, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            0.95: { fillColor: 'rgba(42,75,12, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            1.00: { fillColor: 'rgba(32,61,10, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' }
        },
        fertilization: {
            0: { fillColor: 'rgba(252, 251, 253, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            5: { fillColor: 'rgba(247, 246, 250, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            10: { fillColor: 'rgba(242, 241, 247, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            15: { fillColor: 'rgba(236, 234, 244, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            20: { fillColor: 'rgba(228, 227, 240, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            25: { fillColor: 'rgba(220, 220, 236, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            30: { fillColor: 'rgba(209, 209, 231, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            35: { fillColor: 'rgba(198, 198, 225, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            40: { fillColor: 'rgba(186, 187, 219, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            45: { fillColor: 'rgba(174, 173, 211, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            50: { fillColor: 'rgba(163, 160, 203, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            55: { fillColor: 'rgba(151, 147, 197, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            60: { fillColor: 'rgba(140, 136, 192, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            65: { fillColor: 'rgba(128, 125, 186, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            70: { fillColor: 'rgba(117, 103, 175, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            75: { fillColor: 'rgba(106, 81, 163, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            80: { fillColor: 'rgba(99, 67, 156, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            85: { fillColor: 'rgba(91, 53, 150, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            90: { fillColor: 'rgba(84, 39, 143, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            95: { fillColor: 'rgba(73, 19, 134, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            100: { fillColor: 'rgba(63, 0, 125, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' }
        },
        tillage: {
            0: { fillColor: 'rgba(235, 225, 0, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            75: { fillColor: 'rgba(210, 175, 5, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            150: { fillColor: 'rgba(180, 140, 0, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            225: { fillColor: 'rgba(190, 130, 5, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            300: { fillColor: 'rgba(175, 105, 0, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' }
        },
        seeding: {
            0: { fillColor: 'rgba(205, 175, 120, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            25: { fillColor: 'rgba(196, 150, 71, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            50: { fillColor: 'rgba(169, 129, 61, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            75: { fillColor: 'rgba(142, 109, 51, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            100: { fillColor: 'rgba(115, 88, 42, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' }
        },
        spraying: {
            0: { fillColor: 'rgba(230, 245, 250, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            25: { fillColor: 'rgba(165, 205, 230, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            50: { fillColor: 'rgba(90, 160, 210, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            75: { fillColor: 'rgba(12, 110, 205, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' },
            100: { fillColor: 'rgba(10, 70, 160, 0.9)', strokeColor: 'rgba(0, 0, 0, 1)' }
        },
        default: {
            fillColor: 'rgba(255, 255, 255, 0)', strokeColor: 'rgba(0, 0, 0, 1)'
        }

    },
};

export const _styles = {

    isobus: {
        zones: function (type, unit) {

            this.default = function (feature, resolution) {

                if (typeof type !== "undefined" && type !== feature.get('Type')) {
                    return false;
                }

                var properties = feature.getProperties();
                var PDV = properties.PDV;
                var zoneId = feature.get('ZoneId');

                var styleParams = style_config.zones[feature.get('Type')];

                var styles = [
                    new Style({
                        fill: new Fill({
                            color: styleParams.fillColor
                        }),
                        stroke: new Stroke({
                            color: styleParams.strokeColor,
                            width: 2
                        }),
                        zIndex: 100
                    }),
                    new Style({
                        /*
                        text: new Text({
                            font: '10px arial',
                            scale: 1.2,
                            text: '(' + id + ') ' + PDV + ' kg/ha',
                            fill: new Fill({
                                color: 'rgba(0, 0, 0, 1)',
                            })                              
                        }),
                        zIndex: 100
                        */

                        text: new Text({
                            font: '10px arial',
                            scale: 1.2,
                            text: '(' + zoneId + ') ' + PDV + ' ' + unit,
                            fill: new Fill({
                                color: 'rgba(0, 0, 0, 0.9)',
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            backgroundFill: new Fill({
                                color: 'rgba(245, 245, 61, 0.7)'
                            }),

                            padding: [3, 5, 0, 5]
                        }),
                        zIndex: 100

                    })
                ];

                return styles;
            };

            return this;

        },

        grid: {
            default: function (feature, resolution) {
                var styleParams = style_config.grid.default;
                return [
                    new Style({
                        fill: new Fill({
                            color: styleParams.fillColor
                        }),
                        stroke: new Stroke({
                            color: styleParams.strokeColor,
                            width: 0.1
                        }),
                        zIndex: 100
                    })
                ];
            },

            sentinel: function (feature, resolution) {
                var properties = feature.getProperties();
                var ndvi = properties.NDVI;

                if (parseInt(ndvi) === -9999) {
                    return false;
                }

                var style_ndvi = (ndvi > 0) ? Math.round((ndvi / 0.05), 2) * 0.05 : 0;
                var styleParams = style_config.grid.ndvi[parseFloat(Number(style_ndvi).toFixed(2))];
                return [
                    new Style({
                        fill: new Fill({
                            color: styleParams.fillColor
                        }),
                        stroke: new Stroke({
                            color: styleParams.strokeColor,
                            width: 0.1
                        }),
                        zIndex: 100
                    })
                    /*new Style({
                        text: new Text({
                            font: '10px arial',
                            scale: 1,
                            text: '' + ndvi.toFixed(2),
                            fill: new Fill({
                                color: 'rgba(0, 0, 0, 1)'
                            })
                        }),
                        zIndex: 100
                    })*/
                ];
            },

            // TESTED
            tillage: function (min_value, max_value) {

                this.colormap = style_config.grid.tillage;

                this.default = function (feature, resolution) {
                    var properties = feature.getProperties();
                    var amount = parseFloat(properties.values[0]).toFixed(0);
                    //if (properties.Values.Tillage.Overlay !== null) {
                    //    amount = parseFloat(properties.Values.Tillage.Overlay).toFixed(0);
                    //}
                    var value = (amount > 0) ? parseInt(((Math.round(amount / 75) * 75)).toFixed(0)) : 0;
                    if (parseInt(value) < 0) {
                        value = 0;
                    } else if (value > 300) {
                        value = 300;
                    }

                    var styleParams = style_config.grid.tillage[value];

                    return [
                        new Style({
                            fill: new Fill({
                                color: styleParams.fillColor
                            }),
                            stroke: new Stroke({
                                color: styleParams.strokeColor,
                                width: 0.1
                            }),
                            zIndex: 100
                        }),
                        new Style({
                            text: new Text({
                                font: '8px arial',
                                scale: 1,
                                text: '' + amount,
                                fill: new Fill({
                                    color: 'rgba(0, 0, 0, 1)'
                                })
                            }),
                            zIndex: 100
                        })
                    ];
                }

                return this;

            },

            // TESTED
            seeding: function (min_value, max_value) {

                this.colormap = style_config.grid.seeding;

                this.default = function (feature, resolution) {
                    var properties = feature.getProperties();
                    var amount = parseFloat(properties.values[0]).toFixed(0);
                    //if (properties.Values.Seed.Overlay !== null) {
                    //    amount = parseFloat(properties.Values.Seed.Overlay).toFixed(0);
                    //}
                    var value = (amount > 0) ? parseInt(((Math.round(amount / 25) * 25)).toFixed(0)) : 0;
                    if (parseInt(value) < 0) {
                        value = 0;
                    } else if (value > 100) {
                        value = 100;
                    }

                    var styleParams = style_config.grid.seeding[value];

                    return [
                        new Style({
                            fill: new Fill({
                                color: styleParams.fillColor
                            }),
                            stroke: new Stroke({
                                color: styleParams.strokeColor,
                                width: 0.1
                            }),
                            zIndex: 100
                        }),
                        new Style({
                            text: new Text({
                                font: '8px arial',
                                scale: 1,
                                text: '' + amount,
                                fill: new Fill({
                                    color: 'rgba(0, 0, 0, 1)'
                                })
                            }),
                            zIndex: 100
                        })
                    ];
                }

                return this;

            },

            // TESTED
            fertilizing: function (min_value, max_value) {

                this.colormap = style_config.grid.fertilization;

                this.default = function (feature, resolution) {
                    var properties = feature.getProperties();
                    var amount = parseFloat(properties.values[0]).toFixed(0);
                    //if (properties.Values.Fertilizer.Overlay !== null) {
                    //    amount = parseFloat(properties.Values.Fertilizer.Overlay).toFixed(0);
                    //}

                    var scale = max_value - min_value;
                    var percentage = (amount > 0) ? ((Math.round((amount / scale) / 0.05) * 0.05) * 100).toFixed(0) : 0;

                    if (parseInt(percentage) < 0) {
                        percentage = 0;
                    } else if (percentage > 100) {
                        percentage = 100;
                    }

                    var styleParams = style_config.grid.fertilization[percentage];

                    return [
                        new Style({
                            fill: new Fill({
                                color: styleParams.fillColor
                            }),
                            stroke: new Stroke({
                                color: styleParams.strokeColor,
                                width: 0.1
                            }),
                            zIndex: 100
                        }),
                        new Style({
                            text: new Text({
                                font: '8px arial',
                                scale: 1,
                                text: '' + amount,
                                fill: new Fill({
                                    color: 'rgba(0, 0, 0, 1)'
                                })
                            }),
                            zIndex: 100
                        })
                    ];
                }

                return this;

            },

            fertilizing_fixed: function () {

                this.colormap = style_config.grid.fertilization;

                this.default = function (feature, resolution) {
                    var properties = feature.getProperties();
                    var amount = parseFloat(properties.Values.Fertilizer.Default).toFixed(0);
                    if (properties.Values.Fertilizer.Overlay !== null) {
                        amount = parseFloat(properties.Values.Fertilizer.Overlay).toFixed(0);
                    }

                    var value = (amount > 0) ? parseInt(((Math.round(amount / 10) * 10)).toFixed(0)) : 0;
                    if (parseInt(value) < 0) {
                        value = 0;
                    } else if (value > 100) {
                        value = 100;
                    }

                    var styleParams = style_config.grid.fertilization[value];

                    return [
                        new Style({
                            fill: new Fill({
                                color: styleParams.fillColor
                            }),
                            stroke: new Stroke({
                                color: styleParams.strokeColor,
                                width: 0.1
                            }),
                            zIndex: 100
                        }),
                        new Style({
                            text: new Text({
                                font: '8px arial',
                                scale: 1,
                                text: '' + amount,
                                fill: new Fill({
                                    color: 'rgba(0, 0, 0, 1)'
                                })
                            }),
                            zIndex: 100
                        })
                    ];
                }

                return this;

            },

            fertilizing_range: function (min_value, max_value) {

                this.colormap = style_config.grid.fertilization;

                this.default = function (feature, resolution) {
                    var properties = feature.getProperties();

                    var amount = parseFloat(properties.Values.Fertilizer.Default).toFixed(0);
                    if (properties.Values.Fertilizer.Overlay !== null) {
                        amount = parseFloat(properties.Values.Fertilizer.Overlay).toFixed(0);
                    }

                    var percentage = (amount - min_value) / (max_value - min_value) * 100;
                    var value = (percentage > 0) ? parseInt(((Math.round(percentage / 5) * 5)).toFixed(0)) : 0;

                    if (parseInt(value) < 0) {
                        value = 0;
                    } else if (value > 100) {
                        value = 100;
                    }

                    var styleParams = style_config.grid.fertilization[value];

                    return [
                        new Style({
                            fill: new Fill({
                                color: styleParams.fillColor
                            }),
                            stroke: new Stroke({
                                color: styleParams.strokeColor,
                                width: 0.1
                            }),
                            zIndex: 100
                        }),
                        new Style({
                            text: new Text({
                                font: '8px arial',
                                scale: 1,
                                text: '' + amount,
                                fill: new Fill({
                                    color: 'rgba(0, 0, 0, 1)'
                                })
                            }),
                            zIndex: 100
                        })
                    ];
                }

                return this;

            },

            // TESTED
            spraying: function (min_value, max_value) {

                this.colormap = style_config.grid.spraying;

                this.default = function (feature, resolution) {
                    var properties = feature.getProperties();

                    var amount = parseFloat(properties.values[0]).toFixed(0);
                    ////if (properties.Values.Pesticide.Overlay !== null) {
                    ////    amount = parseFloat(properties.Values.Pesticide.Overlay).toFixed(0);
                    ////}

                    var percentage = (amount - min_value) / (max_value - min_value) * 100;
                    var value = (percentage > 0) ? parseInt(((Math.round(percentage / 25) * 25)).toFixed(0)) : 0;

                    if (parseInt(value) < 0) {
                        value = 0;
                    } else if (value > 100) {
                        value = 100;
                    }

                    var styleParams = style_config.grid.spraying[value];

                    return [
                        new Style({
                            fill: new Fill({
                                color: styleParams.fillColor
                            }),
                            stroke: new Stroke({
                                color: styleParams.strokeColor,
                                width: 0.1
                            }),
                            zIndex: 100
                        }),
                        new Style({
                            text: new Text({
                                font: '8px arial',
                                scale: 1,
                                text: '' + amount,
                                fill: new Fill({
                                    color: 'rgba(0, 0, 0, 1)'
                                })
                            }),
                            zIndex: 100
                        })
                    ];
                }

                return this;
            },


            spraying_fixed: function () {

                this.colormap = style_config.grid.spraying;

                this.default = function (feature, resolution) {
                    var properties = feature.getProperties();
                    var amount = parseFloat(properties.Values.Pesticide.Default).toFixed(0);
                    if (properties.Values.Pesticide.Overlay !== null) {
                        amount = parseFloat(properties.Values.Pesticide.Overlay).toFixed(0);
                    }

                    var value = (amount > 0) ? parseInt(((Math.round(amount / 25) * 25)).toFixed(0)) : 0;
                    if (parseInt(value) < 0) {
                        value = 0;
                    } else if (value > 100) {
                        value = 100;
                    }

                    var styleParams = style_config.grid.spraying[value];

                    return [
                        new Style({
                            fill: new Fill({
                                color: styleParams.fillColor
                            }),
                            stroke: new Stroke({
                                color: styleParams.strokeColor,
                                width: 0.1
                            }),
                            zIndex: 100
                        }),
                        new Style({
                            text: new Text({
                                font: '8px arial',
                                scale: 1,
                                text: '' + amount,
                                fill: new Fill({
                                    color: 'rgba(0, 0, 0, 1)'
                                })
                            }),
                            zIndex: 100
                        })
                    ];
                }

                return this;

            }

        },

        draw: {
            default: function (feature, resolution) {
                return [


                    new Style({
                        image: new Circle({
                            fill: new Fill({
                                color: 'rgba(0, 0, 0, 0.8)',
                            }),
                            radius: 7
                        }),
                        zIndex: 100
                    }),
                    new Style({
                        image: new Circle({
                            fill: new Fill({
                                color: 'rgba(245, 245, 61, 0.9)'
                            }),
                            radius: 6
                        }),
                        zIndex: 100
                    }),

                    new Style({
                        image: new Circle({
                            fill: new Fill({
                                color: 'rgba(245, 245, 61, 0.9)',
                            }),
                            radius: 5
                        }),
                        zIndex: 200,
                        geometry: function (feature) {
                            var coordinates = feature.getGeometry().getCoordinates();
                            return new MultiPoint(coordinates);
                        }
                    }),

                    new Style({
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.8)',
                            width: 2
                        }),
                        fill: new Fill({
                            color: 'rgba(0, 0, 0, 0.2)'
                        }),
                        zIndex: 100
                    })

                ];
            }
        }

    },

    agr_plot: {
        label: function (feature, resolution) {
            var p = feature.getProperties();
            var styles = [];
            styles.push(
                new Style({
                    fill: new Fill({
                        color: style_config.features.agr_plot.label.fillColor
                    }),
                    stroke: new Stroke({
                        color: style_config.features.agr_plot.label.strokeColor,
                        width: 2.5
                    }),
                    zIndex: 0
                })
            );
            if (resolution <= 2) {
                styles.push(
                    new Style({
                        text: new Text({
                            font: '12px arial',
                            scale: 1,
                            text: p.BasePlotName,
                            fill: new Fill({
                                color: style_config.features.agr_plot.label.textColor
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            backgroundFill: new Fill({
                                color: style_config.features.agr_plot.label.textLabelColor
                            }),
                            offsetY: -15,
                            padding: [3, 5, 0, 5],
                            overflow: true
                        }),
                        zIndex: 1000
                    })
                )
            }
            return styles;
        },
        default: function (feature, resolution) {
            var p = feature.getProperties();
            var styles = [];

            if (p.Source === 'ffa') {
                styles.push(
                    new Style({
                        fill: new Fill({
                            color: style_config.features.ffa_plot.default.fillColor
                        }),
                        stroke: new Stroke({
                            color: style_config.features.ffa_plot.default.strokeColor,
                            width: 2.5
                        }),
                        zIndex: 0
                    })
                );
            }
            else {
                styles.push(
                    new Style({
                        fill: new Fill({
                            color: style_config.features.agr_plot.default.fillColor
                        }),
                        stroke: new Stroke({
                            color: style_config.features.agr_plot.default.strokeColor,
                            width: 2.5
                        }),
                        zIndex: 0
                    })
                );
            }

            if (resolution <= 5) {
                styles.push(
                    new Style({
                        text: new Text({
                            font: '12px arial',
                            scale: 1,
                            text: p.BasePlotName + ' - ' + p.PlotLetter + " (" + p.Area + " ha)",
                            fill: new Fill({
                                color: style_config.features.agr_plot.label.textColor
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            backgroundFill: new Fill({
                                color: style_config.features.agr_plot.label.textLabelColor
                            }),
                            offsetY: 5,
                            padding: [3, 5, 0, 5],
                            overflow: true
                        }),
                        zIndex: 1000
                    }));
                if (resolution <= 3) {
                    styles.push(
                        new Style({
                            text: new Text({
                                font: 'bold 12px arial',
                                scale: 1,
                                text: p.PlantName || '',
                                fill: new Fill({
                                    color: style_config.features.agr_plot.label.textColor
                                }),
                                placement: 'point',
                                textAlign: 'center',
                                offsetY: 25,
                                backgroundFill: new Fill({
                                    color: style_config.features.agr_plot.label.textLabelColor
                                }),
                                padding: [3, 3, 0, 3],
                                overflow: true
                            }),
                            zIndex: 100
                        })
                    )
                }
            }
            return styles;
        },
        hover: function (feature, resolution) {
            var p = feature.getProperties();
            const [start, bolded, end] = p.BasePlotId; //formatBasePlotId(RetrieveBaseplotIdManipulation(p.BasePlotId));
            var styles = [];
            styles.push(
                new Style({
                    fill: new Fill({
                        color: style_config.features.agr_plot.hover.fillColor
                    }),
                    stroke: new Stroke({
                        color: style_config.features.agr_plot.hover.strokeColor,
                        width: 2.5
                    }),
                    zIndex: 0
                })
            );
            if (resolution <= 2) {
                styles.push(
                    new Style({
                        text: new Text({
                            font: '12px arial',
                            scale: 1,
                            text: start,
                            fill: new Fill({
                                color: style_config.features.agr_plot.hover.textColor
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            backgroundFill: new Fill({
                                color: style_config.features.agr_plot.default.textLabelColor
                            }),
                            offsetY: -15,
                            offsetX: -30,
                            overflow: true,
                            padding: [3, 0, 0, 5]
                        }),
                        zIndex: 1000
                    }),
                    new Style({
                        text: new Text({
                            font: 'bold 12px arial',
                            scale: 1,
                            text: bolded,
                            fill: new Fill({
                                color: style_config.features.agr_plot.hover.textColor
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            backgroundFill: new Fill({
                                color: style_config.features.agr_plot.default.textLabelColor
                            }),
                            offsetY: -15,
                            offsetX: -1.2,
                            overflow: true,
                            padding: [3, 0, 0, 0]
                        }),
                        zIndex: 1000
                    }),
                    new Style({
                        text: new Text({
                            font: '12px arial',
                            scale: 1,
                            text: end,
                            fill: new Fill({
                                color: style_config.features.agr_plot.hover.textColor
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            backgroundFill: new Fill({
                                color: style_config.features.agr_plot.default.textLabelColor
                            }),
                            offsetY: -15,
                            offsetX: 24,
                            overflow: true,
                            padding: [3, 5, 0, 0]
                        }),
                        zIndex: 1000
                    })
                )
            }
            return styles;
        },
        select: function (feature, resolution) {
            var p = feature.getProperties();
            var styles = [];
            styles.push(
                new Style({
                    fill: new Fill({
                        color: style_config.features.agr_plot.select.fillColor
                    }),
                    stroke: new Stroke({
                        color: style_config.features.agr_plot.select.strokeColor,
                        width: 2.5
                    }),
                    zIndex: 1
                })
            );

            if (resolution <= 5) {
                styles.push(
                    new Style({
                        text: new Text({
                            font: '12px arial',
                            scale: 1,
                            text: p.BasePlotName + ' - ' + p.PlotLetter + " (" + p.Area + " ha)",
                            fill: new Fill({
                                color: style_config.features.agr_plot.label.textColor
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            backgroundFill: new Fill({
                                color: style_config.features.agr_plot.label.textLabelColor
                            }),
                            offsetY: 5,
                            padding: [3, 5, 0, 5],
                            overflow: true
                        }),
                        zIndex: 1000
                    })
                );

                if (resolution <= 3) {
                    styles.push(
                        new Style({
                            text: new Text({
                                font: 'bold 12px arial',
                                scale: 1,
                                text: p.PlantName || '',
                                fill: new Fill({
                                    color: style_config.features.agr_plot.label.textColor
                                }),
                                placement: 'point',
                                textAlign: 'center',
                                offsetY: 25,
                                backgroundFill: new Fill({
                                    color: style_config.features.agr_plot.label.textLabelColor
                                }),
                                padding: [3, 3, 0, 3],
                                overflow: true
                            }),
                            zIndex: 100
                        })
                    )
                }
            }

            return styles;
        }
    },

    map_features: {
        default: function (feature, resolution) {
            var styles = [];
            return styles;

        },
        select: function (feature, resolution) {
            var p = feature.getProperties();
            var styles = [];
            let text = '';

            if (p.FeatureType === MapConfig.FEATURE.OBSERVATION) { // observation star
                styles.push(
                    new Style({
                        image: new RegularShape({
                            fill: new Fill({ color: style_config.features.map_features.redFill.fillColor }),
                            stroke: new Stroke({ color: style_config.features.map_features.default.strokeColor, width: 1 }),
                            points: 5,
                            radius: 8,
                            radius2: 4,
                            angle: 0
                        })
                    })
                )

                text = p.Note ? p.Note : t('Observation');
            }
            else if (p.FeatureType === MapConfig.FEATURE.NOTE) { // note
                styles.push(
                    new Style({
                        image: new RegularShape({
                            fill: new Fill({ color: style_config.features.map_features.yellowFill.fillColor }),
                            stroke: new Stroke({ color: style_config.features.map_features.default.strokeColor, width: 1 }),
                            points: 4,
                            radius: 6,
                            angle: Math.PI / 4
                        })
                    })
                );
                text = p.Note ? p.Note : t('Note');
            }
            else if (p.FeatureType === MapConfig.FEATURE.TREE) { // tree
                styles.push(
                    new Style({
                        image: new RegularShape({
                            fill: new Fill({ color: style_config.features.map_features.greenFill.fillColor }),
                            stroke: new Stroke({ color: style_config.features.map_features.default.strokeColor, width: 1 }),
                            points: 3,
                            radius: 7,
                            angle: 0
                        })
                    })
                )
                text = t('Tree');
            }
            else if (p.FeatureType === MapConfig.FEATURE.ROCK ||
                p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES || MapConfig.FEATURE.FERTILITY_SAMPLE || MapConfig.FEATURE.NEW_FERTILITY_SERIES || MapConfig.FEATURE.FERTILITY_SERIES_PLANNED) {
                // 5308 rock, 5305 fertility, 5311 fertility series, 5411 new fertility series point
                styles.push(
                    new Style({
                        image: new Circle({
                            radius: 7,
                            fill: (p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES || p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES_COLLECTED) ?
                                new Fill({ color: style_config.features.map_features.violetFill.fillColor }) :
                                (p.FeatureType === MapConfig.FEATURE.FERTILITY_SAMPLE) ?
                                    new Fill({ color: style_config.features.map_features.yellowFill.fillColor }) :
                                    (p.FeatureType === MapConfig.FEATURE.NEW_FERTILITY_SERIES) ?
                                        new Fill({ color: style_config.features.map_features.yellowFill.fillColor }) :
                                        (p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES_PLANNED) ?
                                            new Fill({ color: style_config.features.map_features.blueFill.fillColor }) :
                                            new Fill({ color: style_config.features.map_features.default.strokeColor }),

                            stroke: (p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES_PLANNED || p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES_COLLECTED) ?
                                new Stroke({ color: style_config.features.map_features.select.strokeColor, width: 3 }) :
                                new Stroke({ color: style_config.features.map_features.default.strokeColor, width: 1 }),
                        })
                    })
                )
                if (p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES || p.FeatureType === MapConfig.FEATURE.FERTILITY_SAMPLE ||
                    p.FeatureType === MapConfig.FEATURE.NEW_FERTILITY_SERIES || p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES_PLANNED ||
                    p.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES_COLLECTED) {
                    if (p.Note) {
                        text = p.UnitType > 0 ? p.UnitType + " / " + p.Note : p.Note;
                    }
                    else
                        text = t('FertilitySample');
                }
                else
                    text = t('Rock');
            }
            else { // border flag
                styles.push(
                    new Style({
                        image: new RegularShape({
                            fill: null,
                            stroke: new Stroke({ color: style_config.features.map_features.default.strokeColor, width: 1 }),
                            points: 4,
                            radius: 10,
                            radius2: 0,
                            angle: Math.PI / 4
                        })
                    })
                )
                text = t('BorderMarkersLayer');
            }

            if (resolution <= 1)
                styles.push(
                    new Style({
                        text: new Text({
                            font: 'bold 12px arial',
                            scale: 1,
                            text: text,
                            fill: new Fill({
                                color: style_config.features.map_features.label.textColor
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            offsetY: 20,
                            backgroundFill: new Fill({
                                color: style_config.features.map_features.label.textLabelColor
                            }),
                            padding: [3, 3, 0, 3],
                            overflow: true
                        })
                    })
                );

            return styles;
        }
    }

};

export let init = function () {
    return _styles;
}