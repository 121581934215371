import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import AppContext from '../../AppContext';
import { getContentWithAction, postContentWithAction } from '../../apiConnect';
import { getSelectedPlot, getSelectedLang } from '../../common';
import { textStyle, rowStyle, addNoteRowStyle, addButtonStyle } from '../ComponentStyles/ButtonStyles.js'

import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import '../../css/general.css'
import '../../css/plotOperation.css'
import '../../css/badge.css'

import * as Cards from '../../Constants/AccordionCardConstants.js';
import { toggleActive, formatPlannedDate, dayJsFormatDate } from '../../commonFunctions.js'
import { OperationCardToggle } from '../../commonElements.js'


const SowAccordionCard = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [sows, setSows] = useState([]);
    const [memos, setMemos] = useState([]);
    const [sowIsLoading, setSowIsLoading] = useState(true);
    const [memoIsLoading, setMemoIsLoading] = useState(true);
    const [modalDisabled, setModalDisabled] = useState(false);

    useEffect(() => {
        var plot = getSelectedPlot();
        if (plot) {
            setSowIsLoading(true);

            if (plot.plantId === 0)
                setModalDisabled(true);

            getContentWithAction(auth.getAccessToken(), 'sow', 'sows', { plotId: plot.apNumber, lang: getSelectedLang() }).then((sows) => {
                setSows(sows);
                setSowIsLoading(false);
            });
        }
    }, [props.refresh]);

   useEffect(() => {        
        var memos = props.memos.filter(m => m.plantMemoType === 1);
        memos.sort((a, b) => b.memo > a.memo ? -1 : 1);
        setMemos(memos);
        setMemoIsLoading(false);
    }, [props.memos])

    const setSowDone = (sow) => {
        var id = sow.number;
        var plotId = sow.plotId;
        // Updating individual sow to show spinner
        const updatedSows = sows.map(s => s === sow ? { ...s, saveInProgress: true } : s);
        setSows(updatedSows);

        postContentWithAction(auth.getAccessToken(), 'sow', 'done', { id: id, plotId: plotId }, () =>
        {
            // Updating individual sow to hide spinner
            const updatedSows = sows.map(s => s === sow ? { ...s, saveInProgress: false } : s);
            setSows(updatedSows);
            setSowIsLoading(true);
            props.setRefresh(Date.now());
        });
    }

    const getPlantLetter = (sow) => {
        return sow.description.substring(0,1);
    }

    return (
        <Card className="border-bottom-1">
            <Card.Header className={props.activeCard === Cards.sowCardId ? 'active-panel' : 'inactive-panel'}>
                <OperationCardToggle classname={props.activeCard === Cards.sowCardId ? 'active-panel' : 'inactive-panel'} callback={() => toggleActive(props.activeCard, Cards.sowCardId, props.setActiveCard)} eventKey="0">
                    {t('Sowings')}
                    {sowIsLoading ? <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" /> : <Badge pill bg='light' text='dark' className="badge-pill">{sows.length}</Badge>}
                </OperationCardToggle>
                <Button variant='mmfi-add-btn' style={addButtonStyle} className={modalDisabled ? "float-right disabled-color" : "float-right"}
                    disabled={modalDisabled ? true : false}
                    onClick={() => {
                    props.setSelectEvent({ time: Date.now(), selectedElement: null });
                    props.setModalShow(true);
                }}><Icon icon={ICONS.ADD} color='white' size='16' /></Button>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body className={sowIsLoading ? "card-body-loading" : "card-body-loaded"}>
                    <ListGroup>
                        {(sows) ? sows.map(p =>
                            <div key={p.number} id={p.number} className="row-margin-bot">
                                <div className='row-icon-left row-icon-operation'>{getPlantLetter(p)}</div>

                                <ListGroup.Item style={rowStyle} key={p.number} className={p.sowingDate ? "plot-operation-row-done" : "plot-operation-row"} action onClick={() => {
                                    props.setSelectEvent({ time: Date.now(), selectedElement: p });
                                    props.setModalShow(true);
                                }}>
                                    <div><span className="operation-list-name">{p.description} </span> - {p.amount}  {p.unitText}/ha</div>
                                    <div>{(p.sowingDate) ?
                                        <span className='row-subtext'>{t('Completed')} {dayJsFormatDate(p.sowingDate)}</span>
                                        : <span className='row-subtext'>{formatPlannedDate(p.actionDate, t)}</span>}
                                    </div>
                                </ListGroup.Item>

                                {props.allowedSave ?
                                    <div className="set-done-box">
                                        {p.saveInProgress ? <Spinner className="mark-spin" as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                                        {p.sowingDate ? '' : <Button variant='success' className={p.saveInProgress ? "hidden" : "float-right set-done fert-list-mark-mobile"} onClick={() => setSowDone(p)}>{t('MarkDoneShort')}</Button>}
                                        {p.sowingDate ? '' : <Button variant='success' className={p.saveInProgress ? "hidden" : "float-right set-done fert-list-mark-desktop"} onClick={() => setSowDone(p)}>{t('MarkDone')}</Button>}
                                    </div>
                                : ''}
                            </div>
                        ) : ''}

                       
                        {!props.memosIsLoading ?
                            memos.map(p =>
                                <div key={p.memoId} className="row-margin-bot">
                                    <div className='row-icon-left row-icon-memo'><Icon icon={ICONS.EDIT} color='white' size='20' /></div>
                                    <ListGroup.Item className="plot-memo-row" style={rowStyle} key={p.memoId} action onClick={() => {
                                        props.setMemoType(1);
                                        props.setSelectMemoEvent({ time: Date.now(), selectedElement: p });
                                        props.setMemoModalShow(true);
                                    }}>
                                        <div><b> {t('Note')}</b></div>
                                        <div className='row-subtext'>{p.memo}</div>
                                    </ListGroup.Item>
                                    <span style={textStyle}></span>
                                </div>
                            )
                            : <div>
                                {t('Loding')}
                                <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" />
                            </div>}
                        <ListGroup.Item style={addNoteRowStyle} action
                            onClick={() => {
                                props.setMemoType(1);
                                props.setSelectMemoEvent({ time: Date.now(), selectedElement: null });
                                props.setMemoModalShow(true);
                            }}>
                            <div className='add-note-icon'><Icon icon={ICONS.ADD} color='black' size='16' /></div>
                            <div>{t('AddNote')}</div>
                            {memoIsLoading ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Accordion.Collapse>
            </Card>
    );
}

export default SowAccordionCard;