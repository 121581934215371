import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs'
import CalendarModal from './CalendarModal';
import { getContentWithAction, postContentWithAction, postContentWithAction_, putContentWithAction } from '../../apiConnect';
import { getSelectedPlot } from '../../common';
import { useAlert } from 'react-alert'
import AppContext from '../../AppContext';
import { dayJsFormatDate } from '../../commonFunctions';
import MapModal from './MapModal';
import ImageShowModal from './ImageShowModal';
import { featuresReader, retrieveWgsCoordinates } from '../../Map/commonMapFunctions';
import { deleteRowStyle } from '../ComponentStyles/ButtonStyles';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'

import '../../css/general.css'

const MemoModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [calendarShow, setCalendarShow] = useState(false);
    const [date, setDate] = useState(dayjs().format());
    const [memo, setMemo] = useState("");
    const [memoId, setMemoId] = useState(null);
    const [featureGeometryId, setFeatureGeometryId] = useState(null);
    const [plantMemoType, setPlantMemoType] = useState("");
    const [plantMemoGroup, setPlantMemoGroup] = useState(1);
    const [plantMemoTypeId, setPlantMemoTypeId] = useState(0);
    const [explanation, setExplanation] = useState("");
    const [coordinates, setCoordinates] = useState(null);
    const [mapShow, setMapShow] = useState(false);
    const [loadingCoordinates, setLoadingCoordinates] = useState(false);
    const [buttonWriteShow, setButtonWriteShow] = useState(false); // allowed save btn disabled
    const [showInAllYears, setShowInAllYears] = useState(false);

    //PHOTOS    
    const [files, setFiles] = useState([]);
    const [newImages, setNewImages] = useState([]);

    const fileRef = useRef();
    const [imageShow, setImageShow] = useState(false);
    const [imageToView, setImageToView] = useState(false);

    const selectedPlot = getSelectedPlot();
    const alert = useAlert();
    let plotId = undefined;
  
    if (props.mapEvent?.plotData) {        
        plotId = props.mapEvent.plotData.apNumber;
    }
    else if (props.selectEvent?.selectedElement?.plotId) {        
        plotId = props.selectEvent.selectedElement.plotId;
       
    }
    else if (selectedPlot && selectedPlot.apNumber) {
        plotId = selectedPlot.apNumber;
    }


    const responseHandler = (data) => {
        setMemo(data.memo);
        setExplanation(data.explanation);
        setPlantMemoType(props.memotype ? formatMemoType(props.memotype) : formatMemoType(data.plantMemoType));
        setPlantMemoTypeId(props.memotype ? props.memotype : data.plantMemoType);
        setPlantMemoGroup(data.plantMemoGroup);
        if (data.memoDate) {
            setDate(dayjs(data.memoDate).format());
        }
        if (data.memoId) {
            setMemoId(data.memoId);
        }
        if (data.featureGeometryId) {
            setFeatureGeometryId(data.featureGeometryId);
        }
        if (data.visibility) {
            setShowInAllYears(true);
        }
    }

    const formatMemoType = (type) => {
        switch (type) {
            case 1:
                return t('Sowings');
            case 2:
                return t('Fertilizing');
            case 3:
                return t('Tilling');
            case 4:
                return t('PlantProtection');
            case 5:
                return t('Yield');
            default:
                return t('Plot_Memos');
        }
    }

    const formatFields = () => {
        setMemo("");
        setExplanation("");
        setPlantMemoType(formatMemoType(props.memotype));
        setPlantMemoGroup(1);
        setDate(dayjs(Date.now()).format());
        setCoordinates(null);
        setFiles([]);
        setNewImages([]);
    }

    useEffect(() => {
        setPlantMemoType(formatMemoType(props.memotype));
    }, [props.memotype]);

    useEffect(() => {
        formatFields();

        getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}).then((result) => {
            setButtonWriteShow(result);
        });

        if (props.mapEvent) {
            setCoordinates(props.mapEvent.coordinates);
            var memoId = undefined;
            if (!props.mapEvent.new) {
                const promise = new Promise((resolve, reject) => {
                    getContentWithAction(auth.getAccessToken(), 'memo', 'memo', {
                        featureGeometryId: props.mapEvent.geometryID
                    }).then((data) => {
                        if (data.length > 0) {
                            responseHandler(data[0]);
                            memoId = data[0].memoId;
                        }
                        resolve("Promise resolved successfully");
                    }).catch(e => {
                        reject(Error("Promise rejected"));
                    });
                });
                promise.then(result => {
                    getContentWithAction(auth.getAccessToken(), 'photo', 'memoPhotos', { memoId: memoId })
                        .then((data) => {
                            //console.log(data);
                            photoFetcher(data);
                        });
                }, reason => {
                    console.error(reason); // Error!
                });                       
            }

            // Add this to prevent a bug where modal closes when clicking a map feature
            // this happens because click happens an extra time and calls props.onHide
            // TODO: Fix the bug later?
            setTimeout(() => { props.allowHide() }, 250);
        }

        if (props.selectEvent) {
            if (!props.selectEvent.selectedElement) return;
            if (props.selectEvent.selectedElement.memoId !== 0) {
                responseHandler(props.selectEvent.selectedElement);
            }
            setLoadingCoordinates(true);

            getContentWithAction(auth.getAccessToken(), 'photo', 'memoPhotos', { memoId: props.selectEvent.selectedElement.memoId })
                .then((data) => {
                    //console.log(data);
                    photoFetcher(data);
                });

            getContentWithAction(auth.getAccessToken(), 'map', 'mapFeatures', {})
                .then((data) => {
                    var features = featuresReader(data);
                    const memoFeature = features.find(f => f.getProperties().FeatureGeometryId === props.selectEvent.selectedElement.featureGeometryId);
                    if (memoFeature) {
                        var coordinates = memoFeature.getProperties().geometry.flatCoordinates;
                        const wgscoord = retrieveWgsCoordinates(coordinates);
                        setCoordinates({ longitude: coordinates[0], latitude: coordinates[1], wgs: wgscoord });
                    }
                    setLoadingCoordinates(false);
                });
        }

    }, [props.selectEvent, props.mapEvent, auth]);

    const photoFetcher = (data) => {
        if (data && data.length) {

            let allfiles = []
            for (let i = 0; i < data.length; i++) {
                let image = data[i];
                image.thumbnailImage = 'data:image/jpeg;base64,' + image.thumbnailImage

                allfiles.push(image);
            }
            setFiles(allfiles);
        }
    }

    const removeCoordinates = () => {
        const data = {
            basePlotId: null,
            memoId: props.id,
            memo: memo,
            plantMemoType: props.memotype,
            plantMemoGroup: plantMemoGroup,
            explanation: explanation,
            // modificator:
            plotId: getSelectedPlot() ? getSelectedPlot().apNumber : null,
            memoDate: date,
            longitude: parseInt(coordinates.longitude),
            latitude: parseInt(coordinates.latitude),
            featureGeometryId: props.selectEvent.selectedElement.featureGeometryId
        };
        postContentWithAction(auth.getAccessToken(), 'memo', 'deleteCoordinates', data, () => {
            setCoordinates(null);
            setFeatureGeometryId(null);
            props.setRefresh(Date.now());
        });
    }

    const deleteMemo = () => {
        postContentWithAction_(auth.getAccessToken(), 'memo', 'delete', {
            memoId: memoId,
            plotId: plotId
        }).then(() => {
            if (props.selectEvent) {
                props.setRefresh(Date.now());
                props.onHide();
            }
            if (props.mapEvent) {
                props.deleteMemo();
            }
        });
    }


    const deletePhoto = (mediaId) => {
        postContentWithAction(auth.getAccessToken(), 'memo', 'deletePhoto', {
            mediaId: mediaId,
            plotId: plotId
        }, () => {
            props.setRefresh(Date.now());
            props.onHide();
        });
    }

    const saveMemo = () => {

        let images = [];
        if (newImages.length) {
            newImages.forEach(newI => {
                let photo = newI.thumbnailImage ? newI.thumbnailImage.split(',')[1] : '';
                if (photo) {
                    images.push(photo);
                }
            });
        }

        var visibility = showInAllYears;

        if ((props.selectEvent && !props.selectEvent.selectedElement) || props.mapEvent?.new === true) {
            postContentWithAction_(auth.getAccessToken(), 'memo', 'new', {
                basePlotId: null,
                memoId: props.id,
                memo: memo,
                plantMemoType: props.memotype ? props.memotype : plantMemoTypeId,
                plantMemoGroup: plantMemoGroup,
                explanation: explanation,
                plotId: plotId,
                memoDate: date,
                longitude: coordinates ? parseInt(coordinates.longitude) : 0,
                latitude: coordinates ? parseInt(coordinates.latitude) : 0,
                newPhotos: images,
                visibility: visibility
            }).then((data) => {
                if (props.mapEvent?.new) {
                    props.saveMemo(data);
                }
                else {
                    props.setRefresh(Date.now());
                }
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
            });
        }
        if ((props.selectEvent && props.selectEvent.selectedElement) || props.mapEvent?.new === false) {
            putContentWithAction(auth.getAccessToken(), 'memo', 'update', {
                basePlotId: props.selectEvent?.selectedElement?.basePlotId ? props.selectEvent.selectedElement.basePlotId : null,
                memoId: memoId ? memoId : props.id,
                memo: memo,
                plantMemoType: props.memotype ? props.memotype : plantMemoTypeId,
                plantMemoGroup: plantMemoGroup,
                explanation: explanation,
                plotId: plotId ? plotId : null,
                memoDate: date,
                featureGeometryId: featureGeometryId ? featureGeometryId : 0,
                longitude: coordinates ? parseInt(coordinates.longitude) : 0,
                latitude: coordinates ? parseInt(coordinates.latitude) : 0,
                newPhotos: images,
                visibility: visibility
            }).then(() => {
                if (props.mapEvent?.new === false) {
                    props.saveMemo(props.mapEvent.geometryID);
                }
                else {
                    props.setRefresh(Date.now());
                }
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
            });
        }
        props.onHide();
    }

    const imageHandler = (e) => {

        let allfiles = []
        for (let i = 0; i < e.target.files.length; i++) {
            //console.log(e.target.files[i]);

            const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (acceptedImageTypes.includes(e.target.files[i].type)) {

                var reader = new FileReader();
                reader.onload = function (f) {
                    //console.log(f.target.result);

                    let d = {};
                    d.thumbnailImage = f.target.result;
                    allfiles.push(d)

                    if (files.length > 0) {
                        setFiles([...files, ...allfiles]);
                    }
                    else {
                        setFiles(allfiles);
                    }

                    if (newImages.length > 0) {
                        setNewImages([...newImages, ...allfiles]);
                    }
                    else {
                        setNewImages(allfiles);
                    }
                }

                reader.readAsDataURL(e.target.files[i]);
            }
        }

    }
    const handleImageRemoval = (file) => {
        var allImages = [...files];
        var index = allImages.findIndex((element) => element.name === file.name);
        if (index !== -1) {
            allImages.splice(index, 1);
            setFiles(allImages);
        }
        if (file.mediaId)
            deletePhoto(file.mediaId);
    }

    const imageStyle = {
        width: '125px',
        height: '100px',
        cursor: 'pointer'
    };

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} >
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {t('Note')}
                        <br />
                        <span className="highlighted-plot-name-text">{
                            props.selectEvent?.selectedElement?.groupNameShort ? props.selectEvent.selectedElement.groupNameShort :
                                props.selectEvent?.selectedElement?.plotName ? props.selectEvent.selectedElement.plotName :
                                    props.mapEvent?.plotData ? props.mapEvent.plotData.name + ' - ' + props.mapEvent.plotData.apChar :
                                        selectedPlot && selectedPlot.name && selectedPlot.apChar ? selectedPlot.name + ' - ' + selectedPlot.apChar :
                                            ''}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFieldOperation">
                            <Form.Label className="text-dark">{t('Action')}</Form.Label>
                            <Form.Control type="text" disabled placeholder={plantMemoType} />
                        </Form.Group>

                        <Form.Group controlId="formMemoText">
                            <Form.Label className="text-dark">{t('Note')}</Form.Label>
                            <Form.Control type="text" onChange={(event) => { setMemo(event.target.value) }}
                                value={memo}
                                placeholder={t('Note')} />
                        </Form.Group>

                        <Form.Group controlId="formAdditionalInformation">
                            <Form.Label className="text-dark">{t('NoteDescription')}</Form.Label>
                            <Form.Control type="text" onChange={(event) => { setExplanation(event.target.value) }}
                                value={explanation}
                                placeholder={t('NoteDescription')} />
                        </Form.Group>

                        <Form.Group controlId="formDate">
                            <Form.Label className="text-dark">{t('Date')}</Form.Label>
                            <Form.Control type="text" onClick={() => setCalendarShow(true)} placeholder={t('Date')} value={dayJsFormatDate(date)} onChange={() => { }} />
                        </Form.Group>

                        <Form.Group controlId="formCoordinates">
                            <Form.Label className="text-dark">{t('Coordinates')}</Form.Label>
                            <div className="row">
                                {loadingCoordinates ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                                    <div>
                                        {coordinates && coordinates.longitude !== 0 && coordinates.latitude !== 0 ?
                                            <div>
                                                <Form.Label className="text-dark coordinates-label">
                                                    {t('LocationDefined')} <i>({coordinates.wgs.longitude},   {coordinates.wgs.latitude})</i>
                                                </Form.Label>
                                                <div className="altInputStyle">
                                                    {!buttonWriteShow && props.mapEvent ? "" :
                                                        <Button variant="danger" style={deleteRowStyle} onClick={(e) => {
                                                            e.preventDefault();
                                                            removeCoordinates();
                                                        }} type="text"><Icon icon={ICONS.DELETE} color='black' size='22' /></Button>
                                                    }
                                                </div>
                                            </div>
                                            : // If no coordinates then show button
                                            <div className="col-6">
                                                {buttonWriteShow ? 
                                                    <Button type="text" variant="success" onClick={(e) => {
                                                        e.preventDefault();
                                                        setMapShow(true);
                                                    }}>{t('DefineLocation')}</Button>
                                                    : ''}
                                            </div>}
                                    </div>
                                }
                            </div>
                        </Form.Group>

                        {coordinates && coordinates.longitude !== 0 && coordinates.latitude !== 0 ? 
                            <Form.Group>
                                <div className="show-in-all-years-switch">
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        label={t('ShowInAllYears')}
                                        className="mobi-custom-switch"
                                        checked={showInAllYears}
                                        onChange={(event) => { setShowInAllYears(!showInAllYears) }}
                                    />
                                </div>
                            </Form.Group>
                        : ''}

                        <Form.Group>
                            <Form.Label className="text-dark">{t('Photos')}</Form.Label>
                            <div>
                                {buttonWriteShow ? 
                                    <Button variant="success" className="btn-show-more" onClick={() => fileRef.current.click()}>{t('LoadPhotos')}</Button>
                                    : ''}
                                <input
                                    ref={fileRef}
                                    accept="image/*"
                                    type="file"
                                    onChange={imageHandler}
                                    multiple
                                    hidden
                                />
                            </div>
                            <br />
                            <div className="row">
                                {files.map((file, key) => {
                                    return (
                                        <div key={key}>
                                            {file.name}
                                            <br />
                                            <img src={file.thumbnailImage ? `${file.thumbnailImage}` : URL.createObjectURL(file)} alt={file.name} style={imageStyle} onClick={() => {
                                                setImageToView(file);
                                                setImageShow(true);
                                            }} />

                                            {buttonWriteShow ?
                                                <CloseButton onClick={() => handleImageRemoval(file)} />
                                                : ''}
                                        </div>)
                                })}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    {(props.selectEvent?.selectedElement || !props.mapEvent?.new) && buttonWriteShow === true ?
                        <Button variant='outline-danger' className="footer-button-left"
                            onClick={() => deleteMemo()}>{t('Delete')}</Button>
                        : ''}

                    <Button variant="outline-secondary" onClick={() => { props.onHide(); }}>{t('Cancel')}</Button>


                    {buttonWriteShow === true ?
                        <Button variant="success" className="btn-show-more" onClick={() => { saveMemo(); }}>{t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>
            <CalendarModal
                show={calendarShow}
                onHide={() => setCalendarShow(false)}
                onChange={setDate}
                selecteddate={date}
            />
            <MapModal
                show={mapShow}
                onHide={(point) => {
                    setMapShow(false);
                    if (point) {
                        setCoordinates(point.coord);
                    }
                }}
                pointNote={memo ? memo : t('Note')}
                featureType={"5306"}
            />
            <ImageShowModal
                show={imageShow}
                onHideImage={() => {
                    setImageShow(false);
                }}
                image={imageToView}
            />
        </>
    );
}

export default MemoModal;