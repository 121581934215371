import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import SowModal from './SowModal';
import MarkSowModal from './MarkSowModal';
import MarkFertilizerModal from './MarkFertilizerModal';
import MarkPesticideModal from './MarkPesticideModal';
import FertilizeModal from './FertilizeModal';
import PlotModificationModal from './PlotTillageModal';
import HarvestModal from './HarvestModal';
import PlantProtectionModal from './PlantProtectionModal';
import { checkSowButtonState, checkPlotsHaveNoPlant, checkSelectedPlants, groupFertilizers, groupPesticides } from '../../commonFunctions';
import { getSelectedLang } from '../../common';
import Spinner from 'react-bootstrap/Spinner';
import '../../css/general.css'
import { postContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';

const ActionSelectionModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [sowModalDisabled, setSowButtonDisabled] = useState(true);
    const [harvestModalDisabled, setHarvestModalDisabled] = useState(true);
    const [fertilizerModalDisabled, setFertilizerButtonDisabled] = useState(true);
    const [pesticideModalDisabled, setPesticideButtonDisabled] = useState(true);

    const [selectedPlots, setSelectedPlots] = useState([]);
    const [selectedPlotsCount, setSelectedPlotsCountText] = useState('');
    const [sowModalShow, setSowModalShow] = useState(false);

    const [markSowModalShow, setMarkSowModalShow] = useState(false);
    const [markFertilizerModalShow, setMarkFertilizerModalShow] = useState(false);
    const [markPesticideModalShow, setMarkPesticideModalShow] = useState(false);

    const [fertilizeModalShow, setFertilizeModalShow] = useState(false);
    const [plotModificationModalShow, setPlotModificationModalShow] = useState(false);
    const [harvestModalShow, setharvestModalShow] = useState(false);
    const [plantProtectionModalShow, setPlantProtectionModalShow] = useState(false);
    const [selectMultiPlotEvent, setSelectMultiPlotEvent] = useState({ time: null, selectedElement: null });
    const [addMode, setAddMode] = useState(true);

    const [sowsLoading, setSowsLoading] = useState(false);
    const [fertsLoading, setFertsLoading] = useState(false);
    const [pestsLoading, setPestsLoading] = useState(false);

    const buttonStyle = {
        margin: '10px'
    }

    useEffect(() => {
        setAddMode(props.addMode);
    }, [props.addMode]);

    useEffect(() => {
        setSelectedPlots(props.selectedplots);
        if (props.selectedplots) {
            let ha = Math.round((props.selectedplots.reduce((total, a) => total + a.area, 0) * 100)) / 100;
            setSelectedPlotsCountText(`${props.selectedplots.length + t('Units')} / ${ha}ha`);
        }

        setHarvestModalDisabled(checkPlotsHaveNoPlant(props.selectedplots));

        if (props.show) {
            checkSows();
            checkFerts();
            checkPests();
        }
    }, [props.selectedplots]);

    let checkSows = () => {
        setSowButtonDisabled(true);
        setSowsLoading(true);
        if (props.addMode)
            setSowButtonDisabled(checkSowButtonState(props.selectedplots));
        else {
            let multiPlant = checkSowButtonState(props.selectedplots);

            // only one plant
            if (!multiPlant && props.selectedplots && props.selectedplots.length) {
                var plotIds = [];
                props.selectedplots.forEach(p => {
                    plotIds.push({ plotId: p.apNumber });
                });
                postContentWithAction(auth.getAccessToken(), 'sow', 'sowsForPlots', { models: plotIds, lang: getSelectedLang() }, (data) => {
                    data = data.filter((sow) => sow.sowingDate || sow.actionDate);
                    setSowsLoading(false);

                    if (data.length > 0) {
                        setSowButtonDisabled(false);
                    }
                    else {
                        setSowButtonDisabled(true);
                    }
                });
            }
            else {
                setSowButtonDisabled(true);
                setSowsLoading(false);
            }
        }
    };

    let checkPests = () => {
        setPesticideButtonDisabled(true);
        setPestsLoading(true);
        if (props.addMode) {
            let plants = checkSelectedPlants(props.selectedplots);
            if (plants.length === 1)
                setPesticideButtonDisabled(false);
        }
        else {
            var plotIds = [];
            if (!props.selectedplots) {
                setPesticideButtonDisabled(true);
            }
            else {
                props.selectedplots.forEach(p => {
                    plotIds.push({ plotId: p.apNumber });
                });

                postContentWithAction(auth.getAccessToken(), 'plotOperation', 'getPlantProtectionOperations', { models: plotIds, lang: getSelectedLang() },
                    protections => {
                        setPestsLoading(false);
                        
                        // Group by fertilizer and planned date:
                        let grouped = groupPesticides(protections);

                        if (grouped.length > 0)
                            setPesticideButtonDisabled(false);
                        else {
                            setPesticideButtonDisabled(true);
                        }
                });
            }
        }
    };

    let checkFerts = () => {
        setFertilizerButtonDisabled(true);
        setFertsLoading(true);
        if (props.addMode)
            setFertilizerButtonDisabled(false);
        else {
            var plotIds = [];
            if (!props.selectedplots) {
                setFertilizerButtonDisabled(true);
            }
            else {
                props.selectedplots.forEach(p => {
                    plotIds.push({ plotId: p.apNumber });
                });

                postContentWithAction(auth.getAccessToken(), 'fertilize', 'getFertilizingsForPlots', { models: plotIds, lang: getSelectedLang() }, (ferts) => {
                    ferts = ferts.filter((f) => f.date || f.plannedDate);
                    setFertsLoading(false);

                    // Group by fertilizer and planned date:
                    let grouped = groupFertilizers(ferts);

                    if (grouped.length > 0)
                        setFertilizerButtonDisabled(false);
                    else {
                        setFertilizerButtonDisabled(true);
                    }
                });
            }
        }
    };

    return (
        <>
            {sowModalShow === true ?
                <SowModal
                    show={sowModalShow}
                    setRefresh={() => { props.onHide(); }}
                    onHide={() => setSowModalShow(false)}
                    //onClick={() => setRefreshSows(Date.now())}
                    selectEvent={selectMultiPlotEvent}
                />
                : ''}
            {fertilizeModalShow === true ?
                <FertilizeModal
                    show={fertilizeModalShow}
                    setRefresh={() => { props.onHide(); }}
                    onHide={() => setFertilizeModalShow(false)}
                    selectEvent={selectMultiPlotEvent}
                />
                : ''}
            {plotModificationModalShow === true ?
                <PlotModificationModal
                    show={plotModificationModalShow}
                    setRefresh={() => { props.onHide(); }}
                    onHide={() => setPlotModificationModalShow(false)}
                    selectEvent={selectMultiPlotEvent}
                />
                : ''}
            {harvestModalShow === true ?
                <HarvestModal
                    show={harvestModalShow}
                    setRefresh={() => { props.onHide(); }}
                    onHide={() => setharvestModalShow(false)}
                    selectEvent={selectMultiPlotEvent}
                />
                : ''}
            {plantProtectionModalShow === true ?
                <PlantProtectionModal
                    show={plantProtectionModalShow}
                    setRefresh={() => { props.onHide(); }}
                    onHide={() => setPlantProtectionModalShow(false)}
                    selectEvent={selectMultiPlotEvent}
                />
                : ''}
            {markSowModalShow === true ?
                <MarkSowModal
                    show={markSowModalShow}
                    setRefresh={() => { props.onHide(); }}
                    onHide={() => {
                        setMarkSowModalShow(false);
                        checkSows();
                    }}
                    onClick={() => { }}
                    selectEvent={selectMultiPlotEvent}
                    showFertilizingModal={() => {
                        setMarkFertilizerModalShow(true);
                    }}
                    fertilizingModalDisabled={fertilizerModalDisabled}
                />
                : ''}
            {markFertilizerModalShow === true ?
                <MarkFertilizerModal
                    show={markFertilizerModalShow}
                    setRefresh={() => { props.onHide(); }}
                    onHide={() => {
                        setMarkFertilizerModalShow(false);
                        checkFerts();
                    }}
                    onClick={() => { }}
                    selectEvent={selectMultiPlotEvent}
                />
                : ''}
            {markPesticideModalShow === true ?
                <MarkPesticideModal
                    show={markPesticideModalShow}
                    setRefresh={() => { props.onHide(); }}
                    onHide={() => {
                        setMarkPesticideModalShow(false);
                        checkFerts();
                    }}
                    onClick={() => { }}
                    selectEvent={selectMultiPlotEvent}
                />
                : ''}

            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('SelectAction')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    {addMode === true ?
                        // Adding operations for multiple plots
                        <div className="action-modal-max-width">
                            <Button style={buttonStyle} variant="success" className="btn-show-more"
                                disabled={sowModalDisabled ? true : false}
                                onClick={() => {
                                    setSelectMultiPlotEvent({ time: Date.now(), selectedElement: null, selectedPlots: selectedPlots });
                                    setSowModalShow(true);
                            }}>{t('Sowings')}</Button>
                            <Button style={buttonStyle} variant="success" className="btn-show-more"
                                onClick={() => {
                                setSelectMultiPlotEvent({ time: Date.now(), selectedElement: null, selectedPlots: selectedPlots });
                                setFertilizeModalShow(true);
                            }}>{t('Fertilizinglist')}</Button>
                            <Button style={buttonStyle} variant="success" className="btn-show-more"
                                onClick={() => {
                                setSelectMultiPlotEvent({ time: Date.now(), selectedElement: null, selectedPlots: selectedPlots });
                                setPlotModificationModalShow(true);
                            }}>{t('Tilling_Cultivation')}</Button>
                                <Button style={buttonStyle} variant="success" className="btn-show-more"
                                disabled={pesticideModalDisabled ? true : false}
                                onClick={() => {
                                setSelectMultiPlotEvent({ time: Date.now(), selectedElement: null, selectedPlots: selectedPlots });
                                setPlantProtectionModalShow(true);
                            }}>{t('Observations_PlantProtection')}</Button>
                            <Button style={buttonStyle} variant="success" className="btn-show-more"
                                disabled={harvestModalDisabled ? true : false}
                                onClick={() => {
                                setSelectMultiPlotEvent({ time: Date.now(), selectedElement: null, selectedPlots: selectedPlots });
                                setharvestModalShow(true);
                            }}>{t('Yield')}</Button>
                        </div>

                        // Marking as done
                        : <div className="action-modal-max-width">
                            <Button style={buttonStyle} variant="success" className="btn-show-more"
                                disabled={sowModalDisabled ? true : false}
                                onClick={() => {
                                    setSelectMultiPlotEvent({ time: Date.now(), selectedElement: null, selectedPlots: selectedPlots });
                                    setMarkSowModalShow(true);
                                }}>
                                {sowsLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                    : ''}
                                {t('MarkSowDone')}
                            </Button>

                            {/*fertilizer - TODO*/}
                            <Button style={buttonStyle} variant="success" className="btn-show-more"
                                disabled={fertilizerModalDisabled ? true : false}
                                onClick={() => {
                                    setSelectMultiPlotEvent({ time: Date.now(), selectedElement: null, selectedPlots: selectedPlots });
                                    setMarkFertilizerModalShow(true);
                                    }}>
                                    {fertsLoading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                        />
                                        : ''}
                                    {t('MarkFertilizerDone')}
                            </Button>

                            {/*pesticides - TODO*/}
                            <Button style={buttonStyle} variant="success" className="btn-show-more"
                                disabled={pesticideModalDisabled ? true : false}
                                onClick={() => {
                                    setSelectMultiPlotEvent({ time: Date.now(), selectedElement: null, selectedPlots: selectedPlots });
                                    setMarkPesticideModalShow(true);
                                    }}>{pestsLoading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                        />
                                        : ''}
                                    {t('MarkPesticideDone')}
                            </Button>

                          </div>
                    }
                    {(selectedPlots.length)
                        ? (selectedPlots) ?
                            <Accordion className="action-modal-max-width">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><b>{t('ShowChosenPlots')} - {selectedPlotsCount}</b></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="plot-list">
                                            {selectedPlots.map((plot, i) =>
                                                <div key={i}>
                                                    <b>{plot.name} - {plot.apChar}</b> ({plot.area} ha)
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            : ''
                            : t('NoChosenPlots')}
                    </Form>
                </Modal.Body>   
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ActionSelectionModal;