import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import NumberFormat from 'react-number-format';
import ButtonGroup from 'react-bootstrap/ButtonGroup';  
import ToggleButton from 'react-bootstrap/ToggleButton';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { customStyles, formatGroupLabel, } from '../../common';
import Spinner from 'react-bootstrap/Spinner';
import '../../css/general.css'
import '../../css/plotOperation.css'
import { getContentWithAction, postContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';
import { useAlert } from 'react-alert'
import { GRASSPLANT } from '../../constants';
import ConfirmModal from '../Alerts/ConfirmModal';
import { GardenPlantCheck, IsIrrigation } from '../../commonFunctions';

const ChangePlantModal = (props) => {

    /** Set component state variables */
    const [pageLoading, setPageLoading]                     = useState(true);
    const [validate, setValidation]                         = useState(false);
    const [selectedPlot, setSelectedPlot]                   = useState(null);
    const [plantSelectList, setPlantSelectList]             = useState([]);
    const [spreadingList, setSpreadingList]                 = useState([]);
    const [mulchingList, setMulchingList]                   = useState([]);
    const [fertIntList, setFertIntList]                     = useState([]);
    const [selectedPlant, setSelectedPlant]                 = useState(null);
    const [selectedSpreading, setSelectedSpreading]         = useState(null);
    const [selectedMulching, setSelectedMulching]           = useState(null);
    const [selectedFertInt, setSelectedFertInt]             = useState(null);
    const [buttonWriteShow, setButtonWriteShow]             = useState(false); // allowed save btn disabled
    const [unitTypeText, setUnitTypeText]                   = useState('kg/ha');
    const [yieldTarget, setYieldTarget]                     = useState(0);
    const [fertCount, setFertCount]                         = useState(0);
    const [harvestYear, setHarvestYear]                     = useState(0);
    const [legumesContent, setLegumesContent]               = useState(0);
    const [enableLegumes, setEnableLegumes]                 = useState(false);
    const [enableHarvestYear, setEnableHarvestYear]         = useState(false);
    const [enableGardenPlants, setEnableGardenPlants]       = useState(false);
    const [enableFertInt, setEnableFertInt]                 = useState(false);
    const [originalAgrPlotData, setOriginalAgrPlotData]     = useState(null); // Original AgrPlot data on loading the modal
    const [showConfirm, setShowConfirm]                     = useState(false);
    const [sowsExists, setSowsExists]                       = useState(false);

    /** Set component properties */
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);
    const default_text = (t('ChoosePlant'));
    const default_choice_text = (t('Select'));
    const alert = useAlert();
    const legumesRadios = [
        { name: t('No_legumes'), value: '0' },
        { name: t('Twenty_percent'), value: '1' },
        { name: t('Fourty_percent'), value: '2' }
    ]

    /** 
     * Initializations for modal
     */
    useEffect(() => {
        if (props.selectedPlot == null) {
            props.onHide();
            return;
        }
        setSelectedPlot(props.selectedPlot);
        setSelectedPlant({ label: props.selectedPlot.plantName, value: props.selectedPlot.plantId });
        retrievePlants();
    }, [props.selectEvent]);

    /**
     * Retrieve modal data from database and handle results
     */
    const retrievePlants = () => {
        // Retrieve modal data
        Promise.all([
            getContentWithAction(auth.getAccessToken(), 'plotOperation', 'getAgrPlot', { plotId: props.selectedPlot.apNumber }),
            getContentWithAction(auth.getAccessToken(), 'resource', 'previousPlants', {}),
            getContentWithAction(auth.getAccessToken(), 'resource', 'plants', {}),
            getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}),
            getContentWithAction(auth.getAccessToken(), 'sow', 'sows', { plotId: props.selectedPlot.apNumber }),
            getContentWithAction(auth.getAccessToken(), 'resource', 'spreadingMethods', {}),
            getContentWithAction(auth.getAccessToken(), 'resource', 'mulchingMethods', {}),
            getContentWithAction(auth.getAccessToken(), 'resource', 'fertiliserIntensity', {})
        ]).then(([plot, previousPlants, plants, sessionResult, sows, spreadings, mulchings, intensities]) => {
            // Handle data results -->
            // Filter out duplicates from lists
            previousPlants = previousPlants.filter(p => {
                if (plants.some(a => a.plantId === p.plantId)) return true;
                return false;
            });

            setOriginalAgrPlotData(plot);

            // Plant defaults for plot plant
            var plantData = plants.find(p => p.plantId === props.selectedPlot.plantId);
            // Set unit text for selected plant
            var selected_p_unitType = plantData.unitText;
            setUnitTypeText(selected_p_unitType);
            setHarvestYear(plot.harvestYear);
            setFertCount(plot.plotFertilizationCount);
            setYieldTarget(plot.harvestLevel);

            // ENABLE LEGUMES
            if (props.selectedPlot.plantId.toString().substring(0, 1) === GRASSPLANT) {
                setEnableLegumes(true);
                if (plot.plotLegumes)
                    setLegumesContent(plot.plotLegumes.toString());
                else
                    setLegumesContent('0');
            }

            // ENABLE GARDEN PLANTS
            if (GardenPlantCheck(props.selectedPlot.plantId.toString())) {
                setEnableGardenPlants(true);
                // Set selected garden plant options
                var plotSpreading = spreadings.find(s => s.value === plot.spreadingMethod);
                var plotMulching = mulchings.find(s => s.value === plot.mulchingMethod);
                var plotfertInt = intensities.find(s => s.value === plot.fertiliserIntensity);
                setSelectedSpreading(plotSpreading);
                setSelectedMulching(plotMulching);
                setSelectedFertInt(plotfertInt);
                if (IsIrrigation(plotSpreading.value)) setEnableFertInt(true);
            }

            // SET HARVEST YEAR
            if (plantData.plantPerennial > 1) {
                setEnableHarvestYear(true);
            }

            if (sows.length > 0) setSowsExists(true);

            // Set select list for all plants
            setPlantSelectList([
                { label: t('Previously_Used'), options: previousPlants },
                { label: t('All'), options: plants },
            ]);
            setSpreadingList([
                { label: '', options: spreadings }
            ]);
            setMulchingList([
                { label: '', options: mulchings }
            ]);
            setFertIntList([
                { label: '', options: intensities }
            ]);

            setButtonWriteShow(sessionResult);
            setPageLoading(false);
        });
    }

    /**
     * Handle changing plant 
     * @param {any} plant Newly selected plant
     */
    const plantChanged = (plant) => {
        // Check if grass plant to enable legumes
        if (plant.legumesUse && plant.plantId.toString().substring(0, 1) === GRASSPLANT) {
            setLegumesContent('0');
            setEnableLegumes(true);
        }
        else {
            setLegumesContent(null);
            setEnableLegumes(false);
        }

        // ENABLE GARDEN PLANTS
        if (GardenPlantCheck(plant.plantId.toString())) {
            setEnableGardenPlants(true);
        }
        else {
            setEnableGardenPlants(false);
        }

        // Set default harvest level
        if (plant.harvestLevel) {
            setYieldTarget(plant.harvestLevel);
        }

        // Set default harvest year
        if (plant.plantPerennial > 1) {
            setEnableHarvestYear(true);
            setHarvestYear(plant.plantPerennial);
        }
        else {
            setEnableHarvestYear(false);
            setHarvestYear(1);
        }
        
        setUnitTypeText(plant.unitText);
        setSelectedPlant(plant);
    }

    const fertCountChanged = (value) => {
        var inputValue = value.floatValue;
        if (inputValue)
            setFertCount(inputValue);
        else
            setFertCount(null);
    }

    const legumesChanged = (event) => {
        if (event.currentTarget.value)
            setLegumesContent(event.currentTarget.value);
        else
            setLegumesContent(null);
    }

    const spreadingChanged = (value) => {
        setSelectedSpreading(value);
        setEnableFertInt(false);

        if (IsIrrigation(value.value)) {
            setEnableFertInt(true);
        } 
    }

    /**
     * Updates value field
     * @param {any} value Yield target value
     */
    const yieldTargetChanged = (value) => {
        var inputValue = value.floatValue;
        if (inputValue)
            setYieldTarget(inputValue);
        else
            setYieldTarget(0);
    }

    const harvestYearChanged = (value) => {
        var inputValue = value.floatValue;
        if (inputValue)
            setHarvestYear(inputValue);
        else
            setHarvestYear(null);
    }

    /**
     * Save modal data and handle response
     */
    const savePlant = (removeSows) => {
        var plant = selectedPlant.value.toString();
        var legumes = parseInt(legumesContent);
        if (isNaN(legumes)) legumes = 0;

        var spreading, mulching, fertInt = 0;
        if (enableGardenPlants) {
            spreading = selectedSpreading ? selectedSpreading.value : 0;
            mulching = selectedMulching ? selectedMulching.value : 0;
            fertInt = selectedFertInt ? selectedFertInt.value : 0;
        }

        // Create save model
        var plantModel = {
            plotId: selectedPlot.apNumber,
            harvestLevel: yieldTarget,
            plotFertilizationCount: originalAgrPlotData.plotFertilizationCount,
            harvestYear: harvestYear,
            plotLegumes: legumes,
            plotUndersownCrop: originalAgrPlotData.plotUndersownCrop,
            removeSows: removeSows,
            plantId: plant,
            spreadingMethod: spreading,
            mulchingMethod: mulching,
            fertiliserIntensity: enableFertInt ? fertInt : 0
        };

        // Send save-request 
        postContentWithAction(auth.getAccessToken(), 'plotOperation', 'changePlant', {
            ...plantModel
        }, (data) => {
            // Send refresh to parent page
            props.setRefresh(Date.now()); 
            // Show success message pop-up
            alert.show(t('SavedSuccesfully'), { type: 'success' });
        }).catch(e => {
            // Show error message pop-up
            alert.show(t('SavedFailed'), { type: 'error' });
        });
    }

    /**
     * Component UI code
     */
    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>

                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('Change_Plant')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        {pageLoading ? <div>
                            {t('Loding')}
                            <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" />
                        </div> :

                            <div>
                                {/* === PLANT SELECT === */}
                                <Form.Group controlId="plantSelect">
                                    <Form.Label className="text-dark">{t('Plant')}</Form.Label>
                                    <Select
                                        className={validate && !selectedPlant.value ? "text-dark invalid-border" : "text-dark"}
                                        value={selectedPlant?.label ? selectedPlant : { label: default_text, value: null }}
                                        onChange={(data) => plantChanged(data)}
                                        options={plantSelectList}
                                        styles={customStyles}
                                        formatGroupLabel={formatGroupLabel}
                                    />
                                </Form.Group>

                                {/* === YIELD TARGET === */}
                                <Form.Group controlId="yieldTarget">
                                    <Form.Label className="text-dark">{t('YieldTarget')}</Form.Label>
                                    <NumberFormat
                                        className={"form-control"}
                                        value={yieldTarget}
                                        placeholder={' ' + unitTypeText}
                                        decimalScale={2}
                                        fixedDecimalScale={false}
                                        onValueChange={(value) => yieldTargetChanged(value)}
                                        displayType={'input'}
                                        thousandSeparator={' '}
                                        allowedDecimalSeparators={['.', ',']}
                                        allowNegative={false}
                                        suffix={'  ' + unitTypeText}
                                    />
                                </Form.Group>

                                {/* === FERTILIZATION COUNT === */}
                                <Form.Group controlId="fertilizationCount">
                                    <Form.Label className="text-dark">{t('Fert_Count')}</Form.Label>
                                    <NumberFormat
                                        className={"form-control"}
                                        value={fertCount}
                                        placeholder={ ''}
                                        decimalScale={2}
                                        fixedDecimalScale={false}
                                        onValueChange={(values) => fertCountChanged(values)}
                                        displayType={'input'}
                                        thousandSeparator={' '}
                                        allowedDecimalSeparators={['.', ',']}
                                        allowNegative={false}
                                    />
                                </Form.Group>

                                {/* === HARVEST YEAR === */}
                                {enableHarvestYear ? 
                                    <Form.Group controlId="harvestYear">
                                        <Form.Label className="text-dark">{t('HarvestYear')}</Form.Label>
                                        <NumberFormat
                                            className={"form-control"}
                                            value={harvestYear}
                                            placeholder={''}
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            onValueChange={(values) => harvestYearChanged(values)}
                                            displayType={'input'}
                                            thousandSeparator={' '}
                                            allowedDecimalSeparators={['.', ',']}
                                            allowNegative={false}
                                        />
                                    </Form.Group>
                                    : ''}

                                {/* === LEGUMES CONTENT === */}
                                {enableLegumes ? 
                                    <Form.Group controlId="legumesContent">
                                        <Form.Label className="text-dark">{t('LegumesContent')}</Form.Label>
                                        <br />
                                        <ButtonGroup>
                                            {legumesRadios.map((radio, i) => (
                                                <ToggleButton
                                                    key={i}
                                                    id={radio.name + i}
                                                    type="radio"
                                                    variant="outline-success"
                                                    name="radio"
                                                    value={radio.value}
                                                    checked={legumesContent === radio.value}
                                                    onChange={(e) => legumesChanged(e)}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </Form.Group>
                                    : ''}


                                {/* === GARDEN PLANT OPTIONS === */}
                                {enableGardenPlants ?
                                    <Accordion defaultActiveKey="0" className="pesticide-selection-margin">
                                        <Accordion.Item eventKey="0" className="extra-info-accordion-header">
                                            <Accordion.Header><b>{t('GardenPlantOptions')}</b></Accordion.Header>
                                            <Accordion.Body>
                                                {/* === SPREADING METHOD === */}
                                                <Form.Group controlId="spreadingMethod">
                                                    <Form.Label className="text-dark">{t('SpreadingMethod')}</Form.Label>
                                                    <Select
                                                        className={validate && !selectedSpreading.value ? "text-dark invalid-border" : "text-dark"}
                                                        value={selectedSpreading?.label ? selectedSpreading : { label: default_choice_text, value: null }}
                                                        onChange={(data) => spreadingChanged(data)}
                                                        options={spreadingList}
                                                        styles={customStyles}
                                                        formatGroupLabel={formatGroupLabel}
                                                    />
                                                </Form.Group>

                                                {/* === MULCHING METHOD === */}
                                                <Form.Group controlId="mulchingMethod">
                                                    <Form.Label className="text-dark">{t('MulchingMethod')}</Form.Label>
                                                    <Select
                                                        className={validate && !selectedMulching.value ? "text-dark invalid-border" : "text-dark"}
                                                        value={selectedMulching?.label ? selectedMulching : { label: default_choice_text, value: null }}
                                                        onChange={(data) => setSelectedMulching(data)}
                                                        options={mulchingList}
                                                        styles={customStyles}
                                                        formatGroupLabel={formatGroupLabel}
                                                    />
                                                </Form.Group>

                                                {/* === FERTILISER INTENSITY === */}
                                                <Form.Group controlId="fertiliserIntensity">
                                                    <Form.Label className="text-dark">{t('FertiliserIntensity')}</Form.Label>
                                                    <Select
                                                        isDisabled={!enableFertInt}
                                                        className={validate && !selectedFertInt.value ? "text-dark invalid-border" : "text-dark"}
                                                        value={selectedFertInt?.label ? selectedFertInt : { label: default_choice_text, value: null }}
                                                        onChange={(data) => setSelectedFertInt(data)}
                                                        options={fertIntList}
                                                        styles={customStyles}
                                                        formatGroupLabel={formatGroupLabel}
                                                    />
                                                </Form.Group>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    : ''}
                            </div>
                            }
                    </Form>
                </Modal.Body>  

                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Close')}</Button>
                    {buttonWriteShow === true ?
                        <Button variant="success" className="btn-show-more" onClick={() => {
                            //setValidation(true);
                            if (sowsExists && selectedPlant.value !== selectedPlot.plantId)
                                setShowConfirm(true);
                            else
                                savePlant(false);
                        }}>{t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>

            {showConfirm === true ?
                <ConfirmModal
                    show={showConfirm}
                    accept={() => {
                        setShowConfirm(false);
                        savePlant(true);
                    }}
                    decline={() => {
                        setShowConfirm(false);
                        savePlant(false);
                    }}
                    onHide={() => setShowConfirm(false)}
                    title={ t('DeleteSows')}
                />
                : ''}
        </>
    )
}

export default ChangePlantModal;