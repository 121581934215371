import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import NavMenu from '../NavMenu';

import { taskViewer_viewComponents } from './../../constants';
import AppContext from '../../AppContext';
import { enableGPS } from '../../gps';

const GPS = (props) => {
    const { setGeolocation, setMapInitCoordinates } = useContext(AppContext);
    const { t } = useTranslation();
    
    const onClickHandler = () => {
        enableGPS(redirect)
    }

    const redirect = (geo, mapInitPosition) => {
        setMapInitCoordinates(mapInitPosition);
        setGeolocation(geo);    
        props.callBack({ viewComponent: taskViewer_viewComponents.taskTypeSelection, data: [] });
    }

    return (
        <Container>
            <NavMenu {...props} />
            <Card>
                <Card.Header className="text-dark">{t('GPSDevice')}</Card.Header>
                <Card.Body>
                    <Button className="btn-success" onClick={onClickHandler}>{t('GPSToUse')}</Button>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default GPS;