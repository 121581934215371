import React, { useState } from 'react';

import TaskTypeSelection from './TaskTypeSelection';
import Tasks from './Tasks';
import GPS from './GPS';
import MapComponent from './TaskViewerMap'
import { taskViewer_viewComponents } from './../../constants';

const TaskViewer = (props) => {
    const [currentComponent, setCurrentComponent] = useState({ viewComponent: taskViewer_viewComponents.GPS, data: [] });

    const getComponent = () => {
        switch (currentComponent.viewComponent) {
            case taskViewer_viewComponents.GPS:
                return <GPS callBack={setCurrentComponent} {...props} />;
            case taskViewer_viewComponents.taskTypeSelection:
                return <TaskTypeSelection callBack={setCurrentComponent} {...props} />;
            case taskViewer_viewComponents.tasks:
                return <Tasks callBack={setCurrentComponent} data={currentComponent.data} {...props} />;
            case taskViewer_viewComponents.taskRunner:
                return <MapComponent callBack={setCurrentComponent} data={currentComponent.data} {...props} />;
            case taskViewer_viewComponents.endTaskViewer:
                return <TaskTypeSelection callBack={setCurrentComponent} {...props} />;
            default:
                return <GPS callBack={setCurrentComponent} {...props} />; 
        }
    }

    return (
        <>
            {getComponent()}
        </>
    );
}

export default TaskViewer;