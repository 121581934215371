import React, { useState, useEffect } from 'react';
import { Col, Row, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import {
    getSelectedPlantsToView, setSelectedPlantsToView,
    getSelectedPlotsToView, setSelectedPlotsToView
} from '../../common';

import '../../css/farmPlot.css'

const ShowItemSelectionModal = (props) => {
    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({})
    const [plants, setPlants] = useState(false);
    const [plots, setPlots] = useState(false);

    useEffect(() => {
        if (props.plants) {
            setPlants(props.plants)
        }
        if (props.plots) {
            setPlots(props.plots)
        }        
    }, []);

    useEffect(() => {
        formatCheckedItems();
    }, [plants, plots]);

    const handleCheckboxSelection = (e, p) => {
        setCheckedItems({ ...checkedItems, [e.target.name]: e.target.checked })
    }

    const handleClosing = () => {
        formatCheckedItems();
        props.onHide();
    }

    const formatCheckedItems = () => {
        if (plants) {
            const view = getSelectedPlantsToView();
            if (view && view.length) {
                setCheckedItems(view.reduce((a, v) => ({ ...a, [v.plantId]: true }), {}));
            }
        }
        if (plots) {
            const view2 = getSelectedPlotsToView();
            if (view2 && view2.length) {
                setCheckedItems(view2.reduce((a, v) => ({ ...a, [v.groupId]: true }), {}));
            }
        }
    }

    const getSelectionHeader = () => {
        if (plants)
            return t('Plant');
        return t('PlotGroup');
    }

    const buttonSaveClick = () => {
        const viewList = [];
        if (plants) {
            Object.entries(checkedItems).map(([key, value]) => {
                if (value === true) {
                    props.itemList.forEach(v => {
                        if (v.plantId === parseInt(key, 10)) {
                            let copyData = { ...v };
                            viewList.push(copyData);
                        }
                    });
                }
            })
            setSelectedPlantsToView(viewList);
            props.onHide();
        }
        if (plots) {
            Object.entries(checkedItems).map(([key, value]) => {
                if (value === true) {
                    props.itemList.forEach(v => {
                        if (v.groupId === parseInt(key, 10)) {
                            let copyData = { ...v };
                            viewList.push(copyData);
                        }
                    });
                }
            })
            setSelectedPlotsToView(viewList);
            props.onHide();
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton onClick={() => handleClosing()}>
                    <Modal.Title className="text-dark">{plants ? t('Plants') : t('Plotgroups')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>                  
                    <Form>
                        {/* HEADERS */}
                        <div>
                            <Form.Group>
                                <Row className="show-extra show-extra-headers" >
                                    <Col xs={5}>
                                        <b>{getSelectionHeader()}</b>
                                    </Col>
                                    <Col xs={3}>
                                        <b>{t('Area')} (ha)</b>
                                    </Col>
                                    <Col xs={2}>
                                        <b>{t('UnitAmount')} ({t('Units')})</b>
                                    </Col>
                                    <Col xs={1}>
                                        <b>{t('Show')}</b>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </div>

                        {/* SELECTIONS */}
                        {(plants) ? props?.itemList?.map((p,i) =>
                            <div key={i}>
                                <Form.Group
                                    key={p.plantId}>
                                    <Row className="show-extra" >
                                        <Col xs={5}>
                                            {p.plantName}
                                        </Col>
                                        <Col xs={3}>
                                            {p.area}
                                        </Col>
                                        <Col xs={2}>
                                            {p.countId}
                                        </Col>
                                        <Col xs={1}>
                                            <Form.Check
                                                type='checkbox'
                                                inline
                                                value={checkedItems[p.plantId]}
                                                name={p.plantId}
                                                checked={!!checkedItems[p.plantId]}
                                                onChange={(e) => handleCheckboxSelection(e, p)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                        ) : props?.itemList?.map((p,i) =>
                            <div key={i}>
                                <Form.Group>
                                    <Row className="show-extra" >
                                        <Col xs={5}>
                                            {p.groupName && p.groupName.length > 13 ? p.groupName.substr(0, 13) + '...' : p.groupName}
                                        </Col>
                                        <Col xs={3}>
                                            {p.area}
                                        </Col>
                                        <Col xs={2}>
                                            {p.countId}
                                        </Col>
                                        <Col xs={1}>
                                            <Form.Check
                                                type='checkbox'
                                                inline
                                                value={checkedItems[p.groupId]}
                                                name={p.groupId}
                                                checked={!!checkedItems[p.groupId]}
                                                onChange={(e) => handleCheckboxSelection(e, p)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" className="btn-show-more" onClick={() => buttonSaveClick()}>{t('Save')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShowItemSelectionModal;