export const chartMode = {
    temperatureSum: 1,
    rainSum: 2,
    dValue: 3,
    growthStage: 4
}

// #region TaskViewer
export const taskViewer_viewComponents = {
    GPS: 'GPS',
    taskTypeSelection: 'TaskTypeSelection',
    tasks: 'Tasks',
    taskRunner: 'TaskRunner',
    endTaskViewer: 'EndTaskViewer'
}

export const taskViewer_taskStatuses = {
    planned: 1,
    running: 2,
    paused: 3,
    completed: 4,
    endUnfinished: 7
}

export const taskViewer_stopComponents = {
    stopSelectionForm: 1,
    endTaskFinished: 2,
    endTaskMarkDone: 3,
    showDataForFinishedTask: 4,
    endUnfinishedContinueLater: 5,
    finish: 6
}
export const GRASSPLANT = "6";
// #endregion