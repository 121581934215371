import React from 'react';

const Icon = props => {
    const styles = {

        svg: props.style ? props.style :
            {
            display: 'inline-block',
            verticalAlign: 'middle',
            flexShrink: '0'
            }
        ,
        path: {
            fill: props.color
        },
    };

    return (
        <svg
            style={styles.svg}
            width={`${props.size}px`}
            height={`${props.size}px`}
            viewBox="0 0 1024 1024"
        >
            <path
                style={styles.path}
                d={props.icon}
            >
            </path>         
        </svg>
    )
}

export default Icon;
