import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Select, { createFilter } from 'react-select';

import Form from 'react-bootstrap/Form';
import AppContext from '../../AppContext';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { getContentWithAction, getContent } from '../../apiConnect';
import {
    getSelectedYear, setSelectedYear, removeSelectedPlot, FarmIdToView,
    getSelectedLang, setSelectedLang, getSelectedFarmId, setSelectedFarmId,
    setSelectedFarmName, setGPStoStorage, getGPSfromStorage, getSelectedForecastStation,
    setSelectedForecastStation, getSelectedHistoryStation, setSelectedHistoryStation
} from '../../common';
import { GetLogoutIcon } from '../../commonElements';
import { useAlert } from 'react-alert'
import '../../css/settings.css'


const SettingsModal = (props) => {
    const getlanguages = () => {
        return [
            { text: t('Lang_Fi'), value: 'fi' },
            { text: t('Lang_En'), value: 'en' },
            { text: t('Lang_Sv'), value: 'sv' }
            //{ test: t('Lang_Ru'), value: 'ru' }
        ];
    }

    const { auth } = useContext(AppContext);
    const { t } = useTranslation();
    const [farmYears, setFarmYears] = useState([]);
    const [year, setSetYear] = useState(getSelectedYear());
    const [lang, setLang] = useState(getSelectedLang());
    const [farms, setFarms] = useState([])
    const [farm, setFarm] = useState(getSelectedFarmId())
    const [forecastStations, setForecastStations] = useState([]);
    const [forecastStation, setForecastStation] = useState(getSelectedForecastStation().stationId);
    const [forecastHistoryStations, setForecastHistoryStations] = useState([]);
    const [forecastHistoryStation, setForecastHistoryStation] = useState(getSelectedHistoryStation().stationId);
    const [isLoading, setIsLoading] = useState(true);
    const [gpsValue, setGpsValue] = useState(getGPSfromStorage);

    const [farmsOptions, setFarmsOptions] = useState([])

    const alert = useAlert();

    useEffect(() => {
        let mounted = true;
        getYears();
        getForecastStations();
        getContent(auth.getAccessToken(), 'farm', {}).then((data) => {
            
            if (mounted) {
                formatFarms(data);
                setFarms(data);
                setIsLoading(false);
            }
        });

        return function cleanup() {
            mounted = false;
        }
    }, []);

    const formatFarms = (data) => {          
        let tempList = [];
        for (var v in data) {                    
            let obj = { label: FarmIdToView(data[v].farmId) + " - " + data[v].name, value: data[v].farmId, name: data[v].name };
                tempList.push(obj);          
        }
        setFarmsOptions([...tempList]);

    }

    const getYears = (checkLoad = false) => {
        getContentWithAction(auth.getAccessToken(), 'settings', 'farmYear', {})
            .then((data) => {
                var y = getSelectedYear();

                // if selected year doesn't exists in data, select first (high)
                if (data && data.length > 0 &&
                    data.some(f => f.year === y) === false) {
                    setSelectedYear(data[0].year);
                    y = data[0].year;
                }

                if (checkLoad) {
                    // refresh page content
                    props.setRefresh();
                    var alertText = <span>{t('Farm')}: <b>{FarmIdToView(getSelectedFarmId())}</b></span>;
                    alert.show(alertText, { type: 'success', timeout: 5000 });
                }

                setFarmYears(data || []);
            })
            .catch(() => {
                setFarmYears([]);
            });
    }

    const getForecastStations = () => {
        Promise.all([
            getContentWithAction(auth.getAccessToken(), 'forecast', 'retrieveForecastStations', {}),
            getContentWithAction(auth.getAccessToken(), 'forecast', 'retrieveForecastHistoryStations', {})
        ]).then(([stations, historyStations]) => {
            if (forecastStation === -1) {
                stations.unshift({label: "", value: -1});
            }
            if (forecastHistoryStation === -1) {
                historyStations.unshift({ label: "", value: -1});
            }
            setForecastStations(stations);
            setForecastHistoryStations(historyStations);
        }).catch((c) => { });
    }

    const selectedYearHandler = (e) => {
        setSelectedYear(parseInt(e.target.value));
        removeSelectedPlot();
        setSetYear(getSelectedYear());

        // refresh page content
        props.setRefresh();
        var alertText = <span>{t('Year')}: <b>{getSelectedYear()}</b></span>;
        alert.show(alertText, { type: 'success', timeout: 5000 });
    }

    const selectedLanguageHandler = (e) => {
        i18n.changeLanguage(e.target.value);
        setSelectedLang(e.target.value);
        setLang(e.target.value);

        // refresh page content
        props.setRefresh();
        var alertText = <span>{t('Language')}: <b>{getSelectedLang()}</b></span>;
        alert.show(alertText, { type: 'success', timeout: 5000 });
    }
    const selectedFarmHandler = (e) => {
        setFarmYears([]);

        setSelectedFarmId(e.value);
        removeSelectedPlot();
        setFarm(getSelectedFarmId());
     
        setSelectedFarmName(e.name);
        getYears(true);
        getForecastStations();
    }

    const selectedStationHandler = (e) => {
        setForecastStation(e.target.value);
        var station = forecastStations.find(s => s.value === e.target.value);
        setSelectedForecastStation(station);
        props.setRefresh();
    }

    const selectedHistoryStationHandler = (e) => {
        setForecastHistoryStation(e.target.value);
        var station = forecastHistoryStations.find(s => s.value === e.target.value);
        setSelectedHistoryStation(station);
        props.setRefresh();
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}
                size="sm"
                id="settings-modal">
                <Modal.Header closeButton >
                    <Modal.Title className="text-dark">{t('Settings')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        {/*{farms.length > 1 ?*/}
                        <Form.Group>
                            <Form.Label className="text-dark">{t('Farm')}</Form.Label> {(isLoading) ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                            {/*<Form.Control as="select" id="farm-select" onChange={selectedFarmHandler} value={farm}>*/}
                            {/*    {farms.map((f, i) =>*/}
                            {/*        <option key={i} value={f.farmId} name={f.name} data-name={f.name}>{FarmIdToView(f.farmId)} - {f.name}</option>*/}
                            {/*    )}*/}
                            {/*</Form.Control>*/}
                            <Select                              
                                value={farmsOptions.find(f => f.value === farm) || farmsOptions[0]}
                                onChange={(value) => selectedFarmHandler(value)}
                                options={farmsOptions}
                                filterOption={createFilter({ ignoreAccents: false })}
                            />
                        </Form.Group>
                        {/*: ''}*/}
                        <Form.Group controlId="formYear">
                            <Form.Label className="text-dark">{t('Year')}</Form.Label>
                            <Form.Control as="select" onChange={selectedYearHandler} value={year}>
                                {farmYears.map(y =>
                                    <option key={y.year} value={y.year}>{y.year}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formLanguage">
                            <Form.Label className="text-dark">{t('Language')}</Form.Label>
                            <Form.Control as="select" onChange={selectedLanguageHandler} value={lang}>
                                {getlanguages().map(y =>
                                    <option key={y.value} value={y.value}>{y.text}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        {auth.showForecasts() ?
                            <Form.Group controlId="formStation">
                                <Form.Label className="text-dark">{t('Forecast_Station')}</Form.Label>
                                <Form.Control as="select" onChange={selectedStationHandler} value={forecastStation}>
                                    {forecastStations.map(y =>
                                        <option key={y.value} value={y.value}>{y.label}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            : ''}
                        {auth.showForecasts() ?
                            <Form.Group controlId="formHistoryStation">
                                <Form.Label className="text-dark">{t('Forecast_HistoryStation')}</Form.Label>
                                <Form.Control as="select" onChange={selectedHistoryStationHandler} value={forecastHistoryStation}>
                                    {forecastHistoryStations.map(y =>
                                        <option key={y.value} value={y.value}>{y.label}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            : ''}
                        {/* TODO: USE AUTOCOMPLETE FOR STATIONS?
                        <Select className="text-dark forecast-stations" label="ForecastStations"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={selectedStation}
                            onChange={(value) => { }}
                            options={forecastStationData}
                            placeholder={t('Select')}
                            />
                        */}
                        <Form.Group>
                            <Form.Check
                                type='checkbox'
                                className={"checkboxStyle"}
                                value={gpsValue}
                                name='gps_allowed'
                                label={t('GPSToUse')}
                                defaultChecked={gpsValue}
                                onChange={(e) => {
                                    setGPStoStorage(!gpsValue);
                                    setGpsValue(!gpsValue)
                                    
                                       }
                                    }
                                />
                                
                        </Form.Group>
                        <Form.Group className="logout">
                            <Button onClick={() => auth.logout()} className="log-out-btn">
                                <GetLogoutIcon />
                                <br />
                                <Form.Label className="text-dark">{t('LogOut')}</Form.Label>
                            </Button>
                            {/*<Button variant="outline-danger" className="col-6 center" id="log-out-btn" onClick={() => auth.logout()}>{t('LogOut')}*/}
                        </Form.Group>
                    </Form>
                </Modal.Body>

            </Modal>
            
        </>
    );
}

export default SettingsModal;