import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getContentWithAction, getContent, postContentWithAction, postContentWithAction_ } from '../apiConnect';
import { ICONS } from '../img/icons'
import Icon from '../img/Icon'
import Collapse from 'react-bootstrap/Collapse'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Accordion from 'react-bootstrap/Accordion';
import { useAlert } from 'react-alert'

import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import { Form, Row, Col } from 'react-bootstrap'

import 'ol/ol.css';
import { Fill, Circle, RegularShape, Stroke, Style, Text } from 'ol/style.js';
import '../css/map.css';
import satelite_img from '../img/tci-icon.png';
import map_img from '../img/map-icon.png';

import { Map as Map2, View } from 'ol';
import { defaults as defaultInteractions, Select } from 'ol/interaction';
import * as proj from 'ol/proj';
import Collection from 'ol/Collection';
import { defaults as defaultControls, FullScreen, Zoom, OverviewMap, Attribution, ScaleLine } from 'ol/control';
import { click, doubleClick } from 'ol/events/condition';
import MousePosition from 'ol/control/MousePosition';
//import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import { toStringHDMS } from 'ol/coordinate';
import { GeoJSON } from 'ol/format';
import { Group as LayerGroup, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { all as strategyAll } from 'ol/loadingstrategy.js';
import { Vector as VectorSource, WMTS } from 'ol/source';

import {
    getGPSfromStorage, saveSelectedPlots, getSelectedPlots, getSelectedLang,
    getSelectedFarmId, getSelectedYear, FarmIdToView, getSelectedFarmName,
    setSelectedPlot, getSelectedPlot, setSelectedPage,
    storeSelectedFeature, retrieveSelectedFeature
} from '../common';
import { featuresReader, retrieveWgsCoordinates, returnRoundedValue } from '../Map/commonMapFunctions';
import AppContext from '../AppContext';
import { enableGPS, disableGPS } from '../gps.js';
import { addMapBaseLayer } from '../Map/addMapBaseLayer';
import { checkCoordsInsideGridPolygon } from '../Map/addMapTaskGrid';
import { addMapPosition, updateMapPosition } from '../Map/addMapPosition';
import * as styles from '../Map/mapStyle'
import { MapConfig } from '../Map/mapConfig';
import ActionSelectionModal from './Modals/ActionSelectionModal'
import MemoModal from './Modals/MemoModal'
import PlantProtectionModal from './Modals/PlantProtectionModal'
import FeatureSelectionModal from './Modals/FeatureSelectionModal'

// needed for epsg transforms
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';
//import AccordionBody from 'react-bootstrap/esm/AccordionBody';
//import GPS from './TaskViewer/GPS';

proj4.defs("EPSG:3067", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:3857", "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs");
register(proj4);

let WisuMap = {}, _styles = null;

const Map = (props) => {
    const { t } = useTranslation();
    //const { geolocation, mapInitCoordinates } = useContext(AppContext);
    const { setGeolocation, setMapInitCoordinates } = useContext(AppContext);
    const [currentTaskAmount, setCurrentTaskAmount] = useState(-1);
    const { auth } = useContext(AppContext);
    const alert = useAlert();

    let [plots, setPlots] = useState([]);
    let [agrPlotFeatures, setAgrPlotFeatures] = useState([]);
    //const [mapFeatures, setMapFeatures] = useState([]);

    const [activatedGps, setActivatedGps] = useState(false);
    const [selectModalDisabled, setSelectModalDisabled] = useState(true);
    const [selectMultiModalDisabled, setSelectMultiModalDisabled] = useState(true);
    const [showActionModal, setShowActionModal] = useState(false)
    const [actionModeAdd, setActionModeAdd] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertContent, setAlertContent] = useState({ header: '', text: '', close: '' });
    const [sidePaneShow, setSidePaneShow] = useState(false);
    const [extendSidePane, setExtendSidePane] = useState(false);
    const [selectionEvent, setSelectionEvent] = useState(null);
    const [mapIsRendered, setMapIsRendered] = useState(false);

    // Observation visibility modal
    const [featureSelectionEvent, setFeatureSelectionEvent] = useState({ coordinates: null, feature: null, plotData: null });
    const [showFeatureSelectionModal, setShowFeatureSelectionModal] = useState(false);
    let allowModalHide = false;

    //layer selects
    const [basicLayer, setBasic] = useState(null)
    const [satelliteLayer, setSatellite] = useState(null)
    const [selectedLayer, setSelectedLayer] = useState(0)

    //const timeLogId = useRef(null);
    const currentPosition = useRef({ latitude: null, longitude: null, timestamp: null });
    //const timer = useRef(null);
    const gpsWatch = useRef(null);
    const running = useRef(false);

    let RasterBaseGroup;
    //const zoomIdx = useRef(0);

    const taskLayerName = 'taskLayer';
    //const logInterval = 2000; // Milliseconds
    const gpsAccuracyUpperLimit = 20; // Meters
    const gpsAccuracyLowerLimit = 0;
    const gpsLocationFilterSpeed = 6; // m/s
    const gpsOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    }

    ///GPS BUTTON FEATURES 
    const [disableGPSEvent, setDisableGPSEvent] = useState(null);
    const [isGPSDisabled, setGPSDisabled] = useState(false);
    const [initalCoordinatesView, setInitalCoordinatesView] = useState(null);

    ///MAP FEATURES   
    const [selectedFeature, setSelectedFeature] = useState([]);

    const [isDisabled, setDisabled] = useState(true);
    const [noObservations, setNoObservations] = useState(true);
    const [noNotes, setNoNotes] = useState(true);
    const [noTrees, setNoTrees] = useState(true);
    const [noRocks, setNoRocks] = useState(true);
    const [noSampleSets, setNoSampleSets] = useState(true);
    const [noFertilitySamples, setNoFertilitySamples] = useState(true);
    const [enableDelete, setEnableDelete] = useState(false);
    const [allowChanges, setAllowChanges] = useState(false);

    ///PLOT LIST  
    const [reachedBottom, setReachedBottom] = useState(false);

    ///MEMO FEATURES
    const [showMemoModal, setMemoModal] = useState(false)
    const [memoEvent, setMemoEvent] = useState({ coordinates: null, geometryID: null, plotData: null, new: null });

    ///OBSERVATION FEATURES
    const [showObservationModal, setObservationModal] = useState(false)
    const [observationEvent, setObservationEvent] = useState({ coordinates: null, geometryID: null, plotData: null, new: null });

    const sSelectedPlot = getSelectedPlot();
    setSelectedPage(3);

    useEffect(() => {
        _styles = styles.init();
        styles.setLang(t);

        getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}).then((result) => {
            setAllowChanges(result);
        });

        if (!plots || plots.length === 0) {
            getContent(auth.getAccessToken(), 'farmPlot', { lang: getSelectedLang() }).then((data) => {
                data.forEach(d => {
                    d.plantName = d.plantName ? d.plantName : t('NoPlants');
                });
                setPlots(data);
                renderMap(data);
                setMapIsRendered(true);
                setSelectionEvent(Date.now());
            });
        }

        window.addEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (mapIsRendered) {
            if (getSelectedPlots().length > 0) setSidePaneShow(true);
            else setSidePaneShow(false);
        }

    }, [selectionEvent]);

    useEffect(() => {
        if (isGPSDisabled && !WisuMap.settingsGPSDirective) {
            WisuMap.getView().setCenter(initalCoordinatesView);
        }
    }, [disableGPSEvent]);

    const handleResize = () => {
        setTimeout(() => {
            WisuMap.updateSize();
        }, 255);
    }

    const view = new View({
        center: proj.transform([23.66702093861786, 63.019978712597218], 'EPSG:4326', 'EPSG:3857'), // Temporary center for testing, todo figure out center based on selected farm.
        zoom: 14
    })

    const renderMap = (plots) => {
        // kasvulohkon rajat
        let agrPlotFeature = new VectorLayer({
            title: 'AgrPlot feature',
            source:
                createMapVectorSource({
                    layer: 'mtech:agrplots',
                    setExtent: true,
                    callBack: function (features) {

                        agrPlots.setVisible(true);
                        WisuMap.ContextChanged = true;
                    }
                }),
            style: _styles.agr_plot.default,
            zIndex: 300,
            visible: true
        });

        // peruslohkon rajoista LayerGroup
        let agrPlots = new LayerGroup({
            title: 'AgrPlots',
            layers: [agrPlotFeature],
            zIndex: 300,
            visible: true
        });


        // map points
        let usermapFeatures = new VectorLayer({
            title: 'map features',
            source: createMapVectorSourceMapFeatures({
                layer: 'mtech:features',
                //setExtent: true,
                callBack: function (features) {
                    mapPointsGroup.setVisible(true);
                    WisuMap.usermapFeatures = usermapFeatures;
                }
            }),
            style: _styles.map_features.select,
            zIndex: 1000,
            visible: true
        });

        // karttapisteist� LayerGroup
        let mapPointsGroup = new LayerGroup({
            title: 'mapPointsGroup',
            layers: [usermapFeatures],
            zIndex: 1000,
            visible: true
        });

        // liitet��n basePlots Group yleiseen VectorGrouppiin
        let VectorGroup = new LayerGroup({
            title: 'vectors',
            layers:
                [
                    agrPlots
                ],
            zIndex: 300,
            visible: true
        });

        let orthoImage = addMapBaseLayer();
        setSatellite(orthoImage);

        let basicImage = addMapBaseLayer("taustakartta");
        setBasic(basicImage);

        orthoImage.setVisible(true);
        basicImage.setVisible(false);

        RasterBaseGroup = new LayerGroup({
            title: 'Base rasters',
            layers: [orthoImage, basicImage]
        });

        WisuMap = new Map2({
            target: 'map',
            layers: [
                //addMapBaseLayer(), // map layer
                RasterBaseGroup,
                addMapPosition(), // gps point
                VectorGroup, // agrplots
                mapPointsGroup
            ],
            view: view,
            interactions: defaultInteractions({
                doubleClickZoom: true,
                mouseWheelZoom: true
            }),
            controls: defaultControls({ attribution: false }).extend([
                new MousePosition({
                    projection: 'EPSG:4326', //Config.CRS.WGS84,
                    coordinateFormat: function (coord) {
                        return toStringHDMS(coord) + ' (WGS84)';
                    },
                    className: 'mouse-position'
                }),
                new Attribution({
                    collapsible: true,
                    label: '�'
                }),
                new ScaleLine({
                    units: 'metric',
                    minWidth: 80
                })
            ]),
            moveTolerance: 5
        });

        // assign state propertys to wisumap
        WisuMap.ContextChanged = false;
        WisuMap.plots = plots;
        WisuMap.usermapFeatures = null;
        WisuMap.mapPointsGroup = mapPointsGroup;

        var gpsValue = getGPSfromStorage();
        if (gpsValue) {
            WisuMap.settingsGPSDirective = true;
            startGPS();
        }
        else if (gpsWatch.current)
            disableGPS(gpsWatch.current);


        mapPointsGroup.interaction = {};
        mapPointsGroup.interaction.collection = { select: new Collection(), single: new Collection() };

        // Possible not needed -> keeped here until WisuMap.mapPointsGroup.interaction.select is made?
        mapPointsGroup.interaction.select = new Select({
            condition: click,
            toggleCondition: click,
            multi: false,
            layers: [usermapFeatures],
            hitTolerance: 6,
            style: [styles.retrieveMapFeatureStyle()],
            features: mapPointsGroup.interaction.collection.select
        });
        WisuMap.addInteraction(mapPointsGroup.interaction.select);
        mapPointsGroup.interaction.select.setActive(true);

        function selectFeature(e) {

            if (e.selected.length) {
                //eventListener.callBack(e.selected[0]);

                e.selected.forEach(function (feature) {
                    var prop = feature.getProperties();

                    //FERTILITY points not to be selectablde
                    var ignorable = prop.FeatureType === MapConfig.FEATURE.FERTILITY_SAMPLE ? true : prop.FeatureType === MapConfig.FEATURE.FERTILITY_SERIES ? true : false;

                    if (ignorable === false) {
                        //Make text visible on tree/rock features for clarity
                        var text = prop.FeatureType === MapConfig.FEATURE.TREE ? t('Tree') : prop.FeatureType === MapConfig.FEATURE.ROCK ? t('Rock') : '';
                        e.selected[0].style_[0].text_ = new Text({
                            font: 'bold 14px arial',
                            scale: 1,
                            text: text,
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 1)'
                            }),
                            placement: 'point',
                            textAlign: 'center',
                            offsetY: 20,
                            backgroundFill: new Fill({
                                color: '#F44336'
                            }),
                            padding: [3, 3, 0, 3],
                            overflow: true
                        })
                    }

                    if (prop.PlotId) {
                        mapPointsGroup.interaction.collection.select.clear();
                    }
                    else {
                        mapPointsGroup.interaction.collection.select.clear();

                        if (ignorable === false) {
                            mapPointsGroup.interaction.collection.select.push(feature);

                            const [coordinates, plotData] = getPlotDataWithCoordinates(e.mapBrowserEvent.originalEvent, agrPlotFeature)

                            featureClickHandler(feature, coordinates, plotData);
                        }
                    }
                });
            }
            else if (e.deselected.length) {
                // automatically take off from collection.select list   
                setEnableDelete(false);
            }
            
            e.mapBrowserEvent.stopPropagation();
            e.mapBrowserEvent.preventDefault();
            WisuMap.featureSelected = false;
        }

        mapPointsGroup.interaction.select.on("select", selectFeature)

        /** agrPlots select interaction - select/unselect on singleClick */
        agrPlots.interaction = {};
        agrPlots.interaction.collection = { hover: new Collection(), select: new Collection(), single: new Collection() };
        agrPlots.interaction.select = new Select({
            condition: click,
            toggleCondition: click,
            layers: [agrPlotFeature],
            multi: true,
            hitTolerance: 4,
            style: _styles.agr_plot.select,
            features: agrPlots.interaction.collection.select,
            filter: function (feature, layer) {
                //Check if feature has been selected as well and disbale plot selection
                if (WisuMap.featureSelected === true || WisuMap.addNewPointMemo === true || WisuMap.addNewPointObservation === true || WisuMap.addNewPointRock === true || WisuMap.addNewPointTree === true) {
                    return false;
                }
                return true;
            }
        });
        WisuMap.addInteraction(agrPlots.interaction.select);
        agrPlots.interaction.select.setActive(true);

        agrPlots.interaction.select.on("select", function (e) {

            var selected = e.selected;
            var deselected = e.deselected;

            if (selected.length) {

                selected.forEach(function (feature) {

                    var p = feature.getProperties();
                    let find = WisuMap.plots.find(w => w.apChar === p.PlotLetter && w.number === p.BasePlotId);
                    if (find)
                        saveSelectedPlots(find.apNumber);
                });
            }
            if (deselected.length) {
                deselected.forEach(function (feature) {

                    var p = feature.getProperties();
                    let find = WisuMap.plots.find(w => w.apChar === p.PlotLetter && w.number === p.BasePlotId);
                    if (find)
                        saveSelectedPlots(find.apNumber);

                });
            }
            checkPlotSelectionToButtons();
            setSelectionEvent(Date.now());

        }, this);

        let agrPlotSource = agrPlotFeature.getSource();
        agrPlotSource.on("change", function () {
            var state = agrPlotSource.getState();
            // only onload
            if (state == 'ready' && WisuMap.ContextChanged == true) {
                WisuMap.ContextChanged = false;

                let features = agrPlotSource.getFeatures();
                let selectedAgrPlotFeatureIds = getSelectedPlots();
                agrPlots.interaction.collection.select.clear();

                if (selectedAgrPlotFeatureIds.length) {
                    for (let i = 0; i < features.length; i++) {
                        let feature = features[i];
                        let p = feature.getProperties();

                        let find = WisuMap.plots.find(w => w.apChar === p.PlotLetter && w.number === p.BasePlotId);
                        if (find) {
                            if (selectedAgrPlotFeatureIds.indexOf(parseInt(find.apNumber)) !== -1) {
                                agrPlots.interaction.collection.select.push(feature);
                            }
                        }
                    }
                }

                checkPlotSelectionToButtons();
            }
        });

        WisuMap.on('click', function (e) {

            WisuMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {

                if (layer.values_.title === "map features") {
                    WisuMap.featureSelected = true;
                }
                if (layer.values_.title === "AgrPlot feature") {
                    WisuMap.plotProperties = feature.getProperties();
                }
            });

            //var allFeatures = WisuMap.getFeaturesAtPixel(e.pixel);

            var pixel = WisuMap.getEventPixel(e.originalEvent);
            var hit = WisuMap.hasFeatureAtPixel(pixel);

            if (hit && (WisuMap.addNewPointTree || WisuMap.addNewPointRock)) // need to hit on a plot and need to have Add-button pressed
            {
                let coor4326 = getNewPointCoor(WisuMap.getEventCoordinate(e.originalEvent));

                // save tree (5307) feature as test
                if (WisuMap.addNewPointTree) {
                    saveUserFeature(coor4326, '5307');
                }

                // save rock (5306) feature as test
                if (WisuMap.addNewPointRock) {
                    saveUserFeature(coor4326, '5308');
                }
            }

            if (hit && (WisuMap.addNewPointMemo)) // need to hit on a plot and need to have Add-button pressed
            {
                const [coor4326, plotData] = getPlotDataWithCoordinates(e.originalEvent, agrPlotFeature);

                setMemoEvent({ coordinates: coor4326, plotData: plotData, new: true });
                setMemoModal(true);
            }
            if (hit && (WisuMap.addNewPointObservation)) // need to hit on a plot and need to have Add-button pressed
            {
                const [coor4326, plotData] = getPlotDataWithCoordinates(e.originalEvent, agrPlotFeature);

                setObservationEvent({ coordinates: coor4326, plotData: plotData, new: true });
                setObservationModal(true);
            }

        });

    }

    function getPlotDataWithCoordinates(event, agrPlotFeature) {
        let coor4326 = getNewPointCoor(WisuMap.getEventCoordinate(event));
        let find = undefined;

        if (WisuMap.plotProperties) {
            find = WisuMap.plots.find(w => w.apChar === WisuMap.plotProperties.PlotLetter && w.number === WisuMap.plotProperties.BasePlotId);
        }

        return [coor4326, find];

    }

    // #region "map - add feature"
    function saveUserFeature(coor4326, featureId) {
        //console.log(coor4326);
        postContentWithAction(auth.getAccessToken(), 'map', 'addNewFeature',
            { latitude: coor4326.latitude, longitude: coor4326.longitude, feature: featureId, note: undefined },
            (response) => {
                //console.log(response)

                if (response) {
                    var feature = featuresReader(response);

                    let source = WisuMap.usermapFeatures.getSource();
                    let addedFeatures = WisuMap.AllFeatures;

                    // added feature is added to collection to drawing
                    feature.forEach(f => {
                        WisuMap.mapPointsGroup.interaction.collection.single.push(f);
                        source.addFeature(f);
                        addedFeatures.push(f);
                    });

                    WisuMap.AllFeatures = addedFeatures;
                    featuresChecker(addedFeatures);
                    WisuMap.addNewPointTree = false;
                    WisuMap.addNewPointRock = false;
                }
            });
    }

    function getNewPointCoor(coor3857) {
        //var zoom = map.getView().getZoom();
        let coor = proj.transform(coor3857, 'EPSG:3857', 'EPSG:3067');

        let coordinates = {
            wgs: {},
            latitude: coor && coor.length > 1 ? Math.round(returnRoundedValue(coor[1], 0)) : 0,
            longitude: coor && coor.length > 0 ? Math.round(returnRoundedValue(coor[0], 0)) : 0
        };

        // return gps also in another format (if wanted to show in somewhere..)
        coordinates.wgs = retrieveWgsCoordinates(coor3857);

        return coordinates;
    }

    //End of map add feature
    // #endregion

    function mapfeaturesChanged() {
        let source = WisuMap.usermapFeatures.getSource();
        let features = WisuMap.AllFeatures;

        handleSelectedFeatures(source, features)
    }

    function handleSelectedFeatures(source, features) {
        source.clear();
        WisuMap.mapPointsGroup.interaction.collection.single.clear();

        // stored values
        let selectedFeatures = retrieveSelectedFeature();

        if (!selectedFeatures || !selectedFeatures.length) { // show none           
            setDisabled(false);
        }
        else { // show selected 
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                let properties = feature.getProperties();
                if (selectedFeatures.indexOf(parseInt(properties.FeatureType, 10)) !== -1) {
                    WisuMap.mapPointsGroup.interaction.collection.single.push(feature);
                    source.addFeature(feature);
                }
            }
            setSelectedFeature(selectedFeatures);
            setDisabled(false);
        }
    }

    const getCurrentLayer = () => {
        let layers = WisuMap.getLayers().array_;
        let currentLayer;

        let taskLayer = layers.filter(layer => layer.get('name') === taskLayerName)[0];
        currentLayer = taskLayer;
        return currentLayer;
    }

    const success = (position) => {
        console.log('new GPS location!');
        let pos = validateNewPosition(position.coords);
        if (!pos.valid) {
            console.log('Insufficient GPS accurary');
            return;
        }
        currentPosition.current = pos;
        // Update user location on map
        updateMapPosition(currentPosition.current);
        // Move map center to user position
        WisuMap.getView().setCenter(proj.transform([currentPosition.current.longitude, currentPosition.current.latitude], 'EPSG:4326', 'EPSG:3857'));
        // Check which grid cell you are in and update task value
        if (running.current === true) checkCoordsInsideGridPolygon(getCurrentLayer(), currentPosition.current, setCurrentTaskAmount);
    }

    const errorHandler = () => {
        console.log('gps error');
    }

    /**
     * Vector layer source and get from db
     */
    function createMapVectorSource(obj) {

        obj.callBack = (typeof obj.callBack !== 'undefined') ? obj.callBack : function () { };

        var vectorSource = new VectorSource({
            format: new GeoJSON(),
            strategy: strategyAll,
            loader: function () {

                getContentWithAction(auth.getAccessToken(), 'map', 'agrplots', {})
                    .then((data) => {
                        setAgrPlotFeatures(data);
                        featureLoader(data);
                    })
                    .catch(errorHandler);
            }
        });

        function featureLoader(response) {
            var features = new GeoJSON().readFeatures(response.featureCollection, {
                dataProjection: response.crs,
                featureProjection: 'EPSG:3857'//Config.CRS.WEBMERCATOR
            });

            obj.callBack(features);
            //console.log(features)
            if (!features.length) {
                const alertText = t('Error_boundaries_not_found');
                alert.show(alertText, { type: 'info', timeout: 10000 });
                return false;
            }

            if (response.dataYear !== getSelectedYear()) {
                const alertText = t('Error_old_boundaries').replace('{0}', response.dataYear);
                alert.show(alertText, { type: 'info', timeout: 10000 });            

                 // filter out baseplots that are not in use in chosen year
                features = features.filter((item) => {
                    if (WisuMap.plots.find(p => p.number === item.values_.BasePlotId))
                        return item;
                });               

                // change previous year plant information
                features.forEach(f => {
                    var pro = f.getProperties();
                    let find = WisuMap.plots.find(w => w.apChar === pro.PlotLetter && w.number === pro.BasePlotId);
                    if (find) {
                        f.set('PlantName', find.plantName);
                        f.set('PlantCode', find.plantId);
                        f.set('Area', find.area);
                    }
                });
            }
            vectorSource.addFeatures(features);

            // selected plots
            let plotIds = getSelectedPlots();
            var vectorSource2 = null;

            //check selected plot
            let selectedFeature = null;
            if (sSelectedPlot) {
                features.forEach(f => {
                    var pro = f.getProperties();

                    let find = WisuMap.plots.find(w => w.apChar === pro.PlotLetter && w.number === pro.BasePlotId);
                    if (find) {
                        if (find.apNumber === sSelectedPlot.apNumber)
                            selectedFeature = f;
                    }
                });
            }
            else if (plotIds && plotIds.length) {

                let plotFound = false;
                vectorSource2 = new VectorSource({
                    format: new GeoJSON(),
                    strategy: strategyAll
                });

                features.forEach(f => {
                    var pro = f.getProperties();

                    plotIds.forEach(p => {
                        if (pro.PlotId === p) {
                            plotFound = true;
                            vectorSource2.addFeature(f);
                        }
                    });
                });

                if (!plotFound)
                    vectorSource2 = null;
            }

            if (selectedFeature) { // zoom to plot
                WisuMap.getView().fit(selectedFeature.getGeometry(), { duration: 1000 });
            }
            else if (vectorSource2) { // this will center map on selected plots
                WisuMap.getView().fit(vectorSource2.getExtent());
            }
            else if (obj.setExtent === true) { // center to farm plots
                WisuMap.getView().fit(vectorSource.getExtent());

            } // this will center map

        }

        return vectorSource;
    }

    function createMapVectorSourceMapFeatures(obj) {

        obj.callBack = (typeof obj.callBack !== 'undefined') ? obj.callBack : function () { };

        var vectorSource = new VectorSource({
            format: new GeoJSON(),
            strategy: strategyAll,
            loader: function () {
                getContentWithAction(auth.getAccessToken(), 'map', 'mapFeatures', {})
                    .then((data) => {
                        featureLoader2(data);
                    })
                    .catch(errorHandler);
            }
        });

        function featureLoader2(response) {
            let features = featuresReader(response);

            WisuMap.AllFeatures = features;

            obj.callBack(features);

            if (!features.length) {
                return false;
            }

            featuresChecker(features);

            // this will handle seleced features to visible/on draw
            handleSelectedFeatures(vectorSource, features)
            //vectorSource.addFeatures(features);
        }

        return vectorSource;
    }

    const featuresChecker = (features) => {
        if (features.length) {
            if (features.some(e => e.getProperties().FeatureType === '5304')) {
                setNoObservations(false);
            }
            else if (!features.some(e => e.getProperties().FeatureType === '5304')) {
                handleCheckboxSelection(5304, true);
                setNoObservations(true);
            }
            if (features.some(e => e.getProperties().FeatureType === '5306')) {
                setNoNotes(false);
            }
            else if (!features.some(e => e.getProperties().FeatureType === '5306')) {
                handleCheckboxSelection(5306, true);
                setNoNotes(true);
            }
            if (features.some(e => e.getProperties().FeatureType === '5307')) {
                setNoTrees(false);
            }
            else if (!features.some(e => e.getProperties().FeatureType === '5307')) {
                handleCheckboxSelection(5307, true);
                setNoTrees(true);
            }
            if (features.some(e => e.getProperties().FeatureType === '5308')) {
                setNoRocks(false);
            }
            else if (!features.some(e => e.getProperties().FeatureType === '5308')) {
                handleCheckboxSelection(5308, true);
                setNoRocks(true);
            }
            if (features.some(e => e.getProperties().FeatureType === '5311')) {
                setNoSampleSets(false);
            }
            if (features.some(e => e.getProperties().FeatureType === '5305')) {
                setNoFertilitySamples(false);
            }
        }
    }

    // Return a potential object for updating current position
    const validateNewPosition = (newPosition) => {
        let potentialPosition = { valid: false };

        if (currentPosition.current.latitude === null) {
            if (newPosition.accuracy > gpsAccuracyUpperLimit || newPosition.accuracy < gpsAccuracyLowerLimit) {
                console.log('Bad accuracy for a potential initial position');
            }

            let lat = newPosition.latitude;
            let lon = newPosition.longitude;
            let time = new Date();
            potentialPosition = { valid: true, latitude: lat, longitude: lon, timestamp: time, heading: newPosition.heading, velocity: 0 };
        }

        let newTime = new Date();
        let timeDiff = (newTime - currentPosition.current.timestamp) / 1000; // seconds
        let dist = distance(newPosition.latitude, newPosition.longitude, currentPosition.current.latitude, currentPosition.current.longitude); // metres
        let velocity = dist / timeDiff;
        if (velocity <= gpsLocationFilterSpeed) {
            potentialPosition = { valid: true, latitude: newPosition.latitude, longitude: newPosition.longitude, timestamp: newTime, heading: newPosition.heading, velocity: velocity };
        }

        return potentialPosition;
    }

    const distance = (lat1, lon1, lat2, lon2) => {
        const R = 6378137;
        let dlon = radians(lon2 - lon1);
        let dlat = radians(lat2 - lat1);
        let f = squared(Math.sin(dlat / 2)) + Math.cos(radians(lat1)) * Math.cos(radians(lat2)) * squared(Math.sin(dlon / 2.0));
        let c = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(1 - f));
        return R * c;
    }

    const radians = (x) => {
        return x * Math.PI / 180;
    }

    const squared = (x) => {
        return x * x;
    }

    const redirect = (geo, mapInitPosition) => {
        setMapInitCoordinates(mapInitPosition);
        setGeolocation(geo);
        if (WisuMap != null) {
            gpsWatch.current = geo.watchPosition(success, errorHandler, gpsOptions);
        }
    }

    const startGPS = () => {
        if (activatedGps) {
            disableGPS(gpsWatch.current);
            document.getElementById('coordinate-btn').style.backgroundColor = 'red';
            setGPSDisabled(true);
            setActivatedGps(false);
            setDisableGPSEvent(Date.now());
        }
        if (!activatedGps) {
            if (!WisuMap.settingsGPSDirective) {
                let oldView = WisuMap.getView().getCenter();
                setInitalCoordinatesView(oldView);
            }
            setActivatedGps(true);
            setGPSDisabled(false);
            enableGPS(redirect);
            document.getElementById('coordinate-btn').style.backgroundColor = 'green';
        }

    }

    let checkPlotSelectionToButtons = () => {
        let p = getSelectedPlots();

        setSelectModalDisabled(true);
        setSelectMultiModalDisabled(true);

        if (p && p.length === 1) {

            setSelectModalDisabled(false);
            setSelectMultiModalDisabled(false);
        }
        else if (p && p.length > 1) {

            setSelectModalDisabled(true);
            setSelectMultiModalDisabled(false);
        }
    }

    const farmPlotClickHandler = () => {
        let g = getSelectedPlots();
        if (g && g.length === 1) {
            let plot = WisuMap.plots.find(p => p.apNumber === g[0]);
            if (plot) {
                setSelectedPlot(plot);
                props.history.push('/plotOperation');
            }
        }
    }

    let featureClickHandler = (feature, coordinates, plotData) => {

        if (feature.values_.FeatureType === MapConfig.FEATURE.NOTE) {

            setMemoEvent({ coordinates: coordinates, geometryID: feature.values_.FeatureGeometryId, plotData: plotData, new: false });
            WisuMap.SelectedFeature = feature;
            setMemoModal(true);
        }
        if (feature.values_.FeatureType === MapConfig.FEATURE.OBSERVATION) {

            if (feature.values_.Visibility && feature.values_.FarmYear != getSelectedYear()) {
                setFeatureSelectionEvent({ coordinates: coordinates, feature: feature.values_, plotData: plotData});
                WisuMap.SelectedFeature = feature;
                setShowFeatureSelectionModal(true);
            }
            else if (plotData) {
                setObservationEvent({ coordinates: coordinates, geometryID: feature.values_.FeatureGeometryId, plotData: plotData, new: false });
                WisuMap.SelectedFeature = feature;
                setObservationModal(true);
            }
        }
        if (feature.values_.FeatureType === MapConfig.FEATURE.TREE ||
            feature.values_.FeatureType === MapConfig.FEATURE.ROCK) {
            WisuMap.SelectedFeature = feature;
            setEnableDelete(true);
        }
    }

    let deleteFeature = () => {

        let source = WisuMap.usermapFeatures.getSource();

        postContentWithAction_(auth.getAccessToken(), 'map', 'delete', { featureGeometryId: WisuMap.SelectedFeature.values_.FeatureGeometryId }, () => { }
        ).then((data) => {
            source.removeFeature(WisuMap.SelectedFeature);
            WisuMap.AllFeatures = filterDeletedFeature();
            setEnableDelete(false);
            alert.show(t('SavedSuccesfully'), { type: 'success' });
        }).catch(e => {
            alert.show(t('SavedFailed'), { type: 'error' });
        });
    }

    const getSidePaneContent = () => {
        var totalArea = 0;
        var amountSelected = 0;
        plots.filter(p => getSelectedPlots().includes(p.apNumber)).forEach(p => {
            totalArea += p.area;
            amountSelected++;
        });

        return <div>{t('ChosenPlots')} <b>{amountSelected}</b> ({totalArea.toFixed(2)} ha)</div>;
    }

    document.title = t('Map') + ' | Mobiwisu';

    const handleCheckboxSelection = (value, uncheck) => {
        if (selectedFeature.includes(value)) {
            var checked = [...selectedFeature.filter(c => c !== value)];
            setDisabled(true);
            setSelectedFeature(checked);
            storeSelectedFeature(checked);
            mapfeaturesChanged();
        }
        else if (!uncheck) {
            var checked = [...selectedFeature];
            checked.push(value);
            setDisabled(true);
            setSelectedFeature(checked);
            storeSelectedFeature(checked);
            mapfeaturesChanged();
        }
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <Row xs="auto">
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="custom-switch"
                            checked={selectedFeature.indexOf(5304) !== -1 ? true : false}
                            disabled={noObservations}
                            onChange={() => handleCheckboxSelection(5304)}
                        />
                    </Col>
                    <Col>
                        <Form.Label className={!noObservations ? 'feature_filter-font-normal' : 'feature_filter-font-disabled'}>
                            {t('Observations')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row xs="auto">
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="custom-switch2"
                            checked={selectedFeature.indexOf(5306) !== -1 ? true : false}
                            disabled={noNotes}
                            onChange={() => handleCheckboxSelection(5306)}
                        />
                    </Col>
                    <Col>
                        <Form.Label className={!noNotes ? 'feature_filter-font-normal' : 'feature_filter-font-disabled'}>
                            {t('Notes')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row xs="auto">
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="custom-switch3"
                            checked={selectedFeature.indexOf(5307) !== -1 ? true : false}
                            disabled={noTrees}
                            onChange={() => handleCheckboxSelection(5307)}
                        />
                    </Col>
                    <Col>
                        <Form.Label className={!noTrees ? 'feature_filter-font-normal' : 'feature_filter-font-disabled'}>
                            {t('TreeLayer')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row xs="auto">
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="custom-switch4"
                            checked={selectedFeature.indexOf(5308) !== -1 ? true : false}
                            disabled={noRocks}
                            onChange={() => handleCheckboxSelection(5308)}
                        />
                    </Col>
                    <Col>
                        <Form.Label className={!noRocks ? 'feature_filter-font-normal' : 'feature_filter-font-disabled'}>
                            {t('RockLayer')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row xs="auto">
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="custom-switch5"
                            checked={selectedFeature.indexOf(5311) !== -1 ? true : false}
                            disabled={noSampleSets}
                            onChange={() => handleCheckboxSelection(5311)}
                        />
                    </Col>
                    <Col>
                        <Form.Label className={!noSampleSets ? 'feature_filter-font-normal' : 'feature_filter-font-disabled'}>
                            {t('SampleSets')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row xs="auto">
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="custom-switch6"
                            checked={selectedFeature.indexOf(5305) !== -1 ? true : false}
                            disabled={noFertilitySamples}
                            onChange={() => handleCheckboxSelection(5305)}
                        />
                    </Col>
                    <Col>
                        <Form.Label className={!noFertilitySamples ? 'feature_filter-font-normal' : 'feature_filter-font-disabled'}>
                            {t('FertilitySamplesLayer')}
                        </Form.Label>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    const popover2 = (
        <Popover id="popover-basic-add">
            <Popover.Header className="header-font" as="h3"> {t('AddNew')}</Popover.Header>
            <Popover.Body>
                <div>
                    <ToggleButtonGroup type="checkbox" vertical="true" >
                        <ToggleButton id="tbg-btn-11" variant='success' className="margin-bottom-10" onClick={() => {
                            WisuMap.addNewPointTree = true;
                        }}>
                            {t('Tree')}
                        </ToggleButton>
                        {/*<br />*/}
                        <ToggleButton id="tbg-btn-12" variant='success' className="margin-bottom-10" onClick={() => {
                            WisuMap.addNewPointRock = true;
                        }}>
                            {t('Rock')}
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-13" variant='success' className="margin-bottom-10" onClick={() => {
                            WisuMap.addNewPointMemo = true;
                        }}>
                            {t('Note')}
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-14" variant='success' className="margin-bottom-10" onClick={() => {
                            WisuMap.addNewPointObservation = true;
                        }}>
                            {t('Observation')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </Popover.Body>
        </Popover>
    );

    const handlePlotSelectionRemoval = (plot) => {
        let selected = WisuMap.getInteractions().pop();
        let selectedFiltered = selected.features_.array_.filter(item => item.values_.BasePlotId !== plot.number)
        selected.features_.array_ = selectedFiltered;
        WisuMap.addInteraction(selected);

        let find = WisuMap.plots.find(w => w.apChar === plot.apChar && w.number === plot.number);
        if (find)
            saveSelectedPlots(find.apNumber);

        checkPlotSelectionToButtons();
        setSelectionEvent(Date.now());
    }

    const listInnerRef = useRef();

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                //console.log("reached bottom");
                setReachedBottom(true);
            }
            else
                setReachedBottom(false);
        }
    }
    const handleMemoModalClose = () => {
        if (allowModalHide) {
            WisuMap.addNewPointMemo = false;
            setMemoModal(false);
            WisuMap.mapPointsGroup.interaction.collection.select.clear();
            allowModalHide = false;
        }
    }

    const handleFeatureSelectionClose = () => {
        if (allowModalHide) {
            setShowFeatureSelectionModal(false);
            WisuMap.mapPointsGroup.interaction.collection.select.clear();
            allowModalHide = false;
        }
    }

    const handleObservationVisibility = (show) => {
        handleFeatureSelectionClose();
        if (!show) {
            let source = WisuMap.usermapFeatures.getSource();
            source.removeFeature(WisuMap.SelectedFeature);
            WisuMap.AllFeatures = filterDeletedFeature();
            WisuMap.mapPointsGroup.interaction.collection.select.clear();
        }
    }

    const handleMemoModalEdit = (geometryId) => {
        WisuMap.addNewPointMemo = false;
        setMemoModal(false);

        getContentWithAction(auth.getAccessToken(), 'map', 'mapFeatureWithId', { id: geometryId }).then((data) => {
            if (data) {
                var feature = featuresReader(data);

                let source = WisuMap.usermapFeatures.getSource();
                let addedFeatures = WisuMap.AllFeatures;

                // added feature is added to collection to drawing
                feature.forEach(f => {
                    WisuMap.mapPointsGroup.interaction.collection.single.push(f);
                    source.addFeature(f);
                    addedFeatures.push(f);
                });

                WisuMap.AllFeatures = addedFeatures;
                featuresChecker(addedFeatures);
            }
        }).catch((c) => { });
    }

    const handleMemoModalDelete = () => {
        WisuMap.addNewPointMemo = false;
        setMemoModal(false);

        let source = WisuMap.usermapFeatures.getSource();
        source.removeFeature(WisuMap.SelectedFeature);
        WisuMap.AllFeatures = filterDeletedFeature();
    }

    const handleObservationsModalClose = () => {
        if (allowModalHide) {
            WisuMap.addNewPointObservation = false;
            setObservationModal(false);
            WisuMap.mapPointsGroup.interaction.collection.select.clear();
            allowModalHide = false;
        }
    }

    const handleObservationModalEdit = (geometryId) => {
        WisuMap.addNewPointObservation = false;
        setMemoModal(false);
        if (geometryId > 0) {
            getContentWithAction(auth.getAccessToken(), 'map', 'mapFeatureWithId', { id: geometryId }).then((data) => {
                if (data) {
                    var feature = featuresReader(data);

                    let source = WisuMap.usermapFeatures.getSource();
                    let addedFeatures = WisuMap.AllFeatures;

                    // added feature is added to collection to drawing
                    feature.forEach(f => {
                        WisuMap.mapPointsGroup.interaction.collection.single.push(f);
                        source.addFeature(f);
                        addedFeatures.push(f);
                    });

                    WisuMap.AllFeatures = addedFeatures;
                    featuresChecker(addedFeatures);
                }
            }).catch((c) => { });
        }
    }

    const handleObservationModalDelete = () => {
        WisuMap.addNewPointObservation = false;
        setObservationModal(false);

        let source = WisuMap.usermapFeatures.getSource();
        source.removeFeature(WisuMap.SelectedFeature);
        WisuMap.AllFeatures = filterDeletedFeature();
    }

    const filterDeletedFeature = () => {
        const filteredItems = WisuMap.AllFeatures.filter(item => item.values_.FeatureGeometryId !== WisuMap.SelectedFeature.values_.FeatureGeometryId);
        featuresChecker(filteredItems);

        return filteredItems;
    }

    return (
        <>
            <div className="farmNameMap ">
                <span ><h6 className="map-header-backg">
                    {FarmIdToView(getSelectedFarmId())} - {getSelectedFarmName()} / {getSelectedYear()}</h6></span>
            </div>

            <div className={sidePaneShow ? "vh-97-5 map-height-transition" : "map-default map-height-transition"} id='map'></div>

            <div className="gps-button-div">
                <button type="button" className="btn btn-info mapButtonStyle gpsMapButton" onClick={() => startGPS()} id="coordinate-btn" >
                    <Icon icon={ICONS.GPS} color='black' size='22' />
                </button>
            </div>

            <button type="button" className="btn btn-info mapButtonStyle backMapButton"
                onClick={() => {
                    if (gpsWatch.current) {
                        disableGPS(gpsWatch.current);
                    }
                    props.history.push('/plots')
                }}>
                <Icon icon={ICONS.BACKBTN} color='black' size='22' />
            </button>

            {/* ACTION BUTTONS */}
            <ButtonGroup vertical className="map-action-button-group">
                <button type="button" id="map-info-btn" className="btn-info2 mapInfoStyle mapGreenButton"
                    disabled={selectModalDisabled ? true : false}
                    onClick={() => {
                        if (gpsWatch.current) {
                            disableGPS(gpsWatch.current);
                        }
                        farmPlotClickHandler();
                    }}>
                    <Icon icon={ICONS.INFO} color='white' size='30' />
                </button>
                {allowChanges ? 
                    <button type="button" id="map-add-btn" className="btn-info2 mapAddModalStyle mapGreenButton"
                        disabled={selectMultiModalDisabled ? true : false}
                        onClick={() => {
                            setActionModeAdd(true);
                            setShowActionModal(true);
                        }}>
                        <Icon icon={ICONS.PLUS} color='white' size='30' />
                    </button>
                    : ''}
                {allowChanges ? 
                    <button type="button" id="map-select-btn" className="btn-info2 mapMarkAsStyle mapGreenButton"
                        disabled={selectMultiModalDisabled ? true : false}
                        onClick={() => {
                            setActionModeAdd(false);
                            setShowActionModal(true);
                        }}>
                        <Icon icon={ICONS.MARK_OK} color='white' size='30' />
                    </button>
                    : ''}
            </ButtonGroup>
            {/* --------------- */}

            <span id="truecolor_satelite" className="active map-satellite-top"
                onClick={() => {
                    if (!basicLayer || !satelliteLayer) return;

                    if (selectedLayer === 0) {
                        basicLayer.setVisible(true);
                        satelliteLayer.setVisible(false);
                    }
                    else if (selectedLayer === 1) {
                        basicLayer.setVisible(false);
                        satelliteLayer.setVisible(true);
                    }

                    setSelectedLayer(selectedLayer === 0 ? 1 : 0)
                }}>
                <img src={selectedLayer === 0 ? map_img : satelite_img} alt="Satelliitti" className="map-satellite-img" />
            </span>
            {!enableDelete ?
                <><span id="feature_filter" className="feature_filter-top">
                    <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
                        <Button size="sm" variant="success" className="greenBackground" disabled={isDisabled}> {<Icon icon={ICONS.SETTINGS} color='white' size='20' />}</Button>
                    </OverlayTrigger>
                </span>
                    {allowChanges ? 
                    <span id="feature_add" className="feature_add_point-top">
                        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover2}>
                            <Button size="sm" variant="success" className="feature_add_point-btn greenBackground"> {<Icon icon={ICONS.NEEDLE} color='white' size='20' />}</Button>
                        </OverlayTrigger>
                    </span>
                        : ''}
                </>
                :
                <span id="feature_add" className="feature_delete_point-top" >
                    {allowChanges ? 
                        <button type="button" id="map-delete-btn" className="btn-info2 mapModalDeleteStyle mapRedButton"
                            disabled={enableDelete ? false : true}
                            onClick={() => {
                                deleteFeature();
                            }}>
                            <Icon icon={ICONS.DELETE} color='white' size='25' />
                        </button>
                        : ''}
                </span >}
            <Collapse
                in={sidePaneShow}
                className={extendSidePane ? "bottom-pane bottom-pane-extended" : "bottom-pane"}>
                <div>
                    <div>
                        {getSidePaneContent()}
                    </div>
                    <Accordion>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><b>{t('ShowChosenPlots')}</b></Accordion.Header>
                            <Accordion.Body>
                                <div onScroll={onScroll} ref={listInnerRef} className={getSelectedPlots() && getSelectedPlots().length > 3 ? "selection-area-trim" : ""}>
                                    <Form.Group>
                                        {plots.filter(p => getSelectedPlots().includes(p.apNumber)).map((plot, i) =>
                                            <Row className="show-selected-plots" key={i}>
                                                <><Col xs={10}>
                                                    <b>{plot.name} - {plot.apChar} ({plot.area} ha)</b>
                                                </Col>
                                                    <Col xs={1}>
                                                        <Button size="sm" variant="light" onClick={() => handlePlotSelectionRemoval(plot)}><b>X</b></Button>
                                                    </Col></>
                                            </Row>
                                        )}
                                    </Form.Group>
                                </div>
                                {!reachedBottom ?
                                    <span className="scrollable-bottom">
                                        <Icon icon={ICONS.ARROW_RIGHT} color='black' size='35' />
                                    </span >
                                    : ""}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Collapse>

            {/*add new action or markAs done modal*/}
            <ActionSelectionModal
                show={showActionModal}
                onHide={() => setShowActionModal(false)}
                selectedplots={
                    getSelectedPlots()
                        ? plots.filter(p => getSelectedPlots().includes(p.apNumber)) : getSelectedPlots()}
                addMode={actionModeAdd}
                setAlertContent={setAlertContent}
                showAlert={setShowAlert}
            />
            {showFeatureSelectionModal === true ?
                <FeatureSelectionModal
                    show={showFeatureSelectionModal}
                    onHide={() => handleFeatureSelectionClose()}
                    mapEvent={featureSelectionEvent}
                    saveFeature={(data) => handleObservationVisibility(data)}
                    allowHide={() => { allowModalHide = true }}
                />
                : ''}
            {showMemoModal === true ?
                <MemoModal
                    show={showMemoModal}
                    onHide={() => handleMemoModalClose()}
                    mapEvent={memoEvent}
                    saveMemo={(data) => handleMemoModalEdit(data)}
                    deleteMemo={() => handleMemoModalDelete()}
                    allowHide={() => { allowModalHide = true }}
                />
                : ''}
            {showObservationModal === true ?
                <PlantProtectionModal
                    show={showObservationModal}
                    onHide={() => handleObservationsModalClose()}
                    mapEvent={observationEvent}
                    saveObservation={(data) => handleObservationModalEdit(data)}
                    deleteObservation={() => handleObservationModalDelete()}
                    allowHide={() => { allowModalHide = true }}
                />
                : ''}
        </>
    );
}
export default Map;


