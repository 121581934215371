import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import icon from '../img/arrow.png';

let vectorSource;
const velocityLimit = 0.556; // metres per second, 2 km/h

export const addMapPosition = () => {
    vectorSource = new VectorSource();

    let vectorLayer = new VectorLayer({
        source: vectorSource,
        zIndex: 3
    });

    return vectorLayer;
}

export const updateMapPosition = (position) => {
    let locationPoint = new Point([position.longitude, position.latitude]).transform('EPSG:4326', 'EPSG:3857');
    let features = vectorSource.getFeatures();
    let feature;
    if (features.length > 0) {
        feature = features[0];
        feature.setGeometry(locationPoint);
    } else {
        feature = new Feature(locationPoint);
        vectorSource.addFeature(feature);
    }
    // Modify feature style: rotation with heading
    feature.setStyle(getStyle(position.heading, position.velocity));
}

/**
 * *
 * @param {double} heading  - degrees
 * @param {double} velocity - m/s
 */
const getStyle = (heading, velocity) => {
    if (heading !== null && velocity > velocityLimit) {
        var headingStyle = new Style({
            image: new Icon({
                src: icon,
                scale: 0.5,
                rotation: heading * Math.PI / 180 // Convert to rad
            }),
        });
        return headingStyle;
    } else {
        var noDirectionStyle = new Style({
            image: new CircleStyle({
                radius: 10,
                fill: new Fill({
                    color: 'green'
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3
                }),
            })
        });
        return noDirectionStyle;
    }
}