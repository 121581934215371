import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal'
import { Col, Row, Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { dayJsFormatDate } from '../../commonFunctions.js'
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import MapModal from './MapModal';

import '../../css/general.css'

const SampleSetModal = (props) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [mapModal, setMapModalShow] = useState(false);
    const [sampleSet, setSampleSet] = useState([]);
    const [allowChanges, setAllowChanges] = useState(false);

    useEffect(() => {
        if (props.selectEvent.selectedElement.samples.some(x => x.collected === false)) {
            const sortedPlannedSet = props.selectEvent.selectedElement.samples.sort(
                (a, b) => {
                    if (a.collected < b.collected) {
                        return 1;
                    }
                    if (a.collected > b.collected) {
                        return -1;
                    }
                    if ([a.collected, b.collected].filter(Boolean).length === 2) {
                        if (a.nbr > b.nbr) {
                            return 1;
                        }
                        if (a.nbr < b.nbr) {
                            return -1;
                        }
                    }
                    return 0;
                });

            setSampleSet(sortedPlannedSet);
        }
        else {
            const sortedSampleSet = props.selectEvent.selectedElement.samples.sort((a, b) => a.nbr - b.nbr);
            setSampleSet(sortedSampleSet);
        }
        setAllowChanges(props.allowSave);
        setIsLoading(false);
    }, [props.selectEvent.selectedElement.samples]);


    const openMapModal = () => {
        setIsLoading(true);
        setMapModalShow(true);
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {sampleSet.some(x => x.collected === false) ? t('SamplePlan') : t('SampleSetsCollected')}
                        <br />
                        <span className="highlighted-plot-name-text">
                            {dayJsFormatDate(props.selectEvent.selectedElement.sampleDate)} {props.selectEvent.selectedElement.plotCount} {t('Plots2')} - {props.selectEvent.selectedElement.sampleCount} {t('Samples')}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* HEADERS */}
                        <div>
                            <Form.Group>
                                <Row className="show-extra-samples show-extra-headers-samples" >
                                    <Col xs={1}>
                                        <b>{t('Number')}</b>
                                    </Col>
                                    <Col xs={5}>
                                        <b>{t('Plot')}</b>
                                    </Col>
                                    <Col xs={2}>
                                        <b>Ha</b>
                                    </Col>
                                    {sampleSet.some(x => x.collected === false) ?
                                        <><Col xs={1}>
                                            <b>GPS</b>
                                        </Col><Col xs={2}>
                                                {allowChanges ? 
                                                    <b>{t('Delete')}</b>
                                                    : ''}
                                            </Col><Col xs={1}>
                                                {allowChanges && sampleSet.some(x => x.collected === false) ?
                                                    <b>{t('Collect')}</b>
                                                    : null}
                                            </Col></>
                                        :
                                        <><Col xs={2}>
                                            <b>GPS</b>
                                        </Col><Col xs={2}>
                                                {allowChanges ? 
                                                    <b>{t('Delete')}</b>
                                                    : ''}
                                            </Col></>
                                    }
                                </Row>
                            </Form.Group>
                        </div>
                        {(isLoading) ? <Spinner animation="border" variant="success" />
                            :
                            sampleSet.map((item, index) => (
                                <Form.Group
                                    key={item.id}>
                                    <Row className="show-extra-samples" >
                                        <Col xs={1}>
                                            {item.nbr ? item.nbr : '-'}
                                        </Col>
                                        <Col xs={5}>
                                            {item.baseplotName}
                                        </Col>
                                        <Col xs={2}>
                                            {item.baseplotArea}
                                        </Col>
                                        {sampleSet.some(x => x.collected === false) ?
                                            <><Col xs={1}>
                                                {item.lat && item.lon ?
                                                    <Icon icon={ICONS.GPS} color='green' size='22' />
                                                    : <Icon icon={ICONS.GPS} color='gainsboro' size='22' />}
                                            </Col><Col xs={2}>
                                                    {sampleSet.length > 1 && allowChanges ?
                                                        <Button size="sm" variant="danger" onClick={() => props.refreshAndHandleOperationsSamples([undefined, undefined, item.id])}><Icon icon={ICONS.DELETE} color='white' size='18' /></Button>
                                                        : null}
                                                </Col><Col xs={1}>
                                                    {allowChanges ? 
                                                        <Form.Check
                                                            type='checkbox'
                                                            inline
                                                            value={item.collected}
                                                            name={item.id}
                                                            checked={item.collected}
                                                            onChange={() => props.refreshAndHandleOperationsSamples([item])} />
                                                        : ''}

                                                </Col></>
                                            :
                                            <><Col xs={2}>
                                                {item.lat && item.lon ?
                                                    <Icon icon={ICONS.GPS} color='green' size='22' />
                                                    : <Icon icon={ICONS.GPS} color='gainsboro' size='22' />}
                                            </Col><Col xs={2}>
                                                    {sampleSet.length > 1 && allowChanges ?
                                                        <Button size="sm" variant="danger" onClick={() => props.refreshAndHandleOperationsSamples([undefined, undefined, item.id])}><Icon icon={ICONS.DELETE} color='white' size='18' /></Button>
                                                        : null}

                                                </Col></>
                                        }
                                    </Row>
                                </Form.Group>))}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={() => { props.onHide(); }}>{t('Back')}</Button>
                    <Button variant="success" className="btn-show-more" onClick={() => {
                        openMapModal();
                    }}>{t('ShowOnMap')}</Button>
                </Modal.Footer>
            </Modal>

            {
                mapModal === true ?
                    <MapModal
                        show={mapModal}
                        onHide={(...operations) => {
                            setMapModalShow(false);
                            setIsLoading(true);
                            /* UPDATE[0] and/or ADD NEW SAMPLE POINTS[1] and/or DELETE SAMPLE POINTS[2]*/
                            if (operations[0] || operations[1] || operations[2]) {
                                props.refreshAndHandleOperationsSamples(operations);
                            }
                            /*DELETE SET*/
                            if (operations[3]) {
                                props.refreshAndHandleOperationsSet(operations[3]);
                            }
                            else
                                setIsLoading(false);
                        }}
                        collected={props.collected}
                        selectedSet={props.selectEvent.selectedElement.id}
                        selectEvent={props.selectEvent.selectedElement.samples}
                    />
                    : ''
            }
        </>
    );
}

export default SampleSetModal;