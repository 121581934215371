import React, { Component } from 'react';

class Callback extends Component {

    componentDidMount() {

        //if (this.props.location.search.includes("login_required")) {
        //    this.props.auth.logout();
        //    return;
        //}

        this.props.auth.handleAuthentication(this.props.location.hash);



        //if (/access|id_token|error/.test(this.props.location.hash)) {
        //if (/code|error/.test(this.props.location.hash)) {
            /*this.props.auth.handleAuthentication(this.props.location.hash);*/
        //}
        //else {
        //    throw new Error("Invalid callback URL.");
        //}
    }

    render() {
        return (
            <>Loading...</>
        );
    }
}

export default Callback;