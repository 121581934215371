export const homeHeaderStyle = {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '5px',
    marginTop: '3px'
}

export const forecastMeterStyle = {
    marginTop: "5px",
    verticalAlign: "middle",
    flexShrink: "0"
}

export const forecastDropStyle = {
    marginTop: "-4px",
    marginRight: "5px",
    verticalAlign: "middle",
    flexShrink: "0"
}