import React, { useState, useEffect, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
import AppContext from '../../AppContext';
import { dayJsFormatDate } from '../../commonFunctions.js'

import MemoModal from './MemoModal';
import { getContentWithAction, postContentWithAction } from '../../apiConnect';

import '../../css/farmPlot.css'

const ShowMemoListModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [memos, setMemos] = useState([]);
    const [selectMemoEvent, setSelectMemoEvent] = useState({ time: null, selectedElement: null });
    const [memoType, setMemoType] = useState(0);
    const [memoModalShow, setMemoModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const reload = () => handleMemoFetching();
    const [memoAttachments, setMemoAttachments] = useState([]);

    useEffect(() => {
            setMemos(props.memos);
    }, []); 

    const handleMemoFetching = () => {
        setIsLoading(true);
        getContentWithAction(auth.getAccessToken(), 'memo', 'memosAll', {}).then((data) => {
            if (!data) return;
            formatMemos(data);
        }).catch((c) => { setIsLoading(false) });
    }

    useEffect(() => {
        if (memos.length > 0) {
            postContentWithAction(auth.getAccessToken(), 'photo', 'memoPhotosAll', { models: memos },
                (data) => {
                    if (data.length > 0) {
                        const memoPhotos = memoAttachments;
                        data.forEach(d => {
                            memoPhotos.push({ mediaId: d.mediaId, memoId: d.memoId });
                        });
                        setMemoAttachments(memoPhotos);
                    }
                    setIsLoading(false);
                });
        }
    }, [memos]);

    const formatMemos = (memos) => {
         let sortedMemoList = memos.sort((a, b) => new Date(b.memoDate) - new Date(a.memoDate))
        setMemos(sortedMemoList);
    };   

    const handleOperationMemoModal = () => {
        if (memoModalShow)
            reload();
    };

    const memoClickHandler = (memo) => {
        setIsLoading(true);
        setMemoType(memo.plantMemoType);
        setSelectMemoEvent({ time: Date.now(), selectedElement: memo });
        setMemoModalShow(true);
    }

    const getMemoText = (plot) => {
        if (plot.memo.length > 30)
            return plot.memo.slice(0, 30) + "...";
        return plot.memo;
    };

    const memoHasAttachment = (memo) => {
        var item = memoAttachments.find(m => m.memoId === memo.memoId);
        if (item) return true;
        return false;
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('Notes')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(isLoading) ? <Spinner animation="border" variant="success" />
                        :
                        <ListGroup>
                            {(memos && !isLoading) ? memos.map(p =>
                                <div className={"memoRowStyle"} key={p.memoId}>
                                    <ListGroup.Item
                                        className={"memoItemStyle"}
                                        key={p.memoId}
                                        action onClick={() => memoClickHandler(p)}>
                                        <div className="memo-listmodal-attachment-icon">
                                            {memoHasAttachment(p) ?
                                                <Icon icon={ICONS.ATTACHMENT} color='gray' size='20' />
                                                : ''}
                                        </div>
                                        <div className='row-icon-right memo-list-arrow'><Icon icon={ICONS.ARROW_RIGHT} color='gray' size='36' /></div>
                                        {(p.plotName) ? <div><b> {p.plotName}</b></div> : <div><b>Yleinen</b></div>}
                                        <div>
                                            {getMemoText(p)}
                                        </div>
                                        <div className='row-subtext'>
                                            {dayJsFormatDate(p.memoDate)}
                                        </div>
                                    </ListGroup.Item>
                                </div>
                            ) : ""}
                        </ListGroup>}
                </Modal.Body>
            </Modal>
            {memoModalShow === true ?
                <MemoModal
                    show={memoModalShow}
                    memotype={memoType}
                    onHide={() => {
                        setMemoModalShow(false);
                        setIsLoading(false);
                    }}
                    setRefresh={() => handleOperationMemoModal()}
                    baseplotid={selectMemoEvent.basePlotId}
                    selectEvent={selectMemoEvent}
                />
                : ''}
        </>
    )
}

export default ShowMemoListModal;