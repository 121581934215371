import axios from 'axios';
import { getSelectedFarmId, getSelectedYear, getSelectedPlot, getSelectedLang } from './common';

export async function getContent(content, data) {

    try {
        const response = await axios.get(content, {
            params: { farmId: getSelectedFarmId(), farmYear: getSelectedYear(), ...data },
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        return response.data;
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

//export async function getContentWithAction(content, action, data) {

//    try {
//        const response = await axios.get(content + '/' + action, {
//            params: { farmId: getSelectedFarmId(), farmYear: getSelectedYear(), lang: getSelectedLang(), ...data },
//            headers: {
//                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
//            }
//        });

//        return response.data;
//    }
//    catch (e) {
//        return new Promise((resolve, reject) => {
//            console.log(e);
//            reject();
//        });
//    }
//}

//export async function getContentWithActionNoParams(content, action) {

//    try {
//        const response = await axios.get(content + '/' + action, {
//            headers: {
//                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
//            }
//        });

//        return response.data;
//    }
//    catch (e) {
//        console.log(e);
//    }
//}

//export async function getContentWithActionAndFarmId(content, action, data) {

//    try {
//        const response = await axios.get(content + '/' + action, {
//            params: { farmId: getSelectedFarmId(), lang: getSelectedLang() },
//            headers: {
//                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
//            }
//        });

//        return response.data;
//    }
//    catch (e) {
//        console.log(e);
//    }
//}

//export async function getContentWithActionAndPlantId(content, action, data) {

//    try {
//        const response = await axios.get(content + '/' + action, {
//            params: { plantId: getSelectedPlot().plantId, lang: getSelectedLang() },
//            headers: {
//                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
//            }
//        });

//        return response.data;
//    }
//    catch (e) {
//        console.log(e);
//    }
//}

//export async function postContentWithAction(content, action, data, callback = () => { }) {

//    try {
//        const response = await axios.post(content + '/' + action, { farmId: Number(getSelectedFarmId()), farmYear: getSelectedYear(), ...data }, {
//            headers: {
//                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
//            }
//        });

//        callback(response.data);
//    }
//    catch (e) {
//        return new Promise((resolve, reject) => {
//            console.log(e);
//            reject();
//        });
//    }
//}

//export async function putContentWithAction(content, action, data) {

//    try {
//        const response = await axios.put(content + '/' + action, { farmId: Number(getSelectedFarmId()), farmYear: getSelectedYear(), ...data }, {
//            headers: {
//                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
//            },
//        });

//        return response.data;
//    }
//    catch (e) {
//        console.log(e);
//        return new Promise((resolve, reject) => {
//            console.log(e);
//            reject();
//        });
//    }
//}