import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { _styles } from './mapStyle';
import Point from 'ol/geom/Point';

import { getVectorContext } from 'ol/render';
import { Style, Fill } from 'ol/style';

/**
 * 
 * @param {any} taskType - 1 = fertilizing, 2 = seeding, 3 = spraying, 4 = tillage. If taskData.products has more than one product, fertilizing is a part of seeding-fertilizing.
 * Seeding is always in seeding-fertilizing.
 * @param {any} taskData - taskSpecificData from MapComponent
 * @param {any} isSeedingFertilizing - boolean
 * @param {any} partfield - used to crop the grid to fit the field limits
 */
export const addMapTaskGrid = (taskType, taskData, isSeedingFertilizing = false, partfieldLayer) => {

    let features = new GeoJSON().readFeatures(taskData.grid.gridGeometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    });

    // Modify feature values. For seeding-fertilizing, the fertilizing value is the 2nd of the values array, seed value is the 1st.
    let idx = (taskType === 1 && isSeedingFertilizing ? 1 : 0);
    features.map(feat => {
        let value = feat.get('values')[idx];
        feat.set('values', [value]); // The style file uses an array
        return feat;
    });

    let vectorSource = new VectorSource({
        features: features
    });

    let vectorLayer = new VectorLayer({
        source: vectorSource,
        zIndex: 0
    });

    // TaskTypes:  1: fertilizing, 2: seeding-fertilizing, 3: spraying, 4: tillage
    let styleObject;
    let min; let max;
    
    if (taskData.products.length > 1 && taskType === 1) {
        //Seeding fertilizing: fertilizer
        min = taskData.products[1].minAmount;
        max = taskData.products[1].maxAmount;
    } else {
        min = taskData.products[0].minAmount;
        max = taskData.products[0].maxAmount;
    }

    switch (taskType) {
        case 1:
            styleObject = new _styles.isobus.grid.fertilizing(min, max);
            break;
        case 2:
            styleObject = new _styles.isobus.grid.seeding(min, max);
            break;
        case 3:
            styleObject = new _styles.isobus.grid.spraying(min, max);
            break;
        case 4:
            styleObject = new _styles.isobus.grid.tillage(min, max);
            break;
        default:
    }
    
    vectorLayer.setStyle(styleObject.default);

    var style = new Style({
        fill: new Fill({
            color: 'black'
        })
    });

    vectorLayer.on('postrender', function (e) {
        e.context.globalCompositeOperation = 'destination-in';
        var vectorContext = getVectorContext(e);
        partfieldLayer.getSource().forEachFeature(function (feature) {
            vectorContext.drawFeature(feature, style);
        });
        e.context.globalCompositeOperation = 'source-over';
    });

    return vectorLayer;
};

export const checkCoordsInsideGridPolygon = (layer, coords, callback) => {
    let vectorSource = layer.getSource();
    let features = vectorSource.getFeatures();
    if (!features.length > 0) {
        console.log('No grid features');
        return;
    }

    let coordinate = new Point([coords.longitude, coords.latitude]).transform('EPSG:4326', 'EPSG:3857').getCoordinates();
    let gridCellFeature = features.filter(feat => feat.getGeometry().intersectsCoordinate(coordinate));

    if (gridCellFeature.length > 0) {
        let gridValue = gridCellFeature[0].get('values');
        if (gridValue.length > 0) {
            callback(gridValue[0]);
        } else {
            console.log('Grid cell found but values was empty.');
        }
    } else {
        console.log('No matching grid cell found');
    }
}