import React, { useState, useEffect, useContext, useRef } from 'react';
import OlMap from 'ol/Map';
import View from 'ol/View';
import { defaults as defaultInteractions } from 'ol/interaction';
import * as proj from 'ol/proj';

import 'ol/ol.css';

import AppContext from '../../AppContext';
import TaskViewerMapFooter from './TaskViewerMapFooter';
import TaskViewerMapHeader from './TaskViewerMapHeader';
import { taskViewer_taskStatuses, taskViewer_viewComponents } from '../../constants';

import { addMapBaseLayer } from '../../Map/addMapBaseLayer';
import { addMapTaskGrid, checkCoordsInsideGridPolygon } from '../../Map/addMapTaskGrid';
import { addMapPartfieldGeometry } from '../../Map/addMapPartfieldGeometry';
import { addMapPosition, updateMapPosition } from '../../Map/addMapPosition';
import { addMapRoute } from '../../Map/addMapRoute';
import { startSimulateGPS } from '../../gps.js';

import { getContentWithAction, postContentWithAction } from '../../apiConnect';

const TaskViewerMap = (props) => {
    const { geolocation, mapInitCoordinates } = useContext(AppContext);
    const { auth } = useContext(AppContext);

    const [map, setMap] = useState(null);
    const [taskSpecificData, setTaskSpecificData] = useState([]);
    const [currentTaskAmount, setCurrentTaskAmount] = useState(-1);
    const [currentTaskUnit, setCurrentTaskUnit] = useState('');

    const timeLogId = useRef(null);
    const currentPosition = useRef({ latitude: null, longitude: null, timestamp: null });
    const timer = useRef(null);
    const gpsWatch = useRef(null);
    const running = useRef(false);
    const taskHasBeenStarted = useRef(false);

    const zoomIdx = useRef(0);
    const zoomLevels = [16.1, 17.2, 18.1];

    let dataIsSet = false;
    const fertLayerName = 'fertLayer';
    const seedLayerName = 'seedLayer';
    const taskLayerName = 'taskLayer';
    const logInterval = 2000; // Milliseconds
    const gpsAccuracyUpperLimit = 20; // Meters
    const gpsAccuracyLowerLimit = 0;
    const gpsLocationFilterSpeed = 6; // m/s
    const gpsOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    }
    // Simulator: developer testing purposes only!
    const simulator = false;
    //const simulator = true;

    useEffect(() => {
        getContentWithAction(auth.getAccessToken(), 'taskViewer', 'task', { taskId: props.data.taskDataId }).then((data) => setTaskSpecificData(data));
    }, []);

    useEffect(() => {
        if (!dataIsSet && taskSpecificData.hasOwnProperty('grid')) {
            dataIsSet = true;
            setCurrentTaskUnit(taskSpecificData.taskType === 2 ? taskSpecificData.products[1].unitDesignator : taskSpecificData.products[0].unitDesignator);
            if (taskSpecificData.taskStatus !== taskViewer_taskStatuses.planned) taskHasBeenStarted.current = true;
            renderMap();
        }
    }, [taskSpecificData]);

    useEffect(() => {
        if (map != null) {
            if (!simulator) {
                gpsWatch.current = geolocation.watchPosition(success, errorHandler, gpsOptions);
            } else {
                startSimulateGPS(simulatorSuccess);
            }
        }
    }, [map]);

    const view = new View({
        center: proj.transform([mapInitCoordinates.coords.longitude, mapInitCoordinates.coords.latitude], 'EPSG:4326', 'EPSG:3857'),
        zoom: 17
    })

    // For header buttons in case of seeding-fertilizing
    const switchGridLayer = (task) => {
        let layers = map.getLayers().array_;
        let fertLayer = layers.filter(layer => layer.get('name') === fertLayerName)[0];
        let seedLayer = layers.filter(layer => layer.get('name') === seedLayerName)[0];
        if (task === 1) {
            fertLayer.setVisible(true);
            seedLayer.setVisible(false);
            setCurrentTaskUnit(taskSpecificData.products[1].unitDesignator);
        } else {
            fertLayer.setVisible(false);
            seedLayer.setVisible(true);
            setCurrentTaskUnit(taskSpecificData.products[0].unitDesignator);
        }
    }

    const renderMap = () => {
        if (dataIsSet) {
            let myMap = new OlMap({
                target: 'map',
                layers: [
                    addMapBaseLayer(),
                    addMapRoute(taskSpecificData.route.routeGeometry),
                    addMapPosition()
                ],
                view: view,
                interactions: defaultInteractions({
                    doubleClickZoom: false,
                    mouseWheelZoom: simulator ? true : false
                })
            });

            // Custom zoom
            myMap.on('click', function (event) {
                // Move zoom array idx
                shiftZoomArrayIdx();
                // Set view zoom
                myMap.getView().setZoom(zoomLevels[zoomIdx.current]);
            });

            let partfieldLayer = addMapPartfieldGeometry(taskSpecificData.partfield.partfieldGeometry);
            myMap.addLayer(partfieldLayer);

            let isSeedingFertilizing = false;
            if (taskSpecificData.taskType === 2) {
                // Seeding-fertilizing
                isSeedingFertilizing = true;
                let fertLayer = addMapTaskGrid(1, taskSpecificData, isSeedingFertilizing, partfieldLayer);
                fertLayer.set('name', fertLayerName);
                let seedLayer = addMapTaskGrid(2, taskSpecificData, isSeedingFertilizing, partfieldLayer);
                seedLayer.set('name', seedLayerName);
                seedLayer.setVisible(false);
                myMap.addLayer(fertLayer);
                myMap.addLayer(seedLayer);
            } else {
                let layer = addMapTaskGrid(taskSpecificData.taskType, taskSpecificData, isSeedingFertilizing, partfieldLayer);
                layer.set('name', taskLayerName);
                myMap.addLayer(layer);
            }

            setMap(myMap);
        }
    }

    const setStatusFromMapFooter = (status) => {
        switch (status) {
            case (taskViewer_taskStatuses.running):
                running.current = true;
                taskHasBeenStarted.current = true;
                startLogTimer();
                postContentWithAction(auth.getAccessToken(), 'taskViewer', 'task/status', createTaskStatusLogObject(taskViewer_taskStatuses.running));
                break;
            case (taskViewer_taskStatuses.paused):
                running.current = false;
                stopLogTimer();
                if (taskHasBeenStarted.current) postContentWithAction(auth.getAccessToken(), 'taskViewer', 'task/status', createTaskStatusLogObject(taskViewer_taskStatuses.paused));
                break;
            case (taskViewer_taskStatuses.endUnfinished):
                running.current = false;
                stopLogTimer();
                if (taskHasBeenStarted.current) postContentWithAction(auth.getAccessToken(), 'taskViewer', 'task/status', createTaskStatusLogObject(taskViewer_taskStatuses.endUnfinished));
                props.callBack({ viewComponent: taskViewer_viewComponents.endTaskViewer, data: [] });
                break;
            case (taskViewer_taskStatuses.ended):
                running.current = false;
                stopLogTimer();
                if (taskHasBeenStarted.current) postContentWithAction(auth.getAccessToken(), 'taskViewer', 'task/status', createTaskStatusLogObject(taskViewer_taskStatuses.completed));
                props.callBack({ viewComponent: taskViewer_viewComponents.endTaskViewer, data: [] });
                break;
        }
    }

    const createTaskStatusLogObject = (taskStatus) => {
        let statusLogObject = {
            taskDataId: taskSpecificData.taskDataId,
            taskStatus: taskStatus,
            timeLogId: timeLogId.current
        };
        return statusLogObject;
    }

    const createTaskDataLogObject = (coordinates) => {
        let logObject = {
            taskId: taskSpecificData.taskDataId,
            timeLogId: timeLogId.current,
            positionNorth: coordinates.latitude,
            positionEast: coordinates.longitude,
            positionUp: 0.0,
            timeStap: null,
            dataLogValues: []
        };
        return logObject;
    }

    const getCurrentLayer = () => {
        let layers = map.getLayers().array_;
        let currentLayer;
        if (taskSpecificData.taskType === 2) {
            let fertLayer = layers.filter(layer => layer.get('name') === fertLayerName)[0];
            let seedLayer = layers.filter(layer => layer.get('name') === seedLayerName)[0];
            if (fertLayer.getVisible()) {
                currentLayer = fertLayer;
            } else {
                currentLayer = seedLayer;
            }
        } else {
            let taskLayer = layers.filter(layer => layer.get('name') === taskLayerName)[0];
            currentLayer = taskLayer;
        }
        return currentLayer;
    }

    const success = (position) => {
        console.log('got new GPS location!');
        let pos = validateNewPosition(position.coords);
        if (!pos.valid) {
            console.log('Insufficient GPS accurary');
            return;
        }
        currentPosition.current = pos;
        // Update user location on map
        updateMapPosition(currentPosition.current);
        // Move map center to user position
        map.getView().setCenter(proj.transform([currentPosition.current.longitude, currentPosition.current.latitude], 'EPSG:4326', 'EPSG:3857'));
        // Check which grid cell you are in and update task value
        if (running.current === true) checkCoordsInsideGridPolygon(getCurrentLayer(), currentPosition.current, setCurrentTaskAmount);
    }

    const errorHandler = () => {
        console.log('gps error');
    }

    const simulatorSuccess = (val, position) => {
        // Update user location on map
        updateMapPosition(position);
        // Move map center to user position
        map.getView().setCenter(proj.transform([position.longitude, position.latitude], 'EPSG:4326', 'EPSG:3857'));
        // Check which grid cell you are in and update task value
        if (running.current === true) {
            checkCoordsInsideGridPolygon(getCurrentLayer(), position, setCurrentTaskAmount);
            // Write to log file
            let logObject = createTaskDataLogObject(position);
            postContentWithAction(auth.getAccessToken(), 'taskViewer', 'task/route', logObject, postContentWithActionCallback);
        }
    }

    const startLogTimer = () => {
        if (!simulator) {
            timer.current = setInterval(function () {
                // Write to log file
                let logObject = createTaskDataLogObject(currentPosition.current);
                postContentWithAction(auth.getAccessToken(), 'taskViewer', 'task/route', logObject, postContentWithActionCallback);
            }, logInterval);
        }
    } 

    const stopLogTimer = () => {
        if (!simulator) {
            clearInterval(timer.current);
        }
    }

    const postContentWithActionCallback = (data) => {
        timeLogId.current = data.timeLogId;
    }

    // Return a potential object for updating current position
    const validateNewPosition = (newPosition) => {
        let potentialPosition = { valid: false };

        if (currentPosition.current.latitude === null) {
            if (newPosition.accuracy > gpsAccuracyUpperLimit || newPosition.accuracy < gpsAccuracyLowerLimit) {
                console.log('Bad accuracy for a potential initial position');
            }

            let lat = newPosition.latitude;
            let lon = newPosition.longitude;
            let time = new Date();
            potentialPosition = { valid: true, latitude: lat, longitude: lon, timestamp: time, heading: newPosition.heading, velocity: 0 };
        }

        let newTime = new Date();
        let timeDiff = (newTime - currentPosition.current.timestamp) / 1000; // seconds
        let dist = distance(newPosition.latitude, newPosition.longitude, currentPosition.current.latitude, currentPosition.current.longitude); // metres
        let velocity = dist / timeDiff;
        if (velocity <= gpsLocationFilterSpeed) {
            potentialPosition = { valid: true, latitude: newPosition.latitude, longitude: newPosition.longitude, timestamp: newTime, heading: newPosition.heading, velocity: velocity };
        }

        return potentialPosition;
    }

    const distance = (lat1, lon1, lat2, lon2) => {
        const R = 6378137;
        let dlon = radians(lon2 - lon1);
        let dlat = radians(lat2 - lat1);
        let f = squared(Math.sin(dlat / 2)) + Math.cos(radians(lat1)) * Math.cos(radians(lat2)) * squared(Math.sin(dlon / 2.0));
        let c = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(1 - f));
        return R * c;
    }

    const radians = (x) => {
        return x * Math.PI / 180;
    }

    const squared = (x) => {
        return x * x;
    }

    const shiftZoomArrayIdx = () => {
        // End of array
        if (zoomIdx.current === 3) {
            zoomIdx.current = 0;
        } else {
            zoomIdx.current = zoomIdx.current + 1;
        }
    }

    return (
        <>
            <div className="fixed-top">{taskSpecificData.taskType ? <TaskViewerMapHeader switchGridLayer={switchGridLayer} taskSpecificData={taskSpecificData} /> : ''}</div>
            <div className="vh-100" id='map'></div>
            <div className="fixed-bottom"><TaskViewerMapFooter sendStatusToMap={setStatusFromMapFooter} currentTaskAmount={currentTaskAmount} currentTaskUnit={currentTaskUnit} taskSpecificData={taskSpecificData} /></div>
        </>
        );
}

export default TaskViewerMap;