import { get as getProjection } from 'ol/proj';
import { getTopLeft, getWidth } from 'ol/extent';
import { Tile as TileLayer } from 'ol/layer';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';

import { MapModels } from './mapModels';
import { MapConfig } from './mapConfig';

/** WMS-layers
     * # Add WMS -layers
     * @param layers
     * @param config
     */
export const addMapBaseLayer = (layer = "ilmakuva") => {
    let selectedWMTS;
    const tileSizePixels = 256;
    let projection = getProjection('EPSG:3857');
    let extent = projection.getExtent();
    let tileSizeMtrs = getWidth(extent) / tileSizePixels;
    let matrixIds = [];
    let resolutions = [];
    for (let i = 0; i <= 18; i++) {
        matrixIds[i] = i;
        resolutions[i] = tileSizeMtrs / Math.pow(2, i);
    }

    switch (layer) {
        case "peruskartta":
            selectedWMTS = new WMTS({
                name: MapModels.WMTSLayer.Peruskartta.name,
                url: MapModels.WMTSLayer.Peruskartta.url,
                layer: MapModels.WMTSLayer.Peruskartta.layer_name,
                matrixSet: MapConfig.MATRIXSET,
                format: "image/png",
                style: "default",
                opacity: 1,
                tileGrid: new WMTSTileGrid({
                    origin: getTopLeft(extent),
                    resolutions: resolutions,
                    matrixIds: matrixIds
                }),
                requestEncoding: 'REST'
            });
            break;
        case "ilmakuva":
            selectedWMTS = new WMTS({
                name: MapModels.WMTSLayer.Ortokuva.name,
                url: MapModels.WMTSLayer.Ortokuva.url,
                layer: MapModels.WMTSLayer.Ortokuva.layer_name,
                matrixSet: MapConfig.MATRIXSET,
                format: "image/png",
                style: "default",
                //opacity: 1,
                tileGrid: new WMTSTileGrid({
                    origin: getTopLeft(extent),
                    resolutions: resolutions,
                    matrixIds: matrixIds
                }),
                requestEncoding: 'REST'

            });
            break;
        case "taustakartta":
            selectedWMTS = new WMTS({
                name: MapModels.WMTSLayer.Taustakartta.name,
                url: MapModels.WMTSLayer.Taustakartta.url,
                layer: MapModels.WMTSLayer.Taustakartta.layer_name,
                matrixSet: MapConfig.MATRIXSET,
                format: "image/png",
                style: "default",
                opacity: 1,
                tileGrid: new WMTSTileGrid({
                    origin: getTopLeft(extent),
                    resolutions: resolutions,
                    matrixIds: matrixIds
                }),
                requestEncoding: 'REST'
            });
            break;
        default:
            selectedWMTS = new WMTS({
                name: MapModels.WMTSLayer.Peruskartta.name,
                url: MapModels.WMTSLayer.Peruskartta.url,
                layer: MapModels.WMTSLayer.Peruskartta.layer_name,
                matrixSet: MapConfig.MATRIXSET,
                format: "image/png",
                style: "default",
                opacity: 1,
                tileGrid: new WMTSTileGrid({
                    origin: getTopLeft(extent),
                    resolutions: resolutions,
                    matrixIds: matrixIds
                }),
                requestEncoding: 'REST'
            });
            break;
    }

    return new TileLayer({
        source: selectedWMTS,
        zIndex: 1,
        visible: true
    });
};
