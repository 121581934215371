import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
import { dayJsFormatDate, getGrowthStageText } from '../../commonFunctions';
import { chartMode } from '../../constants';
import ChartModal from './ChartModal';

import '../../css/general.css'
import '../../css/farmPlot.css'

const ShowGrowthStageListModal = (props) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [growthData, setGrowthData] = useState([]);
    const [chartModalShow, setChartModalShow] = useState(false);
    const [chartModalData, setChartModalData] = useState([]);
    const [hideContent, setHideContent] = useState(false);

    useEffect(() => {
        var sortedData = props.data.sort((a, b) => {
            return b.growthStage - a.growthStage;
        });
        setGrowthData(sortedData);
        setIsLoading(false);
    }, [props.data]);

    const getGrowthStage = (row) => {
        if (row.growthStage < 0) return ''
        return row.growthStage;
    }

    const openGrowthStageGraph = (data) => {
        setChartModalData(data);
        setChartModalShow(true);
        setHideContent(true);
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('GrowthStage_Modal_Title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(isLoading) ? <Spinner animation="border" variant="success" />
                        :
                        <ListGroup>
                            {(growthData && !hideContent) ? growthData.map((d, i) =>
                                <div key={i}>
                                    <ListGroup.Item
                                        className={d.harvest ? "plotItemStyle" : "plotItemStyle plotItemStyle-not-active"}
                                        action={d.harvest ? true : false}
                                        onClick={() => { if (d.harvest) openGrowthStageGraph(d); }}
                                    >
                                        {d.harvest ? <div className='row-icon-right growth-stage-list-arrow'><Icon icon={ICONS.ARROW_RIGHT} color='gray' size='36' /></div> : ''}
                                        <div className="operation-list-name">{d.plotName} - {d.plotLetter}</div>
                                        <div>{d.plantName} - {d.plantVariety}</div>
                                        <div className="growth-stage-text">{t('PlantProtection_GrowthStage')}: {getGrowthStage(d)} - {getGrowthStageText(d.growthStage, t)}</div>
                                        <div className="harvest-prognosis">{t('Harvest_Prognosis')}: {d.harvest ? dayJsFormatDate(d.harvest) : ''}</div>
                                    </ListGroup.Item>
                                </div>
                            ) : ""}
                        </ListGroup>}
                </Modal.Body>
                {!hideContent ? 
                    <Modal.Footer className="modal-footer-right-align">
                        <Button variant="outline-secondary" onClick={props.onHide}>{t('Close')}</Button>
                    </Modal.Footer>
                    : ''}
            </Modal>
            {chartModalShow === true ?
                <ChartModal
                    show={chartModalShow}
                    data={chartModalData}
                    chartMode={chartMode.growthStage}
                    onHide={() => {
                        setChartModalShow(false);
                        setHideContent(false);
                    }}
                />
                : ''}
        </>
    )
}

export default ShowGrowthStageListModal;