import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AppContext from '../../AppContext';

import { taskViewer_stopComponents } from './../../constants';
import { getContentWithAction } from '../../apiConnect';

const Summary = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [show, setShow] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        getContentWithAction(auth.getAccessToken(), 'taskViewer', 'task/details', { taskId: props.taskId }).then(dataCallbackHandler);
    }, [props.taskId]);

    const dataCallbackHandler = (data) => {
        setData(data);
        setShow(true);
    }

    return (
        <Modal show={show} centered onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title className="text-dark">{t('SummaryEndTask')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-dark">
                <Row>
                    <Col>
                        {t('TimePassed')}:
                    </Col>
                    <Col>
                        {data.totalTime ? data.totalTime : '00:00'} {t('UnitHour')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {t('ActiveWorkingTime')}:
                    </Col>
                    <Col>
                        {data.effectiveTime ? data.effectiveTime : '00:00'} {t('UnitHour')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {t('DistanceDriven')}:
                    </Col>
                    <Col>
                        {data.totalDistance ? data.totalDistance : '0'} {t('UnitKm')}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-success" onClick={() => props.callback(taskViewer_stopComponents.finish)}>{t('OK')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Summary;