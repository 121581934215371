import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import '../../css/general.css'
import { useTranslation } from 'react-i18next';

const ConfirmModal = (props) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState(null);

    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        { title }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={() => { props.decline(); }}>{t('No')}</Button>
                    <Button variant="success" className="btn-show-more" onClick={() => { props.accept(); }}>{t('Yes')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfirmModal;