import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import AppContext from '../../AppContext';
import { useTranslation } from 'react-i18next';
import { textStyle, rowStyle, addNoteRowStyle, addButtonStyle } from '../ComponentStyles/ButtonStyles.js'
import { getContentWithAction } from '../../apiConnect';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import { getSelectedPlot } from '../../common';
import * as harvestConstants from '../../Constants/HarvestConstants.js';
import * as Cards from '../../Constants/AccordionCardConstants.js';
import { toggleActive, formatPlannedDate, dayJsFormatDate } from '../../commonFunctions.js'
import { OperationCardToggle } from '../../commonElements.js'
import '../../css/plotOperation.css'

const HarvestAccordionCard = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [harvests, setHarvests] = useState([]);
    const [memos, setMemos] = useState([]);
    const [harvestIsLoading, setHarvestIsLoading] = useState(true);
    const [memosIsLoading, setMemosIsLoading] = useState(true);
    const [sectionIsLoading, setSectionIsLoading] = useState(true);
    const [harvestModalDisabled, setHarvestModalDisabled] = useState(false);

    useEffect(() => {
        var plot = getSelectedPlot();
        if (plot) {
            setHarvestIsLoading(true);
            setHarvests([]);

            if (plot.plantId === 0)
                setHarvestModalDisabled(true);

            getContentWithAction(auth.getAccessToken(), 'harvest', 'harvests', { plotId: plot.apNumber }).then((harvests) => {
                harvests = harvests && harvests.length ? harvests.filter(h => h.plotId === plot.apNumber) : []
                setHarvests(harvests);
                setHarvestIsLoading(false);
            });
        }
    }, [props.refresh]);

    useEffect(() => {
        setMemosIsLoading(true);
        var memos = props.memos.filter(m => m.plantMemoType === 5);
        memos.sort((a, b) => b.memo > a.memo ? -1 : 1);
        setMemos(memos);
        setMemosIsLoading(false);
    }, [props.memos])

    useEffect(() => {
        !harvestIsLoading && !memosIsLoading ? setSectionIsLoading(false) : setSectionIsLoading(true);
    }, [harvestIsLoading, memosIsLoading])

    const getDisplayAmount = (harvest) => {
        let displayAmount = "";
        harvest.harvestedUnit === harvestConstants.SMALL_BALE_UNIT ||
            harvest.harvestedUnit === harvestConstants.ROUND_BALE_UNIT ||
            harvest.harvestedUnit === harvestConstants.CUBIC_BALE_UNIT ||
            harvest.harvestedUnit === harvestConstants.WAGON_UNIT
            ? displayAmount = parseFloat((harvest.loadAmount * harvest.baleWeight * (harvest.dryMateriaContent / 100)) / harvest.area).toFixed(2) + " kg ka"
            : displayAmount = harvest.amount + " kg";
        return displayAmount;
    }

    const getRowLetter = (row) => {
        return row.productName.substring(0, 1);
    }

    return (
        <Card className="border-bottom-1">
            <Card.Header className={props.activeCard === Cards.harvestCardId ? 'active-panel' : 'inactive-panel'}>
                <OperationCardToggle classname={props.activeCard === Cards.harvestCardId ? 'active-panel' : 'inactive-panel'} callback={() => toggleActive(props.activeCard, Cards.harvestCardId, props.setActiveCard)} eventKey="4">
                    {t('Yield')}
                    {sectionIsLoading ? <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" /> : <Badge pill bg='light' text='dark' className="badge-pill">{harvests.length}</Badge>}
                </OperationCardToggle>
                <Button style={addButtonStyle} variant='mmfi-add-btn' className={harvestModalDisabled ? "float-right disabled-color" : "float-right"}
                    disabled={harvestModalDisabled ? true : false}
                    onClick={() => {
                        props.setSelectEvent({ time: Date.now(), selectedElement: null });
                        props.setModalShow(true);
                    }}><Icon icon={ICONS.ADD} color='white' size='16' /></Button>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
                <Card.Body>
                    <ListGroup>
                        {harvests.map(p =>
                            <div key={p.harvestId} className="row-margin-bot">
                                <div className='row-icon-left row-icon-operation'>{getRowLetter(p)}</div>

                                <ListGroup.Item key={p.harvestId} style={rowStyle} className="row-margin-bot plot-operation-row-done" action onClick={() => {
                                    props.setSelectEvent({ time: Date.now(), selectedElement: p });
                                    props.setModalShow(true);
                                }}>

                                    <div><span className="operation-list-name"> {p.productName} </span> - {p.amount}  {p.unitText}/ha</div>
                                    <div>{(p.beginDate) ?
                                        <span className='row-subtext'>{t('Completed')} {dayJsFormatDate(p.beginDate)}</span>
                                        : <span className='row-subtext'>{formatPlannedDate(p.plannedDate, t)}</span>}
                                    </div>
                                </ListGroup.Item>
                                {/*<div className="set-done-box">*/}
                                {/*    {!p.date ? <Button variant='success' className="float-right set-done" disabled onClick={() => console.log(true)}>{t('MarkDone')}</Button> : ''}*/}
                                {/*</div>*/}
                            </div>
                        )}
                        {!props.memosIsLoading ? memos.map(p =>
                            <div key={p.memoId} className="row-margin-bot">
                                <div className='row-icon-left row-icon-memo'><Icon icon={ICONS.EDIT} color='white' size='20' /></div>
                                <ListGroup.Item className="plot-memo-row" key={p.memoId} style={rowStyle} action onClick={() => {
                                    props.setMemoType(5);
                                    props.setSelectMemoEvent({ time: Date.now(), selectedElement: p });
                                    props.setMemoModalShow(true);
                                }}>
                                    <div><b> {t('Note')}</b></div>
                                    <div className='row-subtext'>{p.memo}</div>
                                </ListGroup.Item>
                                <span style={textStyle}></span>
                            </div>
                        ) : <div>
                            {t('Loding')}
                            <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" />
                        </div>}
                        <ListGroup.Item style={addNoteRowStyle} action
                            onClick={() => {
                                props.setMemoType(5);
                                props.setSelectMemoEvent({ time: Date.now(), selectedElement: null });
                                props.setMemoModalShow(true);
                            }}>
                            <div className='add-note-icon'><Icon icon={ICONS.ADD} color='black' size='16' /></div>
                            <div>{t('AddNote')}</div>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default HarvestAccordionCard;