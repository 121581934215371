import axios from 'axios';
import { getSelectedFarmId, getSelectedYear, getSelectedPlot, getSelectedLang } from './common';


export async function getContent(userToken, content, data) {

    try {
        const response = await axios.get(content, {
            params: { farmId: getSelectedFarmId(), farmYear: getSelectedYear(), ...data },
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });
        return response.data;
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

export async function getContentWithAction(userToken, content, action, data) {

    try {
        const response = await axios.get(content + '/' + action, {
            params: { farmId: getSelectedFarmId(), farmYear: getSelectedYear(), lang: getSelectedLang(), ...data },
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });

        return response.data;
    }
    catch (e) {
        return new Promise((resolve, reject) => {
            console.log(e);
            reject();
        });
    }
}

export async function getContentWithActionNoParams(userToken, content, action) {

    try {
        const response = await axios.get(content + '/' + action, {
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });

        return response.data;
    }
    catch (e) {
        console.log(e);
    }
}

export async function getContentWithActionAndLanguage(userToken, content, action, data) {

    try {
        const response = await axios.get(content + '/' + action, {
            params: { lang: getSelectedLang() },
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });

        return response.data;
    }
    catch (e) {
        console.log(e);
    }
}

export async function getContentWithActionAndFarmId(userToken, content, action, data) {

    try {
        const response = await axios.get(content + '/' + action, {
            params: { farmId: getSelectedFarmId() },
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });

        return response.data;
    }
    catch (e) {
        console.log(e);
    }
}

export async function getContentWithActionAndPlantId(userToken, content, action, data) {

    try {
        const response = await axios.get(content + '/' + action, {
            params: { plantId: getSelectedPlot().plantId, lang: getSelectedLang() },
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });

        return response.data;
    }
    catch (e) {
        console.log(e);
    }
}

export async function postContentWithAction(userToken, content, action, data, callback = () => { }) {

    try {
        const response = await axios.post(content + '/' + action, { farmId: Number(getSelectedFarmId()), farmYear: getSelectedYear(), ...data }, {
            headers: {               
                'Authorization': `Bearer ${userToken}`
            }
        });

        callback(response.data);
    }
    catch (e) {
        return new Promise((resolve, reject) => {
            console.log(e);
            reject();
        });
    }
}

/// CORRECT way to handle async with returning data
export async function postContentWithAction_(userToken, content, action, data = () => { }) {

    try {
        const response = await axios.post(content + '/' + action, { farmId: Number(getSelectedFarmId()), farmYear: getSelectedYear(), ...data }, {
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });

        return response.data;
    }
    catch (e) {
        return new Promise((resolve, reject) => {
            console.log(e);
            reject();
        });
    }
}


export async function putContentWithAction(userToken, content, action, data) {

    try {
        const response = await axios.put(content + '/' + action, { farmId: Number(getSelectedFarmId()), farmYear: getSelectedYear(), ...data }, {
            headers: {
                'Authorization': `Bearer ${userToken}`
            },
        });

        return response.data;
    }
    catch (e) {
        return new Promise((resolve, reject) => {
            console.log(e);
            reject();
        });
    }
}

export async function putContentWithActionFertility(userToken, content, action, data, callback = () => { }) {

    try {
        const response = await axios.put(content + '/' + action, { farmId: Number(getSelectedFarmId()), farmYear: getSelectedYear(), ...data }, {
            headers: {
                'Authorization': `Bearer ${userToken}`
            },
        });

        callback(response.data);
    }
    catch (e) {
        return new Promise((resolve, reject) => {
            console.log(e);
            reject();
        });
    }
}