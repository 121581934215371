export const deleteStyle = {
    borderRadius: '0',
    width: '42px',
    height: '48px',
    float: 'right',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '12px',
    marginTop: '8px'
}

export const deleteRowStyle = {
    width: '36px',
    height: '36px',
    padding: '0',
    marginLeft: '6px',
    marginTop: '1px',
    marginBottom: '2px'
}

export const secondaryTextStyle = {
    color: 'gray'
}

export const textStyle = {
    height: '48px',
    display: 'flex',
    color: 'black',
    justifyContent: 'center',
    background: 'white',
    paddingTop: '12px',
    marginTop: '8px'
}

export const rowStyle = {
    borderRadisu: '5px',
    float: 'left',
    borderRight: 0,
    border: '0px',
    backgroundColor: 'white'
}

export const nutrientRowStyle = {
    marginTop: '10px',
    backgroundColor: '#27b25f'
}

export const nutrientNumberCellStyle = {
    textAlign: 'right'
}

export const addNoteRowStyle = {
    border: '1px solid lightgray'
}

export const setDoneStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '19px',
    right: '8%',
    position: 'absolute',
    zIndex: '1',
    borderRadius: '1.25rem'
}

export const addButtonStyle = {
    borderRadius: '15px',
    width: '27px',
    height: '27px',
    float: 'right',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px'
}

export const changePlantStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '27px',
    right: '2%',
    position: 'absolute',
    zIndex: '1',
    borderRadius: '1.25rem'
}

export const deleteGrowthStage = {
    width: '36px',
    height: '36px',
    padding: '0',
    marginLeft: '-12px',
    marginTop: '1px',
    marginBottom: '2px'
}

