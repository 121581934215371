import React, { useState } from 'react';
import GPS from './TaskViewer/GPS';
import MapComponent from './Map'
import { taskViewer_viewComponents } from '../constants';

const MapViewer = (props) => {
    const [currentComponent, setCurrentComponent] = useState({ viewComponent: taskViewer_viewComponents.taskTypeSelection, data: [] });

    const getComponent = () => {
        switch (currentComponent.viewComponent) {
            case taskViewer_viewComponents.GPS:
                return <GPS callBack={setCurrentComponent} {...props} />;
            case taskViewer_viewComponents.taskTypeSelection:
                return <MapComponent callBack={setCurrentComponent} data={currentComponent.data} {...props} />;
            default:
                return <GPS callBack={setCurrentComponent} {...props} />; 
        }
    }

    return (
        <>
            {getComponent()}
        </>
    );
}

export default MapViewer;