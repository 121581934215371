import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import { Container, Navbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../img/icons'
import Icon from '../img/Icon'

import logo from '../img/mobiwisu_logo.png'; // Tell webpack this JS file uses this image
import SettingsModal from './Modals/SettingsModal';
import {
    getSelectedFarmId, getSelectedPage
} from '../common';

import '../css/nav.css'

const NavMenu = (props) => {

    const { t } = useTranslation();
    const [settingsModalShow, setSettingsModalShow] = useState(false);

    const refreshPlots = () => {
        //props.history.push('/plots');

        if (props.getPlots)
            props.getPlots();
    };

    return (
        <div className="header-div">
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3 header-nav" light>
                <Container className="nav-container-style">

                    {/*position top-left*/}
                    <Link to="/">
                        <img src={logo} alt="Mobiwisu" className="header-logo"/>
                    </Link>

                    <span className="col-2 nav-mobiwisu">&nbsp;</span>
                    <span className="col-3 nav-col-left">
                        <Nav.Link onClick={() => props.history.push('/')}>
                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                <div className={getSelectedPage() === 0 ? "nav-text-green bottom-tab-label" : "text-dark bottom-tab-label"}>{t('Home')}</div>
                            </div>
                        </Nav.Link>
                    </span>
                    <span className="col-3 nav-col-center">
                        <Nav.Link onClick={() => props.history.push('plots')} disabled={(getSelectedFarmId() === 0) ? "disabled" : ""}>
                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                <div className={getSelectedPage() === 1 ? "nav-text-green bottom-tab-label": "text-dark bottom-tab-label"}>{t('Plots')}</div>
                            </div>
                        </Nav.Link>
                    </span>
                    <span className="col-3 nav-col-right">
                        <Nav.Link onClick={() => props.history.push('mapViewer')} disabled={(getSelectedFarmId() === 0) ? "disabled" : ""}>
                            <div className="row d-flex flex-column justify-content-center align-items-center">
                            <div className="text-dark bottom-tab-label">{t('Map')}</div>
                            </div>
                        </Nav.Link>
                    </span>

                    <span className="col-1 hide-mobile">&nbsp;</span>

                    {/*position top-right*/}
                    <Nav.Link onClick={() => {
                            //props.history.push('settings');
                            setSettingsModalShow(true);
                        }}
                        className="settings-style">
                        
                        <div className="row d-flex flex-column justify-content-center align-items-center">                            
                            <div className="text-dark bottom-tab-label">
                                <Icon icon={ICONS.SETTINGS} color='black' size='22' />
                            </div>
                        </div>
                    </Nav.Link>

                    {/*<Nav.Link onClick={() => auth.logout()}>*/}
                    {/*    <div className="row d-flex flex-column justify-content-center align-items-center">*/}
                    {/*        */}{/*<Icon icon={ICONS.LOGOUT} color='gray' size='22' />*/}
                    {/*        <div className="text-dark bottom-tab-label">{t('LogOut')}</div>*/}
                    {/*    </div>*/}
                    {/*</Nav.Link>*/}
                   

                </Container>
            </Navbar>

            {settingsModalShow === true ?
                <SettingsModal
                    show={settingsModalShow}
                    onHide={() => setSettingsModalShow(false)}
                    setRefresh={refreshPlots} />
                : ''
            }

        </div>
    );
}

export default NavMenu;