import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import '../../css/general.css'
import AppContext from '../../AppContext';
import { postContentWithAction } from '../../apiConnect';

const SiloModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);
    const [silo, setSilo] = useState(props.defaultName ? props.defaultName: '');

    return (
        <Modal
            size='sm'
            show={props.showSilo} onHide={props.onHideSilo} centered className="mobi-calendar-content harvest-silo-modal">
            <Modal.Header closeButton>
                <Modal.Title className="text-dark">{t('AddNewStorage')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className="silo-name-div">

                        <Form.Group controlId="formAdditionalInformation">
                        <Form.Control type="text"
                            value={silo}
                            onChange={(e) => {
                                setSilo(e.currentTarget.value)
                            }}
                            placeholder='' />
                        </Form.Group>
                    </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer-right-align">
                <Button variant="outline-secondary" onClick={props.onHideSilo}>{t('Close')}</Button>
                {props.buttonWriteShow === true ?
                    <Button variant="success" className="btn-show-more" onClick={() => {

                        postContentWithAction(auth.getAccessToken(), 'harvest', 'newStorage',
                            {
                                name: silo,
                                product: Number(props.selectedProduct).toString()
                            },
                            (newId) => {
                                props.saveSilo(newId);
                                props.onHideSilo();
                            });

                    }}>{t('Save')}</Button>
                : ''}
            </Modal.Footer>
        </Modal>
    );
}

export default SiloModal;