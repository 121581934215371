import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import NavMenu from '../NavMenu';

import { taskViewer_viewComponents, taskViewer_taskStatuses } from './../../constants';

const Tasks = (props) => {
    const { t } = useTranslation();

    const getTaskTypeName = (taskType) => {
        if (taskType === 1) {
            return t('Fertilizing');
        }
        if (taskType === 2) {
            return t('SowingFertilization');
        }
        if (taskType === 3) {
            return t('PlantProtection');
        }
        if (taskType === 4) {
            return t('SoilTillage');
        }
    }

    const getVariant = (task) => {
        if (task.taskStatus === taskViewer_taskStatuses.planned) {
            return "success";
        } else if (task.taskStatus === taskViewer_taskStatuses.paused || task.taskStatus === taskViewer_taskStatuses.running || task.taskStatus === taskViewer_taskStatuses.endUnfinished) {
            return "warning";
        } else {
            return undefined;
        }
    }

    return (
        <Container>
            <NavMenu {...props} />
            <Card>
                <Card.Header className="text-dark">
                    {t('SelectTaskType')} {getTaskTypeName(props.data.taskType)}
                </Card.Header>
                <Card.Body>
                    <Card.Title className="text-dark"> {t('SelectAgrPlot')}</Card.Title>
                    <ListGroup>
                        {props.data.dataArray.map(task =>
                            <ListGroup.Item
                                key={task.taskDataId}
                                onClick={() => props.callBack({ viewComponent: taskViewer_viewComponents.taskRunner, data: task })}
                                disabled={(task.taskStatus === taskViewer_taskStatuses.completed) ? "disabled" : ""}
                                variant={getVariant(task)}
                            >
                                <div>{task.plotDesignator}</div>
                                <div>{task.taskName}</div>
                                <div>{task.products[0]}</div>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Tasks;