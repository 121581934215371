import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import NumberFormat from 'react-number-format';
import AppContext from '../../AppContext';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs'
import { getSelectedPlot, formatGroupLabel, customStyles } from '../../common';
import CalendarModal from './CalendarModal';
import { useAlert } from 'react-alert'
import { getContentWithAction, postContentWithAction, putContentWithAction } from '../../apiConnect';
import { dayJsFormatDate, round, valueMax } from '../../commonFunctions';

import '../../css/general.css'
import '../../css/plotOperation.css'

const PlotModificationModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [validate, setValidation] = useState(false);
    const [calendarShow, setCalendarShow] = useState(false);
    const [plotModifications, setPlotModifications] = useState([]);
    const [selectedPlotModification, setSelectedPlotModification] = useState(null);
    const [depth, setDepth] = useState(0);
    const [modificationCount, setModificationCount] = useState(0);
    const [tillingId, setTillingId] = useState(0);
    const [date, setDate] = useState(dayjs().format());
    const [multiSelect, setMultiSelect] = useState(false);
    const [unitText, setUnitText] = useState('');
    const [unit, setUnit] = useState(0);
    const [showDepth, setShowDepth] = useState(true);
    const [buttonWriteShow, setButtonWriteShow] = useState(false); // allowed save btn disabled
    const [cultivatedArea, setCultivatedArea] = useState(0);
    const [validCultivatedArea, setValidCultivatedArea] = useState(false);

    const textt = (t('ChooseModifications'));

    let selectedPlot = useRef(getSelectedPlot());
    const areaUnit = 'ha';

    const allowIdsWithUnit = useRef([21, 22, 42, 51]);
    const alert = useAlert();


    useEffect(() => {
        getContentWithAction(auth.getAccessToken(), 'resource', 'plotModificationOptions', {}).then((data) => {
            formatModificationGroups(data);

            if (props.selectEvent.selectedPlots) { // If multiple chosen plots, no responseHandler needed
                setMultiSelect(true);
            }
            else {
                if (props.selectEvent.selectedElement) {
                    responseHandler(props.selectEvent.selectedElement);
                    setValidation(true);
                }
                else {
                    responseHandler({
                        way: 0,
                        count: 1,
                        depth: 0,
                        actionDate: dayjs().format()
                    });
                    setValidation(true);
                }
            }
        });

        getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}).then((result) => {
            setButtonWriteShow(result);
        });

    }, [props.selectEvent]);

    const formatModificationGroups = (data) => {
        var previousList = data.filter(item => item.farmUsed === 1).map(x => ({ ...x }));

        setPlotModifications([
            { label: t('Previously_Used'), options: previousList },
            { label: t('All'), options: data }
        ]);
    };

    const responseHandler = (data) => {
        setSelectedPlotModification({ label: data.description ? data.description : undefined, value: data.way });
        setModificationCount(data.count);
        setDepth(data.depth);

        if (data.cultivatedArea) {
            validateCultivatedArea(data.cultivatedArea);
            setCultivatedArea(data.cultivatedArea);
        }
        else {
            validateCultivatedArea(selectedPlot.current.area);
            setCultivatedArea(selectedPlot.current.area);
        }

        if (data.unitText)
            setUnitText(data.unitText || 'kg');

        if (data.unitNumber)
            setUnit(data.unitNumber);

        if (!allowIdsWithUnit.current.find(a => a === data.unitNumber)) {
            setDepth(0);
            setShowDepth(false);
        }

        setTillingId(data.number);

        if (data.actionDate) {
            setDate(dayjs(data.actionDate).format());
        }
    }

    const deletePlotModification = () => {
        var modification = {
            number: props.selectEvent.selectedElement.number,
            plotId: getSelectedPlot().apNumber
        };
        postContentWithAction(auth.getAccessToken(), 'plotModification', 'delete', modification, () => {
            props.onHide();
            props.setRefresh(Date.now());
        });
    }

    const saveTilling = () => {
        // validate
        if (!selectedPlotModification || !selectedPlotModification.value) return;

        var newModifications = [];

        if (multiSelect) {
            props.selectEvent.selectedPlots.forEach((plot) => {
                newModifications.push({
                    plotId: plot.apNumber,
                    depth: depth,
                    count: modificationCount,
                    way: selectedPlotModification.value,
                    actionDate: date,
                    cultivatedArea: plot.area
                });
            });

            postContentWithAction(auth.getAccessToken(), 'plotModification', 'new', {
                models: newModifications
            }, () => {
                props.setRefresh(Date.now());
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
            });
        }
        else {
            if (!props.selectEvent.selectedElement) {
                newModifications.push({
                    plotId: getSelectedPlot().apNumber,
                    depth: depth,
                    count: modificationCount,
                    way: selectedPlotModification.value,
                    actionDate: date,
                    cultivatedArea: cultivatedArea
                });

                postContentWithAction(auth.getAccessToken(), 'plotModification', 'new', {
                    models: newModifications
                }, () => {
                    props.setRefresh(Date.now());
                    alert.show(t('SavedSuccesfully'), { type: 'success' });
                }).catch(e => {
                    alert.show(t('SavedFailed'), { type: 'error' });
                });
            }
            else {
                putContentWithAction(auth.getAccessToken(), 'plotModification', 'update', {
                    plotId: getSelectedPlot().apNumber,
                    depth: depth,
                    count: modificationCount,
                    way: selectedPlotModification.value,
                    actionDate: date,
                    number: tillingId,
                    cultivatedArea: cultivatedArea
                }).then(() => {
                    props.setRefresh(Date.now());
                    alert.show(t('SavedSuccesfully'), { type: 'success' });
                }).catch(e => {
                    alert.show(t('SavedFailed'), { type: 'error' });
                });
            }
        }
        props.onHide();
    }

    let selectChanged = (data) => {
        setSelectedPlotModification(data);
        if (data.unitText)
            setUnitText(data.unitText || 'kg');

        if (data.unitNumber)
            setUnit(data.unitNumber);

        if (!allowIdsWithUnit.current.find(a => a === data.unitNumber)) {
            setDepth(0);
            setShowDepth(false);
        }
        else {
            setDepth(1);
            setShowDepth(true);
        }
    }

    let depthValueValidator = (data) => {
        if (data < 1)
            setDepth(parseInt(0))
        else if (data > 99)
            setDepth(parseInt(99))
        else
            setDepth(parseInt(data))
    }
    let setValueValidator = (data) => {
        if (data < 1)
            setModificationCount(0)
        else if (data > 9)
            setModificationCount(parseInt(9))
        else
            setModificationCount(parseInt(data))
    }

    const validateCultivatedArea = (value) => {
        if (value === 0 ||  value > selectedPlot.current.area) {
            setValidCultivatedArea(false);
        }
        else {
            setValidCultivatedArea(true);
        }
    }

    const checkSaveDisabled = () => {
        //validate
        if (!selectedPlotModification || !selectedPlotModification.value) return true;

        if (multiSelect) {
            return false;
        }

        return validCultivatedArea ? false : true;
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('Tilling')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formPlotModification">
                            <Form.Label className="text-dark">{t('Tilling')}</Form.Label>
                            <Select
                                className={validate && !selectedPlotModification.value ? "text-dark invalid-border" : "text-dark"}
                                value={selectedPlotModification?.label ? selectedPlotModification : { label: textt, value: null }}
                                onChange={(value) => selectChanged(value)}
                                options={plotModifications}
                                formatGroupLabel={formatGroupLabel}
                                styles={customStyles}
                            />
                        </Form.Group>

                        <Form.Group controlId="formMeasure">
                            <Form.Label className="text-dark">{t('Depth')} {unitText ? '(' + unitText + ')' : null}</Form.Label>
                            <div className="row">
                                <div className="col-3">
                                    <Form.Control
                                        disabled={allowIdsWithUnit.current.find(a => a === unit) ? false : true}
                                        type="input"
                                        value={showDepth && depth > 0 ? depth : ''}
                                        onChange={(value) => {
                                            if (value) {
                                                depthValueValidator(value.target.value);
                                            }
                                        }} />
                                </div>
                                <div className="col-9">
                                    <Form.Range className="tilling-form-range" min={1} max={99}
                                        disabled={allowIdsWithUnit.current.find(a => a === unit) ? false : true}
                                        type="range" onChange={(value) => setDepth(parseInt(value.target.value))}
                                        value={depth > 0 ? depth : 0}
                                        variant='success'
                                    />
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formTimes">
                            <Form.Label className="text-dark">{t('Sets')}</Form.Label>
                            <div className="row">
                                <div className="col-3">
                                    <Form.Control
                                        type="input"
                                        value={modificationCount > 0 ? modificationCount : ''}
                                        onChange={(value) => {
                                            if (value) {
                                                setValueValidator(value.target.value);
                                            }
                                        }} />

                                </div>
                                <div className="col-9">
                                    <Form.Range className="tilling-form-range"
                                        type="range"
                                        onChange={(value) => {
                                            if (value) {
                                                setValueValidator(value.target.value);
                                            }
                                        }}
                                        value={modificationCount > 0 ? modificationCount : 0}
                                        min={1} max={9}
                                    />
                                </div>
                            </div>
                        </Form.Group>

                        {/* LANNOITUSALA */}
                        {!multiSelect ? 
                            <Form.Group controlId="formAmount">
                                <Form.Label className="text-dark">{t('FertilizerArea')}</Form.Label>
                                <NumberFormat
                                    className={!validCultivatedArea ? "form-control invalid-border" : "form-control"}
                                    value={cultivatedArea >= 0 ? cultivatedArea : ''}
                                    placeholder={' ' + areaUnit}
                                    decimalScale={2}
                                    onValueChange={(values) => {
                                        if (values.floatValue !== null && values.floatValue
                                            !== cultivatedArea) {
                                            validateCultivatedArea(values.floatValue);
                                            setCultivatedArea(values.floatValue);
                                        }
                                        if (!values.floatValue) {
                                            setValidCultivatedArea(false);
                                        }
                                    }}
                                    displayType={'input'}
                                    thousandSeparator={' '}
                                    suffix={'  ' + areaUnit}
                                    allowNegative={false}
                                    isAllowed={valueMax}
                                    allowedDecimalSeparators={['.', ',']} />
                            </Form.Group>
                            : ''}

                        <Form.Group controlId="formDate">
                            <Form.Label className="text-dark">{t('Date')}</Form.Label>
                            <Form.Control type="text" onClick={() => setCalendarShow(true)} placeholder={t('Date')} value={dayJsFormatDate(date)} onChange={() => { }} />
                        </Form.Group>
                        {multiSelect ?
                            <Accordion className="show-chosen-plots-accordion">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><b>{t('ShowChosenPlots')}</b></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="plot-list">
                                            {props.selectEvent.selectedPlots.map((plot, i) =>
                                                <div key={i}>
                                                    <b>{plot.name} - {plot.apChar}</b> ({plot.area} ha)
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            : ''}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    {props.selectEvent.selectedElement && buttonWriteShow === true ?
                        <Button variant='outline-danger'
                            className="footer-button-left"
                            onClick={() => deletePlotModification()}>{t('Delete')}</Button> : ''}

                    <Button variant='outline-secondary' onClick={props.onHide}>{t('Cancel')}</Button>

                    {buttonWriteShow === true ?
                        <Button disabled={checkSaveDisabled()} variant="success" className="btn-show-more" onClick={() => {
                            setValidation(true);
                            saveTilling();
                        }}>{t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>
            <CalendarModal
                show={calendarShow}
                onHide={() => setCalendarShow(false)}
                onChange={setDate}
                selecteddate={date}
            />
        </>
    );
}

export default PlotModificationModal;