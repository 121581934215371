import React, { useContext } from 'react';
import * as dayjs from 'dayjs'
import Calendar from 'react-calendar'
import Modal from 'react-bootstrap/Modal';

import 'react-calendar/dist/Calendar.css';
import '../../css/general.css'
import AppContext from '../../AppContext';

const CalendarModal = (props) => {
    const { selectedLanguage } = useContext(AppContext);

    let d = new Date();
    let selecteddate = props.selecteddate ? new Date(props.selecteddate) : new Date()
    
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter center" centered className="mobi-calendar-content">
            <Modal.Body className="mobi-calendar-body">
                <Calendar locale={selectedLanguage}
                    className="css-mobi-calendar"
                    maxDate={selecteddate > d ? selecteddate: d}
                    value={selecteddate}
                    onChange={(selectedDate) => { props.onChange(dayjs(selectedDate).format()); props.onHide(); }} />
            </Modal.Body>
        </Modal>
    );
}

export default CalendarModal;