import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ActionSelectionModal from './Modals/ActionSelectionModal'
import InputGroup from 'react-bootstrap/InputGroup'

import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import AppContext from '../AppContext';

import NavMenu from './NavMenu';
import { ICONS } from '../img/icons'
import Icon from '../img/Icon'
import { getContent, getContentWithAction } from '../apiConnect';
import {
    setSelectedPlot, getSelectedFarmId, getSelectedYear, FarmIdToView, removeSelectedPlot,
    getSelectedLang, getSelectedFarmName, setSelectedPage, getSelectedPlots, saveSelectedPlots,
    saveSelectedPlotIds, removeSelectedPlotIds,
    setSelectedPlantId, getSelectedPlantId, removeSelectedPlantId,
    setFarmList, farmHaveUIWriteRights,
    setSelectedPlotGroupId, getSelectedPlotGroupId, removeSelectedPlotGroupId,
    formatGroupLabel
} from '../common';
import Select from 'react-select';
import { groupByKey } from '../commonFunctions';
import { useAlert } from 'react-alert'

import '../css/checkbox.css'
import '../css/general.css'
import '../css/farmPlot.css'

const FarmPlot = (props) => {

    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [plots, setPlots] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [checkedPlots, setCheckedPlots] = useState([])
    const [showActionModal, setShowActionModal] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [searchResults, setSearchResults] = useState([])
    const [rotation, setRotation] = useState(window.innerHeight > 415)
    const [errorText, setErrorText] = useState("");
    const [totalAmountText, setTotalAmountText] = useState(0);
    const [mainList, setMainList] = useState([]);
    const [actionModeAdd, setActionModeAdd] = useState(false);
    const [allowedSave, setAllowedSave] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertContent, setAlertContent] = useState({ header: '', text: '', close: '' });

    const textt = (t('SelectPlantOrPlotgroup'));
    const selectAlltext = (t('SelectAll'));
    const deselectAlltext = (t('DeselectAll'));
    const [selected, setSelected] = useState(false);
    const [item, setItem] = useState(null);

    const plant = props.history && props.history.location && props.history.location.state && props.history.location.state.id ? Number(props.history.location.state.id) : -1;
    const plotGroup = props.history && props.history.location && props.history.location.state && props.history.location.state.groupId ? Number(props.history.location.state.groupId) : -1;
    const savedPlantId = getSelectedPlantId();
    const savedPlotGroupId = getSelectedPlotGroupId();


    document.title = t('Plots') + ' | Mobiwisu';
    setSelectedPage(1);

    const alert = useAlert();

    const updateViewMode = () => {
        setRotation(window.innerHeight > 415)
    }

    const farmPlotClickHandler = (plot) => {
        setSelectedPlot(plot);
        props.history.push('/plotOperation');
    }

    useEffect(() => {
        removeSelectedPlot();

        let plotIds = getSelectedPlots();
        setCheckedPlots(plotIds);
        if (plotIds && plotIds.length) {
            setSelected(true);
        }

        getContentWithAction(auth.getAccessToken(), 'session', 'allowedFarms', {}).then((result) => {

            if (!result || !result.length)
                props.history.push('/');

            setFarmList(result);
            setAllowedSave(farmHaveUIWriteRights());
        });
    }, []);

    useEffect(() => {
        getContentFarmPlot(plots);      
        areaCalculatorAndFormatter();
    }, [searchTerm]);

    useEffect(() => {
        areaCalculatorAndFormatter();
    }, [searchResults, checkedPlots]);

    useEffect(() => {
        window.addEventListener("resize", updateViewMode)
        return () => window.removeEventListener("resize", updateViewMode)
    })

    const refreshContentFarmPlot = () => {
        getContentFarmPlot([]);
    }

    const getContentFarmPlot = (plotData) => {
        if (!plotData || plotData.length === 0) {
            getContent(auth.getAccessToken(), 'farmPlot', { lang: getSelectedLang()  }).then((data) => {
                data.forEach(d => {
                    d.plantName = d.plantName ? d.plantName : t('NoPlants');
                });

                setPlots(data);
                plotResults(data);
            });
        }
        else
            plotResults(plotData);
    }

    const plotResults = (data) => {
        if (data.length > 0) {            
                
            filterSearchResults(savedPlantId > -1 ? savedPlantId : plant, savedPlotGroupId > -1 ? savedPlotGroupId : plotGroup, data);
            setErrorText('');
            setIsLoading(false);

            let group = groupByKey(data, 'plantId');
            let group2 = groupByKey(data, 'groupId');
            formatPlantPlotsGroup(group, group2);
        }
        else {
            filterSearchResults(plant, plotGroup, []);
            setErrorText(t('ErrorNoPlots'));
            setIsLoading(false);
        }
    }

    const formatPlantPlotsGroup = (groups, groups2) => {

        let plants = [];
        let plots = [];

        for (var g in groups) {
            let obj = {};
            for (var p in groups[g]) {
                let plot = groups[g][p];
                obj.value = Number(plot.plantId);
                obj.label = plot.plantName ? plot.plantName : t('NoPlants');
                obj.type = 'plant';

                if (plant > -1 && plant === obj.value) {                   
                    setItem(obj);
                }
                if (savedPlantId && Number(savedPlantId) === obj.value) {                    
                    setItem(obj);
                }

            }
            plants.push(obj);
        }

        for (var g in groups2) {
            if (!parseInt(g, 10) > 0) {
                continue;
            }
            let obj = {};

            for (var p in groups2[g]) {
                let plot = groups2[g][p];
                obj.value = Number(plot.groupId);
                obj.label = plot.groupName;
                obj.type = 'plotGroup';

                if (plotGroup > -1 && plotGroup === obj.value) {                 
                    setItem(obj);                
                }
                if (savedPlotGroupId && Number(savedPlotGroupId) === obj.value) {                  
                    setItem(obj);                
                }
            }
            plots.push(obj);
        }

        setMainList([
        { value: -1, label: ' ' + t('All') },
        { label: t('Plants'), options: plants.sort((a, b) => b.label - a.label) },
        { label: t('Plots'), options: plots.sort((a, b) => b.label - a.label) }
        ]);

    };

    const handleCheckboxSelection = (p) => {
        if (checkedPlots.includes(p.apNumber)) {
            var checked = [...checkedPlots.filter(c => c !== p.apNumber)];
            setCheckedPlots(checked);
        }
        else {
            var checked = [...checkedPlots];
            checked.push(p.apNumber);
            setCheckedPlots(checked);
        }

    }

    const handleCheckboxSelectionAll = () => {
        let checked = [];
        if (!selected) {
            if (checkedPlots && checkedPlots.length) {
                checked = [...new Set(searchResults.filter(item => !checkedPlots.includes(item.apNumber)).map(c => c.apNumber))];
            }
            else {
                checked = [...new Set(searchResults.map(c => c.apNumber))];
            }
            saveSelectedPlotIds(checked);
            setSelected(true);
            setCheckedPlots([...checkedPlots, ...checked]);
        }
        else {
            if (checkedPlots && checkedPlots.length) {
                checked = [...new Set(searchResults.filter(item => checkedPlots.includes(item.apNumber)).map(c => c.apNumber))];
            }
            else {
                checked = [...new Set(searchResults.map(c => c.apNumber))];
            }
            removeSelectedPlotIds();
            setSelected(false);
            setCheckedPlots([]);
        }
    }
    const areaCalculatorAndFormatter = () => {
        if (searchResults && checkedPlots && checkedPlots.length)
            setTotalAmountText(searchResults.filter(item => checkedPlots.includes(item.apNumber)).reduce((total, currentItem) => total = total + currentItem.area, 0));
        else
            setTotalAmountText(0);
    }

    const handleSearchTermChange = event => {
        setSearchTerm(event.target.value.toLowerCase())
    }

    const handleSearchPlantChange = event => {       
        setItem(event);

        filterSearchResults(event.type === 'plant' ? Number(event.value) : null, event.type === 'plotGroup' ? Number(event.value) : null, plots);
    }

    const filterSearchResults = (plantValue, plotgroupValue, data) => {

        let results = data; // data is original data

        if (searchTerm)
            results = results.filter(plot => {
                if (!plot.name)
                    return false
                else if (plot.name.toLowerCase().includes(searchTerm))
                    return true;
                else if (plot.plantName && plot.plantName.toLowerCase().includes(searchTerm))
                    return true;

            })

        if ((plantValue && plantValue > -1) || plantValue === 0)
            results = results.filter(plot => {
                if (plot.plantId === Number(plantValue)) {
                    removeSelectedPlotGroupId();
                    setSelectedPlantId(plantValue == 0 ? "0" : plantValue);
                    return true;
                }
                else
                    return false;
            });       

        if (plotgroupValue && plotgroupValue > -1)
            results = results.filter(plot => {
                if (plot.groupId === Number(plotgroupValue)) {
                    removeSelectedPlantId();
                    setSelectedPlotGroupId(plotgroupValue);
                    return true;
                }
                else
                    return false;
            });     

        if (plantValue == null && plotgroupValue == null) {
            removeSelectedPlantId();
            removeSelectedPlotGroupId();
        }

        setSearchResults(results);
    }      

    return (
        <Container style={{ paddingBottom: '80px' }}>
            <NavMenu {...props} getPlots={refreshContentFarmPlot} />
            <Card className="lastCard">
                {/*<Card.Header className="text-dark" style={{ fontSize: 'x-large' }}>*/}
                {/*    {t('Farm')} {FarmIdToView(getSelectedFarmId())} - {getSelectedYear()}*/}
                {/*    <Button variant='info' style={mapButtonStyle}><Icon icon={ICONS.NEEDLE} color='black' size='22' /></Button>*/}
                {/*</Card.Header>*/}
                <Card.Body>
                    <div className="text-dark farm-info-header">
                        <span className="farm-name-span"><h6>{FarmIdToView(getSelectedFarmId())} - {getSelectedFarmName()}</h6></span>
                        <span className="farm-year-span"><h6>{getSelectedYear()}</h6></span>
                    </div>

                    <div className="first-card-div">
                        <Select className="text-dark selecti" id="grouped-select" label="Grouping"   
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}                            
                            value={item ? item : null}
                            onChange={handleSearchPlantChange}
                            options={mainList}
                            placeholder={textt}
                            formatGroupLabel={formatGroupLabel}                        
                        />

                        <InputGroup className="search-input-group">
                            <Form.Control
                                type='text'
                                placeholder={t('Search')}
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                className={"searchFieldStyle"}
                            />
                            <InputGroup.Text className="search-icon-style">
                                <Icon icon={ICONS.SEARCH} color='gray' size='20' />
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                    {(isLoading) ? <Spinner animation="border" variant="success" /> : ''}

                    {(isLoading) ?
                        ''
                        :
                        <div className={selected ? "first-card-div fade-in" : "first-card-div fade-in-again"}>
                            {selected ?
                                <span className="selection-text" onClick={() => {
                                    handleCheckboxSelectionAll();
                                }}>{deselectAlltext}</span>
                                :
                                <span className="selection-text" onClick={() => {
                                    handleCheckboxSelectionAll();
                                }}>{selectAlltext}</span>}
                            <span className="total-amount-text"> {checkedPlots.length}{t('Units')} / {totalAmountText.toFixed(2)}ha</span>
                        </div>
                    }

                    <ListGroup className="list-group">
                        {(plots) ? searchResults.map(p =>
                            <div
                                className={checkedPlots && checkedPlots.some(c => c === p.apNumber) ? "plotRowStyle plot-row-selected" : "plotRowStyle"}
                                key={p.apNumber}>
                                {/*<Form.Check*/}
                                {/*    type='checkbox'*/}
                                {/*    className={"checkboxStyle"}*/}
                                {/*    value={checkedPlots ? checkedPlots.find(c => c === p.apNumber) : 0}*/}
                                {/*    name={p.apNumber}*/}
                                {/*    checked={checkedPlots ? checkedPlots.some(c => c === p.apNumber) : 0}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        handleCheckboxSelection(e, p);*/}
                                {/*        saveSelectedPlots(p.apNumber);*/}
                                {/*    }}*/}
                                {/*/>*/}

                                <button type="button" id="plot-select-btn" className={checkedPlots && checkedPlots.some(c => c === p.apNumber) ? "tractor-style tractor-selected" : "tractor-style"}
                                    onClick={() => {
                                        handleCheckboxSelection(p);
                                        saveSelectedPlots(p.apNumber);
                                    }}>
                                    <Icon icon={ICONS.TRACTOR} color={checkedPlots && checkedPlots.some(c => c === p.apNumber) ? "white" : "black"} size='20' />
                                </button>

                                <ListGroup.Item
                                    className={checkedPlots && checkedPlots.some(c => c === p.apNumber) ? "plotItemStyle plot-row-selected" : "plotItemStyle"}
                                    /*className={"plotItemStyle"}*/
                                    key={p.apNumber}
                                    action onClick={() => farmPlotClickHandler(p)}>
                                    <div className='row-icon-right'><Icon icon={ICONS.ARROW_RIGHT} color='gray' size='36' /></div>
                                    <div><b> {p.name} - {p.apChar}</b></div>
                                    <div>
                                        {p.area} ha - {item?.type === 'plant' && p.plantVariety ? p.plantVariety : p.plantName}
                                    </div>
                                </ListGroup.Item>
                            </div>
                        ) : ""}
                    </ListGroup>
                    <div className="text-dark">{errorText}</div>
                </Card.Body>
            </Card >
            {/*<div className="d-block d-lg-none">*/}
            {/*    <Button variant='info' className={rotation ? "floatingButtonStyleVertical" : "floatingButtonStyleHorizontal"} onClick={() => setShowActionModal(true)} > <Icon icon={ICONS.ADD} color='black' size='22' /> </Button>*/}
            {/*</div>*/}

            <div className="center div-footer-buttons">
                <Button variant='success' className="footersStyle buttonStyle"
                    onClick={() => {
                                setActionModeAdd(true); 
                                setShowActionModal(true); 
                            }} 
                    disabled={allowedSave && checkedPlots && checkedPlots.length ? false : true}>
                    <Icon icon={ICONS.ADD} color='white' size='16' /> 
                    <span className="floatButton-text">{t('Action')}</span>
                </Button>

                <Button variant='success' className="footersStyle buttonStyle"
                    onClick={() => {
                            setActionModeAdd(false);
                            setShowActionModal(true);
                        }}
                    disabled={allowedSave && checkedPlots && checkedPlots.length ? false : true}>
                    <span className="floatButton-text">{t('MarkDone')}</span>
                </Button>
            </div>


            <ActionSelectionModal
                show={showActionModal}
                onHide={() => setShowActionModal(false)}
                selectedplots={checkedPlots ? plots.filter(p => checkedPlots.includes(p.apNumber)) : checkedPlots}
                addMode={actionModeAdd}
                setAlertContent={setAlertContent}
                showAlert={setShowAlert}
            />


        </Container >
    );
}

export default FarmPlot;