import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Accordion from 'react-bootstrap/Accordion';
import NumberFormat from 'react-number-format';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs'
import CalendarModal from './CalendarModal';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'

import {
    getContentWithAction, 
    postContentWithAction, putContentWithAction
} from '../../apiConnect';
import { getSelectedPlot, formatGroupLabel} from '../../common';
import { useAlert } from 'react-alert'
import { valueMax, dayJsFormatDate, round } from '../../commonFunctions';
import AppContext from '../../AppContext';

import '../../css/general.css'
import '../../css/plotOperation.css'

const FertilizeModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [calendarShow, setCalendarShow] = useState(false);
    const [selectedFertilizer, setSelectedFertilizer] = useState(0);
    const [amount, setAmount] = useState(0);
    const [amountArea, setAmountArea] = useState(0);
    const [totalArea, setTotalArea] = useState(0);
    const [prevAndAllFertilizers, setPrevAndAllFertilizers] = useState([]);
    const [prevAndAllFertilizersBasicList, setPrevAndAllFertilizersBasicList] = useState([]);
    const [manureAndFarmFertilizers, setManureAndFarmFertilizers] = useState([]);
    const [manureAndFarmFertilizersBasicList, setManureAndFarmFertilizersBasicList] = useState([]);
    const [date, setDate] = useState(dayjs().format());
    const [fertilizerGroup, setFertilizerGroup] = useState('1')
    const [multiSelect, setMultiSelect] = useState(false);
    const [unit, setUnit] = useState('kg');
    const [validate, setValidation] = useState(false);
    const [tankMixtureText, setTankMixtureText] = useState(false);
    const [buttonWriteShow, setButtonWriteShow] = useState(false); // allowed save btn disabled
    const [validFertilizerArea, setValidFertilizerArea] = useState(false);

    const [showFertilizerArea, setShowFertilizerArea] = useState(false);
    const [fertilizerArea, setFertilizerArea] = useState(0);
    const [areaUnit, setAreaUnit] = useState('ha');

    const textt = (t('ChooseFertilizer'));

    const alert = useAlert();

    let selectedPlot = useRef(getSelectedPlot());

    const fertilizerRadios = [
        { name: t('FarmFertilizers'), value: '1' },
        { name: t('All'), value: '2' }
    ]

    useEffect(() => {
        Promise.all([
            //KAIKKI
            getContentWithAction(auth.getAccessToken(), 'resource', 'fertilizers', {}),
            //AIEMMIN KÄYTETYT
            getContentWithAction(auth.getAccessToken(), 'resource', 'previousfertilizers', {}),
            //KARJANLANNAT
            getContentWithAction(auth.getAccessToken(), 'resource', 'manurefertilizers', {}),
            //TILAN LANNOITTEET
            getContentWithAction(auth.getAccessToken(), 'resource', 'farmfertilizers', {}),
            //TILAN LANNOITESEOKSET
            getContentWithAction(auth.getAccessToken(), 'resource', 'ownfertilizers', {}),
            //TILAN KALKIT
            getContentWithAction(auth.getAccessToken(), 'resource', 'farmlimefertilizers', {}),
            // session
            getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {})
        ]).then(([allFertilizers, previousfertilizers, manureFertilizers, farmFertilizers, ownFertilizers, limeFertilizers, sessionResult]) => {

            previousfertilizers = previousfertilizers.filter(p => {
                if (allFertilizers.some(a => a.product === p.product)) return true;

                return false;
            });

            let farmAndOwn = [...farmFertilizers, ...ownFertilizers]

            setPrevAndAllFertilizers([
                { label: t('Previously_Used'), options: previousfertilizers },
                { label: t('All'), options: allFertilizers },
            ]);
            setPrevAndAllFertilizersBasicList([...previousfertilizers, ...allFertilizers]);

            let list = [
                { label: t('FarmFertilizers'), options: farmAndOwn },
                { label: t('Manures'), options: manureFertilizers },
            ];
            if (limeFertilizers.length) {
                list.push({ label: t('Limes'), options: limeFertilizers });
            }
            setManureAndFarmFertilizers(list);
            setManureAndFarmFertilizersBasicList([...farmAndOwn, ...manureFertilizers, ...limeFertilizers]);

            setButtonWriteShow(sessionResult);
        });

        if (selectedPlot && selectedPlot.current && selectedPlot.current.area) {
            validateFertilizerArea(selectedPlot.current.area);
            setFertilizerArea(selectedPlot.current.area);
        }

    }, []);

    useEffect(() => {
        // Set fertilizerGroup based on selected fertilizer
        if (manureAndFarmFertilizers && prevAndAllFertilizers) {
            if (manureAndFarmFertilizers.some(a => {
                return a.options.some(fert => fert.value === selectedFertilizer.value)
            }))
            {
                setFertilizerGroup('1');
            }
            else if (prevAndAllFertilizers.some(a => {
                    return a.options.some(fert => fert.value === selectedFertilizer.value)
                }))
            {
                setFertilizerGroup('2');
            }
        }
    }, [manureAndFarmFertilizers, prevAndAllFertilizers]);

    const responseHandler = (data) => {
        if (!data) {
            setSelectedFertilizer(0);
            setAmount(0);
            setDate(dayjs(Date.now()).format());
            setFertilizerGroup('1');
            return;
        }
        setSelectedFertilizer({ value: data.product });
        setAmount(data.amount);

        if (data.area) {
            validateFertilizerArea(data.area);
            setFertilizerArea(data.area);
            setAmountArea(data.amount * data.area);
        }
        else if (data.amount && selectedPlot && selectedPlot.current && selectedPlot.current.area) 
            setAmountArea(data.amount * selectedPlot.current.area);

        if (data.unitText)
            setUnit(data.unitText || 'kg');

        if (data.date) {
            setDate(dayjs(data.date).format());
        }

        if (data.pesticideId)
            setTankMixtureText(true);
    }

    useEffect(() => {
        if (props.selectEvent.selectedPlots) { // If multiple chosen plots
            setShowFertilizerArea(false);
            setMultiSelect(true);
            setTotalArea(props.selectEvent.selectedPlots.reduce((total, currentItem) => total = total + currentItem.area, 0));
        }
        else {
            setShowFertilizerArea(true);
        }

        if (props.selectEvent.selectedElement) { // Editing
            setValidation(true);
            responseHandler(props.selectEvent.selectedElement);
        }
        else { // Adding new
            responseHandler({
                product: 0,
                amount: 0,
                disseminationDate: dayjs().format()
            });
        }
    }, [props.selectEvent]);

    const deleteFertilizing = () => {
        postContentWithAction(auth.getAccessToken(), 'fertilize', 'delete', { fertilizingId: props.selectEvent.selectedElement.fertilizingId, plotId: getSelectedPlot().apNumber }, () => {
            props.setRefresh(Date.now());
            props.onHide();
        });
    }

    const saveFertilizing = () => {
        var newFertilizings = [];

        //validate
        if (!amount) return;
        if (!selectedFertilizer || !selectedFertilizer.value) return;

        if (multiSelect) {
            props.selectEvent.selectedPlots.forEach((plot) => {
                newFertilizings.push({
                    plotId: plot.apNumber,
                    amount: round(amount),
                    product: selectedFertilizer.value,
                    productName: selectedFertilizer.label,
                    date: date,
                    fertilizingId: 0,
                    unitType: 0,
                    area: plot.area
                });
            });

            postContentWithAction(auth.getAccessToken(), 'fertilize', 'new', {
                models: newFertilizings
            }, (data) => {
                props.setRefresh(Date.now());
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
            });
        }
        else {
            if (!props.selectEvent.selectedElement) {
                newFertilizings.push({
                    plotId: getSelectedPlot().apNumber,
                    amount: round(amount),
                    product: selectedFertilizer.value,
                    productName: selectedFertilizer.label,
                    date: date,
                    fertilizingId: 0,
                    unitType: 0,
                    area: fertilizerArea > selectedPlot.current.area ? selectedPlot.current.area : fertilizerArea
                });

                postContentWithAction(auth.getAccessToken(), 'fertilize', 'new', {
                    models: newFertilizings
                }, (data) => {
                    props.setRefresh(Date.now());
                    alert.show(t('SavedSuccesfully'), { type: 'success' });
                }).catch(e => {
                    alert.show(t('SavedFailed'), { type: 'error' });
                });
            }
            else {
                var unitti = null;
                unitti = manureAndFarmFertilizersBasicList.find(fert => fert.value === selectedFertilizer.value);
                if (!unitti)
                    unitti = prevAndAllFertilizersBasicList.some(fert => fert.value === selectedFertilizer.value);

                var updateFertilizes = [];
                updateFertilizes.push({
                    plotId: getSelectedPlot().apNumber,
                    number: props.selectEvent.selectedElement.number,
                    amount: round(amount),
                    product: selectedFertilizer.value,
                    productName: selectedFertilizer.label,
                    date: date,
                    fertilizingId: props.selectEvent.selectedElement.fertilizingId,
                    unitType: unitti && unitti.unit ? unitti.unit : 13,
                    plannedDate: props.selectEvent.selectedElement.plannedDate,
                    area: fertilizerArea > selectedPlot.current.area ? selectedPlot.current.area : fertilizerArea
                });

                putContentWithAction(auth.getAccessToken(), 'fertilize', 'update', { models: updateFertilizes }).then((data) => {
                    props.setRefresh(Date.now());
                    alert.show(t('SavedSuccesfully'), { type: 'success' });
                }).catch(e => {
                    alert.show(t('SavedFailed'), { type: 'error' });
                });
            }
        }
        props.onHide();
    }

    let selectChanged = (data) => {
        setSelectedFertilizer(data);

        if (data.unitText)
            setUnit(data.unitText || 'kg');
    }

    const validateFertilizerArea = (value) => {
        if (value === 0 || value > selectedPlot.current.area) {
            setValidFertilizerArea(false);
        }
        else {
            setValidFertilizerArea(true);
        }
    }

    const checkSaveDisabled = () => {
        if (multiSelect) {
            return false;
        }

        return validFertilizerArea ? false : true;
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('Fertiliser')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFertilizer">
                            <Form.Label className="text-dark">{t('Fertiliser')}</Form.Label>
                            <div style={{ marginBottom: '10px' }}>
                                <ButtonGroup>
                                    {fertilizerRadios.map((radio, i) => (
                                        <ToggleButton
                                            key={i}
                                            id={radio.name + i}
                                            type="radio"
                                            variant="outline-success"
                                            name="radio"
                                            value={radio.value}
                                            checked={fertilizerGroup === radio.value}
                                            onChange={(e) => setFertilizerGroup(e.currentTarget.value)}
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                    ))}
                                </ButtonGroup>
                            </div>
                            {(fertilizerGroup === '2') ?
                                <Select
                                    className={validate && selectedFertilizer.value === 0 ? "text-dark invalid-border" : "text-dark"}
                                    value={
                                        prevAndAllFertilizersBasicList.find(f => f.value === selectedFertilizer.value) || { label: textt, value: null }
                                    }
                                    onChange={(data) => selectChanged(data)}
                                    options={prevAndAllFertilizers}
                                    formatGroupLabel={formatGroupLabel}
                                />
                                :
                                <Select
                                    className={validate && selectedFertilizer.value === 0 ? "text-dark invalid-border" : "text-dark"}
                                    value={
                                        manureAndFarmFertilizersBasicList.find(f => f.value === selectedFertilizer.value) || { label: textt, value: null }
                                    }
                                    onChange={(data) => selectChanged(data)}
                                    options={manureAndFarmFertilizers}
                                    formatGroupLabel={formatGroupLabel}
                                />
                            }

                            {tankMixtureText ?
                                <div style={{ marginTop: '10px' }}><span>{t('TankMixture')} </span> <Icon icon={ICONS.INFO} color='#2ba3c4' size='20' /></div>
                                : ''
                            }
                        </Form.Group>

                        {showFertilizerArea ?
                            <Form.Group controlId="formAmount">
                                <Form.Label className="text-dark">{t('FertilizerArea')}</Form.Label>
                                <NumberFormat
                                    className={!validFertilizerArea ? "form-control invalid-border" : "form-control"}
                                    value={fertilizerArea >= 0 ? fertilizerArea : ''}
                                    placeholder={' ' + areaUnit}
                                    decimalScale={2}
                                    onValueChange={(values) => {
                                        if (values.floatValue !== null && values.floatValue
                                            !== fertilizerArea) {
                                            validateFertilizerArea(values.floatValue);
                                            setFertilizerArea(values.floatValue);
                                            setAmountArea(amount * values.floatValue);
                                        }
                                        if (!values.floatValue) {
                                            setValidFertilizerArea(false);
                                        }
                                    }}
                                    displayType={'input'}
                                    thousandSeparator={' '}
                                    suffix={'  ' + areaUnit}
                                    allowNegative={false}
                                    isAllowed={valueMax}
                                    allowedDecimalSeparators={['.', ',']} />
                            </Form.Group>
                            : ''}

                        <Form.Group controlId="formAmount">
                            <Form.Label className="text-dark">{t('Amount_ha')}</Form.Label>
                            <NumberFormat
                                className={validate && amount === 0 ? "form-control invalid-border" : "form-control"}
                                value={amount > 0 ? amount : ''}
                                placeholder={' ' + unit}
                                decimalScale={2}
                                onValueChange={(values) => {
                                    if (values.floatValue && round(values.floatValue) !== round(amount)) {
                                        if (values.floatValue) {
                                            setAmount(values.floatValue)

                                            if (!multiSelect) {
                                                if (fertilizerArea)
                                                    setAmountArea(values.floatValue * fertilizerArea);
                                                else if (selectedPlot.current && selectedPlot.current.area)
                                                    setAmountArea(values.floatValue * selectedPlot.current.area);
                                                else
                                                    setAmountArea(0);
                                            }
                                            else {
                                                if (totalArea)
                                                    setAmountArea(values.floatValue * totalArea);
                                                else
                                                    setAmountArea(0);
                                            }
                                        }
                                        else {
                                            setAmount(0);
                                            setAmountArea(0);
                                        }
                                    }
                                }}
                                displayType={'input'}
                                thousandSeparator={' '}
                                suffix={'  ' + unit}
                                allowNegative={false}
                                isAllowed={valueMax}
                                allowedDecimalSeparators={['.', ',']} />
                        </Form.Group>
                        {!multiSelect ?
                            <Form.Group controlId="formAmountHa">
                                <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                <NumberFormat
                                    className="form-control"
                                    value={amountArea > 0 ? amountArea : ''}
                                    placeholder={' ' + unit}
                                    decimalScale={2}
                                    onValueChange={(values) => {
                                        if (values.floatValue && round(values.floatValue) !== round(amountArea)) {
                                            if (values.floatValue) {
                                                if (selectedPlot.current.area > 0) {
                                                    setAmount(values.floatValue / selectedPlot.current.area);
                                                }
                                                else
                                                    setAmount(0);

                                                setAmountArea(values.floatValue);
                                            }
                                            else {
                                                setAmount(0);
                                                setAmountArea(0);
                                            }
                                        }
                                    }}
                                    displayType={'input'}
                                    thousandSeparator={' '}
                                    suffix={'  ' + unit}
                                    allowNegative={false}
                                    isAllowed={valueMax}
                                    allowedDecimalSeparators={['.', ',']} />
                            </Form.Group>
                            :
                            <Form.Group controlId="formAmountHa">
                                <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                <NumberFormat
                                    className="form-control"
                                    value={amountArea > 0 ? amountArea : ''}
                                    placeholder={' ' + unit}
                                    decimalScale={2}
                                    onValueChange={(values, e) => {
                                        if (values.floatValue && round(values.floatValue) !== round(amountArea)) {
                                            if (values.floatValue) {
                                                if (totalArea > 0) {
                                                    setAmount(values.floatValue / totalArea);
                                                }
                                                else
                                                    setAmount(0);

                                                setAmountArea(values.floatValue);
                                            }
                                            else {
                                                setAmount(0);
                                                setAmountArea(0);
                                            }
                                        }
                                    }}
                                    displayType={'input'}
                                    thousandSeparator={' '}
                                    suffix={'  ' + unit}
                                    isAllowed={valueMax}
                                    allowedDecimalSeparators={['.', ',']} />
                            </Form.Group>
                        }
                        <Form.Group controlId="formDate">
                            <Form.Label className="text-dark">{t('Date')}</Form.Label>
                            <Form.Control type="text" onClick={() => setCalendarShow(true)} placeholder={t('Date')} value={dayJsFormatDate(date)} onChange={() => { }} />
                        </Form.Group>
                        {multiSelect ?
                            <Accordion className="show-chosen-plots-accordion">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><b>{t('ShowChosenPlots')}</b></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="plot-list">
                                            {props.selectEvent.selectedPlots.map((plot, i) =>
                                                <div key={i}>
                                                    <b>{plot.name} - {plot.apChar}</b> ({plot.area} ha)
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            : ''}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    {props.selectEvent.selectedElement && buttonWriteShow === true ?
                        <Button variant='outline-danger' className="footer-button-left"
                        onClick={() => deleteFertilizing()}>{t('Delete')}</Button>
                        : ''}

                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Cancel')}</Button>

                    {buttonWriteShow === true ?
                        <Button variant="success" disabled={checkSaveDisabled()} className="btn-show-more" onClick={() => {
                            setValidation(true);
                            saveFertilizing();
                        }}>{t('Save')}</Button>
                      : ''}
                </Modal.Footer>
            </Modal>
            <CalendarModal
                show={calendarShow}
                onHide={() => setCalendarShow(false)}
                onChange={setDate}
                selecteddate={date}
            />
        </>
    );
}

export default FertilizeModal;