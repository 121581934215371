import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as dayjs from 'dayjs'
import { useTranslation } from 'react-i18next';
import { postContentWithAction, putContentWithAction } from '../../apiConnect';
import { getSelectedLang } from '../../common';
import { formatPlannedDate, groupByKey, valueMax, dayJsFormatDate, groupByMarked, round, updateCalculativeArray } from '../../commonFunctions';
import NumberFormat from 'react-number-format';
import CalendarModal from './CalendarModal';
import Spinner from 'react-bootstrap/Spinner';
import { useAlert } from 'react-alert'
import AppContext from '../../AppContext';
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'

import '../../css/general.css'

const MarkSowModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [plannedSows, setPlannedSows] = useState([]);
    const [combinedSows, setCombinedSows] = useState([]);
    const [calendarShow, setCalendarShow] = useState(false);
    const [date, setDate] = useState(dayjs().format());
    const [contentIsLoading, setContentIsLoading] = useState(true);
    const [infoText, setInfoText] = useState('Ladataan tietoja');
    const [saveInProgress, setSaveInProgress] = useState(false);

    //For area and amount calculations, CCD = CalculationCombinedData
    const [cCD, setCCD] = useState([]);

    const alert = useAlert();

    useEffect(() => {
        var plotIds = [];
        props.selectEvent.selectedPlots.forEach(p => {
            plotIds.push({ plotId: p.apNumber });
        });
        postContentWithAction(auth.getAccessToken(), 'sow', 'sowsForPlots', { models: plotIds, lang: getSelectedLang() }, (data) => {
            data = data.filter((sow) => sow.sowingDate || sow.actionDate);
            data.forEach(sow => {
                sow.plotData = props.selectEvent.selectedPlots.find(p => p.apNumber === sow.plotId);
            });
            setPlannedSows(data);

            var grouped1 = groupByKey(data, 'varietyId');
            var grouped2 = [];
            for (const key in grouped1) {
                var m = groupByMarked(grouped1[key], 'sowingDate', 'actionDate');
                var b = [];
                for (const type in m) {
                    for (const key in m[type]) { // Looping grouped keys
                        var combinedIds = [];
                        var combinedArea = 0;
                        var combinedAmount = { min: m[type][key][0].amount, max: m[type][key][0].amount };
                        m[type][key].forEach(s => { // Looping sows
                            combinedIds.push({
                                plotId: s.plotId,
                                number: s.number,
                                plotName: s.plotData.name + ' - ' + s.plotData.apChar
                            });
                            combinedArea += s.plotData.area;
                            combinedAmount.min = Math.min(combinedAmount.min, s.amount);
                            combinedAmount.max = Math.max(combinedAmount.max, s.amount);
                        });
                        var combinedData = { ...m[type][key][0] }; // Copying first element for base data
                        if (combinedAmount.min != combinedAmount.max) combinedData.amount = null;

                        b.push({
                            date: key,
                            combinedData: combinedData,
                            combinedArea: combinedArea,
                            combinedIds: combinedIds,
                            combinedAmount: combinedAmount
                        });
                    }
                }
                grouped2.push({
                    variety: key,
                    objects: b
                });
            }

            setCCD(grouped2);
            setCombinedSows(grouped2);

            setContentIsLoading(false);
            setInfoText('Ei suunniteltuja kylvöjä');
        });
    }, [props.selectEvent]);

    const updateDate = (value) => {
        var newDate = date;
        newDate.sowingDate = value;
        setDate(newDate);
    };

    const saveSows = (openFerts) => {
        setSaveInProgress(true);
        let today = dayjs().format('YYYYMMDD');
        let invalidDate = false;
        var newSows = [];

        combinedSows.forEach(variety => {
            variety.objects.forEach(sow => {
                sow.combinedIds.forEach(number => {
                    var originalData = plannedSows.find(s => s.number === number.number);
                    var data = sow.combinedData;
                    let formattedDate = dayjs(data.sowingDate).format('YYYYMMDD');
                    if (data.sowingDate && today < formattedDate) invalidDate = true; // Check for valid dates
                    if (data.sowingDate) {
                        newSows.push({
                            actionDate: sow.date,
                            amount: data.amount ? round(data.amount) : originalData.amount,
                            description: data.description,
                            mixture: data.mixture,
                            number: number.number,
                            plotId: number.plotId,
                            sowingDate: data.sowingDate,
                            tiheys: data.tiheys,
                            typeId: data.typeId,
                            unitNumber: data.unitNumber,
                            varietyId: data.varietyId
                        });
                    }
                });
            });
        });

        if (!invalidDate) {
            putContentWithAction(auth.getAccessToken(), 'sow', 'update', {
                models: newSows
            }).then(() => {
                setSaveInProgress(false);
                if (openFerts) {
                    props.setRefresh(Date.now());
                    openFertilizings();
                }
                alert.show(t('SavedSuccesfully'), { type: 'success' });
                props.onHide();
            }).catch(e => {
                setSaveInProgress(false);
                alert.show(t('SavedFailed'), { type: 'error' });
            });
        }
        else {
            alert('invalid date')
        }
    }

    const checkFertilizingsButton = () => {
        return !props.fertilizingModalDisabled;
    }

    const openFertilizings = () => {
        props.showFertilizingModal();
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('MarkSowDone')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-dark">
                    <Form>
                        {contentIsLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> : ''}
                        {(combinedSows) && (combinedSows.length > 0) ? combinedSows.map((variety, i) =>
                            variety.objects.map((sow, j) =>
                                <div key={i + j} id={i + j} className="mark-row-style border-bottom">
                                    <div className="mark-row-header">
                                        <b>{sow.combinedData.description}</b>
                                        <br />
                                        {sow.combinedIds.length > 1 ?
                                            <OverlayTrigger trigger="click" rootClose placement="right" overlay={
                                                <Tooltip id="overlay">
                                                    {sow.combinedIds.slice(1).map((p, i) =>
                                                        <div>{p.plotName}</div>
                                                    )}
                                                </Tooltip>
                                            }>
                                                <Button size="sm" variant="light">
                                                    {sow.combinedIds[0].plotName} <b>+</b> <Badge pill bg="secondary">{sow.combinedIds.length - 1}</Badge>
                                                </Button>
                                            </OverlayTrigger>
                                            :
                                            sow.combinedIds.length === 1 ?
                                                <Button size="sm" variant="light" className="disabledButton">
                                                    {sow.combinedIds[0].plotName}
                                                </Button>
                                                : ''}

                                    </div>
                                    <div className="mark-row-content">
                                        <Form.Group controlId="formAmount">
                                            <Form.Label className="text-dark">{t('Amount_ha')}</Form.Label>
                                            <NumberFormat
                                                className="form-control inline-form-field"
                                                value={cCD[i].objects[j].combinedData.amount}
                                                decimalScale={2}
                                                placeholder={`${sow.combinedAmount.min} - ${sow.combinedAmount.max}`}
                                                fixedDecimalScale={false}
                                                onValueChange={(value) => {                                                    if (value.floatValue && round(value.floatValue) !== round(cCD[i].objects[j].combinedData.amount)) {
                                                        if (value.floatValue) {

                                                            sow.combinedData.amount = value.floatValue;
                                                        }

                                                    }                                                }}                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                isAllowed={valueMax}
                                                suffix={'  ' + (sow.combinedData.unitText || 'kg')}
                                                allowNegative={false}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                        <Form.Group controlId="formAmountHa">
                                            <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                            <NumberFormat
                                                className="form-control inline-form-field"
                                                value={cCD[i].objects[j].combinedData ? cCD[i].objects[j].combinedData.amount * cCD[i].objects[j].combinedArea : 0}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(value) => {
                                                    if (value.floatValue && round(value.floatValue) !== round(cCD[i].objects[j].combinedData.amount * cCD[i].objects[j].combinedArea)) {
                                                        if (value.floatValue) {
                                                            if (cCD[i].objects[j].combinedArea > 0) {

                                                                setCCD(updateCalculativeArray(cCD, (value.floatValue / cCD[i].objects[j].combinedArea), i, j));
                                                                sow.combinedData.amount = value.floatValue / sow.combinedArea;
                                                            }
                                                        }
                                                    }
                                                }}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                isAllowed={valueMax}
                                                suffix={'  ' + (sow.combinedData.unitText || 'kg')}
                                                allowNegative={false}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                        <Form.Group controlId="fromDate">
                                            <Form.Label className="text-dark">{t('Date')}</Form.Label>
                                            <Form.Control className="form-control inline-form-field" type="text" onClick={() => {
                                                setDate(sow.combinedData);
                                                setCalendarShow(true);
                                            }} placeholder={formatPlannedDate(sow.combinedData.actionDate, t)}
                                                value={sow.combinedData.sowingDate ? dayJsFormatDate(sow.combinedData.sowingDate) : ''}
                                                onChange={() => { }} />
                                        </Form.Group>
                                    </div>
                                </div>)
                        ) : <div>{infoText}</div>}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Cancel')}</Button>
                    {checkFertilizingsButton() ?
                        <Button variant="success" className="btn-show-more" onClick={() => {
                            saveSows(true);
                        }}>{t('SaveAndOpenFertilizings')}</Button>
                        : ''}
                    <Button variant="success" disabled={saveInProgress} className="btn-show-more" onClick={() => { saveSows(false); }}>{saveInProgress ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : t('Save')}</Button>
                </Modal.Footer>
            </Modal>
            {calendarShow === true ?
                <CalendarModal
                    show={calendarShow}
                    onHide={() => setCalendarShow(false)}
                    onChange={updateDate}
                    selecteddate={date.sowingDate ? dayjs(date.sowingDate).format() : null}
                />
                : ''}
        </>
    )
}

export default MarkSowModal;