import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { getContentWithAction, postContentWithAction, postContentWithAction_, putContentWithAction } from '../../apiConnect';
import { useAlert } from 'react-alert'
import AppContext from '../../AppContext';

import '../../css/general.css'

const FeatureSelectionModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [coordinates, setCoordinates] = useState(null);
    const [buttonWriteShow, setButtonWriteShow] = useState(false); // allowed save btn disabled
    const [showInAllYears, setShowInAllYears] = useState(false);

    const alert = useAlert();

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}).then((result) => {
            setButtonWriteShow(result);
        });

        if (props.mapEvent) {
            if (!props.mapEvent) return;
            setCoordinates(props.mapEvent.coordinates);
            setShowInAllYears(props.mapEvent.feature.Visibility);
        }

        // Add this to prevent a bug where modal closes when clicking a map feature
        // this happens because click happens an extra time and calls props.onHide
        // TODO: Fix the bug later?
        setTimeout(() => { props.allowHide() }, 250);

    }, [props.mapEvent, auth]);

    const saveVisibility = () => {
        putContentWithAction(auth.getAccessToken(), 'plotOperation', 'updateFeatureVisibility', {
            featureType: props.mapEvent.feature.FeatureType,
            wktGeometry: props.mapEvent.feature.WktGeometry,
            Note: props.mapEvent.feature.Note,
            fieldNo: props.mapEvent.feature.fFeldNo,
            amount: props.mapEvent.feature.Amount,
            unitType: props.mapEvent.feature.UnitType,
            farmYear: props.mapEvent.feature.FarmYear,
            featureGeometryId: props.mapEvent.feature.FeatureGeometryId,
            visibility: showInAllYears
        }).then(() => {
            if (props.mapEvent) {
                props.saveFeature(showInAllYears);
            }
            else {
                props.setRefresh(Date.now());
            }
            alert.show(t('SavedSuccesfully'), { type: 'success' });
        }).catch(e => {
            alert.show(t('SavedFailed'), { type: 'error' });
        });
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} >
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {t('Observations')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formCoordinates">
                            <Form.Label className="text-dark highlighted-observation">{props.mapEvent.feature.Note} - {props.mapEvent.feature.FarmYear}</Form.Label>
                            <div className="row">
                                <div>
                                    {coordinates ? 
                                        <Form.Label className="text-dark coordinates-label">
                                            {t('LocationDefined')} <i>({coordinates.wgs.longitude},   {coordinates.wgs.latitude})</i>
                                        </Form.Label>
                                        : ""}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className="show-in-all-years-switch">
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    label={t('ShowInAllYears')}
                                    className="mobi-custom-switch"
                                    checked={showInAllYears}
                                    onChange={(event) => { setShowInAllYears(!showInAllYears) }}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={() => { props.onHide(); }}>{t('Cancel')}</Button>

                    {buttonWriteShow === true ?
                        <Button variant="success" className="btn-show-more" onClick={() => { saveVisibility(); }}>{t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FeatureSelectionModal;