export const MapConfig = {
    CLUSTER_SCALE_THRESHOLD: 24000,
    FEATURE_LAYER_MAX_SCALE_THRESHOLD: 12000,
    ELEVATION_MODEL_ZOOM_THRESHOLD: 5,
    MLOY_GEOSERVER_WFS_VERSION: "1.0.0",
    MLOY_GEOSERVER_NAMESPACE: "http://www.mtech.fi",
    MML_WMS_VERSION: "1.1.1",
    MML_COPYRIGHT: "MML",
    MTECH_COPYRIGHT: "Mtech",
    WMTS_API: "map/wmts",
    WMS_API: "api/map/wms?",
    WFS_API: "api/map/wfs",
    GEO_API: "api/map",
    PROJECTION: "EPSG:3067",
    MODIFICATOR_NAME: "wisukartta",

    RESOLUTIONS: [8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5],
    EXTENT: [-548576, 6291456, 1548576, 8388608],
    MATRIXSET: 'EPSG_3857',
    WMS_VERSION: '1.3.0',
    FEATURE: {
        OBSERVATION: '5304',
        FERTILITY_SAMPLE: '5305',
        FERTILITY_SERIES: '5311',       
        NEW_FERTILITY_SERIES: '5411',
        FERTILITY_SERIES_PLANNED: '5312',
        FERTILITY_SERIES_COLLECTED: '5313',
        NOTE: '5306',
        TREE: '5307',
        ROCK: '5308',
        FLAG: '5353'
    }
}