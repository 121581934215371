import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Stroke, Style } from 'ol/style';

export const addMapPartfieldGeometry = (partfield) => {

    let partfieldStyle = new Style({
        stroke: new Stroke({
            color: 'green',
            width: 3
        })
    });

    let vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(partfield, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        })
    });

    let vectorLayer = new VectorLayer({
        source: vectorSource,
        style: partfieldStyle,
        zIndex: 2
    });

    return vectorLayer;
};
