import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
import { dayJsFormatDate } from '../../commonFunctions.js'

import SampleSetModal from './SampleSetModal';
import MapModal from './MapModal';

import '../../css/general.css'
import '../../css/farmPlot.css'

const ShowSampleSetListModal = (props) => {
    const { t } = useTranslation();

    const [sampleSets, setSampleSets] = useState([]);
    const [plannedSampleSets, setPlannedSampleSets] = useState([]);
    const [selectedSampleSetId, setSelectedSampleSetId] = useState([]);
    const [selectSampleSetEvent, setSelectSampleSetEvent] = useState({ time: null, selectedElement: null });
    const [sampleSetModal, setSampleSetModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [mapModal, setMapModalShow] = useState(false);
    const [checkedSets, setCheckedSets] = useState([])
    const [checkedPlannedSets, setCheckedPlannedSets] = useState([])
    const [collected, setCollected] = useState(false);
    const [closebuttonActive, setClosebuttonActive] = useState(false);

    useEffect(() => {
        setCheckedPlannedSets([]);
        setCheckedSets([]);
        if (props.sampleSetsCollected) {
            setSampleSets(props.sampleSetsCollected);
            sampleSetUpdater(props.sampleSetsCollected);
        }
        if (props.sampleSetsPlanned) {
            setPlannedSampleSets(props.sampleSetsPlanned);
            setPlannedSampleSets(props.sampleSetsPlanned);
        }
    }, [props.sampleSetsCollected, props.sampleSetsPlanned]);

    const sampleSetUpdater = (sampleList) => {
        const chosenSampleSet = selectedSampleSetId ? sampleList.find(x => x.id === selectedSampleSetId) : sampleList[0];
        if (chosenSampleSet)
            setSelectSampleSetEvent({ time: Date.now(), selectedElement: chosenSampleSet });
        else {
            setSampleSetModalShow(false);
            setIsLoading(false);
        }
        if (!sampleSetModal)
            setIsLoading(false);
    }
    const sampleSetPlannedClickHandler = (sampleSet) => {
        setIsLoading(true);
        setCollected(false);
        setSelectedSampleSetId(sampleSet.id);
        setSelectSampleSetEvent({ time: Date.now(), selectedElement: sampleSet });
        setSampleSetModalShow(true);
    }

    const sampleSetClickHandler = (sampleSet) => {
        setIsLoading(true);
        setCollected(true);
        setSelectedSampleSetId(sampleSet.id);
        setSelectSampleSetEvent({ time: Date.now(), selectedElement: sampleSet });
        setSampleSetModalShow(true);
    }

    const deleteSets = () => {
        setIsLoading(true);
        const combinedChecked = checkedSets.concat(checkedPlannedSets);
        props.refreshAndHandleOperationsSet(undefined, combinedChecked);
    }

    const handleCheckboxSelectionPlannedSets = (e, p) => {
        if (checkedPlannedSets.includes(p.id)) {
            var checked = [...checkedPlannedSets.filter(c => c !== p.id)];
            setCheckedPlannedSets(checked);
        }
        else {
            var checked = [...checkedPlannedSets];
            checked.push(p.id);
            setCheckedPlannedSets(checked);
        }
    }

    const handleCheckboxSelectionSets = (e, p) => {
        if (checkedSets.includes(p.id)) {
            var checked = [...checkedSets.filter(c => c !== p.id)];
            setCheckedSets(checked);
        }
        else {
            var checked = [...checkedSets];
            checked.push(p.id);
            setCheckedSets(checked);
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                {(plannedSampleSets.length) ?
                    <><Modal.Header closeButton>
                        <Modal.Title className="text-dark">{t('SamplePlans')}</Modal.Title>
                    </Modal.Header><Modal.Body>
                            {(isLoading) ? <Spinner animation="border" variant="success" />
                                :
                                <ListGroup>
                                    {(plannedSampleSets) ? plannedSampleSets.map(p => <div className={"sampleRowStyle"} key={p.id}>
                                        <Form.Check className={"sampleRow-checkbox"}
                                            type='checkbox'
                                            value={checkedPlannedSets[p.id]}
                                            name={p.sampleDate}
                                            checked={checkedPlannedSets.some(c => c === p.id)}
                                            onChange={(e) => handleCheckboxSelectionPlannedSets(e, p)} />
                                        <ListGroup.Item
                                            className={"sampleItemStyle"}
                                            key={p.id}
                                            action onClick={() => sampleSetPlannedClickHandler(p)}>
                                            <div className='row-icon-right'><Icon icon={ICONS.ARROW_RIGHT} color='gray' size='36' /></div>
                                            <div><b>{dayJsFormatDate(p.sampleDate)}</b></div>
                                            <div className='row-subtext'>
                                                {p.plotCount} {t('Plots2')} - {p.sampleCount} {t('Samples')}
                                            </div>
                                        </ListGroup.Item>
                                    </div>
                                    ) : ""}
                                </ListGroup>}
                        </Modal.Body></> : null}
                {(sampleSets.length) ?
                    <><Modal.Header closeButton={plannedSampleSets.length ? false : true}>
                        <Modal.Title className="text-dark">{t('SampleSetsCollected')}</Modal.Title>
                    </Modal.Header><Modal.Body>
                            {(isLoading) ? <Spinner animation="border" variant="success" />
                                :
                                <ListGroup>
                                    {(sampleSets) ? sampleSets.map(p => <div className={"sampleRowStyle"} key={p.id}>
                                        <Form.Check className={"sampleRow-checkbox"}
                                            type='checkbox'
                                            value={checkedSets[p.id]}
                                            name={p.sampleDate}
                                            checked={checkedSets.some(c => c === p.id)}
                                            onChange={(e) => handleCheckboxSelectionSets(e, p)} />
                                        <ListGroup.Item
                                            className={"sampleItemStyle"}
                                            key={p.id}
                                            action onClick={() => sampleSetClickHandler(p)}>
                                            <div className='row-icon-right'><Icon icon={ICONS.ARROW_RIGHT} color='gray' size='36' /></div>
                                            <div><b>{dayJsFormatDate(p.sampleDate)}</b></div>
                                            <div className='row-subtext'>
                                                {p.plotCount} {t('Plots2')} - {p.sampleCount} {t('Samples')}
                                            </div>
                                        </ListGroup.Item>
                                    </div>
                                    ) : ""}
                                </ListGroup>}
                        </Modal.Body></> : null}
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant='outline-danger' className="footer-button-left" disabled={!checkedPlannedSets.length && !checkedSets.length} onClick={() => deleteSets()}>{t('Delete')}</Button>
                    <Button variant="success" className="btn-show-options" onClick={() => { setMapModalShow(true); }}>{t('CreateSampleSet')}</Button>
                </Modal.Footer>
            </Modal>
            {sampleSetModal === true ?
                <SampleSetModal
                    show={sampleSetModal}
                    onHide={() => {
                        setSampleSetModalShow(false);
                        setIsLoading(false);
                    }}
                    collected={collected}
                    selectEvent={selectSampleSetEvent}
                    refreshAndHandleOperationsSamples={(operation) => {
                        if (operation[0] || operation[1] || operation[2]) {
                            props.refreshAndHandleOperationsSamples(operation)
                        }
                    }}
                    refreshAndHandleOperationsSet={(operation) => {
                        if (operation) {
                            props.refreshAndHandleOperationsSet(undefined, operation);
                        }
                    }}
                />
                : ''}
            {mapModal === true ?
                <MapModal
                    show={mapModal}
                    newSampleSet={true}
                    collected={true}
                    onHide={(additions) => {
                        setMapModalShow(false);
                        if (additions) {
                            props.refreshAndHandleOperationsSet(additions);
                        }
                    }}
                />
                : ''}
        </>
    )
}

export default ShowSampleSetListModal;