import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup'
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import NavMenu from '../NavMenu';
import TaskTypeSelectionListItem from './TaskTypeSelectionListItem';
import AppContext from '../../AppContext';
import { getContentWithAction } from '../../apiConnect';

const TaskTypeSelection = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [taskList, setTaskList] = useState({ fertilizations: [], sowingFertilizations: [], plantProtections: [], soilTillages: [] });
    const [isLoading, setIsLoading] = useState(true);

    const dataCallbackHandler = (data) => {
        setTaskList(data);
        setIsLoading(false);
    }

    useEffect(() => {
        getContentWithAction(auth.getAccessToken(), 'taskViewer', 'list', {}).then(dataCallbackHandler);
    }, []);

    const taskTypeResolver = (taskList) => {
        return (taskList.length > 0) ? taskList[0].taskType : 0;
    }

    return (
        <Container>
            <NavMenu {...props} />
            <Card>
                <Card.Header className="text-dark">
                    {t('SelectTaskType')}
                </Card.Header>
                <Card.Body>
                    <ListGroup>
                        <TaskTypeSelectionListItem name={t('Fertilizing')} taskList={taskList.fertilizations} callBack={props.callBack} taskType={taskTypeResolver(taskList.fertilizations)} isLoading={isLoading} />
                        <TaskTypeSelectionListItem name={t('SowingFertilization')} taskList={taskList.sowingFertilizations} callBack={props.callBack} taskType={taskTypeResolver(taskList.sowingFertilizations)} isLoading={isLoading} />
                        <TaskTypeSelectionListItem name={t('PlantProtection')} taskList={taskList.plantProtections} callBack={props.callBack} taskType={taskTypeResolver(taskList.plantProtections)} isLoading={isLoading} />
                        <TaskTypeSelectionListItem name={t('SoilTillage')} taskList={taskList.soilTillages} callBack={props.callBack} taskType={taskTypeResolver(taskList.soilTillages)} isLoading={isLoading} />
                    </ListGroup>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TaskTypeSelection;

