import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'

const AlertTemplate = ({ style, options, message, close }) => {
    const [progress, setProgress] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 12);
        }, 100);
        return () => clearInterval(interval);
    }, [seconds]);

    useEffect(() => {
        let calculateProgress = (seconds / (options.timeout / 10)) * 100;
        setProgress(calculateProgress);
    }, [seconds]);

    return (
        <>
            {
                options.style === "alternative"
                    ? <div className="custom-alert-pesticideoperation">
                        {/* <ProgressBar variant={options.type === "error" ? "warning" : "success"} className="alert-timer" />*/}
                        <div style={style} className="alert-box">
                            <span className={options.type === 'info' ? "alert-message-info" : "alert-message"}>
                                {options.type === 'info' && <span className="alert-icon"><Icon icon={ICONS.INFO} color='blue' size='28' /></span>}
                                {options.type === 'success' && <span className="alert-icon"><Icon icon={ICONS.SUCCESS} color='green' size='28' /> </span>}
                                {options.type === 'error' && <span className="alert-icon"><Icon icon={ICONS.WARNING} color='yellow' size='28' /></span>}
                                {message}
                            </span>
                            <button style={style} className={"alert-close alert-close-pesticideoperation-btn btn-close"} onClick={close}></button>
                        </div>

                    </div>

                    : <div className={options.type === "error" ? "custom-alert alert-error" : "custom-alert container"}>
                        <ProgressBar variant={options.type === "error" ? "warning" : "success"} className="alert-timer" now={progress} />
                        <div style={style} className="alert-box">
                            <span className={options.type === 'info' ? "alert-message-info" : "alert-message"}>
                                {options.type === 'info' && <span className="alert-icon"><Icon icon={ICONS.INFO} color='blue' size='26' /></span>}
                                {options.type === 'success' && <span className="alert-icon"><Icon icon={ICONS.SUCCESS} color='green' size='26' /> </span>}
                                {options.type === 'error' && <span className="alert-icon"><Icon icon={ICONS.WARNING} color='yellow' size='26' /></span>}
                                {message}
                            </span>
                            <button style={style} className={options.type === "error" ? "alert-close btn-close alert-close-btn btn-close-white" : "alert-close alert-close-btn btn-close"} onClick={close}></button>
                        </div>
                    </div>
            }
        </>
    )
}

export default AlertTemplate;