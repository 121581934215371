import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { taskViewer_taskStatuses } from './../../constants';
import Stop from './Stop';
import '../../css/taskviewer.css';

const TaskViewerMapFooter = (props) => {
    const { t } = useTranslation();
    const [runState, setRunState] = useState(taskViewer_taskStatuses.planned);
    const [showStopDialogue, setShowStopDialogue] = useState(false);

    useEffect(() => {
        props.sendStatusToMap(runState);
    }, [props, runState]);

    const determineStartPauseText = () => {
        if (runState === taskViewer_taskStatuses.planned) {
            return t('Start');
        } else if (runState === (taskViewer_taskStatuses.paused || taskViewer_taskStatuses.endUnfinished || taskViewer_taskStatuses.ended)) {
            return t('Continue');
        } else {
            return t('Pause');
        }
    }

    const determineStartPauseColor = () => {
        if (runState === taskViewer_taskStatuses.planned) {
            return 'start';
        } else {
            return 'pause';
        }
    }

    const startPause = () => {
        switch (runState) {
            case taskViewer_taskStatuses.planned:
                setRunState(taskViewer_taskStatuses.running);
                break;
            case taskViewer_taskStatuses.running:
                setRunState(taskViewer_taskStatuses.paused);
                break;
            case taskViewer_taskStatuses.paused:
                setRunState(taskViewer_taskStatuses.running);
                break;
            default:
        }
    }

    const stopButtonClick = () => {
        setRunState(taskViewer_taskStatuses.paused);
        setShowStopDialogue(true);
    }

    const setStateFromStopDialogue = (state) => {
        setRunState(state);
        setShowStopDialogue(false);
    }

    const setHideStop = () => {
        setShowStopDialogue(false);
    }

    const showHideStop = () => {
        if (showStopDialogue) {
            return <Stop callBack={setStateFromStopDialogue} taskSpecificData={props.taskSpecificData} closeStop={setHideStop} />;
        } else {
            return <div />
        }
    }

    const determineTaskAmountStyle = () => {
        if (runState === (taskViewer_taskStatuses.paused || taskViewer_taskStatuses.endUnfinished || taskViewer_taskStatuses.ended)) {
            return 'task-amount-disabled';
        } else {
            return '';
        }
    }

    return (
        <>
            <Card id='start-pause' className={determineStartPauseColor()} onClick={() => startPause()}>
                <Card.Body>
                    {determineStartPauseText()}
                </Card.Body>
            </Card>
            <Card id='task-amount' className={determineTaskAmountStyle()} >
                <Card.Body>
                    {props.currentTaskAmount === -1 ? '--' : props.currentTaskAmount + ' ' + props.currentTaskUnit}
                </Card.Body>
            </Card>
            <Card id='stop' onClick={() => stopButtonClick()}>
                <Card.Body>
                    {t('Stop')}
                </Card.Body>
            </Card>
            {showHideStop()}
        </>
    );
}

export default TaskViewerMapFooter;