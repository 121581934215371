import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../../css/general.css'

const style = {
    backgroundColor: '#000',
    color: '#fff',
    opacity: '0.9'
};

const ImageShowModal = (props) => {
   

    return (
        <Modal style={style} show={props.show} onHide={props.onHideImage} centered>
            <Modal.Body >
                <div className="wrapper">
                    <div className="box box1">
                        {props.image ?
                            <img src={props.image.thumbnailImage ? `${props.image.thumbnailImage}` : URL.createObjectURL(props.image)} onClick={props.onHideImage} alt="Zoomed mode" />
                            : ''}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ImageShowModal;