//import GeoJSON from 'ol/format/GeoJSON';

import { MapConfig } from './mapConfig';


/** Private Map Models Class */
//OpenLayers.Renderer.symbol.flag = [1, 0, 1, 13, 0, 13, 1, 14, 2, 13, 1, 13, 1, 8, 8, 4, 1, 0];
//OpenLayers.Renderer.symbol.pipette = [7, 14, 8, 13, 8, 6, 9, 5.5, 10, 5, 10.5, 4, 10.5, 3, 10, 2, 9, 1.5, 5, 1.5, 4, 2, 3.5, 3, 3.5, 4, 4, 5, 5, 5.5, 6, 6, 6, 13, 7, 14];
//OpenLayers.Renderer.symbol.note = [5, 3, 5, 10, 7, 10, 7, 7, 10, 7, 7, 10, 10, 7, 10, 3, 5, 3];
//OpenLayers.Renderer.symbol.bug = [2, 2, 2, 3, 4, 5, 4, 7, 2, 7, 4, 7, 4, 9, 2, 11, 2, 12, 2, 11, 4, 9, 6, 11, 8, 11, 10, 9, 12, 11, 12, 12, 12, 11, 10, 9, 10, 7, 12, 7, 10, 7, 10, 5, 12, 3, 12, 2, 12, 3, 10, 5, 9, 4, 8, 4, 9, 3, 9, 2, 10, 1, 9, 2, 8, 1.5, 6, 1.5, 5, 2, 4, 1, 5, 2, 5, 3, 6, 4, 5, 4, 4, 5, 2, 3, 4, 5];
//OpenLayers.Renderer.symbol.tree = [7, 0, 4, 3, 6, 3, 3, 6, 5, 6, 1, 10, 6, 10, 6, 13, 8, 13, 8, 10, 13, 10, 9, 6, 11, 6, 8, 3, 10, 3, 7, 0];
//OpenLayers.Renderer.symbol.rock = [5, 2, 3, 4, 3, 7, 5, 9, 10, 9, 12, 7, 12, 5, 9, 2, 5, 2];
//OpenLayers.Renderer.symbol.farm = [1, 6, 7, 1, 9.5, 3, 9.5, 1.5, 11, 1.5, 11, 4, 13, 6, 11, 6, 11, 11, 7, 11, 7, 8, 5, 8, 5, 11, 3, 11, 3, 6, 1, 6];


export const MapModels = {

    Attribution: {
        SYKE: MapConfig.SYKE_COPYRIGHT,
        MTECH: MapConfig.MTECH_COPYRIGHT
    },

    /** OpenLayers StyleMap definitions
     *
     * @type {{basePlotDefault: OpenLayers.StyleMap, plotDefault: OpenLayers.StyleMap}}
     */
    //StyleMap: {

    //    basePlotDefault: new OpenLayers.StyleMap({
    //        "default": new OpenLayers.Style({
    //            fillColor: "red",
    //            fillOpacity: 0,
    //            strokeColor: "red",
    //            strokeWidth: 1.5,
    //            pointRadius: 5,
    //            strokeOpacity: 0.8,
    //            cursor: "pointer",
    //            fontFamily: 'Arial'
    //        },
    //            {
    //                rules: [

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 2500,
    //                        minScaleDenominator: 1,
    //                        symbolizer: {
    //                            label: "${BasePlotName}",
    //                            fontColor: "#6DC7FF",
    //                            fontSize: "14px",
    //                            labelYOffset: 9,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 5000,
    //                        minScaleDenominator: 2500,
    //                        symbolizer: {
    //                            label: "${BasePlotName}",
    //                            fontColor: "#6DC7FF",
    //                            fontSize: "12px",
    //                            labelYOffset: 6,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 9000,
    //                        minScaleDenominator: 5000,
    //                        symbolizer: {
    //                            label: "${BasePlotName}",
    //                            fontColor: "#6DC7FF",
    //                            fontSize: "11px",
    //                            labelYOffset: 10,
    //                            labelOutlineColor: "#111",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 12000,
    //                        minScaleDenominator: 9000,
    //                        symbolizer: {
    //                            label: "${BasePlotName}",
    //                            fontColor: "#6DC7FF",
    //                            fontSize: "10px",
    //                            labelYOffset: -4,
    //                            labelOutlineColor: "#222",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 24000,
    //                        minScaleDenominator: 12000,
    //                        symbolizer: {
    //                            strokeColor: "red",
    //                            strokeWidth: 1.5
    //                        }
    //                    })
    //                ]
    //            }),
    //        select: new OpenLayers.Style({
    //            fillColor: "#fc0",
    //            strokeColor: "#1C7AFF",
    //            fillOpacity: 0
    //            /*graphicZIndex: 10*/
    //        }),
    //        vertex: new OpenLayers.Style({
    //            strokeColor: "#FF9400",
    //            fillColor: "#000",
    //            label: "",
    //            fillOpacity: 0.9,
    //            strokeOpacity: 0.9,
    //            strokeWidth: 3,
    //            pointRadius: 5,
    //            cursor: 'pointer'
    //        }),
    //        temporary: new OpenLayers.Style({
    //            pointRadius: 4,
    //            graphicName: "square",
    //            fillColor: "white",
    //            fillOpacity: 1,
    //            strokeWidth: 2,
    //            strokeOpacity: 1,
    //            strokeColor: "#333333"
    //        })
    //    }),

    //    basePlotVectors: new OpenLayers.StyleMap({
    //        "default": new OpenLayers.Style({
    //            fillColor: "red",
    //            fillOpacity: 0,
    //            strokeColor: "red",
    //            strokeWidth: 1.5,
    //            pointRadius: 5,
    //            strokeOpacity: 0.8,
    //            cursor: "pointer"
    //        },
    //            {
    //                rules: [
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 24000,
    //                        minScaleDenominator: 1,
    //                        symbolizer: {
    //                            strokeColor: "red",
    //                            strokeWidth: 1.5
    //                        }
    //                    })
    //                ]
    //            })
    //    }),

    //    basePlotLabels: new OpenLayers.StyleMap({
    //        "default": new OpenLayers.Style({
    //            fillOpacity: 0,
    //            strokeOpacity: 0,
    //            cursor: "pointer",
    //            fontFamily: 'Arial'
    //        },
    //            {
    //                rules: [

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 2500,
    //                        minScaleDenominator: 1,
    //                        symbolizer: {
    //                            label: "${BasePlotName}",
    //                            fontColor: "#6DC7FF",
    //                            fontSize: "14px",
    //                            labelYOffset: 9,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 5000,
    //                        minScaleDenominator: 2500,
    //                        symbolizer: {
    //                            label: "${BasePlotName}",
    //                            fontColor: "#6DC7FF",
    //                            fontSize: "12px",
    //                            labelYOffset: 6,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 9000,
    //                        minScaleDenominator: 5000,
    //                        symbolizer: {
    //                            label: "${BasePlotName}",
    //                            fontColor: "#6DC7FF",
    //                            fontSize: "11px",
    //                            labelYOffset: 10,
    //                            labelOutlineColor: "#111",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 12000,
    //                        minScaleDenominator: 9000,
    //                        symbolizer: {
    //                            label: "${BasePlotName}",
    //                            fontColor: "#6DC7FF",
    //                            fontSize: "10px",
    //                            labelYOffset: -4,
    //                            labelOutlineColor: "#222",
    //                            labelOutlineWidth: 2
    //                        }
    //                    })
    //                ]
    //            })
    //    }),

    //    plotDefault: new OpenLayers.StyleMap({
    //        "default": new OpenLayers.Style({
    //            fillColor: "green",
    //            fillOpacity: 0,
    //            strokeColor: "#009700",
    //            strokeOpacity: 1,
    //            strokeWidth: 2,
    //            strokeDashstyle: "solid",
    //            cursor: "pointer",
    //            fontFamily: 'Verdana',
    //        },
    //            {
    //                rules: [

    //                    new OpenLayers.Rule({
    //                        symbolizer: {
    //                            strokeColor: "#FFBB3D",
    //                            fillOpacity: 0
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                            property: "New",
    //                            value: true
    //                        })
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 2500,
    //                        minScaleDenominator: 1,
    //                        symbolizer: {
    //                            label: "${PlotLetter} (${Area} ha)",
    //                            labelYOffset: -14,//-8,
    //                            fontSize: "14px",
    //                            fontColor: "#00C91A",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 5000,
    //                        minScaleDenominator: 2500,
    //                        symbolizer: {
    //                            label: "${PlotLetter} (${Area} ha)",
    //                            labelYOffset: -14,//-8,
    //                            fontSize: "12px",
    //                            fontColor: "#00C91A",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 9000,
    //                        minScaleDenominator: 5000,
    //                        symbolizer: {
    //                            label: "${PlotLetter} (${Area} ha)",
    //                            labelYOffset: -8,
    //                            fontSize: "11px",
    //                            fontColor: "#00C91A",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2
    //                        }
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 250000,
    //                        minScaleDenominator: 9000,
    //                        symbolizer: {
    //                            strokeColor: "#009700"
    //                        }
    //                    })

    //                ]
    //            }),
    //        select: new OpenLayers.Style({
    //            fillColor: "#fc0",
    //            strokeColor: "#FFC721",
    //            fillOpacity: 0,
    //            fontColor: "#FFC721"
    //        }),
    //        vertex: new OpenLayers.Style({
    //            strokeColor: "#1C7AFF",
    //            fillColor: "#000",
    //            label: "",
    //            fillOpacity: 0.9,
    //            strokeOpacity: 0.9,
    //            strokeWidth: 3,
    //            pointRadius: 5,
    //            cursor: 'pointer'
    //        }), // 00ccff
    //        temporary: new OpenLayers.Style({
    //            pointRadius: 4,
    //            graphicName: "square",
    //            fillColor: "white",
    //            fillOpacity: 1,
    //            strokeWidth: 2,
    //            strokeOpacity: 1,
    //            strokeColor: "#333333"
    //        })
    //    }),

    //    plotThemePlants: new OpenLayers.StyleMap({
    //        "default": new OpenLayers.Style({
    //            fillColor: "white",
    //            fillOpacity: 0.5,
    //            strokeColor: "#008900",
    //            strokeOpacity: 1,
    //            strokeWidth: 2,
    //            strokeDashstyle: "solid",
    //            cursor: "pointer",
    //            fontFamily: 'Verdana'
    //        },
    //            {
    //                rules: [

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 2500,
    //                        minScaleDenominator: 1,
    //                        symbolizer: {
    //                            label: "${Area} ha",
    //                            labelYOffset: -14,
    //                            fontSize: "14px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "white"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                            property: "PlantCode",
    //                            value: "00000"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 2500,
    //                        minScaleDenominator: 1,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -14,
    //                            fontSize: "14px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "yellow"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.BETWEEN,
    //                            property: "PlantCode",
    //                            lowerBoundary: "10000",
    //                            upperBoundary: "20999"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 2500,
    //                        minScaleDenominator: 1,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -14,
    //                            fontSize: "14px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "#0DCE00"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.BETWEEN,
    //                            property: "PlantCode",
    //                            lowerBoundary: "60000",
    //                            upperBoundary: "69999"
    //                        })
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 2500,
    //                        minScaleDenominator: 1,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -14,
    //                            fontSize: "14px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "#BC4ACE"
    //                        },
    //                        elseFilter: true
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 5000,
    //                        minScaleDenominator: 2500,
    //                        symbolizer: {
    //                            label: "${Area} ha",
    //                            labelYOffset: -14,
    //                            fontSize: "12px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "white"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                            property: "PlantCode",
    //                            value: "00000"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 5000,
    //                        minScaleDenominator: 2500,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -14,
    //                            fontSize: "12px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "yellow"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.BETWEEN,
    //                            property: "PlantCode",
    //                            lowerBoundary: "10000",
    //                            upperBoundary: "20999"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 5000,
    //                        minScaleDenominator: 2500,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -14,
    //                            fontSize: "11px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "#0DCE00"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.BETWEEN,
    //                            property: "PlantCode",
    //                            lowerBoundary: "60000",
    //                            upperBoundary: "69999"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 5000,
    //                        minScaleDenominator: 2500,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -14,
    //                            fontSize: "11px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "#BC4ACE"
    //                        },
    //                        elseFilter: true
    //                    }),



    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 9000,
    //                        minScaleDenominator: 5000,
    //                        symbolizer: {
    //                            label: "${Area} ha",
    //                            labelYOffset: -8,
    //                            fontSize: "11px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "white"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                            property: "PlantCode",
    //                            value: "00000"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 9000,
    //                        minScaleDenominator: 5000,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -8,
    //                            fontSize: "11px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "yellow"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.BETWEEN,
    //                            property: "PlantCode",
    //                            lowerBoundary: "10000",
    //                            upperBoundary: "20999"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 9000,
    //                        minScaleDenominator: 5000,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -8,
    //                            fontSize: "11px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "#0DCE00"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.BETWEEN,
    //                            property: "PlantCode",
    //                            lowerBoundary: "60000",
    //                            upperBoundary: "69999"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 9000,
    //                        minScaleDenominator: 5000,
    //                        symbolizer: {
    //                            label: "${PlantName} \n ${Area} ha",
    //                            labelYOffset: -8,
    //                            fontSize: "11px",
    //                            fontColor: "#fff",
    //                            fontOpacity: 1,
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 2,
    //                            fillColor: "#BC4ACE"
    //                        },
    //                        elseFilter: true
    //                    }),


    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 24000,
    //                        minScaleDenominator: 9000,
    //                        symbolizer: {
    //                            strokeWidth: 1.5,
    //                            fillColor: "white"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                            property: "PlantCode",
    //                            value: "00000"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 24000,
    //                        minScaleDenominator: 9000,
    //                        symbolizer: {
    //                            strokeWidth: 1.5,
    //                            fillColor: "yellow"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.BETWEEN,
    //                            property: "PlantCode",
    //                            lowerBoundary: "10000",
    //                            upperBoundary: "20999"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 24000,
    //                        minScaleDenominator: 9000,
    //                        symbolizer: {
    //                            strokeWidth: 1.5,
    //                            fillColor: "#0DCE00"
    //                        },
    //                        filter: new OpenLayers.Filter.Comparison({
    //                            type: OpenLayers.Filter.Comparison.BETWEEN,
    //                            property: "PlantCode",
    //                            lowerBoundary: "60000",
    //                            upperBoundary: "69999"
    //                        })
    //                    }),
    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 24000,
    //                        minScaleDenominator: 9000,
    //                        symbolizer: {
    //                            strokeWidth: 1.5,
    //                            fillColor: "#BC4ACE"
    //                        },
    //                        elseFilter: true
    //                    }),

    //                    new OpenLayers.Rule({
    //                        maxScaleDenominator: 500000,
    //                        minScaleDenominator: 24000,
    //                        symbolizer: {
    //                            graphicName: 'farm',
    //                            pointRadius: 18,
    //                            fillColor: "red",
    //                            fillOpacity: 0.8,
    //                            strokeColor: "black",
    //                            strokeWidth: 2,
    //                            strokeOpacity: 0.8,
    //                            label: "${count}",
    //                            labelYOffset: 2,
    //                            fontColor: "black",
    //                            fontSize: "13px",
    //                            fontWeight: "bold",
    //                            labelOutlineColor: "black",
    //                            labelOutlineWidth: 0
    //                        }
    //                    })

    //                ]
    //            }),
    //        select: new OpenLayers.Style({
    //            fillColor: "#fc0",
    //            strokeColor: "#FFC721",
    //            fillOpacity: 0.2,
    //            fontColor: "#FFC721"
    //            // labelOutlineWidth: 2labelOutlineColor: "#fff"
    //            /*graphicZIndex: 10*/
    //        })

    //    }),

    //    featuresDefault: function (featureAttributes) {

    //        var getFeatureAttribute = function (id, property) {
    //            var feature = _.find(featureAttributes, function (attr) { return attr.FeatureGeometryId == id });
    //            return feature[property];
    //        }


    //        return new OpenLayers.StyleMap({
    //            "default": new OpenLayers.Style({
    //                fillColor: "white",
    //                fillOpacity: 0.9,
    //                strokeWidth: 2,
    //                strokeOpacity: 0.9,
    //                fontColor: "white",
    //                fontFamily: 'Verdana',
    //                labelOutlineColor: "black",
    //                labelOutlineWidth: 2

    //            },
    //                {
    //                    context: {
    //                        SampleIdAndDate: function (feature) {
    //                            if (!featureAttributes.length) return "";
    //                            return getFeatureAttribute(feature.attributes.FeatureGeometryID, "SampleId") + " / " + getFeatureAttribute(feature.attributes.FeatureGeometryID, "SampleDate");
    //                        }
    //                    },

    //                    rules: [

    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            minScaleDenominator: 1,
    //                            symbolizer: {
    //                                strokeColor: "black",
    //                                fillColor: "#C10000",
    //                                graphicName: 'bug',
    //                                pointRadius: 10
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.OBSERVATION
    //                            })
    //                        }),
    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD,
    //                            minScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            symbolizer: {
    //                                strokeColor: "black",
    //                                fillColor: "#C10000",
    //                                graphicName: 'bug',
    //                                pointRadius: 7
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.OBSERVATION
    //                            })
    //                        }),

    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            minScaleDenominator: 1,
    //                            symbolizer: {
    //                                externalGraphic: 'src/img/grain.png',
    //                                graphicWidth: 15,
    //                                graphicHeight: 35,
    //                                graphicYOffset: -35,
    //                                fontSize: "12px",
    //                                labelYOffset: -10,
    //                                label: "${SampleIdAndDate}"
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.FERTILITY_SAMPLE
    //                            })
    //                        }),
    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD,
    //                            minScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            symbolizer: {
    //                                externalGraphic: 'src/img/grain.png',
    //                                graphicWidth: 12,
    //                                graphicHeight: 31,
    //                                graphicYOffset: -25,
    //                                fontSize: "10px",
    //                                labelYOffset: -10,
    //                                label: "${SampleIdAndDate}"
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.FERTILITY_SAMPLE
    //                            })
    //                        }),

    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            minScaleDenominator: 1,
    //                            symbolizer: {
    //                                label: "${Note}",
    //                                labelYOffset: -20,
    //                                strokeColor: "black",
    //                                fillColor: "yellow",
    //                                graphicName: 'note',
    //                                pointRadius: 9,

    //                                fontColor: "#fff",
    //                                fontOpacity: 1,
    //                                labelOutlineColor: "black",
    //                                labelOutlineWidth: 2


    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.NOTE
    //                            })
    //                        }),
    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 2),
    //                            minScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            symbolizer: {
    //                                label: "${Note}",
    //                                labelYOffset: -16,
    //                                strokeColor: "black",
    //                                fillColor: "yellow",
    //                                graphicName: 'note',
    //                                pointRadius: 7,


    //                                fontColor: "#fff",
    //                                fontOpacity: 1,
    //                                labelOutlineColor: "black",
    //                                labelOutlineWidth: 2

    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.NOTE
    //                            })
    //                        }),
    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD,
    //                            minScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 2),
    //                            symbolizer: {
    //                                label: "",
    //                                labelYOffset: -20,
    //                                strokeColor: "black",
    //                                fillColor: "yellow",
    //                                graphicName: 'note',
    //                                pointRadius: 7,
    //                                labelOutlineWidth: 0

    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.NOTE
    //                            })
    //                        }),

    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            minScaleDenominator: 1,
    //                            symbolizer: {
    //                                externalGraphic: 'src/img/tree.png',
    //                                graphicWidth: 26,
    //                                graphicHeight: 29,
    //                                graphicYOffset: -29
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.TREE
    //                            })
    //                        }),
    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD,
    //                            minScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            symbolizer: {
    //                                externalGraphic: 'src/img/tree.png',
    //                                graphicWidth: 13,
    //                                graphicHeight: 15,
    //                                graphicYOffset: -15
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.TREE
    //                            })
    //                        }),

    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            minScaleDenominator: 1,
    //                            symbolizer: {
    //                                strokeColor: "black",
    //                                fillColor: "grey",
    //                                graphicName: 'rock',
    //                                pointRadius: 8
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.ROCK
    //                            })
    //                        }),
    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD,
    //                            minScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            symbolizer: {
    //                                strokeColor: "black",
    //                                fillColor: "grey",
    //                                graphicName: 'rock',
    //                                pointRadius: 5
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.ROCK
    //                            })
    //                        }),

    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            minScaleDenominator: 1,
    //                            symbolizer: {
    //                                externalGraphic: 'src/img/flag.png',
    //                                graphicWidth: 16,
    //                                graphicHeight: 29,
    //                                graphicYOffset: -29,
    //                                graphicXOffset: 0
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.FLAG
    //                            })
    //                        }),
    //                        new OpenLayers.Rule({
    //                            maxScaleDenominator: MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD,
    //                            minScaleDenominator: (MapConfig.FEATURE_LAYER_MAX_SCALE_THRESHOLD / 3),
    //                            symbolizer: {
    //                                externalGraphic: 'src/img/flag.png',
    //                                graphicWidth: 11,
    //                                graphicHeight: 22,
    //                                graphicYOffset: -22,
    //                                graphicXOffset: 0
    //                            },
    //                            filter: new OpenLayers.Filter.Comparison({
    //                                type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                                property: "FeatureType",
    //                                value: MapConfig.FEATURE.FLAG
    //                            })
    //                        }),

    //                        new OpenLayers.Rule({
    //                            symbolizer: {
    //                                label: "",
    //                                fillOpacity: 0,
    //                                strokeOpacity: 0
    //                            },
    //                            elseFilter: true
    //                        })
    //                    ]
    //                }


    //            ),
    //            select: new OpenLayers.Style({
    //            }),
    //            hide: new OpenLayers.Style({
    //                label: "",
    //                fillOpacity: 0,
    //                strokeOpacity: 0
    //            })
    //        })
    //    },

    //    sketchLayer: new OpenLayers.StyleMap({

    //        'default': new OpenLayers.Style({
    //            pointRadius: 6,
    //            fillColor: "#FF1414",
    //            fillOpacity: 1,
    //            strokeWidth: 45,
    //            strokeOpacity: 0.25,
    //            strokeColor: "white",
    //            graphicName: 'circle',
    //            cursor: "pointer"
    //        }),

    //        'temporary': new OpenLayers.Style(null, {
    //            rules: [
    //                new OpenLayers.Rule({
    //                    symbolizer: {
    //                        'Point': {
    //                            pointRadius: 6,
    //                            fillColor: "#FF1414",
    //                            fillOpacity: 1,
    //                            strokeWidth: 45,
    //                            strokeOpacity: 0.25,
    //                            strokeColor: "white",
    //                            graphicName: 'circle',
    //                            cursor: "pointer"
    //                        }
    //                    }
    //                })
    //            ]
    //        })
    //    })

    //},

    /** OpenLayers Filter Definitions
     *
     * @type {{filterByFarmIDAndYear: Function}}
     */
    //Filter: {

    //    filterByFarmIDAndYear: function (farmID, year) {
    //        return new OpenLayers.Filter.Logical({
    //            type: OpenLayers.Filter.Logical.AND,
    //            filters: [
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: "FarmID",
    //                    value: farmID
    //                }),
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: "FarmYear",
    //                    value: year
    //                })
    //            ]
    //        })
    //    },

    //    filterByFarmAndFeatureTypeAndProperty: function (farmID, year, featureTypes, featureFilter) {
    //        var typeFilters = [];
    //        var propertyFilters = [];
    //        for (var i = 0; i < featureTypes.length; i++) {
    //            typeFilters.push(
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: "FeatureType",
    //                    value: featureTypes[i]
    //                })
    //            );
    //        }
    //        for (var i = 0; i < featureFilter.values.length; i++) {
    //            propertyFilters.push(
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: featureFilter.property,
    //                    value: featureFilter.values[i]
    //                })
    //            );
    //        }
    //        return new OpenLayers.Filter.Logical({
    //            type: OpenLayers.Filter.Logical.AND,
    //            filters: [
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: "FarmID",
    //                    value: farmID
    //                }),
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: "FarmYear",
    //                    value: year
    //                }),
    //                new OpenLayers.Filter.Logical({
    //                    type: OpenLayers.Filter.Logical.OR,
    //                    filters: propertyFilters
    //                }),
    //                new OpenLayers.Filter.Logical({
    //                    type: OpenLayers.Filter.Logical.OR,
    //                    filters: typeFilters
    //                })
    //            ]
    //        })
    //    },

    //    filterByFarmAndFeatureType: function (farmID, year, featureTypes) {
    //        var filters = [];
    //        for (var i = 0; i < featureTypes.length; i++) {
    //            filters.push(
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: "FeatureType",
    //                    value: featureTypes[i]
    //                })
    //            );
    //        }
    //        return new OpenLayers.Filter.Logical({
    //            type: OpenLayers.Filter.Logical.AND,
    //            filters: [
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: "FarmID",
    //                    value: farmID
    //                }),
    //                new OpenLayers.Filter.Comparison({
    //                    type: OpenLayers.Filter.Comparison.EQUAL_TO,
    //                    property: "FarmYear",
    //                    value: year
    //                }),
    //                new OpenLayers.Filter.Logical({
    //                    type: OpenLayers.Filter.Logical.OR,
    //                    filters: filters
    //                })
    //            ]
    //        })
    //    }
    //},

    /** OpenLayers Protocol definitions
     *
     * @type {{basePlotProtocol: OpenLayers.Protocol.WFS, plotProtocol: OpenLayers.Protocol.WFS}}
     */
    //Protocol: {

    //    basePlotProtocol: function (params, callback) {
    //        return new OpenLayers.Protocol.HTTP({
    //            url: MapConfig.GEO_API + "/baseplots",
    //            params: params,
    //            callback: function (data) {
    //                callback(data);
    //            },
    //            format: new GeoJSON()
    //        });
    //    },
    //    plotProtocol: function (params, callback) {
    //        return new OpenLayers.Protocol.HTTP({
    //            url: MapConfig.GEO_API + "/agrplots",
    //            params: params,
    //            callback: function (data) {
    //                callback(data);
    //            },
    //            format: new GeoJSON()
    //        });
    //    },
    //    featuresProtocol: function (params, callback) {
    //        return new OpenLayers.Protocol.HTTP({
    //            url: MapConfig.GEO_API + "/features",
    //            params: params,
    //            callback: function (data) {
    //                callback(data);
    //            },
    //            format: new GeoJSON()
    //        });
    //    }
    //},

    /** WMS -layer definitions */
    WMSLayer: {
        Sentinel: {
            name: "NDVI",
            url: MapConfig.WMS_API,
            wms_version: MapConfig.WMS_VERSION,
            layer_name: "mtech:sentinel",
            attribution: MapConfig.MTECH_COPYRIGHT
        },
        Korkeusmalli: {
            name: "Korkeusmalli",
            url: MapConfig.WMS_API,
            wms_version: MapConfig.MML_WMS_VERSION,
            layer_name: "mloy:korkeusmalli",
            attribution: MapConfig.MML_COPYRIGHT
        }
    },

    WMTSLayer: {
        Peruskartta: {
            name: "Peruskartta",
            url: MapConfig.WMTS_API + '/nls/taustakartta/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
            layer_name: "mml:taustakartta",
            attribution: MapConfig.MML_COPYRIGHT
        },
        Ortokuva: {
            name: "Ilmakuva",
            url: MapConfig.WMTS_API + '/nls/ortokuva/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
            layer_name: "mml:ortokuva",
            attribution: MapConfig.MML_COPYRIGHT
        },
        Taustakartta: {
            name: "Taustakartta",
            url: MapConfig.WMTS_API + '/nls/taustakartta/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
            layer_name: "mml:taustakartta",
            attribution: MapConfig.MML_COPYRIGHT
        }
    }
}