import React, { useState } from 'react';
import { Modal, ListGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { taskViewer_taskStatuses, taskViewer_stopComponents } from './../../constants';

const StopSelectForm = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(true);

    return (
        <Modal show={show} centered onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title className="text-dark">{t('EndTaskDialogue')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    <ListGroup.Item onClick={() => { props.stopComponentCallBack(taskViewer_stopComponents.endTaskFinished) }} >
                        {t('EndTask')}
                    </ListGroup.Item>
                    <ListGroup.Item onClick={() => { props.stopComponentCallBack(taskViewer_stopComponents.endUnfinishedContinueLater) }} >
                        {t('PauseTaskContinueLater')}
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-danger" onClick={() => props.runStateCallBack(taskViewer_taskStatuses.paused)}>{t('Back')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default StopSelectForm;