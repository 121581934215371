import React from 'react';
import { Form, Col, Modal, Button, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { taskViewer_stopComponents } from './../../constants';

const EndFinishedTask = (props) => {

    const { t } = useTranslation();

    const taskTypeTextMapping = {
        1: 'Fertiliser',
        3: 'Pesticide',
        4: 'SoilTillage'
    }

    const createFormElements = () => {
        if (props.taskSpecificData.taskType === 2) {
            return (
                <>
                    <Form>
                        <Form.Row>
                            {t('Fertiliser')}
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Label>{props.taskSpecificData.products[1].productDesignator}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control defaultValue={props.taskSpecificData.products[1].averageAmount} />
                            </Col>
                            <Col>
                                <Form.Label>{props.taskSpecificData.products[1].unitDesignator}</Form.Label>
                            </Col>
                        </Form.Row>
                    </Form>
                    <Form>
                        <Form.Row>
                            {t('Seed')}
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Label>{props.taskSpecificData.products[0].productDesignator}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control defaultValue={props.taskSpecificData.products[0].averageAmount} />
                            </Col>
                            <Col>
                                <Form.Label>{props.taskSpecificData.products[0].unitDesignator}</Form.Label>
                            </Col>
                        </Form.Row>
                    </Form>
                </>
            );
        } else {
            return (
                <Form>
                    <Form.Row>
                        {t(taskTypeTextMapping[props.taskSpecificData.taskType])}
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>{props.taskSpecificData.products[0].productDesignator}</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control defaultValue={props.taskSpecificData.products[0].averageAmount} />
                        </Col>
                        <Col>
                            <Form.Label>{props.taskSpecificData.products[0].unitDesignator}</Form.Label>
                        </Col>
                    </Form.Row>
                </Form>
            );
        }   
    }

    return (
        <Modal centered onHide={() => props.returnCallback(taskViewer_stopComponents.stopSelectionForm)}>
            <Modal.Header>
                <Modal.Title>{t('Plot')}: {props.taskSpecificData.plotDesignator}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {createFormElements()}
                <Form>
                    <Form.Row>-</Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>{t('DateLong')}</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control defaultValue="DD/MM/YYYY" />
                        </Col>
                        <Col>
                            <Button>TODO Calendar</Button>
                        </Col>
                    </Form.Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ListGroup horizontal>
                    <ListGroup.Item
                        onClick={() => props.returnCallback(taskViewer_stopComponents.stopSelectionForm)}
                        variant={"danger"}
                        style={{ border: "none" }}
                    >
                        {t('Back')}
                    </ListGroup.Item>
                    <ListGroup.Item
                        onClick={() => props.returnCallback(taskViewer_stopComponents.endTaskFinished)}
                        variant={"success"}
                        style={{ border: "none" }}
                    >
                        {t('Pass')}
                    </ListGroup.Item>
                    <ListGroup.Item
                        onClick={() => props.returnCallback(taskViewer_stopComponents.showDataForFinishedTask)}
                        variant={"success"}
                        style={{ border: "none" }}
                    >
                        {t('Continue')}
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Footer>
        </Modal>
    );
}

export default EndFinishedTask;