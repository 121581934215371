import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Stroke, Style } from 'ol/style';

// From database
export const addMapRoute = (routeGeometry) => {
    let routeStyle = new Style({
        stroke: new Stroke({
            color: 'black',
            lineDash: [3],
            width: 5
        })
    });

    let features = [];
    if (routeGeometry) {
        features = new GeoJSON().readFeatures(routeGeometry, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        })
    }

    let vectorSource = new VectorSource({
        features: features
    });

    let vectorLayer = new VectorLayer({
        source: vectorSource,
        style: routeStyle,
        zIndex: 1
    });

    return vectorLayer;
};